export const cdkEnvironment = {
  production: true,
  BACK_URL: "https://fk6qbi152a.execute-api.us-west-2.amazonaws.com/",
  MMP: "mmp/",
  V1: "",
  V2: "v2/",
  V3: "v3/",
  stage: "dev/",
  apiKey: "xq4xaWwbjt6Vq9WzyTDqQ3zzn90Ix6wr2INv2k5W",
};
