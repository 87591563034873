import { Component, Inject, Input } from '@angular/core';
import { deviceTable } from 'src/app/shared/movano-interfaces';
import * as lit from '../../shared/movano-literals';
import { DarkModeService } from '../../dark-mode.service';
import { Subscription } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-user-device-table',
  templateUrl: './user-device-table.component.html',
  styleUrls: ['./user-device-table.component.scss']
})
export class UserDeviceTableComponent {

  protected devices : deviceTable[] = [];
  protected LITERALS = lit;
  protected loadingUserDeviceList : boolean = true;
  protected batteryColors : string[] = ['#E31482', '#FF830D', '#00B377'];
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();


  constructor(private darkModeSvc: DarkModeService, protected movanoService: MovanoService,
              protected dialogRef: MatDialogRef<UserDeviceTableComponent>,
              @Inject(MAT_DIALOG_DATA) protected data: { user: string })
    {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    this.getUserDeviceList();
  }

  async getUserDeviceList() {
    this.loadingUserDeviceList = true;
    this.movanoService.getDevicesByCustomerUUIDorDeviceSerial(this.data.user).subscribe((res: any) => {
      this.devices = res.data;
      this.loadingUserDeviceList = false;
      console.log(this.devices);
    });
  }

  protected closePanel() {
    this.dialogRef.close();
  }
}
