import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stats-new-users',
  templateUrl: './stats-new-users.component.html',
  styleUrls: ['./stats-new-users.component.scss']
})
export class StatsNewUsersComponent {
  @Input() newUsers: string = '';
  @Input() newUsersDif: number = 0;
  @Input() dynamicText: string = '';
  @Input() dayAverage: string = '';
  @Input() moreNewUser: boolean = true;
}
