<div *ngIf="!loadingData" class="movanoStats">
  <div class="movanoStats_Components">
    <div class="movanoStats_TotalBoxes">
      <div class="movanoTotalInfo" [ngClass]="{ movanoTotalInfo_Dark: darkMode }">
        <div class="movanoTotalInfo_TxtBlock">
          <span class="movanoTotalInfo_TxtBlock_Tittle--Main">{{totalDevices}}</span>
          <div class="movanoTotalInfo_TxtBlock_Txt">
            <span>total</span>
            <span>devices</span>
          </div>
        </div>
      </div>
      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_ActiveUsers" style="font-size: 40px;">{{devicesSyncs}}</span>
        <div class="movanoCarousel_Element_Info">
          <span>rings active</span>
          <span>{{dynamicText}}</span>
        </div>
        <div class="movanoCarousel_Element_Arrows">
          <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
              [ngStyle]="{fill : moreNewRings ? '#00FFAA' : '#C4D9EE'}" />
          </svg>
          <svg width="max(0.833vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
              [ngStyle]="{fill : moreNewRings ? '#C4D9EE' : '#E22B2B'}" />
          </svg>
        </div>
        <div class="movanoCarousel_Element_LastTxt">
          <span class="movanoCarousel_Element_LastTxt_More">{{ringsSoldDif < 0 ? -ringsSoldDif : ringsSoldDif}} {{moreNewRings ? 'more' : 'less'}}</span>
          <span class="movanoCarousel_Element_LastTxt_Pre">than the last</span>
        </div>

        <div class="vertical-line"></div>

        <div style="display: flex; flex-direction: column">
          <div class="men_stats">
            <span class="men_stats_title"> {{menPerc}}%</span>
            <svg width="0.2em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
                [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#0B84FF' }" />
            </svg>
          </div>

          <span class="men_stats_subtitle" [ngStyle]="{ color: darkMode ? '#0DCCFF' : '#0B84FF' }">{{men}}Men</span>
        </div>

        <div class="vertical-line"></div>
        <div style="display: flex; flex-direction: column">
          <div class="women_stats">
            <span class="women_stats_title"> {{womenPerc}}% </span>
            <img style="width: 0.2em" src="../../assets/women_Icon.svg" />
          </div>
          <span class="women_stats_subtitle">{{women}} women</span>
        </div>
      </div>

      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_ActiveUsers">
          {{appVersion}}<span class="faded-text">/{{mostUsed}}</span>
        </span>
        <div class="movanoCarousel_Element_Info">
          <span>firmware <span class="faded-text">/app version</span></span>
          <span>most used</span>
        </div>
      </div>
      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_UnderRepair"> {{avChargeTime}}h </span>
        <div class="movanoCarousel_Element_Info">
          <span>average</span>
          <span>charging time</span>
          <span>{{dynamicText}}</span>
        </div>
      </div>
      <div class="movanoStats_Middle_Activity_Wrapper">
        <div class="movanoStats_Components_Middle">
          <app-stats-widget-chart [dashboardType]="'Sales'"></app-stats-widget-chart>
        </div>
        <!-- <div class="movanoStats_Components_Battery">
          <span style="font-size: 14px; font-weight: 500; position: absolute">Battery level</span>
          <div class="movanoStats_Components_Battery_Grid">
            <div>
              <span style="font-size: 12px; font-weight: 400; padding-bottom: 15px">Time between charges</span>
              <div style="display: flex; padding-top: 10px; align-items: center">
                <div class="movanoStats_CharginChart">
                  <div class="movanoStats_CharginChart_Bar" [ngStyle]="{ bottom: '3.22em' }"></div>
                </div>
                <div class="movanoStats_LegendUser" style="gap: 0.45em; display: flex; flex-direction: column">
                  <div class="battery_first">
                    <span>Max</span>
                    38 slope
                  </div>
                  <div class="battery_second">
                    <span style="font-weight: 400; font-size: 10px">Average</span>
                    28 slope
                  </div>
                  <div class="battery_third">
                    <span style="font-weight: 400; font-size: 10px">Min</span>
                    5 slope
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span style="font-size: 12px; font-weight: 400; padding-bottom: 15px">Distribution slope</span>
            </div>
          </div>
        </div> -->
        <!-- <div class="movanoStats_Components_Map">
          <app-stats-map [templateName]="'mapDevices'"></app-stats-map>
        </div> -->
        <div class="movanoDashboard_Components_ActivityStats">
          <app-stats-devices-last [darkMode]="darkMode" ></app-stats-devices-last>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loadingData" style="position: absolute; top: 50%; left: 50%">
  <p-progressSpinner
    [style]="{ width: '6vw', height: '6vw' }"
    styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
    strokeWidth="2"
    animationDuration="1.5s"
  >
  </p-progressSpinner>
</div>
