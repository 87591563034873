import { Component } from '@angular/core';

@Component({
  selector: 'app-vip-tap',
  templateUrl: './vip-tap.component.html',
  styleUrls: ['./vip-tap.component.scss']
})
export class VipTapComponent {

}
