import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import * as lit from '../../shared/movano-literals';
import { userTable } from 'src/app/shared/movano-interfaces';
import { Table } from 'primeng/table';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit, OnDestroy{

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  //users: userTable[] = [];
  filter_Male: boolean = false;
  filter_Female: boolean = false;  
  protected filter_Country ?: string;
  protected filter_registerDate : Date[] = [];
  protected filter_lastSync : Date[] = [];
  @Input() today ?: Date;
  @Input() table !: Table;
  @Input() filteredUsers: userTable[] = [];
  @Input() isFiltered: boolean = false;
  protected selectedStatuses: string[] = [];
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
  @Output() filterStatusChange = new EventEmitter<boolean>();
  @Output() dateFilterApplied = new EventEmitter<void>();
  
  

  @Output() closeHimself = new EventEmitter<void>();
  // @Output() applyFilter = new EventEmitter<void>();

  constructor(
    private darkModeSvc: DarkModeService) { }

  ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }
  
  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85; 
  
    checkboxDivs.forEach((cb:HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    checkbox.forEach((cb:HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }


  applyFilter() {
    this.table.reset();
    let genderFilter = [];
    if (this.filter_Female) genderFilter.push('F');
    if (this.filter_Male) genderFilter.push('M');

    if (genderFilter.length > 0) {
        this.table.filter(genderFilter, 'gender', 'in');
    }

    if (this.filter_Country) this.table.filter(this.filter_Country, 'country', 'equals');

    if (this.filter_registerDate.length === 2 && this.filter_registerDate[0] && this.filter_registerDate[1]) {
  
      const startDate = this.formatDate(this.filter_registerDate[0]);
      const endDate = this.formatDate(this.filter_registerDate[1]);
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);
      this.table.filter(startDate, 'register_date', 'gte');
      this.table.filter(endDate, 'register_date', 'lte');

 
    }
    
    if (this.filter_lastSync.length === 2 && this.filter_lastSync[0] && this.filter_lastSync[1]) {
      
      const startDate = this.formatDate(this.filter_lastSync[0]);
      const endDate = this.formatDate(this.filter_lastSync[1]);
      this.table.filter(startDate, 'last_sync', 'gte');
      this.table.filter(endDate, 'last_sync', 'lte');
      console.log(startDate);
      console.log(endDate);
  
    }

    const filteredData = this.table.filteredValue || this.table.value;  
    this.dateFilterApplied.emit();
    this.checkIfFiltered();
  }

  checkIfFiltered() {
    const hasActiveFilter = this.filter_Female || this.filter_Male|| this.filter_registerDate.length > 0 || this.filter_lastSync.length > 0 || this.selectedStatuses.length > 0;
    this.isFiltered = hasActiveFilter;
  }


  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }  
  

  resetFilter() {
    this.table.reset();
    this.filter_Female = false;
    this.filter_Male = false;
    this.filter_Country = undefined;
    this.filter_registerDate = [];
    this.filter_lastSync = [];
    this.checkboxes.forEach(checkbox => checkbox.checked = false);
    this.table.filter('', 'register_date', 'gte');
    this.table.filter('', 'register_date', 'lte');
    this.table.filter('', 'last_sync', 'gte');
    this.table.filter('', 'last_sync', 'lte'); 
    this.table.filter('', 'gender', 'in');
    this.table.filter('', 'country', 'equals')
    

    this.filterStatusChange.emit(false);
  }
}





