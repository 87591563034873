<div class="movanoHeader" [ngClass]="{'movanoDark': darkMode}">
    <div class="movanoHeader_Tittle">
      <svg
      class="movanoHeader_Tittle_btn"
      width="max(1.3889vw, 20px)" height="max(1.11vw, 16px)"
      (click)="menuDisplay = true" viewBox="0 0 20 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="Group 567">
        <path id="Vector 19" d="M0 6.5L14.4375 6.5" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
        <path id="Vector 21" d="M0 1L14.4375 0.999999" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
        <path id="Vector 20" d="M0 12L8.25 12" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"/>
        </g>
      </svg>

      <span class="movanoHeader_Tittle_txt">{{title}}</span>
    </div>
    <div class="movanoHeader_Logo">
      <img class="movanoHeader_Logo_IMG"
        src="{{ LITERALS.MOVANO.IMG_HEADER_DARK}}">
  <!--     <span class="movanoHeader_Version">v{{LITERALS.MOVANO.VERSION}}</span> -->
    </div>
    <div class="movanoHeader_Functions">
      <div class="movano_switch" style="position: relative; ">
   <!--      <label class="switch">
          <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
          <span class="slider round"></span>
        </label> -->
      </div>
  <!--     <svg class="movanoHeader_Functions_Icon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
          [ngStyle]="{fill : darkMode ? '#FFFFFF' : 'var(--main-blue-dark)', boxShadow: darkMode ? '0px 0px  5px rgba(255, 255, 255, 0.5)' : 'none'}" />
      </svg> -->

      <div (click)="userDisplay = true" class="movanoHeader_userIcon">
        <svg width="max(1.18vw, 17px)" height="max(1.32vw, 19px)" viewBox="0 0 17 19" fill="#FFFFFF"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
        </svg>
      </div>
    </div>
  </div>
  <p-sidebar styleClass="menu-left" [modal]="false" [(visible)]="menuDisplay" position="left">
    <app-features-tab (closeHimself)="menuDisplay = false"></app-features-tab>
  </p-sidebar>

  <p-sidebar styleClass="menu-filter" [modal]="false" [(visible)]="filterDisplay" (onShow)="onResize()" position="left">
    <app-user-filter (closeHimself)="filterDisplay = false" [table]="table" [today]="today"></app-user-filter>
  </p-sidebar>

  <p-sidebar styleClass="user-menu" [(visible)]="userDisplay" position="right">
    <app-user-menu (closeHimself)="userDisplay = false"></app-user-menu>
  </p-sidebar>
