import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from "@angular/core";
import * as lit from "../shared/movano-literals";
import { Table } from "primeng/table";
import { MatDialog} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MovanoService } from "../movano.service";
import { MainServiceService } from "../main-service.service";
import { adminUsers } from "../shared/movano-interfaces";
import { DarkModeService } from "../dark-mode.service";
import { lastValueFrom, Subscription } from "rxjs";
import { CreateEditAdminUserComponent } from "../components/dialogs/create-edit-admin-user/create-edit-admin-user.component";


@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent {
  protected LITERALS = lit;
  protected selectedDate: number = 0;
  protected lastselectedDate: number = 0;
  protected dataAvailable: boolean = false;
  protected timeZones: string[] = [];
  protected loadingData: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected actualTimeZone: string = "";
  protected valueSearch: string = "";

  private darkModeSub: Subscription = new Subscription();

  protected darkMode: boolean = false;

  protected adminUsersTimeframeTotal: number = 0;
  protected adminUsersTotal: number = 0;

  protected loadingAdminUsersList:  boolean = true;

  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 50;

  protected actualPage: number = 1;
  protected totalPages: number = 0;
  protected lastTimeFrameUsed: string | undefined = "";
  protected maxPagesToShow = 5;

  protected adminUsers: adminUsers[] = [];


  protected sortedBy: string = "firstName";
  protected sortMode: string = "desc";

  filteredAdminUsers: adminUsers[] = [];

  protected today?: Date;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();


  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService,
    private renderer: Renderer2,
    private el: ElementRef,
    private dialogCommunicationService: MainServiceService
  ) {
    this.today = new Date();
  }

  async ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    await this.getUserDetails();
    this.emitGetInfo();
    //await this.getUserList();
    let tableElement = document.getElementsByClassName("p-datatable-wrapper")[0];
    let scrollHelper = document.getElementById("mobileScroll");
    let scrollHelperLeft = document.getElementById("mobileScroll_Left");
    if (tableElement && scrollHelper) {
      tableElement.addEventListener("scroll", function () {
        if (tableElement.scrollLeft > tableElement.scrollWidth - tableElement.clientWidth - 15) {
          scrollHelper!.style.display = "none";
        } else {
          scrollHelper!.style.display = "block";
        }

        if (tableElement.scrollLeft > 15) {
          scrollHelperLeft!.style.display = "block";
        } else {
          scrollHelperLeft!.style.display = "none";
        }
      });
    } else {
      // console.log(tableElement);
      // console.log(scrollHelper);
    }
    this.dialogCommunicationService.organizationUpdated$.subscribe(() => {
      this.loadData();
    });
  }

  async getUserDetails() {
    const user = await this.movanoService.getUser();
    if (!user) {
      this.router.navigate(["login"]);
    }
  }

  goTo(_place: string) {
    this.router.navigate([_place]);
  }

  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.getInfo.emit(calendarData);
    this.loadData(calendarData);
  }

  async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.loadingAdminUsersList = true;

    if (calendarData) {
      this.lastselectedDate = this.selectedDate;

      this.selectedDate = parseInt(calendarData[0]);

      if (this.selectedDate !== 3) {
        this.daySelected = calendarData[2] as Date;
      } else {
        this.rangeSelected = calendarData[2] as Date[];
      }

      this.actualTimeZone = calendarData[3];
    } else {
      this.selectedDate = 5;

      this.actualTimeZone = this.timeZones ? this.timeZones[0] : "";
    }

    let organizationsTimeFrame: string | undefined = this.lastTimeFrameUsed || "";

    if (calendarData) {
      if (this.selectedDate === 1) {
        organizationsTimeFrame = "last7Days";
      } else if (this.selectedDate === 2) {
        organizationsTimeFrame = "lastMonth";
      } else if (this.selectedDate === 6) {
        organizationsTimeFrame = "lastWeek";
      } else if (this.selectedDate === 4) {
        organizationsTimeFrame = "custom";
      } else if (this.selectedDate === 0) {
        organizationsTimeFrame = "today";
      } else {
        organizationsTimeFrame = "";
      }

      if (this.lastTimeFrameUsed !== organizationsTimeFrame) {
        this.actualPage = 1;
      }
      this.lastTimeFrameUsed = organizationsTimeFrame;
    }

    try {
      const response = await lastValueFrom(
        this.movanoService.getAdminUsers(
          this.rows,
          this.actualPage,
          organizationsTimeFrame,
          this.sortedBy,
          this.sortMode,
        ),
      );

      this.totalPages = response.pageCount;
      this.adminUsers = response.data;
      this.adminUsersTimeframeTotal = response.usersCount;
      this.adminUsersTotal = response.adminUsersTotal;
      this.loadingAdminUsersList = false;
      this.filteredAdminUsers = this.adminUsers;
      this.dataAvailable = true;
      // if (this.valueSearch) {
      //   console.log('Searching')
      //   this.totalPages = organizationsData.pageCount;
      //   this.organizations = organizationsData.data;
      //   this.filteredOrganizations = this.organizations;
      //   this.dataAvailable = true;
      // }
    } catch (error) {
      console.log(error);
      this.dataAvailable = false;
    } finally {
      this.loadingData = false;
    }
  }

  getBadgeColor(status: string): string {

    status = status.trim().toLowerCase();

    if (status === "active") {
      return "movanoTable_Obj_Badge_blueDark";
    } else if (status === "deleted") {
      return "movanoTable_Obj_Badge_Deleted";
    } else if (status === "inactive") {
      return "movanoTable_Obj_Badge_Inactive";
    } else if (status === "blocked") {
      return "movanoTable_Obj_Badge_Blocked";
    } else {
      return status;
    }
  }

  handleOrganizationClick(adminUser: adminUsers) {
    if (adminUser.isSelected) {
      this.adminUsers.forEach((adUs) => (adUs.isSelected = false));
    } else {
      this.adminUsers.forEach((adUs) => (adUs.isSelected = false));
      adminUser.isSelected = true;
    }

    // this.dialog.open(ChangeViewOrganizationComponent, {
    //   width: "364px",
    //   height: "293px",
    // });
  }

  createAdminUser() {
    const dialogRef = this.dialog.open(CreateEditAdminUserComponent, {
      width: "356px",
      data: { isEditMode: false },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadData();
    });
  }

  editAdminUser(adminUser: adminUsers) {
    if (!adminUser.isEdit) {
      const dialogRef = this.dialog.open(CreateEditAdminUserComponent, {
        width: "356px",
        data: { isEditMode: true, adminUserData: adminUser  },

      });
      dialogRef.afterClosed().subscribe(() => {
        this.loadData();
      });
    }
  }

  recoverAdminUser(adminUser: adminUsers) {

      //  this.dialog.open(ActionConfirmationDialogComponent, {
      //     width: '356px',
      //     data: { isRecoverQuestionMode: true, organizationData: adminUser },
      //   });
  }

  toggleSort(column: string) {
    if (this.sortedBy === column) {
      this.sortMode = this.sortMode === "asc" ? "desc" : "asc";
    } else {
      this.sortedBy = column;
      this.sortMode = "desc";
    }

    this.loadData();
  }

  getDisplayedPages(): number[] {
    const pages: number[] = [];

    let startPage = Math.max(1, this.actualPage - Math.floor(this.maxPagesToShow / 2));
    let endPage = Math.min(this.totalPages, startPage + this.maxPagesToShow - 1);

    if (endPage - startPage + 1 < this.maxPagesToShow) {
      startPage = Math.max(1, endPage - this.maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  nextPage() {
    if (this.actualPage < this.totalPages) {
      this.actualPage++;
      this.loadData();
    }
  }

  selectPage(page: number) {
    this.actualPage = page;
    this.loadData();
  }

  previousPage() {
    if (this.actualPage > 1) {
      this.actualPage--;
      this.loadData();
    }
  }

  isFirstPageInRange(): boolean {
    return this.getDisplayedPages().includes(1);
  }

  isLastPageInRange(): boolean {
    return this.getDisplayedPages().includes(this.totalPages);
  }

  shouldShowLeftEllipsis(): boolean {
    return this.actualPage > Math.ceil(this.maxPagesToShow / 2) + 1;
  }

  shouldShowRightEllipsis(): boolean {
    return this.actualPage < this.totalPages - Math.floor(this.maxPagesToShow / 2);
  }


  onResize() {
    const checkboxDivs = document.querySelectorAll(".movano_checkBox") as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll("mat-checkbox") as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85;

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }


  exportCSV() {
    if (this.adminUsers && this.adminUsers.length > 0) {
      const csvData = this.convertToCSV(this.adminUsers);
      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "organizations.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("No data to export");
    }
  }

  convertToCSV(objArray: any[]): string {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "";

    for (const index in array[0]) {
      if (array[0].hasOwnProperty(index)) {
        // Now convert each value to a string
        const header = index + ",";
        row += header;
      }
    }

    row = row.slice(0, -1);
    str += row + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (const index in array[i]) {
        if (array[i].hasOwnProperty(index)) {
          if (line !== "") line += ",";
          line += array[i][index];
        }
      }

      str += line + "\r\n";
    }
    return str;
  }

}
