import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {  organizationPatchData, organizationPostData } from 'src/app/shared/movano-interfaces';
import { ActionConfirmationDialogComponent } from '../action-confirmation-dialog/action-confirmation-dialog.component';
import { DeleteOrganizationDialogComponent } from '../delete-organization-dialog/delete-organization-dialog.component';
import { MovanoService } from 'src/app/movano.service';

@Component({
  selector: 'app-create-edit-organization-dialog',
  templateUrl: './create-edit-organization-dialog.component.html',
  styleUrls: ['./create-edit-organization-dialog.component.scss']
})
export class CreateEditOrganizationDialogComponent {

  protected isEditMode: boolean = false;
  organizationData: organizationPostData = {
    id:'',
    logoUrl: '',
    organizationName: '',
    contactName: '',
    contactPhone: 0,
    contactEmail: '',
    website: '',
    creationDate: new Date(),
    status: 'active',
  };
  logoImage: string | ArrayBuffer | null = null;

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateEditOrganizationDialogComponent>,
    protected movanoService: MovanoService,
    @Inject(MAT_DIALOG_DATA) public data: { isEditMode: boolean; organizationData?: any }
  ) {
    this.isEditMode = data.isEditMode;
    this.organizationData = {
      ...this.organizationData,
      ...data.organizationData,
      organizationName: data.organizationData?.name || '',
    };
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }
  onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.loadImage(file);
    }
  }

  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      this.loadImage(input.files[0]);
    }
  }

  private loadImage(file: File): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.logoImage = reader.result;
      this.organizationData.logoUrl = this.logoImage as string; // Guarda el archivo en la propiedad de datos
    };
    reader.readAsDataURL(file); // Convierte la imagen a base64 para mostrarla
  }

  getBadgeColor(status: string): string {
    status = status.trim().toLowerCase();

    if (status === "active") {
      return "movanoTable_Obj_Badge_blueDark";
    } else if (status === "deleted") {
      return "movanoTable_Obj_Badge_Deleted";
    } else if (status === "inactive") {
      return "movanoTable_Obj_Badge_Inactive";
    } else if (status === "blocked") {
      return "movanoTable_Obj_Badge_Blocked";
    } else {
      return status;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createOrganization(): void {
    this.movanoService.postOrganization(this.organizationData).subscribe({
      next: () => {
        this.dialogRef.close();

        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: false }
        });
      },
      error: (err) => {
        console.error('Error al crear la organización:', err);
      }
    });
  }

  updateOrganization(): void {
    if (!this.organizationData?.id) return;

    let organizationStatus;
    switch (this.organizationData?.status.trim().toLowerCase()) {
      case "active":
        organizationStatus = 1;
        break;
      case "inactive":
        organizationStatus = 2;
        break;
      case "deleted":
        organizationStatus = 3;
        break;
      case "blocked":
        organizationStatus = 4;
        break;
      default:
        organizationStatus = 1;
        break;
    }

    const updatedData: organizationPatchData = {
      id: this.organizationData?.id,
      logoUrl: this.logoImage as string,
      organizationName: this.organizationData?.organizationName,
      contactName: this.organizationData?.contactName,
      contactPhone: this.organizationData?.contactPhone,
      contactEmail: this.organizationData?.contactEmail,
      website: this.organizationData?.website,
      creationDate: this.organizationData?.creationDate,
      organizationStatusId: organizationStatus,
    };


    this.movanoService.patchOrganization(updatedData, this.organizationData.id).subscribe({
      next: () => {
        this.dialogRef.close();
        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: true }
        });
      },
      error: (err) => {
        console.error('Error al actualizar la organización:', err);
      }
    });
  }

  deleteOrganization(): void {
    this.dialogRef.close();
    this.dialog.open(DeleteOrganizationDialogComponent, {
      width: '364px',
      data: {
        isDeletedMode: true, organization: this.organizationData
      }
    });
  }
}
