<div class="container">
  <div [ngStyle]="{ display: 'flex', 'justify-content': 'end', position: 'relative', left: '20px', top: '-5px', opacity: '0.5' }">
    <mat-icon (click)="closeDialog()" [ngStyle]="{ cursor: 'pointer' }">close</mat-icon>
  </div>
  <p class="title">{{ isEditMode ? "Edit organization" : "New organization" }}</p>

  <div class="form-group">
    <!-- <p>Logo</p>
    <div class="image-upload" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (click)="fileInput.click()">
      <input type="file" #fileInput (change)="onFileChange($event)" accept="image/*" style="display: none" />
      <p *ngIf="!logoImage">Drag & drop an image here or click to select a file</p>
      <img *ngIf="logoImage" [src]="logoImage" alt="Logo Preview" class="image-preview" />
    </div> -->

    <p>Organization name</p>
    <input type="text" placeholder="Write a name" [(ngModel)]="organizationData.organizationName" required pattern="^[A-Za-zÀ-ÿ\s]{2,50}$" />

    <p *ngIf="isEditMode">Status</p>
    <div *ngIf="isEditMode" class="status-options">
      <label class="custom-radio" *ngFor="let status of ['active', 'inactive']">
        <input type="radio" [(ngModel)]="organizationData.status" [value]="status" required />
        <span class="checkmark">✔</span>
        <div class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(status.toLowerCase())">
          {{ status }}
        </div>
      </label>
    </div>

    <p>Contact name</p>
    <input type="text" placeholder="Write a name" [(ngModel)]="organizationData.contactName" required pattern="^[A-Za-zÀ-ÿ\s]{2,50}$" />

    <p>Phone</p>
    <input type="text" placeholder="xxxxxxxxxxx" [(ngModel)]="organizationData.contactPhone" required pattern="^\d{7,15}$" />

    <p>Email</p>
    <input type="email" placeholder="xxx@xxx.com" [(ngModel)]="organizationData.contactEmail" required />

    <p>Website</p>
    <input type="text" placeholder="Write the website" [(ngModel)]="organizationData.website" required pattern="^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\/\S*)?$" />
  </div>
<div style="margin-bottom: 2.5vh;">
  <app-main-button
    [label]="isEditMode ? 'Save changes' : 'Add organization'"
    [fullWidthMarginBottom]="true"
    (click)="isEditMode ? updateOrganization() : createOrganization()"
    style="margin-bottom: 10px"
    [disabled]="!organizationData.organizationName || !organizationData.contactName || !organizationData.contactPhone || !organizationData.contactEmail || !organizationData.website"
  ></app-main-button>

  <app-main-button
    *ngIf="isEditMode"
    label="Delete organization"
    [fullWidth]="true"
    color="red"
    (click)="deleteOrganization()"
  ></app-main-button>
</div>
</div>
