<div class="movanoStats" #userStats [ngClass]="{ movanoStats_Dark: darkMode }">
  <span class="movanoStats_Tittle">Distribution</span>

  <div class="movanoStats_DognutStats">
    <span [ngStyle]="{ color: darkMode ? '#7797B8' : '#284767' }" style="font-weight: 400; font-size: 78%"
      >Total users</span
    >

    <div
      echarts
      [options]="option_Gender ?? option_DonutEmpty"
      style="height: 9em; width: 9em; padding-top: 10px"
    ></div>
    <div class="movanoStats_DognutStats_Percentage">
      <span [ngStyle]="{ color: this.getGenderColor(0) }" style="font-size: 180%; font-weight: 700"
        >{{ this.genderStats.data.total_users.gender.woman.percentage | number : "1.0-0" }}%</span
      >
      <div style="display: flex; gap: 0.33em">
        <span
          [ngStyle]="{ color: this.getGenderColor(1) }"
          style="font-size: 100%; font-weight: 400"
          *ngIf="genderPercentageArray.length > 1"
          >{{ this.genderStats.data.total_users.gender.man.percentage | number : "1.0-0" }}%</span
        >
        <!--     <span [ngStyle]="{color: this.getGenderColor(2)}" style="font-size: 100%; font-weight: 400;"
                *ngIf="genderPercentageArray.length>2">{{this.getGenderPercentage(2) | number: '1.0-0'}}</span> -->
      </div>
    </div>
    <!--    <div style=" display: flex; flex-direction: column; justify-content: center; padding-left: 20px;">
          <span [ngStyle]="{color: this.getGenderColor(0)}"
          style="font-size: movanoResize(16); font-weight: 700;">{{this.getGenderPercentage(0) | number: '1.0-0'}}% </span>
          <div style="display: flex; gap: 0.33em;">
            <span [ngStyle]="{color: this.getGenderColor(1)}" style="font-size: movanoResize(16); font-weight: 700;"
              *ngIf="genderPercentageArray.length>1">{{this.getGenderPercentage(1) | number: '1.0-0'}} % men </span>
          </div>
        </div> -->
  </div>

  <div class="movanoStats_LegendUser" style="gap: 3.5em; position: relative; bottom: 0.5em">
    <div style="display: flex; flex-direction: column; gap: 2.5em; padding-top: 30px; transform: scale(0.8)">
      <div style="display: flex; gap: 1.5em; align-items: center">
        <!-- <img style="width: 1.6em;" src="../../assets/men_Icon.svg"> -->

        <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
            [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#0B84FF' }"
          />
        </svg>
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span
            [ngStyle]="{ color: this.getGenderColor(1) }"
            style="font-size: movanoResize(16); font-weight: 700"
            *ngIf="genderPercentageArray.length > 1"
            >{{ this.genderStats.data.total_users.gender.man.percentage }} %
          </span>

          <span [ngStyle]="{ color: darkMode ? '#0DCCFF' : '#0B84FF' }" style="font-size: 100%; font-weight: 500"
            >{{ this.genderStats.data.total_users.gender.man.count }} Men</span
          >
        </div>
      </div>
      <div style="display: flex; gap: 1.5em; color: #0b84ff; align-items: center">
        <img style="width: 1.6em" src="../../assets/women_Icon.svg" />
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span [ngStyle]="{ color: this.getGenderColor(0) }" style="font-size: movanoResize(16); font-weight: 700"
            >{{ this.genderStats.data.total_users.gender.woman.percentage }}%
          </span>
          <span style="color: #b01065; font-size: 100%; font-weight: 500"
            >{{ this.genderStats.data.total_users.gender.woman.count }} Women</span
          >
        </div>
      </div>
      <div style="display: flex; gap: 1.5em; align-items: center">
        <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
            [ngStyle]="{ fill: darkMode ? '#d9d9d9' : '#646464' }"
          />
        </svg>
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span
            [ngStyle]="{ color: darkMode ? '#d9d9d9' : '#646464' }"
            style="font-size: movanoResize(16); font-weight: 700"
            *ngIf="genderPercentageArray.length > 1"
            >{{ this.genderStats.data.total_users.gender.other.percentage }} %
          </span>

          <span [ngStyle]="{ color: darkMode ? '#d9d9d9' : '#646464' }" style="font-size: 100%; font-weight: 500"
            >{{ this.genderStats.data.total_users.gender.other.count }} Other</span
          >
        </div>
      </div>
      <div style="display: flex; gap: 1.5em; align-items: center">
        <div style="font-size: 1.6em; color: #424242; font-weight: 700">Unknown</div>
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span
            [ngStyle]="{ color: '#424242' }"
            style="font-size: movanoResize(16); font-weight: 700"
            *ngIf="genderPercentageArray.length > 1"
            >{{ this.genderStats.data.total_users.gender.unkown.percentage }} %
          </span>

          <span [ngStyle]="{ color: '#424242' }" style="font-size: 100%; font-weight: 500"
            >{{ this.genderStats.data.total_users.gender.unkown.count }} Unknown</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="movanoStats_lastChart">
    <!-- <svg viewBox="0 0 2 98" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L1 97" stroke="#0B84FF" stroke-linecap="round" stroke-dasharray="4 4"/>
        </svg> -->
    <span [ngStyle]="{ color: darkMode ? '#7797B8' : '#284767' }" style="font-weight: 400; font-size: 78%"
      >Age Range</span
    >
    <p-chart
      type="bar"
      [data]="genderDataChart"
      [plugins]="[genderPrueba]"
      id="genderAgeChart"
      width="{{ innerWidth > 425 ? 'max(15vw, 250px)' : innerWidth - 50 + 'px' }}"
      height="10em"
      [options]="genderOptions"
    >
    </p-chart>

    <div class="movanoStats_lastChart_Axys" style="padding-top: 10px">
      <div class="movanoStats_lastChart_Axys_X">
        <span *ngFor="let age of xAxys">
          {{ age }}
        </span>
      </div>
    </div>
  </div>
  <div class="vertical-line"></div>
  <div class="movanoStats_DognutStats">
    <span [ngStyle]="{ color: darkMode ? '#7797B8' : '#284767' }" style="font-weight: 400; font-size: 78%"
      >Active users</span
    >

    <div
      echarts
      [options]="option_Active ?? option_DonutEmpty"
      style="height: 9em; width: 9em; padding-top: 10px"
    ></div>
    <div class="movanoStats_DognutStats_Percentage">
      <span style="font-size: 180%; font-weight: 700; color: #00b377">{{ this.activePercentage }}%</span>
      <div style="display: flex; gap: 0.33em">
        <span style="font-size: 100%; font-weight: 400; color: #7797b8">{{ this.inactivePercentage }}%</span>
        <!--     <span [ngStyle]="{color: this.getGenderColor(2)}" style="font-size: 100%; font-weight: 400;"
               *ngIf="genderPercentageArray.length>2">{{this.getGenderPercentage(2) | number: '1.0-0'}}</span> -->
      </div>
    </div>
    <!--    <div style=" display: flex; flex-direction: column; justify-content: center; padding-left: 20px;">
         <span [ngStyle]="{color: this.getGenderColor(0)}"
         style="font-size: movanoResize(16); font-weight: 700;">{{this.getGenderPercentage(0) | number: '1.0-0'}}% </span>
         <div style="display: flex; gap: 0.33em;">
           <span [ngStyle]="{color: this.getGenderColor(1)}" style="font-size: movanoResize(16); font-weight: 700;"
             *ngIf="genderPercentageArray.length>1">{{this.getGenderPercentage(1) | number: '1.0-0'}} % men </span>
         </div>
       </div> -->
  </div>

  <div class="movanoStats_LegendUser" style="gap: 3.5em; position: relative; bottom: 0.5em">
    <div style="display: flex; flex-direction: column; gap: 2.5em; padding-top: 30px">
      <div style="display: flex; gap: 1.5em; align-items: center">
        <!-- <img style="width: 1.6em;" src="../../assets/men_Icon.svg"> -->

        <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
            [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#00B377' }"
          />
        </svg>
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span
            style="font-size: movanoResize(16); font-weight: 700; color: #00b377"
            *ngIf="genderPercentageArray.length > 1"
            >{{ this.activePercentage }}%
          </span>

          <span [ngStyle]="{ color: darkMode ? '#0DCCFF' : '#00B377' }" style="font-size: 100%; font-weight: 500"
            >{{ this.activeUsers }} active</span
          >
        </div>
      </div>
      <div style="display: flex; gap: 1.5em; color: #7797b8; align-items: center">
        <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
            [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#7797B8' }"
          />
        </svg>
        <div style="display: flex; flex-direction: column; font-size: 14px">
          <span style="font-size: movanoResize(16); font-weight: 700; color: #7797b8"
            >{{ this.inactivePercentage }}%
          </span>
          <span style="color: #7797b8; font-size: 100%; font-weight: 500">{{ this.inactiveUsers }} inactive</span>
        </div>
      </div>
    </div>
  </div>

  <div class="gender-active">
    <div class="gender-active-man">
      <div class="movanoStats_DognutStats">
        <span
          [ngStyle]="{ color: darkMode ? '#7797B8' : '#284767' }"
          style="font-weight: 400; font-size: 78%; padding-left: 50px"
          >Men/women Active</span
        >

        <div
          echarts
          [options]="option_Active_gender ?? option_DonutEmpty"
          style="height: 9em; width: 9em; padding-top: 10px"
        ></div>
        <div class="movanoStats_DognutStats_Percentage">
          <svg width="0.7em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
              [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#00B377' }"
            />
          </svg>
        </div>
      </div>
      <div class="movanoStats_LegendUser" style="gap: 3.5em; position: relative; bottom: 0.5em">
        <div style="display: flex; flex-direction: column; gap: 2.5em; padding-top: 25px">
          <div style="display: flex; gap: 1.5em; color: #7797b8; align-items: center">
            <div style="display: flex; flex-direction: column; font-size: 14px">
              <span style="font-size: movanoResize(16); font-weight: 700; color: #0b84ff"
                >{{ this.menActiveUsers }}%
              </span>
              <span style="font-size: movanoResize(16); font-weight: 700; color: #0b84ff"
                >{{ this.menActiveUsersUnits }} active
              </span>
              <span style="color: #b01065; font-size: 100%; font-weight: 500">{{ this.womenActiveUsers }}% </span>
              <span style="color: #b01065; font-size: 100%; font-weight: 500"
                >{{ this.womenActiveUsersUnits }} active
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gender-active-woman">
      <div class="movanoStats_DognutStats">
        <span [ngStyle]="{ color: 'white' }" style="font-weight: 400; font-size: 78%; padding-left: 50px"
          >Men/women Active</span
        >

        <div
          echarts
          [options]="option_Inactive_gender ?? option_DonutEmpty"
          style="height: 9em; width: 9em; padding-top: 10px"
        ></div>
        <div class="movanoStats_DognutStats_Percentage">
          <svg width="0.7em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
              [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#7797B8' }"
            />
          </svg>
        </div>
      </div>
      <div class="movanoStats_LegendUser" style="gap: 3.5em; position: relative; bottom: 0.5em">
        <div style="display: flex; flex-direction: column; gap: 2.5em; padding-top: 25px">
          <div style="display: flex; gap: 1.5em; color: #7797b8; align-items: center">
            <div style="display: flex; flex-direction: column; font-size: 14px">
              <span style="font-size: movanoResize(16); font-weight: 700; color: #0b84ff"
                >{{ this.menInactiveUsers }}%
              </span>
              <span style="font-size: movanoResize(16); font-weight: 700; color: #0b84ff"
                >{{ this.menInactiveUsersUnits }} inactive
              </span>
              <span style="color: #b01065; font-size: 100%; font-weight: 500">{{ this.womenInactiveUsers }}% </span>
              <span style="color: #b01065; font-size: 100%; font-weight: 500"
                >{{ this.womenInactiveUsersUnits }} inactive
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
