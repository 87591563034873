<div class="donutsContainer" style="display: flex; flex-direction: row; margin-bottom: 20px">
  <div class="responsiveScaleDonutsSales">
    <div class="inner-container">
      <div class="movanoStats" style="height: 200px; width: 500px;">
        <span *ngIf="front" class="movanoStats_Tittle"> % RMAs over total by ring size </span>
        <div class="sizeChart">
          <p-chart
            *ngIf="front"
            type="bar"
            [data]="sizeDataChart"
            id="sizeChart"
            width="450px"
            height="8em"
            [options]="barSize"
          >
          </p-chart>
        </div>
        <!-- Span 2 -->
        <span *ngIf="!front" class="movanoStats_Tittle"> % RMAs over total by ring color </span>
        <div class="colorChart">
          <p-chart
            *ngIf="!front"
            type="bar"
            [data]="colorDataChart"
            id="colorChart"
            width="22vw"
            height="8em"
            [options]="colorBarSize"
          >
          </p-chart>
        </div>
        <div class="movanoStats_Buttons">
          <button [disabled]="front" (click)="changeInfo()">
            <svg
              width="max(0.69vw, 10px)"
              height="max(0.83vw, 12px)"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
                [ngStyle]="{ fill: '#284767', opacity: front ? 0.5 : 1 }"
              />
            </svg>
          </button>

          <button [disabled]="!front" (click)="changeInfo()">
            <svg
              width="max(0.69vw, 10px)"
              height="max(0.83vw, 12px)"
              viewBox="0 0 10 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
                [ngStyle]="{ fill: '#284767', opacity: !front ? 0.5 : 1 }"
              />
            </svg>
          </button>
        </div>
      </div>

      <div class="movanoStats" style="height: 200px; width: 350px;">
        <span class="movanoStats_Tittle">Last month RMAs by ring size Distribution</span>
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 0.5em; padding-top: 3%">
            <div class="movanoStats_DognutStats">
              <div
                echarts
                [options]="option_SizeDistribution ?? option_DonutEmpty"
                style="height: 8em; width: 8em"
              ></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span style="font-size: 167%; font-weight: 700; color: '#061E37'"
                  >{{ sizeDistributionPer[0] | number : "1.0-0" }}%</span
                >
                <div style="display: flex; gap: 0.33em">
                  <span style="font-size: 100%; font-weight: 400"
                    >{{ sizeDistributionPer[1] | number : "1.0-0" }}%</span
                  >
                  <span style="color: #0b84ff; font-size: 100%; font-weight: 400"
                    >{{ sizeDistributionPer[2] | number : "1.0-0" }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 15%; margin-top: 1em; width: 200px">
          <div
            *ngFor="let sizeDistribution of sizeDistributionName; let i = index"
            style="display: flex; gap: 1.1em; color: #022e5c; align-items: center"
            [style.color]="getSizeDistributionColor(i)"
          >
            <div
              [style.background-color]="getSizeDistributionColor(i)"
              style="width: 1.5em; border-radius: 0.75em; height: 0.67em"
            ></div>
            Size {{ sizeDistribution || "Others" }}
          </div>
        </div>
      </div>

      <!-- SEGUNDO DONUT -->
      <div class="movanoStats" style="height: 200px; width: 350px;">
        <span class="movanoStats_Tittle">RMA by ring color</span>
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 0.5em; padding-top: 3%">
            <div class="movanoStats_DognutStats">
              <div
                echarts
                [options]="option_ColorDistribution ?? option_DonutEmpty"
                style="height: 8em; width: 8em"
              ></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span
                  style="font-size: 167%; font-weight: 700"
                  [style.color]="getColorFromName(colorDistributionName[0])"
                  >{{ colorDistributionPer[0] | number : "1.0-0" }}%</span
                >
                <div style="display: flex; gap: 0.33em">
                  <span
                    [style.color]="getColorFromName(colorDistributionName[1])"
                    style="font-size: 100%; font-weight: 400"
                    >{{ colorDistributionPer[1] | number : "1.0-0" }}%</span
                  >
                  <span
                    [style.color]="getColorFromName(colorDistributionName[2])"
                    style="font-size: 100%; font-weight: 400"
                    >{{ colorDistributionPer[2] | number : "1.0-0" }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px; margin-top: 2em">
          <div
            *ngFor="let colorDistribution of colorDistributionName; let i = index"
            style="display: flex; gap: 1.1em; color: #022e5c; align-items: center"
            [style.color]="getColorDistributionColor(i)"
          >
            <div
              [style.background-color]="getColorDistributionColor(i)"
              style="width: 1.5em; border-radius: 0.75em; height: 0.67em"
            ></div>
            Size {{ colorDistribution || "Others" }}
          </div>
        </div>
      </div>

      <!-- TERCER DONUT -->
      <div class="movanoStats" style="height: 200px; width: 340px;">
        <span class="movanoStats_Tittle">RMAs Distribution</span>
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 0.5em; padding-top: 3%">
            <div class="movanoStats_DognutStats">
              <div
                echarts
                [options]="option_rmasDistribution ?? option_DonutEmpty"
                style="height: 8em; width: 8em"
              ></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span style="font-size: 167%; font-weight: 700; color: '#e31482'"
                  >{{ rmasSalesDistributionPer[0] | number : "1.0-0" }}%</span
                >
                <div style="display: flex; gap: 0.33em">
                  <span style="color: #7797b8; font-size: 100%; font-weight: 400"
                    >{{ rmasSalesDistributionPer[1] | number : "1.0-0" }}%</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px; margin-top: 5em">
            <div
              *ngFor="let salesDistribution of rmasSalesDistributionName; let i = index"
              style="display: flex; gap: 1.1em; color: #022e5c; align-items: center"
              [style.color]="getRmasDistributionColor(i)"
            >
              <div
                [style.background-color]="getRmasDistributionColor(i)"
                style="width: 1.5em; border-radius: 0.75em; height: 0.67em"
              ></div>
              {{ salesDistribution || "Others" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
