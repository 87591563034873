<div class="movanoCarousel_Element">
    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_RingsProduction">{{maxValue}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>max value</span>
      <span>{{dynamicText}}</span>
    </div>
    <div class="vertical-line"></div>

    <span class="movanoCarousel_Element_Total movanoCarousel_Element_Total_UnderRepair">{{minValue}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>min value</span>
      <span>{{dynamicText}}</span>
    </div>
  </div>
