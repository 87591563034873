import { Component, HostListener, Inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EChartsOption } from 'echarts';
import { MovanoService } from 'src/app/movano.service';
import * as  LITERALS from '../../../shared/movano-literals';
import { UserPanelTimeSelectorComponent } from '../time-selector/user-panel-time-selector.component';
import { lastValueFrom } from 'rxjs';
import { spotCheck_List, healthMeasure_Hour, healthMeasure_Day, Measure, SpotCheck_Hourly, Measurement, DataPoint, SpotCheck_Daily, MeasurementDaily, SpotDataSeries } from 'src/app/shared/movano-interfaces';
import { abbrNum, dateMarkLineConstructor, fixPixelsBy1440, getDayArrrayLedIV, getDayArrraySimpleIV, getDayArrraySimpleSPOT, getDayArrraySimpleSPOTDay, getDayOfWeek, multipleYaxisConstructor } from 'src/app/shared/utils';
import { MONTHS } from '../../../shared/movano-literals';
import { Console } from 'console';
import { SECONDS_IN_A_DAY } from 'src/app/shared/constants';
import { NewTimeSelectorComponent } from 'src/app/new-time-selector/new-time-selector.component';
import { MainServiceService } from 'src/app/main-service.service';

export type spotCheckLabel = {
  [key: string]: { c: string; l: string };
};
interface MeasureColors {
  [key: string]: string[];
};
@Component({
  selector: 'user-panel-spot-check',
  templateUrl: './user-panel-spot-check.component.html',
  styleUrls: ['./user-panel-spot-check.component.scss']
})
export class UserPanelSpotCheckComponent {

  protected loading: boolean = false; //Flag that indicates whether data loading is in progress.


  protected secondsXaxys: number[] = Array.from({ length: SECONDS_IN_A_DAY }, (_, index) => index); //[0,1,2,3,...,45051,45052,...,86400]
  protected loadingData: boolean = true;
  protected dataAvailable: boolean = true;
  protected timeLabels: string[] = [
    '01am', '02am', '03am', '04am', '05am', '06am', '07am', '08am', '09am', '10am', '11am', '12am',
    '01pm', '02pm', '03pm', '04pm', '05pm', '06pm', '07pm', '08pm', '09pm', '10pm', '11pm', '12pm',];
  protected sleepCl = ['#0B84FF', '#284767', '#0DCCFF', '#E31482'];
  protected sleepLbl: string[] = ['Light', 'Deep', 'REM', 'Awake'];
  protected spotCheckLbl: spotCheckLabel = {
    'light': { c: '#0B84FF', l: 'Light' },
    'deep': { c: '#284767', l: 'Deep' },
    'rem': { c: '#0DCCFF', l: 'REM' },
    'awake': { c: '#E31482', l: 'Awake' },
  };
  options_SleepData: EChartsOption = {};

  private innerWidth: number = 1440;
  //protected measuresSelected: number[] = [0];
  protected lastMeasureSelected: string = '';

  // protected totalZone: sleepingZonesTotal;
  // protected totalSleep: totalSleep;
  // protected acticityZonePerRangeDays: SleepingZonesActivity;
  // protected acticityZonePerDay: sleepData_HourIntervals[] = [];
  protected spotCheckHistory: { l: string, v: number, lp: boolean, d: number }[] = [];
  protected spotCheckList?: spotCheck_List;
  protected spotCheckHourly!: SpotCheck_Hourly;
  protected spotCheckDaily!: SpotCheck_Daily;
  protected measurementday!: MeasurementDaily;
  protected measurement!: Measurement;
  protected measure!: Measure;
  protected measuresLabels: string[] = ['HR', 'SpO2', 'LP']; //Label names
  protected measuresSelected: boolean[] = [true, true, false];
  protected measuresSelectedLP: boolean = false;
  protected numberSelected: number = 2;

  //CALENDAR VARIABLES
  protected slctdDateOpt: number = 2;
  protected lastSlctdDateOpt: number = 0;
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected timeZones?: string[];
  protected actualTimeZone: string = '';
  protected rangeSelected: Date[];
  protected today: Date;
  protected daySelected?: Date;
  protected nextIsPosible: boolean = false; //Indicates whether it's possible to load data for the next day. Today => false

  protected markLinesData: any[] = [];
  protected areaToShow: any[] = [];

  @Input() calendarData?: [any, boolean, Date | Date[], string, any];
  @Input() daySelector !: NewTimeSelectorComponent;
  @Input() isUTC?: boolean;

  //Colors per measure
  protected measureColors: MeasureColors = {
    SpO2: ['#0B84FF', '#054280', 'rgba(11, 132, 255, 0.5)', 'rgba(11, 132, 255, 0)'],
    Distance: ['#FF830D', '#FF830D', 'rgba(255, 131, 13, 0.5)', 'rgba(255, 131, 13, 0)'],
    HR: ['#00B377', '#00593B', 'rgba(0, 178, 119, 0.5)', 'rgba(0, 178, 119, 0)'],
    'Temp Skin': ['#B01065', '#66006A', 'rgba(176, 16, 101, 0.5)', 'rgba(176, 16, 101, 0)'],
    LP: ['#E31482', '#E31482', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)'],
    RR: ['#E22B2B', '#E22B2B', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)'],
    Steps: ['#284767', '#061E37', 'rgba(5, 66, 128, 0.5)', 'rgba(5, 66, 128, 0)'],
    'Sleep Session': ['#284767', '#061E37', 'rgba(5, 66, 128, 0.5)', 'rgba(5, 66, 128, 0)'],
  };
  defaultToUTC: boolean = true;
  dataHR: SpotDataSeries[] = []
  dataHR_LP: SpotDataSeries[] = []
  dataSpO2: SpotDataSeries[]  = []
  dataSpO2_LP: SpotDataSeries[] = []

  constructor(private movanoService: MovanoService, @Inject(MAT_DIALOG_DATA) protected data: { user: string }, private mainService: MainServiceService) {
    this.today = new Date(Date.now());
    this.daySelected = new Date(Date.now());
    this.rangeSelected = [new Date(Date.now()), new Date(Date.now())];
  }

  ngOnInit(): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.getInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['calendarData']) {
      this.getInfo(changes['calendarData'].currentValue)
    }

    if (changes['isUTC']) {
      this.getInfo()
    }
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.getInfo();
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: '2-digit',
      month: '2-digit',
      day: '2-digit',
    });
  }

  formatTime(dateString: string): string {
    const time = new Date(dateString);
    return time.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

  }
  /**
 * Advances to the next day.
 * Updates properties and calls the getInfo function to fetch data for the new date.
 */
  nextDate() {
    // If next day is tomorrow, exit the function
    if (!this.nextIsPosible) return;

    //Advances to the next day.
    this.daySelected?.setDate(this.daySelected.getDate() + 1);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  /**
   * Goes to the previous day
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    //Goes to the previous day
    this.daySelected?.setDate(this.daySelected.getDate() - 1);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  async getInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    if (calendarData) {
      console.log(calendarData);
      this.lastSlctdDateOpt = this.slctdDateOpt;
      this.slctdDateOpt = parseInt(calendarData[0]);
      this.nextIsPosible = calendarData[1];
      (this.slctdDateOpt != 3) ? this.daySelected = calendarData[2] as Date : this.rangeSelected = calendarData[2] as Date[];
      this.actualTimeZone = calendarData[3];
    } else this.daySelected!.getTime() < this.today!.getTime();
    this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();
    this.loadingData = true;
    this.dataAvailable = true;
    // Reset variables
    this.loadingData = true;
    this.spotCheckHistory = [];

    try {

      let timeFrame: string | undefined;
      let dateStart: Date | undefined;
      let dateEnd: Date | undefined;
      let spotData: any;


      switch (this.slctdDateOpt) {
        case 0:
          timeFrame = 'today';
          dateStart = this.daySelected;
          dateEnd = this.daySelected;

          break;
        case 1:
          timeFrame = 'lastWeek';
          break;
        case 2:
          timeFrame = 'lastMonth';
          break;
        case 3:
          dateStart = this.rangeSelected[0];
          dateEnd = this.rangeSelected[1];
          break;
        case 4:
          timeFrame = 'last7Days';
          break;
        case 5:
          timeFrame = 'actualYear';
          break;
        case 0:
        default:
          break;
      }
      if (this.slctdDateOpt === 0) {
        spotData = await lastValueFrom(this.movanoService.getSpotCheckHour(this.data.user, this.daySelected ?? this.today, this.isUTC ? 'utc' : undefined));
        this.spotCheck_Parse(spotData.data);
        console.log("Spot Data for HOur:", spotData.data);
        this.dataAvailable = this.spotCheckHourly.spotchecks_count > 0;
      } else {
        spotData = (this.slctdDateOpt != 3) ?
          await lastValueFrom(this.movanoService.getSpotCheckDay(this.data.user, this.isUTC ? 'utc' : undefined, undefined, undefined, timeFrame)) :
          await lastValueFrom(this.movanoService.getSpotCheckDay(this.data.user, this.isUTC ? 'utc' : undefined, dateStart, dateEnd, undefined));

        console.log("Spot Data for Day:", spotData.data);
        this.spotCheck_Parse_Day(spotData.data);
        console.log("Spot Data for Day:", spotData);
        this.dataAvailable = this.spotCheckDaily.spotchecks_count > 0;

      }

      this.mainService.updateUserRangedTimezones(spotData.data.timezonesRanked);

    } catch (error) {
      console.log(error);
    } finally {
      this.loadingData = false;
    }
  }

  spotCheck_Parse_Day(dml: SpotCheck_Daily) {
    // Set the spotCheckDaily object and handle time zones if necessary
    this.spotCheckDaily = dml;

    // Function to process a single measure (either HR or SpO2)
    const processMeasure = (measure: Measure, measureLabel: string) => {
      if (measure.value > 0) {
        this.spotCheckHistory.push({
          l: measureLabel,      // Label (HR or SpO2)
          v: measure.value,     // Value of the measurement
          lp: measure.low_perfusion, // Low perfusion status
          d: new Date(measure.date).getTime() // Date as a timestamp
        });
      }
    };

    // Function to process all measures for a specific type (HR or SpO2)
    const processMeasureArray = (measures: Measure[][], measureLabel: string) => {
      measures.forEach(dayMeasures => {
        // Check if there are any measures for the given day
        if (dayMeasures.length > 0) {
          dayMeasures.forEach(measure => processMeasure(measure, measureLabel));
        }
      });
    };

    // Process SpO2 measurements
    if (dml.spotcheck_spO2) {
      processMeasureArray(dml.spotcheck_spO2, 'SpO2');
    }

    // Process Heart Rate (HR) measurements
    if (dml.spotcheck_hr) {
      processMeasureArray(dml.spotcheck_hr, 'HR');
    }

    // Update the day's information after processing
    this.updateInfoDay();
  }

  spotCheck_Parse(dml: SpotCheck_Hourly) {
    this.spotCheckHourly = dml;
    console.log("Parsed Spot Check hourly:", dml)
    /*   if (!this.timeZones) {
      this.timeZones = dml.timezones;
      this.actualTimeZone = this.timeZones[0];
      this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);
    } */

    // Function to process a single measure (either HR or SpO2)
    const processMeasure = (measure: Measure, measureLabel: string) => {
      if (measure.value > 0) {
        this.spotCheckHistory.push({
          l: measureLabel,      // Label (HR or SpO2)
          v: measure.value,     // Value of the measurement
          lp: measure.low_perfusion, // Low perfusion status
          d: new Date(measure.date).getTime() // Date as a timestamp
        });
      }
    };

    // Function to process all measures for a specific type (HR or SpO2)
    const processMeasureArray = (measures: Measure[][], measureLabel: string) => {
      measures.forEach(dayMeasures => {
        // Check if there are any measures for the given day
        if (dayMeasures.length > 0) {
          dayMeasures.forEach(measure => processMeasure(measure, measureLabel));
        }
      });
    };

    // Process SpO2 measurements
    if (dml.spotcheck_spO2) {
      processMeasureArray(dml.spotcheck_spO2, 'SpO2');
    }

    // Process Heart Rate (HR) measurements
    if (dml.spotcheck_hr) {
      processMeasureArray(dml.spotcheck_hr, 'HR');
    }
    this.updateInfo();
  }

  /*   processDataPerDay(data: SpotCheck_Daily) {
      let markLinesData: { xAxis: string }[] = [];
      const firtsMonth: number = parseInt(data.date_start.split('-')[1]);
      const lastMonth: number = parseInt(data.date_end.split('-')[1]);
      const year: string = data.date_start.split('-')[0];
      for (let index = (firtsMonth + 1); index <= lastMonth; index++) {
        let str = index.toString();
        markLinesData.push({ xAxis: `${year}-${(index < 10) ? ("0" + str) : str}-01` })
      }
    } */

  /**
* Selects a specific day and updates relevant properties and data.
* @param _index - The index of the selected day in the data array.
*/
  selectDay(_date: string) {
    this.lastSlctdDateOpt = this.slctdDateOpt
    this.slctdDateOpt = 0;
    const dateParts: string[] = _date.split('-');
    // // Get the date of the selected day from the data
    const selectedDay: Date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
    // // Convert the selected day to a Date object
    this.daySelected = new Date(selectedDay);
    console.log(this.timeZones);
    // Call the getInfo function to update information with the selected day
    this.daySelector.updateDaySelectedSpot(this.daySelected!, this.slctdDateOpt);
    this.getInfo();
  }

  /**
   * Handles a chart event and triggers the selection of a specific day.
   * @param event - The chart event object containing relevant data.
   * @param type - The type of event being handled.
   */
  onChartEvent(event: any, type: string) {
    // Call the selectDay function with the dataIndex from the event
    this.selectDay(event.name);
  }
  returnToLabel(): string {
    if (this.slctdDateOpt > 0) return '';
    if (this.lastSlctdDateOpt == 1) return `Back to weekly view`;
    if (this.lastSlctdDateOpt == 2) return `Back to monthly view`;
    if (this.lastSlctdDateOpt == 4) return `Back to last 7 days view`;
    if (this.lastSlctdDateOpt == 5) return `Back to yearly view`;
    if (this.lastSlctdDateOpt == 0) return '';
    return `Back to
    ${this.rangeSelected[0].getDate()} ${this.rangeSelected[0].toLocaleString('default', { month: 'short' })} -
    ${this.rangeSelected[1].getDate()} ${this.rangeSelected[1].toLocaleString('default', { month: 'short' })} view`;
  }





  returnToLastChart() {
    const savedOption = this.slctdDateOpt;
    this.slctdDateOpt = this.lastSlctdDateOpt;
    this.lastSlctdDateOpt = savedOption;
    this.getInfo();
  }
  /**
* Updates the information and configuration for the chart.
* Sets up series data, axis labels, colors, and other chart settings.
* Also triggers updating battery information.
*

*/
  updateInfo() {
    // Reset the loadingMesureData flag
    this.loadingData = false;

    this.options_SleepData = this.optionsConstructor();
    this.options_SleepData.yAxis = [];
    if (!this.spotCheckHourly) return;

    //HR
    if (this.measuresSelected[0]) {
      const yIndex = this.options_SleepData.yAxis.length;
      this.dataHR = getDayArrraySimpleSPOT(this.spotCheckHourly.measurement_list, 'Spot HR');
      const newSeriesHR = {
        type: 'scatter',
        name: 'HfR',
        yAxisIndex: yIndex,
        dimensions: ['x', 'y'],
        symbolSize: fixPixelsBy1440(12, this.innerWidth),
        color: this.measureColors['HR'][0],
        data: this.dataHR,
        //markLine: (this.slctdDateOpt != 0) ? dateMarkLineConstructor(this.measurement!.date, this.innerWidth) : undefined,
      };
      (this.options_SleepData.yAxis as any[]).push(multipleYaxisConstructor('HR', yIndex, this.measureColors['HR'][0], this.innerWidth));
      (this.options_SleepData.series as any[]).push(newSeriesHR);

      //HR LP
      if (this.measuresSelected[2]) {
        this.dataHR_LP = this.dataHR.filter(d => d[2]);
        const newSeriesHR_LP =
        {
          name: 'LP',
          type: 'scatter',
          dimensions: ['x', 'y'],
          yAxisIndex: yIndex,
          color: 'rgba(0,0,0,0)',
          symbolSize: fixPixelsBy1440(17, this.innerWidth),
          itemStyle: {
            borderColor: '#E31482',
            borderWidth: fixPixelsBy1440(2, this.innerWidth)
          },
          data: this.dataHR_LP,
          visible: this.measuresSelected[2]
        };
        (this.options_SleepData.series as any[]).push(newSeriesHR_LP);
      }
    }
    //SpO2
    if (this.measuresSelected[1]) {
      this.dataSpO2 = getDayArrraySimpleSPOT(this.spotCheckHourly.measurement_list, 'Spot SpO2');
      const yIndex = this.options_SleepData.yAxis.length;
      const newSeriesSpO2 = {
        type: 'scatter',
        name: 'SpO2',
        yAxisIndex: yIndex,
        dimensions: ['x', 'y'],
        symbolSize: fixPixelsBy1440(12, this.innerWidth),
        color: this.measureColors['SpO2'][0],
        data: this.dataSpO2,
        //markLine: (this.slctdDateOpt != 0) ? dateMarkLineConstructor(this.measurement!.date, this.innerWidth) : undefined,
      };
      (this.options_SleepData.yAxis as any[]).push(multipleYaxisConstructor('SpO2', yIndex, this.measureColors['SpO2'][0], this.innerWidth));
      (this.options_SleepData.series as any[]).push(newSeriesSpO2);

      //SpO2 LP
      if (this.measuresSelected[2]) {
        this.dataSpO2_LP = this.dataSpO2.filter(d => d[2]);

        const newSeriesSpO2_LP =
        {
          name: 'LP',
          type: 'scatter',
          dimensions: ['x', 'y'],
          yAxisIndex: yIndex,
          color: 'rgba(0,0,0,0)',
          symbolSize: fixPixelsBy1440(17, this.innerWidth),
          itemStyle: {
            borderColor: '#E31482',
            borderWidth: fixPixelsBy1440(2, this.innerWidth)
          },
          data: this.dataSpO2_LP,
          visible: this.measuresSelected[2]
        };
        (this.options_SleepData.series as any[]).push(newSeriesSpO2_LP);
      }
    }
  }

  getDayOfWeek(date: string): string {
    // Convert the date in "YYYY/MM/DD" format to a Date object
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months start at 0 (January is 0)
    const day = parseInt(dateParts[2]);
    const dateObj = new Date(year, month, day);

    // Days of the week in text
    const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];

    // Get the day of the week number (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeekNumber = dateObj.getDay();

    // Get the name of the day of the week
    const dayOfWeekName = daysOfWeek[dayOfWeekNumber];

    return `${dayOfWeekName}`;
  }

  updateInfoDay() {
    this.loadingData = false;

    this.options_SleepData = this.optionsConstructor();
    this.options_SleepData.yAxis = [];
    if (!this.spotCheckDaily) return;

    //HR
    if (this.measuresSelected[0]) {
      const yIndex = this.options_SleepData.yAxis.length;
      this.dataHR = getDayArrraySimpleSPOTDay(this.spotCheckDaily, 'Spot HR');
      const newSeriesHR = {
        type: 'scatter',
        name: 'HR',
        yAxisIndex: yIndex,
        dimensions: ['x', 'y'],
        symbolSize: fixPixelsBy1440(12, this.innerWidth),
        color: this.measureColors['HR'][0],
        data: this.dataHR.map(d => [d[0], d[1]]),
        //markLine: (this.slctdDateOpt != 0) ? dateMarkLineConstructor(this.measurementday.date, this.innerWidth) : undefined,
      };
      (this.options_SleepData.yAxis as any[]).push(multipleYaxisConstructor('HR', yIndex, this.measureColors['HR'][0], this.innerWidth));
      (this.options_SleepData.series as any[]).push(newSeriesHR);

      //HR LP
      if (this.measuresSelected[2]) {
        this.dataHR_LP = getDayArrraySimpleSPOTDay(this.spotCheckDaily, 'Spot HR');
        const newSeriesHR_LP =
        {
          name: 'LP',
          type: 'scatter',
          dimensions: ['x', 'y'],
          yAxisIndex: yIndex,
          color: 'rgba(0,0,0,0)',
          symbolSize: fixPixelsBy1440(17, this.innerWidth),
          itemStyle: {
            borderColor: '#E31482',
            borderWidth: fixPixelsBy1440(2, this.innerWidth)
          },
          data: this.dataHR.filter(d => d[2]).map(d => [d[0], d[1]]),
        };
        (this.options_SleepData.series as any[]).push(newSeriesHR_LP);
      }
    }
    //SpO2
    if (this.measuresSelected[1]) {
      this.dataSpO2 = getDayArrraySimpleSPOTDay(this.spotCheckDaily, 'Spot SpO2');
      const yIndex = this.options_SleepData.yAxis.length;
      const newSeriesSpO2 = {
        type: 'scatter',
        name: 'SpO2',
        yAxisIndex: yIndex,
        dimensions: ['x', 'y'],
        symbolSize: fixPixelsBy1440(12, this.innerWidth),
        color: this.measureColors['SpO2'][0],
        data: this.dataSpO2.map(d => [d[0], d[1]]),
        //markLine: (this.slctdDateOpt != 0) ? dateMarkLineConstructor(this.measurementday.date, this.innerWidth) : undefined,
      };
      (this.options_SleepData.yAxis as any[]).push(multipleYaxisConstructor('SpO2', yIndex, this.measureColors['SpO2'][0], this.innerWidth));
      (this.options_SleepData.series as any[]).push(newSeriesSpO2);

      //SpO2 LP
      if (this.measuresSelected[2]) {
        this.dataSpO2_LP = getDayArrraySimpleSPOTDay(this.spotCheckDaily, 'Spot SpO2');

        const newSeriesSpO2_LP =
        {
          name: 'LP',
          type: 'scatter',
          dimensions: ['x', 'y'],
          yAxisIndex: yIndex,
          color: 'rgba(0,0,0,0)',
          symbolSize: fixPixelsBy1440(17, this.innerWidth),
          itemStyle: {
            borderColor: '#E31482',
            borderWidth: fixPixelsBy1440(2, this.innerWidth)
          },
          data: this.dataSpO2_LP.filter(d => d[2]).map(d => [d[0], d[1]]),
        };
        (this.options_SleepData.series as any[]).push(newSeriesSpO2_LP);
      }
    }
  }


  optionsConstructor(): EChartsOption {
    // Determine the selected measure label and color
    //const measureLabelRange = this.measuresLabels[this.measuresSelected[0]];
    //let colorSelectedRange = this.measureColors[measureLabelRange]!;
    const displayedLabels = new Set();



    const weekNames: boolean = (this.slctdDateOpt == 1) ||
      (this.slctdDateOpt == 3 && this.rangeSelected[1].getTime() - this.rangeSelected[0].getTime() < 600000000);
    return {
      renderer: 'svg',
      color: ['rgb(77, 119, 255)', 'rgb(116, 21, 219)', 'green', '#FF0087', '#FFBF00'],


      tooltip: (this.slctdDateOpt == 0) ? {
        trigger: 'axis',
        triggerOn: "mousemove",
        className: "movanoSpotCheck_Tooltip",
        axisPointer: {
          type: 'line',
        },
        formatter: (params: any) => {
          if (!Array.isArray(params)) {
            return '';
          }
          const hrParam = params.find((p: any) => p.seriesName === 'HR');
          const spo2Param = params.find((p: any) => p.seriesName === 'SpO2');
          // Instead of looking for LP series specifically, check the LP value directly in HR or SpO2 series
          const lp = hrParam && hrParam.value[2] || spo2Param && spo2Param.value[2];
          console.log(lp);

          const hrValue = hrParam ? hrParam.value[1] : 'No Data';
          const spo2Value = spo2Param ? spo2Param.value[1] : 'No Data';

          const axisValueLabel = params[0].axisValueLabel;
          const date = new Date(axisValueLabel);
          const formattedDate = this.formatDate(date.toISOString());
          const formattedTime = this.formatTime(date.toISOString());

          return `
            <div class="movanoSpotCheck_Tooltip">
              <div class="movanoSpotCheck_Tooltip_Header">
                <span>${formattedDate}</span>
                <span> ${formattedTime}</span>
              </div>
              <div class="movanoSpotCheck_Tooltip_Measures">
                <div class="movanoSpotCheck_Tooltip_Measures_Block">
                  <span style="color : #01B277">HR Value</span>
                  <span style="color : #01B277">${hrValue}</span>
                </div>
                <div class="movanoSpotCheck_Tooltip_Measures_Block">
                  <span style="color : #0B84FF">SpO2 Value</span>
                  <span style="color : #0B84FF">${spo2Value}</span>
                </div>
              </div>
              <div class="movanoSpotCheck_Tooltip_Bottom" style="display: ${lp ? 'flex' : 'none'}">
                <span>Low Perfusion</span>
              </div>
            </div>
            `
        },
        hideDelay: 300000,
      } :
        {
          trigger: 'item',
          triggerOn: "mousemove",
          className: "movanoSpotCheck_Tooltip",
          axisPointer: {
            type: 'line',
          },
          formatter: (params: any) => {
            const hrValue = params.seriesName === 'HR' ? params.value[1] : 'No Data';
            const spo2Value = params.seriesName === 'SpO2' ? params.value[1] : 'No Data';
            const lp = params.value[2];

            const date = new Date(params.value[0]);
            const formattedDate = this.formatDate(date.toISOString());

            const showHR = params.seriesName === 'HR' ? 'block' : 'none';
            const showSpo2 = params.seriesName === 'SpO2' ? 'block' : 'none';
            const currentIndex = params.dataIndex;
            const timezoneHR = this.dataHR[currentIndex][3].label + ' ' + this.dataHR[currentIndex][3].offset;
            const timezoneSp02 = this.dataSpO2[currentIndex][3].label + ' ' + this.dataHR[currentIndex][3].offset;

            const currentTimezone = params.seriesName === 'HR' ? timezoneHR : timezoneSp02;

            return `
            <div class="movanoSpotCheck_Tooltip">
              <div class="movanoSpotCheck_Tooltip_Header">
                <span>${formattedDate}</span>
                <p class="timezone-text">${currentTimezone}</p>
              </div>
              <div class="movanoSpotCheck_Tooltip_Measures">
                <div style="display: ${showHR}" class="movanoSpotCheck_Tooltip_Measures_Block">
                  <span style="color : #01B277">HR Value</span>
                  <span style="color : #01B277">${hrValue}</span>
                </div>
                <div style="display: ${showSpo2}" class="movanoSpotCheck_Tooltip_Measures_Block">
                  <span style="color : #0B84FF">SpO2 Value</span>
                  <span style="color : #0B84FF">${spo2Value}</span>
                </div>
              </div>
              <div class="movanoSpotCheck_Tooltip_Bottom" style="display: ${lp ? 'flex' : 'none'}">
                <span>Low Perfusion</span>
              </div>
            </div>
            `
          },
          hideDelay: 300000,
        },


      grid: {
        right: fixPixelsBy1440(30, this.innerWidth),
        top: '2%',
        bottom: '2%',
        left: fixPixelsBy1440(25, this.innerWidth),
        containLabel: true,
        height: 'auto',
        width: 'auto',
      },
      xAxis: (this.slctdDateOpt == 0) ? {
        type: 'time',
        show: true,
        //data: this.secondsXaxys,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        boundaryGap: ['5%', '5%'],
        min: function (value) {
          let minDate = new Date(value.min);
          minDate.setMinutes(minDate.getMinutes() - 3);
          return minDate;
        },
        max: function (value) {
          let maxDate = new Date(value.max);
          maxDate.setMinutes(maxDate.getMinutes() + 3);
          return maxDate;
        },
        splitLine: {
          show: true,
          interval: function (index: number, value: string) {
            return index % 21600 === 0;
          },
          lineStyle: {
            color: '#D1F5FF'
          }
        },


        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(0.7vw, 10px)',
          show: true,
          hideOverlap: true,
          color: '#7797B8',
          formatter: function (value: number, index: number) {
            const markDayDate = new Date(value);
            const hours = markDayDate.getHours();
            const minutes = markDayDate.getMinutes();

            const amPm = hours >= 12 ? "pm" : "am";
            const formattedHours = hours % 12; // Convert 0 to 12 for 12-hour format

            const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
            return formattedTime;
          }
        }
      } :
        {

          type: 'category',
          show: true,
          data: this.spotCheckDaily?.dates_info.dates,
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          axisLine: {
            show: false
          },
          min: -1,
          boundaryGap: false,
          max: this.spotCheckDaily.dates_info.dates.length,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D1F5FF'
            }
          },
          axisLabel: {
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(0.7vw, 10px)',
            show: true,
            hideOverlap: true,
            interval: 0,
            color: '#7797B8',
            formatter: weekNames ? (value: string, index: number) => {
              if (!value || value == '') return '';
              return this.getDayOfWeek(value);
            } : (value: string, index: number) => {
              if (!value || value == '') return '';
              const dateParts = value.split("-");
              if (dateParts.length >= 2) {
                return `${dateParts[2]}`; // Format as dd/mm
              }
              return value;
            }
          }
        },
      yAxis:
      {
        //offset: 20,
        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(1vw, 12px)',
          color: 'rgb(5, 66, 128)',
          verticalAlign: 'bottom',
          lineHeight: fixPixelsBy1440(30, this.innerWidth),
          formatter: function (value: any, index: any) {
            let label: string = Math.round(value).toString();
            return label;
          }
        },
      },
      series: []
    }
  }
  /**
 * Changes the selected measure, updating the measuresSelected array.
 * @param _index - The index of the measure to be added or removed.
 */
  changeMeasure(_index: number) {
    if (this.measuresLabels[_index] !== 'LP') {
      this.measuresSelected[_index] = !this.measuresSelected[_index];
    }
    if (this.slctdDateOpt === 0) {
      this.updateInfo();
    } else {
      this.updateInfoDay();
    }
  }


  toggleMeasureLP() {
    const lpIndex = this.measuresLabels.findIndex(label => label === 'LP');
    if (lpIndex !== -1) {
      this.measuresSelectedLP = !this.measuresSelectedLP;
      this.measuresSelected[lpIndex] = this.measuresSelectedLP;
      if (this.slctdDateOpt === 0) {
        this.updateInfo();
      } else {
        this.updateInfoDay();
      }
    }
  }


  filteredMeasuresLabels() {
    return this.measuresLabels.filter(label => label !== 'LP');
  }

}

