<form [formGroup]="recoverFormGroup">
  <div class="userNewEdit">
    <button mat-icon-button mat-dialog-close class="userNewEdit_close">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </button>
    <div class="userNewEdit_Header">
      <span class="userNewEdit_Header_Tittle">{{LITERALS.COGNITO_NEW_EDIT_USER.NEW_TITTLE}}</span>
    </div>
    <div class="userNewEdit_InputList">
      <div class="userNewEdit_Input">
        <span class="userNewEdit_Input_Title">{{LITERALS.COGNITO_NEW_EDIT_USER.ID}}</span>
        <input [ngClass]="{errorInput: errorMsg && recoverFormGroup.controls['userId'].hasError('required')}" type="text"
          [placeholder]="LITERALS.COGNITO_NEW_EDIT_USER.ID_PLACEHOLDER" formControlName="userId"
          style="margin-bottom: 1vw;">
      </div>
      <div class="userNewEdit_Input">
        <span class="userNewEdit_Input_Title">{{LITERALS.COGNITO_NEW_EDIT_USER.NAME}}</span>
        <input [ngClass]="{errorInput: errorMsg && recoverFormGroup.controls['userName'].hasError('required')}" type="text"
          [placeholder]="LITERALS.COGNITO_NEW_EDIT_USER.NAME_PLACEHOLDER" formControlName="userName"
          style="margin-bottom: 1vw;">
      </div>
      <div class="userNewEdit_Input">
        <span class="userNewEdit_Input_Title">{{LITERALS.COGNITO_NEW_EDIT_USER.FULL_NAME}}</span>
        <input [ngClass]="{errorInput: errorMsg && recoverFormGroup.controls['userFullName'].hasError('required')}" type="text"
          [placeholder]="LITERALS.COGNITO_NEW_EDIT_USER.FULL_NAME_PLACEHOLDER" formControlName="userFullName"
          style="margin-bottom: 1vw;">
      </div>
      <div class="userNewEdit_Input movanoFilterMenu_Filter">
        <span class="userNewEdit_Input_Title">{{LITERALS.COGNITO_NEW_EDIT_USER.ROLE}}</span>
        <mat-form-field>
          <mat-select  [placeholder]="LITERALS.COGNITO_NEW_EDIT_USER.ROLE_PLACEHOLDER" panelClass="movano-select" 
            [disableOptionCentering]="true">
            <mat-option *ngFor="let role of userRoles" [value]="role">{{role}}</mat-option>
          </mat-select>
          <svg class="movanoTriangleInput" matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767" />
          </svg>
        </mat-form-field> 
        <span class="userNewEdit_Input_Title">{{LITERALS.COGNITO_NEW_EDIT_USER.EMAIL}}</span>
        <input [ngClass]="{errorInput: errorMsg && (recoverFormGroup.controls['email'].hasError('email') || recoverFormGroup.controls['email'].hasError('required'))}" type="text"
          [placeholder]="LITERALS.COGNITO_NEW_EDIT_USER.EMAIL_PLACEHOLDER" formControlName="email"
          style="margin-bottom: 1vw;">
        
      </div>
    </div>
    <span class="userNewEdit_Error" *ngIf="errorMsg">Error: {{errorMsg}}</span>
    <app-movano-button (click)="newUser()" [buttonText]="LITERALS.COGNITO_NEW_EDIT_USER.NEW_BUTTON" 
      [size]="'medium'"></app-movano-button>
  </div>
</form>