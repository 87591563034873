import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { User } from '../shared/movano-interfaces';
import { MovanoService } from '../movano.service';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import * as lit from '../shared/movano-literals';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy{

  protected darkMode: boolean = false;
  private darkModeSub : Subscription = new Subscription();
  protected LITERALS = lit;
  //User details
  user: User;
  //Login is complete
  loginSucesfull = false;
  //login...
  loginLoading : boolean = false;
  //login data with validations
  loginFormGroup: FormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.compose([Validators.required, Validators.min(8)])],
    code: ['', Validators.compose([Validators.required])]
  });

  constructor(
    public dialog: MatDialog,
    private movanoSvc : MovanoService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private router : Router,
    private darkModeSvc: DarkModeService,
    private authService: AuthService)
    {
    this.user = {} as User;
  }

  ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val : any) => {
      this.darkMode = val;
    });
    this.getUserDetails();
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  recoverPass(){
    this.dialog.open(RecoverPasswordComponent);
  }
  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }
  //Login init logic
  async loginWithCognito() {
    if (this.loginFormGroup.value.email && this.loginFormGroup.value.password) {
      this.user.email = this.loginFormGroup.value.email;
      this.user.password = this.loginFormGroup.value.password;
      this.loginLoading = true;
      try{
        const userLogged = await this.movanoSvc.Login(this.user)
        if (userLogged.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await this.movanoSvc.completeSign(userLogged, this.user.password);
          await this.movanoSvc.Login(this.user);
        }

        const accountType = userLogged.signInUserSession.idToken.payload['custom:accountType'];

        this.authService.setAccountName(userLogged.signInUserSession.idToken.payload.name);
        this.authService.setAccountType(accountType);
        this.authService.setAccountIdToken(userLogged.signInUserSession.idToken.jwtToken);
        this.authService.setAccountEmail(userLogged.signInUserSession.idToken.payload.email);

        if(accountType === 'customerSupport'){
          this.router.navigate(['/users']);
        }else{
          this.router.navigate(['/dashboard']);
        }
      }catch(e){
        this.snackBar.open("Wrong password or email", "close");
      }
      this.loginLoading = false;
    }
  }
  async getUserDetails() {
    this.loginLoading = true;
    const user = await this.movanoSvc.getUser();
    if (user) {
     }
    this.loginLoading = false;
  }
  toDashboard(){
    this.router.navigate(['/dashboard']);
  }
}
