<app-header class="fixed-header" [table]="ntfListTable" [title]="LITERALS.NTF_INSIGHT_APP.TITTLE"></app-header>

<div class="ntf-list" [ngClass]="{ movanoDark: darkMode }">
  <div [ngStyle]="{ display: !loadingData ? 'block' : 'none' }" class="movanoTableBox">
    <notification-list-filter
      [table]="ntfListTable"
      [jobId]="jobId"
      [name]="name"
      (ntfListsChanged)="onNtfListsChanged($event)"
      (loadingDataChange)="onLoadingDataChange($event)"
      (dataAvailableChange)="onDataAvailable($event)"
      [isFiltered]="isFiltered"
      [filteredUsers]="filteredUsers"
      [insightApps]="ntfLists"
      [actualPage]="actualPage"
      (totalPagesChange)="onTotalPagesChange($event)"
      (actualPageChange)="onActualPageChange($event)"
    ></notification-list-filter>
    <p-table
      id="ntfListTable"
      #ntfListTable
      dataKey="application_id"
      class="movano-Table"
      [globalFilterFields]="['name', 'user_id', 'job_name', 'message_type', 'sentCustomer', 'sent_date']"
      [value]="ntfLists"
      [paginator]="true"
      [rows]="rows"
      [first]="pageSelected"
      (onFilter)="onFilter($event)"
      [ngClass]="{ hidden: !dataAvailable }"
      [scrollable]="true"
      scrollHeight="75vh"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th (click)="sort('insightName')">
            {{ LITERALS.NTF_LIST.TABLE_NAME }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="insightName-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th (click)="sort('userId')">
            {{ LITERALS.NTF_LIST.TABLE_USER_ID }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="userId-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th (click)="sort('jobName')">
            {{ LITERALS.NTF_LIST.TABLE_JOB_NAME }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="jobName-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th (click)="sort('messageType')">
            {{ LITERALS.NTF_LIST.TABLE_MESSAGE_TYPE }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="messageType-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th (click)="sort('sentCustomer')">
            {{ LITERALS.NTF_LIST.TABLE_SENT_CUSTOMER }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="sentCustomer-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th (click)="sort('sentDate')">
            {{ LITERALS.NTF_LIST.TABLE_SEND_DATE }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="sentDate-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="status">{{ LITERALS.NTF_LIST.TABLE_INFO }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notification let-i="rowIndex">
        <tr
          [ngStyle]="{
            background: darkMode
              ? i % 2 == 0
                ? '#05294d'
                : '#05305B'
              : i % 2 == 0
              ? 'rgba(5, 66, 128, 0.05)'
              : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td style="max-width: max-content !important">
            {{ notification.insightName || notification.name }}
          </td>
          <td>{{ notification.userId || notification.user_id }}</td>
          <td>{{ notification.jobName || notification.job_name }}</td>
          <td>{{ notification.messageType || notification.message_type }}</td>
          <td [style.color]="getNotificationStatus(notification.sentCustomer || notification.sent_customer).color">
            {{ getNotificationStatus(notification.sentCustomer || notification.sent_customer).text }}
          </td>
          <td>
            {{
              notification.sentDate || notification.sent_date
                ? (notification.sentDate || notification.sent_date | date : "MMM d, y") + " / " + (notification.sentDate || notification.sent_date | date : "HH:mm ")
                : ""
            }}
          </td>
          <td class="movanoTable_click" (click)="openUserPanel(notification)">
            <svg
              width="max(1vw,14px)"
              height="max(1vw,14px)"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="remove_red_eye" clip-path="url(#clip0_1510_2444)">
                <path
                  id="Vector"
                  d="M7.00004 3.79167C9.21087 3.79167 11.1825 5.03417 12.145 7C11.1825 8.96583 9.21671 10.2083 7.00004 10.2083C4.78337 10.2083 2.81754 8.96583 1.85504 7C2.81754 5.03417 4.78921 3.79167 7.00004 3.79167ZM7.00004 2.625C4.08337 2.625 1.59254 4.43917 0.583374 7C1.59254 9.56083 4.08337 11.375 7.00004 11.375C9.91671 11.375 12.4075 9.56083 13.4167 7C12.4075 4.43917 9.91671 2.625 7.00004 2.625ZM7.00004 5.54167C7.80504 5.54167 8.45837 6.195 8.45837 7C8.45837 7.805 7.80504 8.45833 7.00004 8.45833C6.19504 8.45833 5.54171 7.805 5.54171 7C5.54171 6.195 6.19504 5.54167 7.00004 5.54167ZM7.00004 4.375C5.55337 4.375 4.37504 5.55333 4.37504 7C4.37504 8.44667 5.55337 9.625 7.00004 9.625C8.44671 9.625 9.62504 8.44667 9.62504 7C9.62504 5.55333 8.44671 4.375 7.00004 4.375Z"
                  [ngStyle]="{ fill: darkMode ? 'white' : '#054280' }"
                />
              </g>
              <defs>
                <clipPath id="clip0_1510_2444">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <!-- <paginator [pageSelected]="pageSelected" [rows]="rows" [state]="state" [lenght]="ntfLists.length" [type]="type"
          (updatePaginator)="updatePaginator($event)"></paginator> -->
        <div class="movanoPaginator">
          <svg (click)="previousPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.33335L3.21996 5.33335L6.94663 1.60669L5.99996 0.666687L0.666626 6.00002L5.99996 11.3334L6.93996 10.3934L3.21996 6.66669L11.3333 6.66669V5.33335Z"
              [ngStyle]="{ fill: state.page > 0 ? '#0B84FF' : '#AABED3' }"
            />
          </svg>

          <button *ngIf="!isFirstPageInRange()" (click)="selectPage(1)" [ngClass]="{ 'active-page': actualPage === 1 }">
            1
          </button>

          <svg *ngIf="shouldShowLeftEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button
            *ngFor="let page of getDisplayedPages()"
            [ngClass]="{ 'active-page': page === actualPage }"
            (click)="selectPage(page)"
          >
            {{ page }}
          </button>

          <svg *ngIf="shouldShowRightEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button
            *ngIf="!isLastPageInRange()"
            (click)="selectPage(totalPages)"
            [ngClass]="{ 'active-page': actualPage === totalPages }"
          >
            {{ totalPages }}
          </button>

          <svg (click)="nextPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.666647 6.66665L8.77998 6.66665L5.05331 10.3933L5.99998 11.3333L11.3333 5.99998L5.99998 0.666646L5.05998 1.60665L8.77998 5.33331L0.666647 5.33331L0.666647 6.66665Z"
              [ngStyle]="{ fill: state.page < state.pageCount - 1 ? '#0B84FF' : '#AABED3' }"
            />
          </svg>
        </div>
      </ng-template>
    </p-table>

    <div *ngIf="!dataAvailable" class="ntf-list_NoData">
      <span>NO DATA AVAILABLE</span>
    </div>

    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>

  <div *ngIf="loadingData" style="position: absolute; top: 50%; left: 50%">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
