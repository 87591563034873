import { Component, Inject, OnInit, Input, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Table } from 'primeng/table';
import { Subscription, lastValueFrom } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import { MovanoService } from 'src/app/movano.service';
import { ntfInsightJobRunsTable, ntfListTable } from 'src/app/shared/movano-interfaces';
import { NTF_INSIGHT_JOB_RUNS } from 'src/app/shared/movano-literals';
import { Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserPanelTimeSelectorComponent } from 'src/app/users/panel/time-selector/user-panel-time-selector.component';

@Component({
  selector: 'insight-job-executions-runs',
  templateUrl: './insight-job-executions-runs.component.html',
  styleUrls: ['./insight-job-executions-runs.component.scss']
})
export class InsightJobExecutionsRunsComponent implements OnInit{
  protected LITERALS = NTF_INSIGHT_JOB_RUNS;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false; 
  protected timeZones : string[] = []; //UTC, Madrid, California... etc
  protected filter_Status : string = 'All Status';
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;  

  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;
  protected type: string = 'job runs';
  jobLogListLength: number = 0;
  lambdaTrigger: string = '';
  getInfoSubscription: Subscription = new Subscription();
  /**
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */
  protected selectedDate: number = 2;


  @ViewChild('jobExecRunTable') jobExecRunTable!: Table;
  @ViewChild('insightJobExeRunsTable') insightJobExeRunsTable! : Table;
  @ViewChild('list_calendar') daySelector !: UserPanelTimeSelectorComponent;
  protected insightJobExeRuns: ntfInsightJobRunsTable[] = [];
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 14;

  constructor(
    protected movanoService: MovanoService,
    private router: Router,
    private darkModeSvc: DarkModeService,
    private renderer: Renderer2, private el: ElementRef,
    protected dialogRef: MatDialogRef<InsightJobExecutionsRunsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: { insightAppName: string, selectedDate: number, daySelected: Date, rangeSelected: Date[] }) {

      this.selectedDate = data.selectedDate;
      this.daySelected = data.daySelected;
      this.rangeSelected = data.rangeSelected; 
      this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

    async ngOnInit() {
      const { insightAppName, selectedDate, daySelected, rangeSelected } = this.data;
      if (insightAppName) {
        if (selectedDate === 3) {
          await this.loadData([selectedDate, true, rangeSelected, '', null]);
        } else {
          await this.loadData([selectedDate, true, daySelected, '', null]);
        }
      } else {
        await this.loadData();
      }
      this.updateDaySelector();
      console.log(this.data);

    }
   
    

    updateDaySelector() {
      if (this.selectedDate !== 3) {
        this.daySelector.updateDaySelected(this.daySelected!, this.timeZones, this.selectedDate);
      } else {
        this.daySelector.updateDaySelected(this.rangeSelected[0], this.timeZones, this.selectedDate); 
      }
    }

  handleIconClick(jobId: string) {
    this.router.navigate(['/notifications/notification-list'], { queryParams: { jobId: jobId } });
    this.dialogRef.close();
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }





  async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {
    if (calendarData) {
        console.log(calendarData);
        this.lastselectedDate = this.selectedDate;
        this.selectedDate = parseInt(calendarData[0]);
        //WHEN NO DATE IS SELECTED UNDEFINED IS EMITTED MAKING CALENDARdATA TRUE, SO WE NEED IT TO HANDLE DEFAULT BEHAVIOR
        if (calendarData[2] === undefined) {
            const today = new Date();
            this.rangeSelected = [
                new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
                today
            ];
            this.selectedDate = 2;
        } else {
            if (this.selectedDate !== 3) {
                this.daySelected = calendarData[2] as Date;
            } else {
                this.rangeSelected = calendarData[2] as Date[];
            }
            this.actualTimeZone = calendarData[3];
        }
    } else {
        const today = new Date();
        this.rangeSelected = [
            new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
            today
        ];
        this.selectedDate = 2; 
    }

    this.dataAvailable = true; 

    this.loadingData = true;

  
    try {
    
      let dateStart: Date | undefined;
      let dateEnd: Date | undefined;
      let timeFrame: string | undefined = '';

          if (this.selectedDate === 6) {
            // Handle "today" or any custom day
          
             timeFrame = 'today'
          } else if (this.selectedDate === 1) {
            // Handle "last week"
            timeFrame = 'lastWeek'
          } else if (this.selectedDate === 2) {
            // Handle " last month"
            timeFrame = 'lastMonth'
           
          } else if (this.selectedDate === 3) {
            // Handle "range"
            dateStart = this.rangeSelected[0];
            dateEnd = this.rangeSelected[1];
            const startDate = this.rangeSelected[0];
            const endDate = this.rangeSelected[1];
            this.movanoService.setSelectedDates(startDate, endDate);
          }
          else if (this.selectedDate === 4) {
            // Handle "last 7 days"
            timeFrame = 'last7Days'
          }
          else if (this.selectedDate === 5)
          {
            timeFrame = 'actualYear'
          }
          
  
          this.movanoService.setTimeFrame(timeFrame);
  
      await this.getNtfnInsightList(this.data.insightAppName, timeFrame, dateStart, dateEnd);
      console.log(this.data.insightAppName)
        this.dataAvailable = this.insightJobExeRuns.length > 0;
  
    } catch (error) {
        console.log(error);
        this.dataAvailable = false;     
    }
   
  }

  async getNtfnInsightList(insightAppName: string, timeFrame: string, startDate?: Date, endDate?: Date) {
    this.loadingData = true;
    this.movanoService.getNtfnInsightJobRuns(insightAppName, timeFrame, startDate, endDate).subscribe((res: any) => {
        console.log('Full API Response:', res.data); 

        const filteredData = res.data.find((item: { insight_name: string; }) => {
            return item.insight_name === insightAppName;
        });

        if (filteredData) {
            console.log('Filtered Data:', filteredData);
            this.jobLogListLength = filteredData.job_log_list.length; // Store the length of job_log_list
            this.lambdaTrigger = filteredData.lambda_trigger; // Store the lambda trigger

            if (startDate && endDate) {
                this.insightJobExeRuns = filteredData.job_log_list.filter((log: { start_time: string; }) => {
                    const logDate = new Date(log.start_time);
                    return logDate >= startDate && logDate <= endDate;
                });
            } else {
                this.insightJobExeRuns = filteredData.job_log_list;
            }
        }
        console.log(this.insightJobExeRuns);

        this.lastPage = this.insightJobExeRuns.length;
        this.dataAvailable = this.insightJobExeRuns.length > 0;
        this.loadingData = false;
    }, error => {
        console.log(error);
        this.loadingData = false;
    });
}




  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]){
    this.loadData(calendarData);
    console.log(calendarData + ' FECHA CALENDARIO');  
  }

  search(event: any, _row: string,  _table: Table) {
    console.log(event.target.value);
    _table.reset();
    _table.filter(event.target.value, 'name', 'contains');
  }


  applyFilter() {
    this.jobExecRunTable.reset();
    if (!this.filter_Status || this.filter_Status === 'reset') return;
    this.jobExecRunTable.filter(this.filter_Status, 'run_status', 'equals');
  }



  protected closePanel() {
    this.dialogRef.close();
  }

  sort(column: string) {
    if (this.movanoService.currentSortedColumn && this.movanoService.currentSortedColumn !== column) {

      this.movanoService.sortOrder[this.movanoService.currentSortedColumn] = 'none';
  
      const prevIcon = this.el.nativeElement.querySelector(`#${this.movanoService.currentSortedColumn}-icon`);
      const { fill, transform } = this.movanoService.updateIcon(this.movanoService.currentSortedColumn);
      this.renderer.setStyle(prevIcon, 'fill', fill);
      this.renderer.setStyle(prevIcon, 'transform', transform);
      this.renderer.setStyle(prevIcon, 'transform-origin', 'center');
    }
    this.movanoService.sortTable(this.insightJobExeRuns, column);
    const icon = this.el.nativeElement.querySelector(`#${column}-icon`);
    const { fill, transform } = this.movanoService.updateIcon(column);
    this.renderer.setStyle(icon, 'fill', fill);
    this.renderer.setStyle(icon, 'transform', transform);
    this.renderer.setStyle(icon, 'transform-origin', 'center');
  }

  getBadgeColor(status: string): string {
    switch(status) {
        case 'Running':
            return 'movanoTable_Obj_Badge_blue';
        case 'finished with errors':
            return 'movanoTable_Obj_Badge_red';
        case 'succeed':
            return 'movanoTable_Obj_Badge_green';
        case 'error in execution':
            return 'movanoTable_Obj_Badge_orange';
        default:
            return '';
    }
}
}
