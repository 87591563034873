import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tab-selector',
  templateUrl: './tab-selector.component.html',
  styleUrls: ['./tab-selector.component.scss']
})
export class TabSelectorComponent {
  @Input() tabs: string[] = [];
  @Output() tabSelected = new EventEmitter<string>();

  selectedTab: string | null = null;

  ngOnInit(){
    this.selectTab(this.tabs[0])
  }

  selectTab(tab: string): void {
    this.selectedTab = tab;
    this.tabSelected.emit(tab);
  }
}
