
<div class="movanoMap" [ngClass]="{'movanoDark' : darkMode}" *ngIf="mapData">
  <!-- *ngIf="statesKey && unitSoldStates" -->
  <span class="movanoMap_Tittle">{{mapTittle[infotype]}}</span>
  
  <mat-form-field class="movanoMap_Short" div *ngIf="mapData.length > 1">
    <mat-select placeholder="States Ranking" panelClass="movano-select" [disableOptionCentering]="true">
      <mat-option>
        <div class="movanoMap_Body_States_State" *ngFor="let states of mapData.slice(0, 10); index as i">
          <div [ngClass]="'movanoMap_Body_States_State_'+mapTittle[infotype]+'_'+i">
            <span>{{i+1}}</span>
            <span>{{stateNamesEEUU.get(states.state)}}</span>
          </div>
          <span 
          style="opacity: 0.5; margin-left: 5px;"
          [ngClass]="'movanoMap_Body_States_State_'+mapTittle[infotype]+'_'+i"
            *ngIf="i < 3">{{states.value}}</span>
        </div>
        <div class="movanoMap_Body_States_State">
          <span>...</span>
          <span>...</span>
        </div>
        <div class="movanoMap_Body_States_State"
          [ngClass]="'movanoMap_Body_States_State_'+mapTittle[infotype]+'_Last'">
          <div>
            <span>{{mapData.length-1}}</span>
            <span>{{stateNamesEEUU.get(mapData[mapData.length-1]?.state)}}</span>
          </div>
          <span class="lastValue">{{mapData[mapData.length-1]?.value}}</span>
        </div>
      </mat-option>
    </mat-select>

    <svg matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
        fill="#284767" />
    </svg>
  </mat-form-field>
  <span class="movanoMap_Day">‘23</span>
  <div class="movanoMap_Buttons">
    <button (click)="passToChart(false)">
      <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
          [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1 }" />
      </svg>
    </button>

    <button (click)="passToChart(true)">
      <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
          [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1 }" />
      </svg>
    </button>
  </div>
  <div class="movanoMap_Body" >
    <svg id="mapSVG" width="100%" height="100%" viewBox="0 0 845 452" fill="none" xmlns="http://www.w3.org/2000/svg">
      <style type="text/css">

      </style>
      <path class="st0" d="M676.6,153.3c-0.6,6.4-5.8,4.1-5.9,12.4c0,1.4-0.2,1.8,1,2.5c1.2,0.8,2.4,2.2,3.9,2.1c1.3-0.1,2.3-0.9,3.6-0.3
        c1,0.5,1.4,1.6,2.6,1.3c0.6-0.1,1.3-0.8,1.9-1.1c1.1-0.5,2.3-0.7,3.5-1c1.6-0.5,2.4-0.9,3.2-2.2c2.4-3.9,6.5-5.7,10.1-8.3
        c3.3-2.4,6.3-5.5,9.2-8.3c1.3-1.2,2.8-1.8,4.1-2.9c0.5-0.4,0.7-0.7,1-1.3c0.3-0.9,0.2-0.4-0.1-1.2c-0.6-1.5-1.3-3-1.8-4.6
        c-0.5-1.5-1-2.2,0.5-3.1c2.4-1.3,6.5-2.1,9.2-1.9c1.2,0.1,2.1,0.8,3.3,0.9c1.4,0.1,3.5-0.7,4.9-1c2-0.5,8.4-1.4,9.2-3.6
        c0.2-0.5-0.2-1.1,0-1.6c0.2-0.5,0.7-0.7,0.9-1.2c0.7-1.5-0.4-2-0.8-3.3c-0.4-1.4,0.4-2-1-2.6c-0.9-0.4-2.2-0.2-1.9-1.7
        c0.1-1,1.8-1.7,2.5-2.4c0.9-0.8,1.8-1.9,2.3-3c0.5-1.1,0.6-2,1.4-3c0.6-0.8,3.9-3.8,4-4.9c1-0.6,20.6-7,30.3-9.7
        c1.5-0.4,3.5-0.8,4.8-1.6c1.2-0.7,0.5-1.7,1.1-2.9c0.5-1.1,1.4-0.9,2.5-1.3c0.8-0.3,2.5-0.5,2.9-1.1c0.5-0.7,0.9-3.3,1.1-4.2
        c0.5-1.7,0.9-3.4,1.8-5c0.8-1.4,0.7-1.4,0.3-3c-0.3-1.1-0.5-2.2-0.1-3.3c0.5-1.7,1-1.9,0.1-3.6c-0.8-1.5-0.4-2.4,0-4.1
        c0.5-2,1.1-4.1,1.9-6c0.7-1.5,1.9-2.8,2-4.5c0.8-0.1,1.5-0.6,2.3-0.5c0,0.7,0.2,2,1,2.3c0.9,0.5,1.9-0.6,2.8-1.2
        c0.5-0.3,1.5-1.3,2.1-1.4c0.7-0.2,0.4-0.1,1.2,0.1c1.3,0.4,2.7,1.4,4,1.9c2.9,1.1,2.9,4.6,3.8,7.3c1.3,3.9,4.5,7.6,4.3,11.9
        c1.2,0.1,3.3-0.4,4.1,0.7c0.4,0.6,0.1,1.5,0.3,2.1c0.3,0.9,1.2,1.5,1.4,2.5c0.6-0.3,1.3-0.7,1.8-1.1c0.5,0.6,1.8,1.3,2.2,2
        c0.5,0.7,0.3,2,0.3,2.9c0,1.9-0.3,3.3-1.8,4.3c-1.5,1-2.9,1.7-4.1,3.1c-1,1.1-3.7,5-5.1,2.5c-0.7,0.9-1.6,2.8-2.5,3.3
        c-1.1,0.5-1.9-0.3-2.9-0.8c-0.6,1.8,0.2,4.2,0.1,6.1c-1.7,0.3-2.8,2.9-3.6,3.8c-0.5,0.2-2.4,1.8-3.5,1.9c-1.1,0.1-3.5-0.5-4.2,0.4
        c-0.3,0.4-0.3,1.7-0.4,2.2c-0.2,1.3-0.5,2.5-0.7,3.8c-0.3,2.2-0.9,4.3-0.8,6.6c2.3,0.1,3.5-0.2,4.5,2.6c0.4,1.2,0.3,1.2-0.8,1.6
        c-1,0.4-2.1,0.8-3.1,1c-0.2,1.3,0.5,2.5,0.5,3.8c2.5,0.7,6.3,2.1,6.4,5.3c1.1-0.1,2.5-0.4,3.6-1c1.2-0.7,1.7-1.6,2.3-0.2
        c0.4,0.9,0.9,2.7,0.3,3.4c-0.8,0.8-3.3,1-4.3,1.2c-0.2,1.2-0.3,2.4-1.3,3.3c-1.1,0.8-2,0.3-3.3,0.3c-1.2-0.1-2.8-0.1-3.8,0.2
        c-1.4,0.4-2.3,2-3.5,2.8c-3.1,2-6.9,2.9-10.4,3.9c-2.8,0.8-7.4,4.9-8,7.7c1.3-0.5,2.4-1,3.8-1.3c1.5-0.3,3-0.3,4.3-0.9
        c1.4-0.6,2.5-1.9,3.7-2.8c0.9-0.7,1.9-1.3,2.7-2c0.9,0.7,1.1,0.7,0.5,1.6c-0.5,0.7-1.7,1.6-2.3,2.1c-2.1,1.8-5.1,4-7.2,5.5
        c-1.4,0.7-3.5,1.2-4.9,1.8c-1.5,0.7-3.6,1.7-5.2,1.6c-0.2,2.1,0.6,2.3,2.5,2.4c0.2,2.2-0.1,4.6-0.2,6.8c-0.1,2.6,0.2,5.1-1,7.4
        c-1,2-2.4,3.5-2.6,5.7c-0.1,0.7,0.1,2.3-0.4,2.9c-0.5,0.7-1.9,0.7-2.6,0.9c-0.4-1-0.6-2.2-1-3.2c-1.6-0.1-3.3-0.4-4.9-0.3
        c0.5,1.6,1.7,3.2,2.9,4.4c0.9,0.9,2.7,1,3.4,2.2c0.6,1.1,0.1,3.8,0,5.1c-0.2,1.6,0.3,3.4-0.1,5c-0.4,1.3-1.4,1.1-1.8,2.2
        c-0.6,1.5-0.4,4-0.5,5.6c-0.2,1.7-0.4,3.4-0.4,5.2c0,1,0.2,1.2-0.6,1.6c-0.3,0.1-1.6,0.1-1.9-0.1c-1.4-1.1-0.9-8.3-0.4-9.4
        c0.3-0.6,2.3-2.1,1.8-2.9c-0.2-0.3-1.2-0.2-1.5-0.4c-0.3-0.2-0.5-0.8-0.7-1.1c-1.9,1.4-5.9-2.7-6.6-4.2c-0.7-1.5-1-3.8-1.4-5.4
        c-0.4-1.4-0.5-2.8-0.7-4.2c-0.2,0.4-0.8,0.7-1.4,0.7c-0.3,2.1-0.2,4.1-0.2,6.2c0.1,1.5,0.1,1.6,1.1,2.6c0.5,0.5,1.2,1.1,1.5,1.7
        c0.5,0.9,0.2,1.7,0.5,2.6c0.3,1,1,0.9,1.6,1.5c1.4,1.4,1,3.2,0.5,4.7c0.5,1.8,3.3,3.6,1.6,5.3c-1.4,1.5-1.1,0.9-0.2,2.5
        c0.6,1.1,0.7,2.5,0.4,3.6c2,0.2,4.1-1.2,5.6,0.6c1.7,1.9,2.2,4.2,3.1,6.5c0.6,1.5,1.9,3.3,2.8,4.5c0.5,0.6,1.3,1.2,1.7,1.8
        c1,1.3,1.3,3.1,1.4,4.7c0.2,1.7,0.4,2.5-0.8,3.6c-0.7,0.7-1.4,1.4-2.1,2.1c-0.7,0.6-1.6,0.9-2.3,1.5c-1.4,1.2-2.5,3.5-3.6,5
        c-1.3,1.7-0.9,2.2-3.2,2.4c-1.5,0.1-2.4,0.1-3.6,1c-1.4,1.1-2,2.3-3,3.8c-1.1,1.6-2.7,2.8-3.6,4.6c-0.8,1.5-2.2,3.7-2.1,5.3
        c-0.7-0.1-1.6-0.6-2.4-0.5c-1.4,0.1-2.9,1-4,1.7c-2,1.2-3.8,2.5-5,4.5c-1.3,2.1-1.9,4.6-2.1,7c-0.2,2.2-0.5,3.1-3,2.5
        c0.1,0.7-0.3,1.2-0.5,1.8c-0.3,0.9-0.3,1.9-0.7,2.7c-1.2,2.7-4.4,3.7-5.9,5.9c-0.6,0.9-1.2,1.7-2,2.3c-0.6,0.6-2.3,1.2-2.6,2
        c-0.3,0.7,0.1,1.5-0.2,2.1c-0.3,0.5-2.1,2.1-2.2,2.1l-1,2.1c0.1,0,0.2,0,0.4,0c-0.3,1.5-0.5,3-0.6,4.6c-0.1,1.5,0.3,3.4-0.5,4.8
        c-0.6,1.1-1.2,1.3-1.2,2.8c0,1.3-0.3,2.8-0.3,4.1c0,1.8,1.8,4.1,2.6,5.8c1.5,3.2,2.5,6.6,4.2,9.8c2.3,4.1,4.3,8.5,7.4,12.1
        c1.4,1.6,4.1,2.6,3.2,5.4c-0.2,0.7-0.7,0.9-0.8,1.6c-0.1,0.6,0.2,1.4,0.3,2c0.2,1.5,0.8,2.8,1.6,4.1c1.9,3.3,4.3,6.7,6.8,9.4
        c0.9,1,1.3,1.4,1.6,2.8c0.3,1.4,0.3,3,0.5,4.4c0.2,1.8,0.3,3.5,0.4,5.4c0,1.1,0.6,3.2,0.1,4.2c-0.6,1.3-1.8,0.6-1.6,2.1
        c0.2,1.2,1.2,2.4,1.3,3.7c0.4,3-2.6,3.1-4.8,3.8c-1.7,0.5-5.4,2-6.4,0.1c-0.5-0.9-0.5-2.3-0.8-3.3c-0.4-1.5-1.2-2.2-2.3-3.4
        c-1.1-1.2-1.3-1.6-3-1.3c-2,0.4-2,1-3-1c-0.6-1.3-1.4-2.4-1.9-3.7c-0.5-1.3-0.7-1.8-2.1-2.1c-1.5-0.3-2.4,0-2.6-1.7
        c-0.2-1.1,0.2-2.3,0.1-3.4c-0.7-0.6-1.2,0.2-2,0c-0.7-0.2-1.2-1.1-1.6-1.6c-0.9-1.2-1.6-2.6-2.6-3.8c-0.6-0.8-2.9-2.2-3.2-3.2
        c-0.4-1.4,1.7-2.2,1.7-3.5c0.1-2.4-1.8-0.9-2.9-0.2c-1.2-1.4-1.9-4.2-1.5-6.1c0.4-1.7,1.2-3.1,1.1-5c-0.2-2.6-0.8-6.2-2.4-8.4
        c-0.8-1.1-2.4-1.5-3.7-2c-3.1-1.3-3.8-3-5.9-5.5c-1-1.2-2.6-2-4-2.5c-1.8-0.7-3.2-2-5.2-2.1c-1.9,0-2.6,0.2-2.9,2
        c-0.2,1.2-0.3,2.4-1.1,3.4c-0.8,1-1.8,1.2-3,1.7c-0.7,0.3-1.7,1.2-2.4,1.3c-1.4,0.2-3.6-1-5-1.5c-0.1-1.1,0.5-2.2,0.1-3.4
        c-1.2-3.1-7.5-3.3-10.1-3.7c-2.7-0.3-5.9-0.9-8.5-1c-1.6,0-3.3,0.8-4.8,1.2c-1.5,0.3-3,0.4-4.5,0.8c-1.2,0.3-3.1,1.4-4.3,1.2
        c-1.3-0.2-1.9-2.2-3-2.9c-0.8-0.6-0.7-0.9-1.1,0.3c-0.2,0.5,0,1.1-0.3,1.7c-0.6,0.1-0.5-0.5-0.9-0.5c-0.4-0.1-0.7,0-1.2,0
        c-1.2,0-2.2,0.4-3.3,0.6c-1.7,0.3-3-0.4-4.7,0.3c-1.2,0.5-2.4,1.3-3.7,1.6c-0.9,0.2-1.6,0.2-2.4,0.6c-1.7,1-2.9,3.1-4.4,4.4
        c0.7,0.6,1.8,0.8,2.6,1.3c0.4-0.7,1.3-1.4,2-1.9c1.1-0.7,1.1-0.6,1.7,0.7c0.4,1,0.6,1.7,0,2.8c-0.7,1.4-2.3,1.5-3.4,2.5
        c1.3,1.9,3.8,1.1,5.7,2.2c0.7,0.4,3.4,1.8,3.5,2.5c0.2,1.2-2.6,2-3.5,2.2c-1.4,0.3-1.3-0.1-2.4-1c-0.8-0.7-1.8-0.7-2.8-1.1
        c-1-0.4-1.4-1.2-2.3-1.8c-0.9-0.7-2-0.9-3.1-0.9c0.2,0.8,0.4,2.8,0.8,3.1l-2.2,1.8c-0.7-0.9-2-1.5-3.3-1c-1.1,0.4-2.1,1.3-3.3,1.4
        c-1.5,0.2-2.7-0.8-3.9-1.5c-1.2-0.7-2.3-1.1-2.8-2.2c-0.3-0.7-0.1-0.8-0.5-1.3c-0.2-0.3-0.7-0.7-1.1-0.9c-1.2-0.6-2.7-0.9-4-1.4
        c-2-0.8-3.2-2.3-5-0.7c-1.2,1.1-1.6,2.4-3.4,2.6c-1.4,0.2-2.8-0.5-4.1-0.7c-4.5-0.6-8.8-1.2-13.4-0.8c-1.2,0.1-2.3,0.1-3.4,0.5
        c-1.2,0.5-2.1,1.4-3.4,1.8c-1.8,0.5-3.9,0.1-5.4,1.4c-1.2,1-1.5,2.8-2.7,3.8c-1.6,1.3-3.7,1.7-5,3.5c-0.6,0.8-0.8,1.6-1.7,2.2
        c-1.3,0.8-3.1,1.1-4.5,1.7c-1.3,0.6-3.2,2.2-4.7,1.8c-0.6-0.1-1-1-1.6-1c-0.4-0.1-1.7,0.6-2.1,0.8c-1.2,0.9-0.6,1.8-0.5,3.3
        c0.1,1.9-1,2.2-2.8,2.7c-1.8,0.5-2.2,0.4-3.3,2c-2.2,3.2-3.8,6.1-5,9.7c-0.4,1.2-0.9,2.9-0.3,4.1c0.8,1.6,2.3,2.5,2.5,4.4
        c0.5,3.6,0.2,7.4,0.6,11c0.1,0.9,0.5,0.7-0.4,1.2c-0.4,0.2-1.4,0-1.9,0c-1.3-0.1-2.6-0.4-3.9-0.5c-2.5-0.2-4.2-1.4-6.2-2.8
        c-1.5-1-3-1.5-4.8-1.6c-1,0-3.2,0.3-3-1.3c-1.4-0.4-3.2-1.6-4.6-1.6c-1.2-1.3-1.7-3.8-2.6-5.4c-1.2-1.9-2.6-3.9-3.6-6
        c-0.7-1.4-0.7-2.7-1.1-4.2c-0.2-1.1-0.4-1.1-1.3-1.8c-1.4-1.3-2.8-3.2-3.9-4.7c-2.5-3.5-1-7.7-3.1-11.4c-2.3-4.1-5.7-7.9-8.9-11.3
        c-1.3-1.4-2.6-4-4.5-4.6c-1.2-0.4-2.7-0.3-3.9-0.6c-1.1-0.2-2.2-0.7-3.3-1c-1.5-0.4-3.1-0.6-4.6-0.7c-1.5-0.1-2.9,0.2-4.3,0.8
        c-1.9,0.8-2.1,1.3-2,3.4c0.1,1.9,0.5,2.7-0.9,3.9c-0.8,0.8-2,2.4-2.9,2.8c-2.7,1.1-5.6-2.4-7.6-3.6c-1.7-1-3.6-1.7-5.1-2.9
        c-1.3-1.1-3.4-2.2-4.4-3.4c-0.8-1-0.4-2-0.6-3.2c-0.2-1.6-1-2.9-1.4-4.4c-0.5-1.7-0.5-3.3-0.6-5c-0.1-1.6-0.9-1.8-2.1-2.8
        c-2.3-1.8-4.6-3.3-5.5-6.3c-0.5-1.7-1.2-2-2.6-3.1c-1.1-0.8-2.6-1.2-3.5-2.2c-1.1-1.2-1.5-2.8-1.8-4.3c-0.5-2.3-2.6-1.3-4.6-1.5
        c-5.9-0.6-20.2-1.6-20.2-1.6v5.8l-35-4.8l-41.9-23.9V284l-26.8-4.8c-0.8-1.6-0.9-4.7-1.2-6.5c-0.3-2-1.3-4.6-2.3-6.4
        c-0.7-1.2-2-2.3-3.2-3c-0.8-0.5-2.7-0.5-3.2-1.2c-0.5-0.7,0.2-2.3-0.1-3.1c-0.5-1.4-1.6-0.8-2.9-1.2c-1.1-0.4-2.5-1.6-3.7-2.3
        c-1.3-0.8-2.4-1.1-3.7-1.6c-2.8-1.1-1.9-5.6-4.9-6.2c-2.8-0.6-5.3-0.6-7.5-2.8c-2.7-2.6,0.8-5.7,0.9-8.4c0.1-2-1.7-1-2.3-2.2
        c-0.4-0.8-0.1-2.5-0.3-3.4c-0.3-1.7-1.3-1-2-2c-0.8-1.2-0.4-3.7-0.6-5c-0.3-1.4-0.8-3.5-1.7-4.6c-0.9-1.2-1.5-1.2-1.9-2.8
        c-0.2-1-1.3-3.3-0.9-4.2c0.4-1.1,3.2-2.7,2.8-4.2c-0.4-2.1-1.2-2.7-2.7-3.4c-1.1-0.5-2.2-1.9-2.5-3.1c-0.4-1.4,0-3.4,0.1-4.9
        c0.2-3.1,1.7-0.1,3,0c0.2-1.2-0.6-2.4-0.6-3.5c0-1.2,0.8-1.3,1.5-2.4c0.4-0.6,1.2-2.5-0.2-2.1c-0.9,0.3-2.1,2.7-2.8,3.4
        c-0.4-0.4-0.8-0.9-1.2-1.3c-0.6-0.7-2.1-1.3-2-2.4c0.1-0.7,0.9-0.9,1-1.6c0.1-0.5-0.4-1.5-0.5-2c-0.8-2.2-1.6-4.6-2.8-6.7
        c-0.9-1.6-2.9-3.4-2.6-5.3c0.1-0.9,0.6-2,0.9-2.9c0.4-1.4,0.7-2.7,1-4.2c0.2-1.7,0.9-3.5,0.6-5.3c-0.3-1.6-1.4-2.4-2.1-3.7
        c-0.6-1.2-0.7-2.9-0.9-4.2c-0.2-1.3-0.6-3.1-0.1-4.3c0.6-1.2,1.8-1.4,2.5-2.2c0.8-1,1.6-2.5,2.2-3.7c1.2-2,2.1-4.3,2.6-6.6
        c0.5-2.4-0.6-4.4-0.5-6.8c0.1-1.8,0-3.6,0.1-5.3c0.1-2.2,2.4-4,1.8-6.2c-0.2-0.8-0.9-1.8-0.7-2.6c0.1-0.7,0.9-1.2,1.4-1.7
        c1.3-1.5,2.8-2.4,3.8-4.2c5-8.3,8.8-16.8,11.2-26.3c0.8-3.1,1.9-6,3.1-9c0.6-1.4,1-2.2,0.8-3.7c-0.1-1.2-0.5-2.1,0.3-3.1
        c1-1.1,1.9-0.6,1.4-2.2c-0.4-1.3-1-1.3,0.4-2.3c0.5-0.3,1.2-0.3,1.3-1.1c0.2-1-0.5-0.7-0.9-1.2c-0.7-0.9-0.4-1.7-0.6-3
        c-0.1-1.3-0.9-2.6-1-3.9c-0.2-2.5,2.4-4.4,1.9-6.8c-0.3-1.4-1.7-2.3-2.1-3.4c-0.4-1.1,0.3-3,0.8-3.9c0.4-0.8,0-0.9,0.9-0.6
        c0.6,0.2,1.1,0.8,1.5,1.2c1,0.9,2,1.7,3.1,2.5c1.3,0.8,2.5,1.6,3.7,2.5c1.4,1.1,2,1.2,3.7,0.9c2-0.4,0.9,0.6,2.2,1.4
        c0.4,0.2,1.3-0.3,1.7,0c0.6,0.4,0.4,1,0.5,1.6c0.3,1.2,1.1,1.4,1.3,2.4c0.2,1.1-0.3,2.4,0.1,3.4c0.8-1,2.1-2,2.9-3
        c-0.9-1.3-1.8-1.1-1.3-3c0.2-0.8,0.7-1.2,0.3-2.1c-0.3-0.8-1.1-1.1-0.9-2.1c1.2-0.8,2.3-1.7,1.6-3.3c-0.4-0.8-1.2-1.1-1.4-2.1
        c-0.2-1.1,0.8-2.9,0.7-4c0,0,61.3,18.7,122.9,27.1c61.6,8.4,137.5,10,137.5,10v-5.8l4.5,0.8c0.8,1.6,0.8,2.9,0.9,4.6
        c0.1,1,0,1.9,0.7,2.5c0.4,0.4,0.8,0,1.2,0.8c0.3,0.5,0.1,1.2,0.2,1.8c2.9,0.3,6.1-0.3,9,0.4c-0.2,0.5-0.1,1.1-0.1,1.7
        c0.7,0,2.2,0,2.8-0.4c0.7-0.5,0.5-0.9,0.5-1.8c1.1,0.5,2.4,0.6,3.7,0.6c1,0,4-0.6,4.8,0.1c0.2,0.2,0.8,1.9,1.1,2.3
        c0.4,0.8,0.8,1.4,0.9,2.3c1.3,0.1,3.4-0.2,4.5,0.5c0.9,0.6,1.1,1.9,2.2,2.4c1.4,0.6,3.3-0.3,4.6-0.6c0.9-0.2,1.3-0.1,1.7-0.7
        c0.3-0.4,0.2-1.2,0.5-1.7c0.9-1.2,3,0.1,4.6,0.4c0.7,0.1,1.6,0,2.3,0.3c0.5,0.2,0.5,0.6,1.2,0.8c1.8,0.5,5.4,0.7,9.1-1.1l7.6-5.9
        l38.6,19.5c0,0,1,2.6,1.3,3.2c0.3,0.7,1.6,1.2,1.7,1.9c0.1,0.7,1.1,1.1,1.1,1.1s0.9-0.5,1.4-0.5s1.4-1.1,1.4-1.1l1.2,0.9
        c0,0,1,4.9,2,5.4c1,0.6,0.3,0.3,1.6,0.3c1.3-0.1,2.4-0.3,3.1,0.9s-0.9,3.1-0.9,3.1l11.7,6.4l4.9,24.5
        C679.1,142.2,677.1,153.8,676.6,153.3z" />
      <path class="st1" d="M591.7,105.2" />
      <path class="st0"
        d="M26.6,339.2H25c-4.9,0-6.2,6.1-0.5,5.4c1.4-0.2,3.5-1.1,4.2-2.6C29.4,340.5,28.3,338.4,26.6,339.2z" />
      <path class="st0" d="M48.4,346.9l-1.2,0.9c-1,1.1-3.4,1.2-3.8,2.6c-0.3,1.1,1.4,3.7,2.4,4.2c1.3,0.7,2.2-0.3,3.5-0.2
        c1.2,0.1,1.8,1.1,3,1.1C52.6,353.2,50.9,347.5,48.4,346.9z" />
      <path class="st0" d="M62.9,357.2c1.1,0.4,0.8,1.3,2.3,1.4c1.2,0.1,2.3-1.1,3-1.8c-2.2-0.4-5-2.9-7-2.2l-0.7-0.5
        c-1.2,0.5-2.8,2.1-1.6,3.1C59.6,357.8,62,356.9,62.9,357.2z" />
      <path class="st0" d="M70.8,360l-0.7-0.2c-2.7-1.4-3.4,4.2,1.2,3.1c0.3,1.8,0,3,2.1,3.2c0.7,0.1,4.4-0.9,4.8-1.3
        C80.6,361.4,72.2,360.7,70.8,360z" />
      <path class="st0" d="M92.2,375.2c-1.3-1.7-3.4-1.6-5.2-2.3c-1.5-0.6-2.9-1.7-4.7-1.7h-1.6c-1.2,1.4,0.2,2.6,0.7,3.9
        c-4.4,2.7-0.8,6.8,0,10.4c0.4,2-1.3,3.2-0.7,5.1c0.3,1.1,2.4,2.6,3.6,3.2c1.9-2.6,3.7-4.8,6.8-5.3c3-0.5,5.1-1.4,5.3-4.9
        C92.2,383.3,94.1,377.7,92.2,375.2z" />
      <path class="st0" d="M69.6,365.8h-1.4c-0.4,0.8-0.5,1.3-1.2,1.6C69.6,369.9,71.8,365.9,69.6,365.8z" />
      <path class="st0"
        d="M63.1,361.2l-1.2-0.2c-0.2,0.1-0.5,0.3-0.7,0.5c0,2,2.2,4,3.8,2.9C67.1,362.8,65.1,361,63.1,361.2z" />
      <path class="st0" d="M15.6,342.2c-1,1.8-2.1,2.9-2.5,5.1c1.6-0.3,4.2-2.3,3.9-4.2L15.6,342.2z" />
      <path class="st0" d="M208.8,402.3c-1.5-0.3-3.4,1.6-5.1,1.5c0-1-0.6-2.2-0.9-3.3c-0.5-2.2-1.3-4.4-1.8-6.7c-1.2-5.1-2.2-10-3-15.1
        c-0.8-5.1-1.5-10.1-2-15.2c-0.3-3.7-1.6-7.2-2.5-10.8c-0.2-1-0.3-2.8-1.3-3.2l-0.8-0.6c-0.6,0-1.3-0.2-1.9-0.3
        c-0.1-0.7-0.4-1.9-1.1-2.2c-0.3-0.1-2.8,0.3-3.1,0.5c-0.8,0.6-0.5,1.5-1.7,1.8c-1,0.3-1.9-0.3-2.7-0.6c0.1-1.5-1.7-0.9-2.5-0.6
        c-1.3,0.4-1.4,0.6-2.7-0.1c-0.7-0.4-1.4-1.1-2.2-1.3c-1.2-0.3-2,0.1-3.1,0.2c-1.5,0.2-1.2-0.3-2.5-1.2c-0.4-0.3-0.9-0.2-1.3-0.6
        c-0.3-0.3,0-0.9-0.4-1.3c-1.3-1.2-1.6,0.6-2.5,1c-1.4,0.7-1.2-1.2-2.7-1c0.4-1,0.4-3.2-1.3-2.8c-0.6,0.1-0.6,0.7-1,1.1
        c-0.7,0.7-1.6,1.3-2.5,1.8c-0.5,0.3-1.3,0.9-1.8,0.9c-0.6,0.1-1.1-0.4-1.8-0.4c-1.8,0.1-1.5,1.7-2.5,2.6c-1,1-2.9,0.6-4,1.5
        c-0.8,0.7-0.9,1.9-1.5,2.8c-0.5,0.8-1.5,2-2.5,2.4c-1.2,0.5-3-0.7-4.1,0.1c-0.1,0.1-0.2,0-0.4,0c-0.2,0.6,0,1.3-0.2,1.9
        c-0.2,0.5-0.8,0.9-0.8,1.4c0,1.5,2.5,3.5,3.4,4.4c0.5,0.5,1,0.9,1.4,1.5c0.3,0.5,0.2,1.3,0.6,1.7c0.8,0.9,2.4,0.5,3.6,0.6
        c0.3,1.2-0.1,2.1,0.7,3c0.9,0.9,1.8,0.8,2.9,0.5c1.3,1.7-1.8,2.8-2.9,2.9c-1.7,0.1-3.7-0.2-5.2-0.9c-0.2-1.3,0.3-2.6,0.2-4
        c-1.2-0.1-2.9-0.3-4,0.1c-1.4,0.5-0.9,1.9-2.1,2.6c-0.9,0.6-2.5,0.1-3.6,0.5c-1.4,0.5-2.6,1.4-1.9,3c0.7,1.8,2.2,0.3,3.2,1.4
        c0.5,0.6-0.2,2,0.3,3c1.2,2.2,4.4,0.8,6.4,2c0.8,0.5,1.3,1.8,2.5,1.4c0.4-0.1,1-0.9,1.4-1.2c0.5-0.4,1.1-0.7,1.7-1
        c0.6-0.3,2.3-0.9,2.7,0.2c0.3,1-1.6,1.5-2.1,2c-1.1,1.2,0,1.2,0.5,2.5c0.3,0.8,0.2,2.2-0.1,3.1c-0.4,1-1.4,1.6-2.6,0.8
        c-0.8-0.4-0.9-1.8-2.1-1.5c0.1,1.3-0.9,1.6-0.9,2.7c-1.8,1-2.9-3.8-4.9-0.1c-0.4,0.7-0.2,1.6-0.9,2.3c-0.7,0.6-1.6,0.7-2.2,1.5
        c-0.6,0.8-0.8,2.1-1.3,3.1c-0.4,0.8-0.8,1.8-0.8,2.6c0,1.9,3.2,3.3,1.1,5.3c0.7,0.8,1.9,1.2,2.5,2.2c0.6,1.1-0.1,2.1,1.3,2.6
        c1.6,0.7,2.1-0.6,2.9-1.5c1-1.2,2-1.7,3.1-0.3c1.7,2.4-0.7,3.9-1.7,5.8c-0.8,1.6,0.8,0.5,1.4,2.1c0.5,1.2-0.5,2.1,1,2.8
        c0.6,0.3,2.5,0.3,3-0.2c0.7-0.6,0.3-2.5,1-2.9c0.3-0.2,2.3,0.6,2.5,1c0.3,0.5,0,2.2,0,2.9c1,0.1,1.7-0.2,2.6-0.6
        c0-0.2,0.1-0.5,0-0.6c1.2,0,2.2-0.6,3.3-0.7c0.3,1.6-0.2,1.7-0.6,3c-0.4,1.2,0.2,2.6-0.2,3.7c-0.4,1.2-1.5,1.3-2.4,2
        c-1,0.8-1.2,2-2.2,2.7c-1.1,0.8-2.4,1.3-3.5,2.2c-0.9,0.8-1.8,1-2.7,1.6c-0.3,0.2-0.8,1.1-1,1.1c-0.5,0.2-1.6-0.3-2.2-0.4
        c-1.9-0.2-2.4,0.4-3.4,1.8c-0.5,0.7-1.7,2.7-2.5,2.9c-0.8,0.2-2.1-0.5-3.1-0.3c-1.7,0.3-3.8,2-3.7,4c1.2,0.3,3.8-0.3,5.1-0.5
        c1.2-0.2,0.8-0.5,1.5-1.4c0.7-1,1-0.9,2.1-1.1c1.6-0.4,7.1-5.7,7.6-1.3c1-0.1,2.2-1.8,3-2.4c1.1-0.9,2.3-1.5,3.5-2.2
        c1.1-0.7,1.1-1.9,1.9-2.5c0.9-0.6,2.1,0.1,3-0.6c1.9-1.3,2.7-4.3,4.9-5.4c1-0.5,1.9-0.4,2.8-1.2c0.9-0.9,1.5-2.3,2.4-3.3
        c0.9-1,1.3-1.2,1.2-2.6c-3.2-0.8-1.5-2.9,0.3-4.5c0.3-0.3,0.9-0.6,1.1-1c0.3-0.5-0.1-1.1,0.2-1.6c0.5-1.1,1.9-2,2.6-3.1
        c1.2-1.6,2.8-5.5,5.2-4.2c1.3,0.7,1.3,1.6,0,2.3c-1.2,0.6-2,0.5-2.3,2.1c-0.3,1.3,0.4,2.2-0.1,3.2c-0.5,1-1.6,1.4-1.4,2.8
        c0.3-0.1,0.7,0.1,1.1,0c0.1,1-2.7,3.8-0.1,2.8c1.8-0.7,3.4-2.5,4.9-3.7c1.4-1.2,2.9-1.2,4.4-2c2.4-1.3-0.3-2.6,0.1-4.2
        c1-0.1,3.3-2.3,4.1-1.8c0.8,0.4-0.2,2.3-0.1,3c1.7,0.6,3.5-0.3,5.2-0.3c1.7,0.1,1.5,1.6,3.1,1.7c1.4,0,3-0.9,4.4-1
        c1.3-0.1,2.9,0.3,4-0.3c0.5,1.3,1.5,0.6,2.5,0.7c0.9,0.1,1.7,0.8,2.6,0.1C205.7,404.9,209,404.1,208.8,402.3z" />
      <path class="st0" d="M216.7,408.2c-0.6-0.4-1.2-0.6-1.8-1.1c-0.7-0.6-1.1-1.4-1.7-2c-0.5-0.4-1.2-0.4-1.7-0.8l-1.5-0.7
        c-0.9,1.3-2.6,2.8-0.5,4.3c1.1,0.8,3.2,0.5,4.5,0.9c-0.1,1.7,0.8,2.9,2.4,3.4c2.6,0.8,2.3-0.4,1.5-2.4
        C217.4,408.9,217.5,408.7,216.7,408.2z" />
      <path class="st0" d="M222.5,409.9c0.1-1.8-0.7-3.2-0.5-5c0.1-1.4,0-2.4-1.9-2.2l-0.4,0.1c-2.6,1.1-1.6,3.5,0.1,4.5
        C220.9,408.1,221.6,409.2,222.5,409.9z" />
      <path class="st0" d="M245.5,422.7c-0.2-1.7-1-3.7-2.1-5.1c-1.5-1.9-3.3-0.6-5.3-0.9c-1.8-0.2-2.7-1.9-3.9-3.2
        c-1.2-1.2-2.5-2-3.8-3.1c-1-0.9-3.9-4.6-5-4.4h-1c-1.1,1.6-0.5,3.9,1.1,5c0.5,0.4,1.3,0.4,1.8,0.9c0.7,0.6,0.9,1.6,1.3,2.5
        c0.7,1.9,1.8,2,3.5,2.9c1.3,0.6,2.8,1.7,4.2,1.9c-0.3,2.6,2.3,1.2,3.7,1.5c1.7,0.3,1.3,1.5,1.2,2.9c-0.1,1.3,0.3,2.5,1.8,2.7
        C245.3,426.4,245.7,424.6,245.5,422.7z" />
      <path class="st0" d="M224.4,414.5l-1-0.5c-0.9,0.2-2.3-0.1-2.2,1.2c0.1,0.8,1.8,1.9,2.2,2.8c0.4,0.7,1.3,3.4,2.7,2.4
        c0.6-0.4,0.2-2.9,0.1-3.5C226.1,415.7,225.6,414.9,224.4,414.5z" />
      <path class="st0" d="M233.3,420.3l-1,0.1c-3.1,1,0,4.2,1.4,5c0.5,0.3,3.8,2.3,4.4,1.7c1-1.1-1.4-4.2-2-4.9
        C235.3,421.2,234.8,420.4,233.3,420.3z" />
      <path class="st0" d="M167.9,418.9c-1.4,1.8-5.5,3.2-5.3,5.7c0.2,1.4,0.9,3.5,1.5,4.7c1.7-0.6,2.3-3.6,3.3-5c0.9-1.3,3-3.2,1.7-5
        L167.9,418.9z" />
      <path class="st0"
        d="M127.6,403.4l-1.8-0.3c-1.5,0.5-2,1.2-0.7,2.8c0.7,0.9,1.7,1.6,2.9,1.1C130.3,406.3,130.3,403.1,127.6,403.4z" />
      <path class="st0" d="M114.1,372.5l-2.2-0.6c-2.9,2.6-0.3,2.1,1.6,4.3c0.7,0.8,1.6,2.5,2.9,1.3C118.3,375.9,115.7,372.9,114.1,372.5z
        " />
      <path class="st2" d="M238,127.3l44.4,13.5l42.5,11.1l49.8,7.7l-2.9,14.5l75.7,9.2l7.4-55.9l3.3-59.3" />
      <path class="st2" d="M374.7,159.5l7.9-42.9l72.4,8.9" />
      <path class="st2" d="M395.5,176.8l-20.8,140.3" />
      <path class="st2" d="M330.7,225l56,10.9l56.2,5.9l41.1,2.4l66.5,2.7v7.4l37.3-2.7l14-0.4v4.4l1.8,3l4.7-0.7" />
      <path class="st2" d="M550.5,247v-32.5l-2.4-1.2l-0.7-2.8l-2.7-2.2l2.9-3.7l-2.9-1.6l-1.8-1.3l-73.4-2.1l-3.5,43.7" />
      <path class="st2" d="M469.5,199.5v-14.3l-22.1-2.1" />
      <path class="st2" d="M529.6,164.9c1.4,1.7,3.5,4.7,4,6.9c0.3,1.4,0.1,2.8,0.5,4.3c0.5,1.8,1.6,3.6,1.8,5.5c0.2,2.1,0.2,3.8,0.8,5.8
        c0.9,3,2.1,5.9,3.3,8.8c0.7,1.5,2.7,4.1,2.5,5.5" />
      <path class="st2" d="M538.8,192.9c1.5,0.6,4.3,0,5.9,0c3.4,0,6.8-0.2,10.2-0.4c4.9-0.3,9.7-0.7,14.6-0.7c3.6,0,7.6-0.5,11.1,0.1
        c0.8,0.1,2,0.4,2.6,0.9c0.6,0.5,1,1.7,1.8,2.2" />
      <path class="st2" d="M523.4,70.9c0.7,1.8,0.8,3.7,1.4,5.5c0.5,1.5,0.8,2.7,1,4.3c0.3,3-0.2,6,0.1,9c0.3,3.6,1.1,7,1.7,10.5
        c0.6,3.2,0.5,6.6,1.1,9.8c0.3,1.8,0.8,3.4,0.9,5.1c0.1,1.8-0.7,3.1-1.5,4.6c-0.9,1.9-0.3,2,1.1,3.7c1.2,1.5,1.8,2.3,1.8,4.3
        c0,5.1-0.4,10-0.4,15c0,3.1,0.3,6.2,0.1,9.3c-0.4-0.1-1.2,0-1.5,0.4c-0.5,0.8,0.4,0.9,0.7,1.4c0.6,1.1,0.8,2.1,0.7,3.4
        c-0.3,2.5-0.9,4.8-0.9,7.4" />
      <path class="st2"
        d="M451.4,154.9c4.9,0,9.8,0.5,14.7,0.6c4.8,0.1,9.5,1.7,14.4,1.8c6.9,0,13.8,0.6,20.7,0.9
        c2.3,0.1,5.1-0.5,7.4,0.1c1.6,0.4,2.2,1.9,3.6,2.7c1.5,0.9,2-0.2,3.7-0.7c1.6-0.4,2.9,0.2,4.5,0.6c3,0.7,6.3,1,9.1,2.2" />
      <path class="st2" d="M455.7,112.8c3.8-0.6,8.1,0.2,11.8,0.6c5.4,0.7,10.8,1.4,16.3,1.9c8.2,0.9,16.6,0.4,24.9,0.4
        c6.6,0,14.3-0.6,20.6,1" />
      <path class="st2" d="M530.6,151.5c1.9-0.5,4.3-0.3,6.4-0.4c3.5-0.2,7.1-0.6,10.6-0.6c6,0,12,0.1,18-0.1c4.6-0.2,9.2,0,13.7,0
        c1.8,0,4.7-0.5,6.2,0.1" />
      <path class="st2" d="M600.2,83.2l-3.5,9.9h-7.2L572,104.6c-0.6,1,0.1,1.8,0.4,2.8c0.9,2.5,1.2,5,1,7.7c-2.6,0-5.9,1.3-5.4,4.5
        c0.2,1.4,1.8,1.5,2.1,2.8c0.2,1.1-0.9,2.9-1,4.1c-0.4,2.8-0.3,5.8,1.9,7.6c3.4,2.8,7,5.9,10.8,8.1c1.6,0.9,2.6,1,3.1,3
        c0.3,1.3,0.1,2.8,0.5,4.1c0.3,1.2,1.1,2.1,1.5,3.3c0.4,1.3,0,2.3,0,3.7c0,3,1.6,5.1,4.3,6.3c1.6,0.7,1.6,1.5,2.6,2.9
        c0.8,1.2,1.9,1.8,3,2.8c3.3,3.1,0.1,6.7-1.4,9.8c-0.9,1.8-2.8,1.6-4.8,2.3c-1.2,0.5-1.3,0.5-1.4,2c0,0.8-0.3,1.9,0.7,2.1
        c0.2,1-0.2,2-0.3,2.9c-0.1,0.5-0.1,1.6-0.4,1.9c-0.3,0.4-1,0.3-1.3,0.6c-1.7,1.3-0.9,3.8-3.3,4.6" />
      <path class="st2"
        d="M592.7,163.3c0.8,0.1,1.9-0.1,2.8-0.2c2-0.2,4-0.1,5.9-0.1c3.3,0,6.5-0.2,9.7-0.3c3.5-0.1,7-1.3,10.4-1.2" />
      <path class="st2" d="M596.7,93.1l-4.7,11.8c1.5,0.5,2.7,1.9,4.1,2.5l10.8,2.7l4,1.5l5.9,0.7c0,0-0.3,0.7,0,1.2
        c0.3,0.5,3.1,0.9,3.1,1.7c0,0.9-0.8,6.2,0,5.8c0.8-0.4,2.5-1.6,1.9,0c-0.6,1.6,1.6,3.7,2.5,3c0.9-0.8,3.3-5.5,3.3-5.5l3.7,0.1
        l4.6,2.7c0,0-3.7,3.2-5,9.6c-1.3,6.4-2.8,14.6-1.6,19.2c1.2,4.6,1.6,10.4,1.6,10.4l-8.5,1" />
      <path class="st2" d="M584.4,194.6c1.1,2.8-0.3,7,1.2,9.9c1.5,2.8,5.4,5.1,7.6,7.3c1.3,1.2,0.4,4.6,1.8,5.4c0.9,0.5,1.9-1,2.7-1.1
        c0.8-0.1,1.4,0.9,2.5,1c0.3,1.5-1.4,4.1-1.9,5.6c-0.8,2.2,0.1,2.6,2.1,3.8c1.2,0.8,2.5,1.7,3.8,2.3c1,0.4,2.1,0.4,2.9,1.2
        c1.2,1.2,0.9,3.1,1.4,4.6c0.7,2.3,1.7,5.8,3.7,7.3c1.6,1.2,1.3,0.5,2.5-0.9c1.3-1.6,2.3-0.7,4.1-0.3c0.2,0.1,2,0.6,2.1,0.6
        c0.6-0.3,0.3-1.8,0.3-2.4c0-1.8,0.5-1.5,2.2-2.2c1.8-0.7,1.8-1.3,1.3-3.2c-0.4-1.7-0.5-3.1-0.1-4.9c0.3-1.3,0.2-3.9,0.9-5.1
        c0.4-0.8,0.5-0.3,1.2-0.8c0.9-0.6,1.4-1.3,1.9-2.2c0.8-1.4,1.4-3.1,2-4.6c0.7-1.8-0.1-2-1.3-3.4c-0.7-0.8-1.6-2-1.5-2.9
        c0.2-1.2,1.2-2,1.2-3.4c-0.2-4.1-0.4-8.5-1.2-12.6c-0.7-4-1-8.1-1-12.1c0-1.7,0.2-9.4-0.6-10.5c4.3-1.1,17.9-1,22.4-1.2
        c2.8-0.1,5.4-0.5,8.1-0.9c4.5-0.6,9.4,0,13.7-1.3" />
      <path class="st2"
        d="M624.8,230.9c3.7,0.3,6.6-2.7,10.2-1.4c2.1,0.8,1.5,0.1,2.7-1.7c1.4-2,1.6-0.2,3.2-0.2c1.8-0.1,1.7-2.7,2.7-3.7
        c1.2-1.1,2.2,0.5,3.5,0.9c2,0.6,1.9-0.3,2.7-2c0.8-1.5,1.9-2.8,2.9-4.2c0.4-0.5,1-1.2,1.2-1.8c0.3-0.8,0-1.7,0.2-2.5
        c0.4-1.6,1.6-1,3.1-1.3c3.3-0.7,2.2-4.9,2.2-7.5c0-4.8-0.9-9.1-1.5-13.7c-0.5-4.1-0.8-8.3-1.2-12.5c-0.3-3.2,0.3-7.1-0.4-10.2" />
      <path class="st2" d="M659.4,207.4c1.5,0.6,3-0.8,4.4,0.2c0.9,0.7,2.4,2.3,2.8,3.3c1.5,0.1,3.2-0.2,4.5,0.5c1.6,0.8,1.6,1.4,3.4,0.8
        c1.5-0.5,2.6-1.2,4.1-1.4c1.6-0.1,2.9-0.4,4.4-0.7c0.5,2.6,2.9,4,5.3,3.5c0.4-2.6,0.4-7.1,2.8-8.4c1.5-0.8,1.8-1,2.3-2.6
        c0.5-1.5,1-2.7,2.4-3.4c1.2-0.6,2.5-0.6,3.4-1.7c0.7-0.9,1.3-2.1,1.9-3.1c1.5-2.3,1-5.8,1.7-8.4c0.5-2.2,0-3.4-0.5-5.5
        c-0.3-1.3-0.3-2.8-0.3-4.1c-0.1-1.7-0.4-3.3-0.7-5c-0.5-2.6-0.6-4.9-0.7-7.6c-0.1-1.3,0.1-4.1-0.6-5" />
      <path class="st2" d="M693.8,226.9c-0.7,0.4-0.5,1.2-1,1.8c-0.5,0.6-1.4,1.3-2.1,1.8c-1.4,1-2.2,1.7-3.3,3c-1.4,1.4-2.5,3.5-4,4.8
        c-0.9,0.7-2.1,1.1-3.1,1.7c-1,0.6-2.1,2.3-3.3,2.5l-7.2,24" />
      <path class="st2"
        d="M686.2,213.4c0.4,2.1-0.5,4.9,0.5,6.8c0.7,1.4,3.1,2.5,4.2,3.6c2,2,3.3,4.3,5.5,6c1,0.7,1.8,1.3,3.1,0.6
        c1.1-0.5,1.8-2.6,3-2.9c0.6-0.1,1.3,0.5,2,0.4c0.8-0.1,1.3-0.6,1.9-1.2c1.2-1,2.6-2.3,4.1-2.8c0.5-0.2,1-0.2,1.6-0.2
        c0.2-1.6-0.1-3.4,0.6-4.9c0.8-1.8,2.1-3.3,2.8-5.2c0.7-1.9,0.7-4.1,1.4-5.9c0.6-1.4,2.3-1.5,2.8,0.3c0.9,0.2,2.9-0.5,3.4-1.3
        c0.8-1.1-0.2-3.2,0.5-4.2c0.7-1,2.7-1.2,3.6-2c0.9-0.9,1.2-2.7,1.4-4c0.2-1,0.3-3.5,1.1-4.1c1-0.8,2.6,0.1,3.7,0.6
        c1.6,0.6,2,1.3,3.3,0.3c0.6-0.5,0.3-1,1.3-1c0.3,0,2.1,0.9,2.3,1.1c0.7,0.8,0.2,1.2,1.5,1.7c1.2,0.4,2.7,0.4,3.9,0.6
        c0.9,2.4,0,3.4-0.9,5.6c-0.3,0.6-1.1,2.4-0.9,3.1c0.4,0.9,2.3,1.1,3.2,1.4c1.9,0.5,3.7,0.6,5.3,1.6c1.3,0.8,2.8,2,4.3,2.2" />
      <path class="st2" d="M703.4,182.8c1.4,0.9,1.6,4.2,2,5.7c0.3,0.8,0.5,2.7,1.2,3.3c1.1,0.8,4.3,0,5.6-0.2c1.4-0.2,3.3-0.1,4.6-0.8
        c0,0.9,0.7,2.7,1,3.6c0.7,2,2.1,1.2,3.6,0.3c1.1-0.7,1.9-1.7,3.1-2.2c1.1-0.5,2.3-0.4,3.5-0.5c0.4-1.5,1.8-2.5,3-3.4
        c1.5-1.2,3-1,4.7-1.6c-1.2,1.1,1.3,1.9,1.7,2.7c0.4,0.8-0.3,1.6-0.2,2.2" />
      <path class="st2" d="M735.5,187c1.6,0.3,4.7-0.7,6.4-1.2c2.7-0.8,5.4-1.5,8.1-2.3c2.2-0.6,4.4-0.5,6.3-1.3c-0.1,1.1,0.7,1.7,1.1,2.7
        c0.5,1.2,0.9,2.3,1.4,3.5c1.2,2.5,1.9,5.4,2.9,8c0.6,1.7,0.7,2.9,2.3,2.9c1.1,0,3.3,0.1,4.1-0.4" />
      <path class="st2" d="M756.3,182.1c0.8-1,1.9-1.9,3.5-2.1c1.4-0.2,1.4-0.1,2.7-1.2c1-0.8,3.4-1.9,4-3.1c0.4-0.9-0.7-2.1-1.4-3
        c-2.1-2.8-5.6-3-4.7-7.1c0.4-1.8,0.7-3.5,0.9-5.2c0.1-1.1,1.2-4.1,1.8-4.7" />
      <path class="st2" d="M760.1,180c0.4,0.2,0.8,4.1,1,5.1c0.2,1.2,0.5,2.2,1.2,3.2" />
      <path class="st2" d="M707.4,152.1c0.6,1.2,0.4,2.9,0.7,4.2c0.1,0.4,0.5,2.9,0.7,3c0.4,0.3,2.8-0.7,3.3-0.8c2.8-0.6,5.7-1,8.6-1.7
        c8-2.1,16.4-3.6,24.5-5c2.4-0.4,4.9-1.1,7.3-1.4c1.9-0.3,2.7,0.8,3.8,2.3c1.3,1.8,1.5,2.3,3.7,2.5c1.9,0.2,3.8,0.9,5.6,1.3
        c1.5,0.3,3.1,0.6,4.4,1.4c1.1,0.6,2,1.6,3.3,2.1c-0.3,1.3,0,5.6-1,6.3" />
      <path class="st2" d="M773.3,161.8c0.4-0.2,0.9-3.8,1.5-4.7c0.9-1.4,0.7-1.7,0.3-3.3c-0.8-3.3-0.9-7-1.1-10.5c-0.1-3.1,0-6.4-0.4-9.5
        c-0.3-2.2-1.1-4.5-1.6-6.7c-0.3-1.5-0.5-3-1-4.5c-0.5-1.4-1.2-1.8-2.7-1.5c0.1-2.3-1.7-3.7-2.2-5.9c-0.4-1.7,0.9-2.8,1-4.4
        c0.1-1.6-0.9-2.9-1.3-4.3c-0.4-1.4-0.2-3.1-0.7-4.4" />
      <path class="st2" d="M774.2,143.7c2.2,0.1,5.4-1.8,7.7-2.4c3.4-0.9,6.7-1.8,10.1-2.4c0.9-0.2,1.7-0.5,2.4,0c0.6,0.4,1.2,2,1.7,2.7
        c1.1,1.7,1.7,2.7,2.2,4.6" />
      <path class="st2" d="M790.7,139.1c1.3,3,1.9,7.3,2.1,10.5" />
      <path class="st2"
        d="M773.7,133.8c2.8-0.1,5.8-1.6,8.6-2.2c2.7-0.6,5.4-1.6,8.1-2.1c1.7-0.3,2.8-0.1,4-1.6c1-1.2,1.9-3.2,3.4-3.3" />
      <path class="st2" d="M780.5,132.1c0.9-2.9,0.2-6.7,0-9.6c-0.1-1.8,0-3.5-0.1-5.3c-0.1-1.7,0.1-2.8,0.4-4.5c0.3-1.6-0.2-2.9-0.3-4.2
        c-0.1-1.8,0.9-1,2.2-2.2c1-0.8,0.8-1.6,0.5-2.8c-0.5-2.2-0.5-4.6-0.9-6.8" />
      <path class="st2" d="M786.4,92.8c1.1,0.1,2.1,2.3,2.7,3.2c1,1.7,1.2,3.6,1.8,5.5c1,3.6,1.4,7.2,2.2,10.8c0.4,2,0.6,5.5,2,7.1
        c0.6,0.6,2.2,1.7,2.7,2.1" />
      <path class="st2"
        d="M335.2,49.3c0.6,2.4-1.9,5.7-2.6,8.2c-1,3.9-1.5,7.9-2.4,11.8c-1.3,6-2.4,12.1-3.6,18.1
        c-0.3,1.5-1.2,3.7-0.8,5.2c0.4,1.5,2.2,2.4,2.9,3.6c0.8,1.6-0.6,2.9-1.8,4.5c-1.2,1.6-1.9,3.4-2.6,5.2c-0.7,1.8-1.1,2.9-2.6,4.1
        c-1.2,0.9-2.7,1.4-2.9,3c-0.3,1.8,1.4,2.5,1.7,4c0.3,1.6-1.4,4-1.9,6c-1.4,5.4-2.1,10.9-2.8,16.3c-0.4,2.9-0.6,6.7-1.6,9.3" />
      <path class="st2" d="M259.3,70.2c1.2,1.5,3.9,1,5,2.1c0.9,0.9,0.6,2.2,1.8,3.2c1.6,1.2,1.7,0.5,2.1,2.9c0.3,1.6,0.6,3.2,0.9,4.8
        c2.4-0.7,5.1,2,7.4,2.6c2.5,0.6,2.4-1.4,4.6-1.7c1.8-0.2,4.7,1.8,6.4,2.5c2.8,1.1,4.7,1.2,7.8,0.5c10-2.1,19.6,2.3,29.4,3.8
        c0.4,0.1,0.5,0.1,0.8,0.2" />
      <path class="st2" d="M344.2,51.6c-0.2,4.6-3.1,9.6-2.6,14.4c0.2,1.6,1.4,3.1,1.8,4.9c0.5,1.9,0,3.5,0.1,5c0.2,2.9,3,7.3,4.6,9.9
        c0.5,0.9,1.7,3.8,2.6,4.4c0.7,0.4,2.9,0,3.7,0.1c-0.4,3.2-3.4,7.2-4.8,10.1c-0.6,1.1-1.1,2.8,0.5,3.6c1.5,0.8,3.1-0.8,4.6-1.2
        c0.5,3.6,1.8,7.6,3,11c1,3,3,4.2,4.5,6.8c1,1.7,0.9,2.1,3.1,2c2.3,0,4.3-0.2,6.7,0.2c2.9,0.5,6.5,0.9,9,2.3" />
      <path class="st2" d="M614.1,242c-0.1,0.6,0.7,1.4,0.8,2.1c0.1,0.9-0.2,1.2-0.6,2c-0.5,1.1-0.9,3-1.8,3.8c-1.2,1.1-2.6,1.2-3.3,2.9
        c-0.5,1.1-0.5,2.7-0.7,3.9c-0.5,3.1-1,5.7-2.9,8.2c-1.9,2.5-2.7,5.5-3.8,8.3c-1.4,3.6-3.7,7-5.2,10.6c-1.9,4.6-3.4,7.9-2.7,12.9
        c0.4,2.8,0.7,5.6,1.3,8.4c0.4,1.8,1,3.5,1.2,5.3c0.2,1.8-0.5,3.4-1.2,5.1c-1,2.5-2.1,5-2.9,7.7c-0.6,1.8-1.8,4.8-1.8,6.7
        c0,1.3,0.1,1.2,1.3,1.5c1.5,0.4,3.7,0.1,5.2,0c3.4-0.2,6.9-0.7,10.3-1c1.6-0.2,3.8-0.5,5.4,0c0.1,1.6-0.3,2.9-0.5,4.4
        c-0.2,2,1,1.7,2.3,2.9l0.3,4.2" />
      <path class="st2" d="M629.5,337.2l-1.9-17.6c0,0,1.4-20.3,0.9-21.6c-0.4-1.3-0.9-27.4-0.9-27.4" />
      <path class="st2"
        d="M601.8,273.2l24.5-1.6l12.7-1l24.2-3.3l19.6-2.8h4.1l6.9-4.9l16.2-1l4.2,3l13.1-1.1l15.4,11.7" />
      <path class="st2"
        d="M686.9,264.5l-1.6,4.6l3.8,2.8l2.1,2.1l7.2,6.7l5.4,4.4l2.3,2.4l4.1,2.1l1.5,4.7l3.6,2.3v4.6l5.3,1.7" />
      <path class="st2" d="M657.1,268.1l7.7,30.9l4.2,6.4v3.4l-2.1,2.8l2.1,4.1v3.6v4.1l3.1,6c0,0,34.1-3.9,34.3-3.4
        c0.2,0.5,1.8,3.3,2.3,3.4c0.5,0.2,0.7-3.4,0.7-3.4l-1.3-4.4l2.9,2.1l4-0.3" />
      <path class="st2" d="M643,337l-2-2.5l2-2l-4.1-3.1v-2h9.1l21.7-2.8" />
      <path class="st2" d="M559.5,303.9l35.2-1.4" />
      <path class="st2" d="M550.5,255.1l3.5,22.4l-0.8,16.5" />
      <path class="st2"
        d="M454.6,249.5l34.1,1.9l-0.5,27.9c2.4-0.2,6.9,2.7,8.3,4.4c0.9,1.1,1.3,3.3,2.8,3.9c1,0.4,2.6-0.1,3.7,0
        c1.7,0.2,3.2,0.5,4.9,0.5c1.8,0,4.1-0.4,5.7-0.1c1.5,0.3,2.6,1.7,4.1,1.9c2,0.2,4-0.3,6,0c2,0.3,3.9,0.3,5.8,1
        c1.9,0.7,4.6,1.2,6.6,0.8c3.3-0.5,7-2.4,10.1-1.1c1.1,0.4,2.2,1.1,3.2,1.7c0.8,0.5,1.2,1.1,2.1,1.5c1.7,0.8,4.7,0.5,6.2,1.1h1.6
        c0,3.3-0.5,6.7-0.6,10.1c-0.1,3.5,0.8,7,0.8,10.7c0,2.8,1.9,5.3,3.2,7.8c0.8,1.6,1.7,3.3,2,5.1c0.2,1.1-0.1,2.5,0.3,3.6
        c0.3,0.9,0.9,0.7,0.8,1.7c-0.1,1.3-2.1,2.9-2.6,4.1c-0.5,1.3-0.1,3.1,0.2,4.4c0.4,1.8,0.5,2.1-0.5,3.6c-0.9,1.4-1.4,2.8-1.3,4.7" />
      <path class="st2" d="M405.5,314.3v-3.1l44.1,3.1l5.1-71.7" />
      <path class="st2" d="M344.8,154.9l-17.2,82.4l-2.9,1.9l-4-2.1l-1.9,4.8v6.4l-1.6,4.3l-47.4-69.4l11.5-42.7" />
      <path class="st2"
        d="M317.5,251.4c0.5,2.6-0.1,5.7,0.5,8.3c0.6,2.3,2.7,4.1,4.3,5.9c-1.4,1-3.4,1.7-4.8,2.9
        c-1.9,1.6-0.9,1.8-1.6,4.1c-0.6,2-2.5,2.4-2.9,4.3c-0.4,1.7,0.4,3.6,0.3,5.3c1.2,0.1,1.9,1.2,1.8,2.4c-2.2,0.9-4.8,3.7-6.3,5.4" />
      <path class="st3" d="M281.6,278c1.3,5.2,1.7,35.6,3.4,40.6c1.4,4.1,3.4,8.1,4.6,12.2c1,3.6,1.2,7.4,1.6,11.1c0.5,4,1.4,4.9,4.5,7.7
        c5.8,5.1,10.9,10.1,14.9,16.6c5.9,9.7-8.7,5.5-6.3,12.4c2.4,7,8.8,7.5,14.9,9.4c5.5,1.7,13.3,6.2,14.4,12.3c1,5.4-7.2,13.2-3.4,18.3
        c0.6,0.8,3.4,1.7,4.4,2.4c1.5,1.1,2.9,2.3,4.3,3.6c3.1,3,5.1,7.1,8.7,9.6c3.3,2.3,7,4.3,8.5,8.4c0.6,1.5-0.2,2.7,1.4,3.1
        c1.1,0.3,5-1.6,6-2c7.3-3.1-1.1-12.6-4.5-16c-2.2-2.1-5.3-2.3-6.9-4.3c-2.2-2.7-1.8-6.9-2.3-10c-1.2-7.4-3.7-15.6-7.8-21.6
        c-3.1-4.5-7.8-7.5-9.8-12.5c-1.3-3.2-1.2-6.6-2.4-9.8c-1.1-3.2-3.7-6.7-5.6-9.5c-3.5-5.3-7.3-10.3-10.6-15.7
        c-1.7-2.8-1.8-6.1-2.3-9.3c-0.7-4.1-1.9-8.1-2.5-12.1c3.3-0.2,11.2,1.2,13.6,4.1c1.1,1.4,0.6,3,0.8,4.6c0.4,2.7,1.3,5.3,2,7.8
        c1.6,5.3,4.5,10,6,15.3c1,3.5,0.7,6.1,0.6,9.5c4.6,0.9,5.3,0.7,7.4,4.8c3.2,6.2,9.2,10.7,14.6,14.9c4.7,3.6,9.6,7.9,13.6,12.4
        c0.9,1,2.6,2.8,2.4,4c-0.1,1.1-2,2-2.1,3.7c-0.1,2.6,1.9,3.7,3.4,5.4c2.8,3.1,5.5,6.2,8.2,9.3c5.1,5.7,11,10.6,16.6,15.6
        c5.3,4.7,11.1,19.1,11.1,19.1 M517,453.3c0.2-3.8,3-19.3,2.2-23c-0.8-3.6-3.3-6.5-3.2-10.3c0.1-3.3,2.4-5,2.9-8.1
        c0.2-1.6-0.9-2.8-0.4-4.4c0.4-1.2,1.7-2,2.3-3" />
      <path class="st3" d="M810,59c7.3,2.4,8.1-8.4,10.1-12.8c2.7-5.9,9-6.2,13.6-10.9c0.2,2.6-1.7,4.6-3.2,6.6c-0.6,0.9-0.8,2.7-1.7,3.4
        c-0.6,0.5-3,0.5-3.3,0.9c-2,2.4-2.1,8.2-3.5,11.9c-2.4,6.2,0.1,6.1,5.1,9.2c4.8,3,5.3,5.3,12,5c1.4-3.9,0.3-8.8,0.9-12.8
        c0.2-1.5,1.2-3.2,1.1-4.7c-0.2-2.1-1.9-3-2.1-4.6c-0.4-3.4,2.1-6.9,3.1-10.6c0.9-3.5,0.5-7.7,2-10.9c-3.9-3.7-12.1,1.1-17,1.4
        c-3.2,0.2-5.8,0.8-8.4-1.8l-4.1-20c0,0,2.5-4.5,2.5-5 M261,3.3c1.2,3.5,0.7,7.3,1.8,10.9c1,3.2,3.7,4.4,5.6,7c3,4.2,2.8,6.1,7.5,8.1
        c2.1,0.9,2.1-0.4,4.1,1.9c1.1,1.2,1.3,3.9,1.3,3.9" />
      <path class="st3" d="M250.4,453.3v-70.7l-59.9-55.5H0" />
      <path class="st3" d="M0,406.4h103v-79.2" />
      <line class="st2" x1="630.8" y1="160.5" x2="629.6" y2="170.2" />

      <!--ID-->
      <circle [attr.r]="bigBall('ID', false)" cx="339.224" cy="131.224"
        [ngStyle]="{fill : statesEEUUobj['ID'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('ID', true)" cx="339.224" cy="131.224"
        [ngStyle]="{fill : statesEEUUobj['ID'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!---->
      <circle [attr.r]="bigBall('UT', false)" cx="366.224" cy="195.224" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['UT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('UT', true)" cx="366.224" cy="195.224" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['UT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('KS', false)" cx="508.818" cy="219.091" r="17.4595"
        [ngStyle]="{fill : statesEEUUobj['KS'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('KS', true)" cx="508.448" cy="219.091" r="22.4479"
        [ngStyle]="{fill : statesEEUUobj['KS'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--KANSAS-->
      <circle [attr.r]="bigBall('OR', false)" cx="292.223" cy="103.224" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['OR'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('OR', true)" cx="292.224" cy="103.224" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['OR'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--WYOMING-->
      <circle [attr.r]="bigBall('WY', false)" cx="428.224" cy="149.224" r="6.04805"
        [ngStyle]="{fill : statesEEUUobj['WY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('WY', true)" cx="428.224" cy="149.224" r="7.77606"
        [ngStyle]="{fill : statesEEUUobj['WY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--TEXAS-->
      <circle [attr.r]="bigBall('TX', false)" cx="491.982" cy="320.753" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['TX'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('TX', true)" cx="491.983" cy="320.753" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['TX'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--PENSILVANIA-->
      <circle [attr.r]="bigBall('PA', false)" cx="731.223" cy="169.224" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['PA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('PA', true)" cx="731.224" cy="169.224" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['PA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--GEORGIA-->
      <circle [attr.r]="bigBall('GA', false)" cx="697.754" cy="305.788" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['GA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('GA', true)" cx="697.755" cy="305.788" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['GA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--CALIFORNIA_UP-->
      <circle [attr.r]="bigBall('CA', false)" cx="249.223" cy="158.224" r="8.72973"
        [ngStyle]="{fill : statesEEUUobj['CA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('CA', true)" cx="249.224" cy="158.224" r="11.2239"
        [ngStyle]="{fill : statesEEUUobj['CA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--CALIFORNIA_DOWN-->
      <circle [attr.r]="bigBall('SC', false)" cx="278.74" cy="226.741" r="4.46547"
        [ngStyle]="{fill : statesEEUUobj['SC'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('SC', true)" cx="278.741" cy="226.741" r="5.74131"
        [ngStyle]="{fill : statesEEUUobj['SC'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--ARIZONA-->
      <circle [attr.r]="bigBall('AZ', false)" cx="344.741" cy="256.241" r="5.63213"
        [ngStyle]="{fill : statesEEUUobj['AZ'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('AZ', true)" cx="344.741" cy="256.241" r="7.24131"
        [ngStyle]="{fill : statesEEUUobj['AZ'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--NEVADA-->
      <circle [attr.r]="bigBall('NV', false)" cx="313.938" cy="184.936" r="6.17265"
        [ngStyle]="{fill : statesEEUUobj['NV'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NV', true)" cx="313.936" cy="184.936" r="7.93626"
        [ngStyle]="{fill : statesEEUUobj['NV'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--NEBRASKA-->
      <circle [attr.r]="bigBall('NE', false)" cx="495.723" cy="180.454" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['NE'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NE', true)" cx="495.724" cy="180.454" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['NE'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('NM', false)" cx="417.058" cy="265.058" r="3.93392"
        [ngStyle]="{fill : statesEEUUobj['NM'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NM', true)" cx="417.058" cy="265.058" r="5.0579"
        [ngStyle]="{fill : statesEEUUobj['NM'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--MICHIGAN-->
      <circle [attr.r]="bigBall('MI', false)" cx="650" cy="140" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['MI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MI', true)" cx="650" cy="140" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['MI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('IN', false)" cx="642.741" cy="194.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['IN'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('IN', true)" cx="642.741" cy="194.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['IN'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('AR', false)" cx="570.741" cy="270.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['AR'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('AR', true)" cx="570.741" cy="270.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['AR'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('MA', false)" cx="792.741" cy="130.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['MA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MA', true)" cx="792.741" cy="130.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['MA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('CT', false)" cx="783.741" cy="143.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['CT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('CT', true)" cx="783.741" cy="143.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['CT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('OK', false)" cx="523.741" cy="264.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['OK'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('OK', true)" cx="523.741" cy="264.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['OK'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('MO', false)" cx="576.482" cy="223.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['MO'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MO', true)" cx="576.482" cy="223.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['MO'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('IA', false)" cx="563.482" cy="172.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['IA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('IA', true)" cx="563.482" cy="172.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['IA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('IL', false)" cx="612.482" cy="186.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['IL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('IL', true)" cx="612.482" cy="186.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['IL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('CO', false)" cx="437.482" cy="217.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['CO'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('CO', true)" cx="437.483" cy="217.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['CO'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('MS', false)" cx="614.482" cy="303.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['MS'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MS', true)" cx="614.482" cy="303.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['MS'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('WA', false)" cx="299.482" cy="53.4827" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['WA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('WA', true)" cx="299.482" cy="53.4826" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['WA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('MT', false)" cx="413.482" cy="85.4827" r="4.26427"
        [ngStyle]="{fill : statesEEUUobj['MT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MT', true)" cx="413.482" cy="85.4826" r="5.48263"
        [ngStyle]="{fill : statesEEUUobj['MT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('WI', false)" cx="595.482" cy="128.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['WI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('WI', true)" cx="595.482" cy="128.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['WI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('MN', false)" cx="550.482" cy="98.4827" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['MN'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('MN', true)" cx="550.482" cy="98.4826" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['MN'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('ND', false)" cx="488.482" cy="91.4827" r="9.73573"
        [ngStyle]="{fill : statesEEUUobj['ND'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('ND', true)" cx="488.483" cy="91.4827" r="12.5174"
        [ngStyle]="{fill : statesEEUUobj['ND'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('SD', false)" cx="496.482" cy="133.483" r="12.8198"
        [ngStyle]="{fill : statesEEUUobj['SD'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('SD', true)" cx="496.482" cy="133.483" r="16.4826"
        [ngStyle]="{fill : statesEEUUobj['SD'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('NC', false)" cx="726.482" cy="237.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['NC'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NC', true)" cx="726.482" cy="237.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['NC'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('ME', false)" cx="805.482" cy="84.4827" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['ME'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('ME', true)" cx="805.482" cy="84.4826" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['ME'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('NH', false)" cx="790.517" cy="116.517" r="3.51351"
        [ngStyle]="{fill : statesEEUUobj['NH'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NH', true)" cx="790.517" cy="116.517" r="4.51737"
        [ngStyle]="{fill : statesEEUUobj['NH'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('VT', false)" cx="773.517" cy="103.517" r="3.51351"
        [ngStyle]="{fill : statesEEUUobj['VT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('VT', true)" cx="773.517" cy="103.517" r="4.51737"
        [ngStyle]="{fill : statesEEUUobj['VT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('NY', false)" cx="754.482" cy="126.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['NY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('NY', true)" cx="754.482" cy="126.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['NY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('FL', false)" cx="710.849" cy="345.071" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['FL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('FL', true)" cx="710.849" cy="345.072" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['FL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('LA', false)" cx="580.482" cy="326.483" r="5.81982"
        [ngStyle]="{fill : statesEEUUobj['LA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('LA', true)" cx="580.482" cy="326.483" r="7.48263"
        [ngStyle]="{fill : statesEEUUobj['LA'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('AK', false)" cx="173.482" cy="373.483" r="3.51383"
        [ngStyle]="{fill : statesEEUUobj['AK'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('AK', true)" cx="173.5" cy="373.5" r="10.5"
        [ngStyle]="{fill : statesEEUUobj['AK'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('KY', false)" cx="658.741" cy="237.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['KY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('KY', true)" cx="658.741" cy="237.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['KY'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('WV', false)" cx="704.741" cy="208.741" r="3.68769"
        [ngStyle]="{fill : statesEEUUobj['WV'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('WV', true)" cx="704.741" cy="208.741" r="4.74131"
        [ngStyle]="{fill : statesEEUUobj['WV'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('CT', false)" cx="751.741" cy="188.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['CT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('CT', true)" cx="751.741" cy="188.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['CT'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('OH', false)" cx="683.741" cy="183.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['OH'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('OH', true)" cx="683.741" cy="183.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['OH'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('AL', false)" cx="649.741" cy="295.741" r="2.90991"
        [ngStyle]="{fill : statesEEUUobj['AL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('AL', true)" cx="649.741" cy="295.741" r="3.74131"
        [ngStyle]="{fill : statesEEUUobj['AL'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
      <!--UTAH-->
      <circle [attr.r]="bigBall('HI', false)" cx="86.7408" cy="377.741" r="4.86787"
        [ngStyle]="{fill : statesEEUUobj['HI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}" />
      <circle [attr.r]="bigBall('HI', true)" cx="86.7414" cy="377.741" r="6.25869"
        [ngStyle]="{fill : statesEEUUobj['HI'] > this.mapData[4]?.value ? (darkMode) ? '#FFFFFF' : bubbleColorsHard[infotype] : bubbleColors[infotype]}"
        fill-opacity="0.3" />
    </svg>
    <div class="movanoMap_Body_States movanoMap_Large" >
      <span class="movanoMap_Body_States_Tittle">States ranking</span>

      <div *ngIf="mapData.length > 1">
      <div class="movanoMap_Body_States_State" *ngFor="let states of mapData.slice(0, 10); index as i">
        <div 
        class="
        movanoMap_Body_States_State_{{i}} 
        movanoMap_Body_States_State_{{i}}_{{mapTittle[infotype]}}"
        >
          <span>{{i+1}}</span>
          <span>{{stateNamesEEUU.get(states.state)}}</span>
        </div>
        <span 
        style="opacity: 0.5;" 
        class="
        movanoMap_Body_States_State_{{i}} 
        movanoMap_Body_States_State_{{i}}_{{mapTittle[infotype]}}" *ngIf="i < 3">{{states.value}}</span>
      </div>
      <div class="movanoMap_Body_States_State">
        <span>...</span>
        <span>...</span>
      </div>
      <div class="
      movanoMap_Body_States_State 
      movanoMap_Body_States_State_{{mapTittle[infotype]}}_Last">
        <div>
          <span>{{mapData.length-1}}</span>
          <span>{{stateNamesEEUU.get(mapData[mapData.length-1]?.state)}}</span>
        </div>
        <span class="lastValue">{{mapData[mapData.length-1]?.value}}</span>
      </div>
      </div>
    </div>
  </div>
</div>