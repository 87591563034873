<div class="movanoUserPanel" [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoUserPanel_Header_Tittle">
    <span>{{ data.user }} ticket</span>
    <svg
      style="margin-bottom: 10px"
      (click)="closePanel()"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill="#C4D9EE"
      />
    </svg>
    <a (click)="openTicketsPanel(data.user)">Back to all tickets</a>
  </div>

  <div *ngIf="!loadingUserDeviceList" class="movanoTableBox movanoDeviceTable">
    <p-table
      id="userDevicesTable"
      #userTable
      class="movano-Table"
      [value]="tickets"
      [paginator]="false"
      scrollable]="true"
      scrollHeight="55vh"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th>{{ LITERALS.DEVICES.TICKET_TITLE }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_CREATED }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_STATUS }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex">
        <tr
          [ngStyle]="{
            background: 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td>Lorem ipsum dolor sit</td>
          <td>25/02/23</td>
          <td>
            <div class="movanoTable_Obj_Badge">new!</div>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="Info">
      <hr style="border: 1px solid #c4d9ee" />
      <span class="Info_Title" style="display: flex">Description</span>
      <span class="Info_Subtitle">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum.</span
      >
    </div>
  </div>

  <div *ngIf="loadingUserDeviceList">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
