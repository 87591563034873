import { Component, EventEmitter, Input, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FilterService } from 'primeng/api';
import { Table } from 'primeng/table';
import { UserPanelTimeSelectorComponent } from 'src/app/users/panel/time-selector/user-panel-time-selector.component';
import { ntfInsightJobTable } from 'src/app/shared/movano-interfaces';
import { lastValueFrom } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import * as lit from '../../../shared/movano-literals';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'insight-job-executions-filter',
  templateUrl: './insight-job-executions-filter.component.html',
  styleUrls: ['./insight-job-executions-filter.component.scss']
})
export class InsightJobExecutionsFilterComponent {
  protected LITERALS = lit;
  protected filter_Status : string = 'All Status';
  protected filter_development : boolean = false;
  protected filter_staging : boolean = false;
  protected filter_production : boolean = false;
  protected searchDisplay : boolean = false;
  protected filterDisplay : boolean = false;
  protected nextIsPosible: boolean = false;
  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;
  protected insightJobs: ntfInsightJobTable[] = [];
  protected loadingData: boolean = false;
  protected selectedStatuses: string[] = [];

  protected actualTimeZone: string = '';
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected lastselectedDate : number = 0;
  protected dataAvailable: boolean = false;
  @Input() name?: string;
  @Input() filteredUsers: ntfInsightJobTable[] = [];
  @Input() isFiltered: boolean = false;
  /**
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */
  protected selectedDate: number = 2;
  protected timeZones : string[] = []; //UTC, Madrid, California... etc
  @ViewChild('list_calendar') daySelector !: UserPanelTimeSelectorComponent;
  @ViewChildren(MatCheckbox) checkboxes!: QueryList<MatCheckbox>;
  @Input() table !: Table;
  @Output() getInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @Output() insightJobsChanged: EventEmitter<ntfInsightJobTable[]> = new EventEmitter<ntfInsightJobTable[]>();
  @Output() dataAvailableChange = new EventEmitter<boolean>();
  @Output() loadingDataChange = new EventEmitter<boolean>();


  constructor(private filterService: FilterService,  protected movanoService: MovanoService){
    const customFilterName = 'multi-select-in';
    this.filterService.register(customFilterName, (value : any, filter : any): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      let selected : boolean = false;
      //console.log(filter);
      filter.forEach((element : any) => {
        if(element === value){
          selected = true;
          return;
        }
      });
      return selected;
    });
  }

  ngOnInit(): void {
    this.today = new Date(Date.now());
    this.emitGetInfo();

    const customFilterName = 'multi-select-in';
    this.filterService.register(customFilterName, (value : any, filter : any): boolean => {
      if (filter == null || filter.length == 0) {
        return true;
      }
      let selected : boolean = false;
      //console.log(filter);
      filter.forEach((element : any) => {
        if(element === value){
          selected = true;
          return;
        }
      });
      return selected;
    });
  }

  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');
    this.isFiltered = true;
  }

  resetFilter(_table: Table) {
    _table.reset();

    this.selectedStatuses = [];
    this.isFiltered = false;
  this.checkboxes.forEach(checkbox => checkbox.checked = false);
  }

  onStatusChange(status: string, event: any, table: Table) {
    if (event.checked) {
      this.selectedStatuses.push(status);
    } else {
      const index = this.selectedStatuses.indexOf(status);
      if (index > -1) {
        this.selectedStatuses.splice(index, 1);
      }
    }
    this.applyFilter();
  }




     applyFilter() {
      this.table.reset();

      if (this.selectedStatuses.length > 0) {
        this.table.filter(this.selectedStatuses, 'status_last_job', 'equals');
      }

    }

    exportCSV() {
      if (this.insightJobs && this.insightJobs.length > 0) {
        const csvData = this.convertToCSV(this.insightJobs);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'insightJobs.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error('No data to export');
      }
    }

    convertToCSV(objArray: any[]): string {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = '';

      for (const index in array[0]) {
        if (array[0].hasOwnProperty(index)) {
          const header = index + ',';
          row += header;
        }
      }

      row = row.slice(0, -1);
      str += row + '\r\n';

      for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
          if (array[i].hasOwnProperty(index)) {
            if (line !== '') line += ',';
            line += array[i][index];
          }
        }

        str += line + '\r\n';
      }
      return str;
    }

 /*    console.log(this.filter_development);
    console.log(this.filter_staging);
    console.log(this.filter_production);
    let statusFilter : string[] = [];
    if(this.filter_development) statusFilter.push('dev');
    if(this.filter_staging) statusFilter.push('staging');
    if(this.filter_production)  statusFilter.push('prod');

    this.table.filter(statusFilter, 'status', 'multi-select-in'); */

  /**
   * Advances to the next date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next date is not possible, exit the function
    if (!this.nextIsPosible) return;
    // Depending on the selected date range, advance to the next day, week, or month
    switch (+this.selectedDate) {
      case 0: //Next day
      this.selectedDate = 0;
      this.daySelected?.setDate(this.daySelected.getDate() + 1);
      break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() + 1);
        break;
      case 1: //Next week
        this.daySelected?.setDate(this.daySelected.getDate() + 7);
        break;
      case 2: //Next month
        this.daySelected?.setMonth(this.daySelected.getMonth() + 1);
        break;
    }
    console.log('Next date:', this.daySelected);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.emitGetInfo();
  }

  /**
   * Goes to the previous date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    // Depending on the selected date range, go to the previous day, week, or month
    switch (+this.selectedDate) {
      case 0: //Previus day
        this.selectedDate = 0;
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        console.log(this.selectedDate);
        break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 1: //Previus week
        this.daySelected?.setDate(this.daySelected.getDate() - 7);
        break;
      case 2: //Previus month
        this.daySelected?.setMonth(this.daySelected.getMonth() - 1);
        break;
    }
    console.log('Previous date:', this.daySelected);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.emitGetInfo();
  }

  emitGetInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    this.getInfo.emit(calendarData);
    console.log('Parent component emitting:', calendarData);
    this.loadData(calendarData);
}
handleDateSelection(selectedDate: Date) {
  const calendarData: [any, boolean, Date | Date[], string, any] = [
    null,
    false,
    selectedDate,
    'timezone',
    null
  ];

  this.emitGetInfo(calendarData);
}

  // async getInsightJobTableByName(name: string) {
  //   this.loadingData = true;
  //   this.loadingDataChange.emit(this.loadingData);
  //   console.log(`Fetching insights for name: ${name}`);

  //   try {

  //   const today = new Date();
  //   const startDate = new Date();
  //   startDate.setDate(today.getDate() - 31);


  //   const res: ntfInsightJobTable[] = await lastValueFrom(this.movanoService.getInsightJobByName(name, startDate, today));
  //   console.log('API Responseeeeeeeeeeeeeeeeeeeeeeeeee:', res);

  //     this.insightJobs = res;
  //     this.insightJobsChanged.emit(this.insightJobs);
  //     this.dataAvailable = this.insightJobs.length > 0;
  //     this.dataAvailableChange.emit(this.dataAvailable);
  //   } catch (error) {
  //     console.error(error);
  //     this.insightJobs = [];
  //     this.insightJobsChanged.emit(this.insightJobs);
  //     this.dataAvailable = false;
  //     this.dataAvailableChange.emit(this.dataAvailable);
  //   } finally {
  //     this.loadingData = false;
  //     this.loadingDataChange.emit(this.loadingData);
  //   }
  // }



  async loadData(calendarData?: [any, boolean, Date | Date[], string, any]) {

    let dateStart: Date | undefined;
    let dateEnd: Date | undefined;
    let timeFrame: string | undefined = '';
    let res: any


    this.loadingData = true;
    this.loadingDataChange.emit(this.loadingData);

    try {
      if (!calendarData) {
        this.selectedDate = 2;
      } else {
        console.log(calendarData);
        this.lastselectedDate = this.selectedDate;
        this.selectedDate = parseInt(calendarData[0]);
        this.nextIsPosible = calendarData[1];
        if (this.selectedDate !== 3) {
          this.daySelected = calendarData[2] as Date;
        } else {
          this.rangeSelected = calendarData[2] as Date[];
        }
        this.actualTimeZone = calendarData[3];
      }

      this.dataAvailable = true;
      this.dataAvailableChange.emit(this.dataAvailable);
      this.loadingData = true;
      this.loadingDataChange.emit(this.loadingData);




      if (this.selectedDate === 0) {
        // Handle "today" or any custom day
        timeFrame = 'today'

      } else if (this.selectedDate === 1) {
        // Handle "last week"
        timeFrame = 'lastWeek'
      } else if (this.selectedDate === 2) {
        // Handle " last month"
        timeFrame = 'lastMonth'

      } else if (this.selectedDate === 3) {
        // Handle "range"
        dateStart = this.rangeSelected[0];
        dateEnd = this.rangeSelected[1];
      }
      else if (this.selectedDate === 4) {
        // Handle "last 7 days"
        timeFrame = 'last7Days'
      }
      else if (this.selectedDate === 5)
      {
        timeFrame = 'actualYear'
      }


      //  if (this.name) {
      //   await this.getInsightJobTableByName(this.name);
      // } else {
        res = await lastValueFrom(this.movanoService.getNtfnInsightJob(
          dateStart, dateEnd, timeFrame
        ));

        console.log('API Response:', res.data);

        this.insightJobs = res.data;
        this.insightJobsChanged.emit(this.insightJobs);

        this.dataAvailable = this.insightJobs.length > 0;
        this.dataAvailableChange.emit(this.dataAvailable);
      // }
    }
     catch (error) {
        console.log(error);
        this.dataAvailable = false;
       this.dataAvailableChange.emit(this.dataAvailable);

    }
    finally {
      this.loadingData = false;
      this.loadingDataChange.emit(this.loadingData);
  }
}

  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85; // Ajusta este valor según tus necesidades

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    //console.log(checkbox);
    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }

}
