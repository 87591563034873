import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { barSideChart } from './shared/movano-interfaces';


type DashboardType = 'Sales' | 'Activity' | 'Users' | 'SpotChecks' | 'Sleep analysis' | 'Workout usage' | 'Notifications';

@Injectable({
  providedIn: 'root'
})
export class DashboardTypeService {
  private dashboardType: BehaviorSubject<DashboardType> = new BehaviorSubject<DashboardType>('Users');
  private dashboradRotation : DashboardType[] = ['Sales', 'Activity', 'Users', 'SpotChecks'];

  private dateOptSelected: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private barSideData: BehaviorSubject<barSideChart | undefined> = new BehaviorSubject<barSideChart | undefined>(undefined);

  private date: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([new Date(),new Date()]);

  constructor() { }
  //DASHBOARDTYPE FUNCTIONS
  getDashboardType() {
    return this.dashboardType.asObservable();
  }

  setDashboardType(dshType : DashboardType) {
    this.dashboardType.next(dshType);
  }

  async next(dashboardNow : string){
    const dashboardPos : number = this.dashboradRotation.indexOf(dashboardNow as DashboardType);
    if(dashboardPos === -1) return;
    const nextIndex: number = (dashboardPos + 1) % this.dashboradRotation.length;
    const nextDashBoardType : DashboardType = this.dashboradRotation[nextIndex];
    this.dashboardType.next(nextDashBoardType);
  }

  async previous(dashboardNow : string) {
    const dashboardPos: number = this.dashboradRotation.indexOf(dashboardNow as DashboardType);
    if(dashboardPos === -1) return;
    const previousIndex: number = (dashboardPos - 1 + this.dashboradRotation.length) % this.dashboradRotation.length;
    const previousDashBoardType: DashboardType = this.dashboradRotation[previousIndex];
    this.dashboardType.next(previousDashBoardType);
  }

  //DATEOPTSELECTED FUNCTIONS
  getDateOptSelected() {
    return this.dateOptSelected.asObservable();
  }

  setDateOptSelected(dateOpt : number) {
    this.dateOptSelected.next(dateOpt);
  }

  //DATEOPTSELECTED FUNCTIONS
  getBarSideData() {
    return this.barSideData.asObservable();
  }

  setBarSideData(bsc : barSideChart) {
    this.barSideData.next(bsc);
  }

  //DATEOPTSELECTED FUNCTIONS
  getDate() {
    return this.date.asObservable();
  }

  setDate(dt : Date[]) {
    this.date.next(dt);
  }
}
