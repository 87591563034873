<div class="panelTimeSlctr">
  <mat-form-field class="panelTimeSlctr_TimeSelector">
    <mat-icon matPrefix>calendar_today</mat-icon>
    <mat-select #dateSelect  autoclose="false" [(ngModel)]="slctdDateOpt"
      placeholder="{{tittleText()}}"
      panelClass="movano-select" [disableOptionCentering]="true">
      <mat-select-trigger>{{tittleText()}}</mat-select-trigger>
      <div [ngStyle]="{position: 'relative', height: (calendarUp) ? '270px' : 'unset'}">
        <mat-option (click)="daySelected = today; getInfo(0)" value=0>Today</mat-option>
        <mat-option (click)="getInfo(1)" value=1>Last Week</mat-option>
        <mat-option (click)="getInfo(2)" value=2>Last Month</mat-option>
        <mat-option (click)="getInfo(4)" value=4>Last 7 days</mat-option>
        <mat-option (click)="getInfo(5)" value=5>Year to date</mat-option>
        <div (click)="slctdDateOpt = 3; calendarUp = true;" class="movano-select_fakeOption">Custom range</div>
        <div *ngIf="calendarUp" style="position: absolute; top: 0px; width: 100%;">
          <p-calendar *ngIf="slctdDateOpt === 3" class="inside-calendar" [maxDate]="today!" [inline]="true"
            selectionMode="range" (onSelect)="getInfo(3); " dateFormat="d MM ‘y" rangeSeparator="|"
            [(ngModel)]="rangeSelected">
            <ng-template pTemplate="header">
              <svg (click)="calendarUp = false" class="inside-calendar_CloseSVG" viewBox="0 0 12 12" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                  fill="#C4D9EE" />
              </svg>
            </ng-template>
            <ng-template pTemplate="date" let-date>
              <div *ngIf="rangeSelected[0]"
                [ngClass]="{'slctdDateOpt' : (date.day === rangeSelected[0].getDate() && date.month === rangeSelected[0].getMonth())}">
              </div>
              <div *ngIf="rangeSelected[1]"
                [ngClass]="{'interDate' : ((date.day != rangeSelected[0].getDate() || date.month !== rangeSelected[0].getMonth()) && (date.day != rangeSelected[1].getDate() || date.month !== rangeSelected[1].getMonth()) ) }">
              </div>
              <div *ngIf="rangeSelected[1]"
                [ngClass]="{ 'slctdDateOpt' : (date.day === rangeSelected[1].getDate() && date.month === rangeSelected[1].getMonth())}">
              </div>
              {{ date.day }}
            </ng-template>
          </p-calendar>
        </div>
      </div>
    </mat-select>

    <svg matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
        fill="#284767" />
    </svg>
  </mat-form-field>
</div>
