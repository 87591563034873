import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as lit from '../../../shared/movano-literals';
import { Table } from 'primeng/table';
import { DarkModeService } from '../../../dark-mode.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { ntfListTable } from '../../../shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';

interface AutoCompleteCompleteEvent {
  originalEvent: Event;
  query: string;
}
interface msgSelected {
  name : string,
  filtered : boolean
}

@Component({
  selector: 'notifiation-list-side-filter',
  templateUrl: './notifiation-list-side-filter.component.html',
  styleUrls: ['./notifiation-list-side-filter.component.scss']
})
export class NotifiationListSideFilterComponent  {

  protected LITERALS = lit;
  protected filteredNotifications: string[] = [];
  protected selectedNotifications ?: string;
  //protected notificationsToShow : notificationsSelected[] = [];
  protected notificationsToShow : Map<string, boolean> = new Map<string, boolean>();
  protected msgTypes : Map<string, msgSelected[]> = new Map<string, msgSelected[]>();
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  @Input() table !: Table;
  @Output() closeHimself = new EventEmitter<void>();
  @Output() sentCustomerFilterChange = new EventEmitter<string>();
  @Output() sentAllUnchecked = new EventEmitter<boolean>();
  @Output() sentInsightAppName = new EventEmitter<string>();
  checkboxYes: boolean = false;
  checkboxNo: boolean = false
  activeFilters: any = {};
  allSelected: { [key: string]: boolean } = {};
  protected ntfLists: ntfListTable[] = [];
  protected inputValue: string = '';
  //PASAR IS FILTERED COMO INPUT
  msg: any;
  //notificationsFilteredByNameAndType: ntfListTable[] = [];

  constructor(private darkModeSvc: DarkModeService,
    protected movanoService: MovanoService,
  ) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  resetFilter() {
    this.sentAllUnchecked.emit(true);
  }

  saveInputValue() {
    if (!this.inputValue) return;

    if (this.msgTypes.size <= 0) {
      this.table._value.forEach((ntf: ntfListTable) => {
        const ntfName: string = ntf.name;
        const msgType: string = ntf.message_type;
        const msgFiler: msgSelected = { name: msgType, filtered: false };
        if (!this.msgTypes.has(ntfName)) {
          this.msgTypes.set(ntfName, []);
        }
        if (!this.msgTypes.get(ntfName)?.some(msgSlct => msgSlct.name == msgType)) {
          this.msgTypes.get(ntfName)?.push(msgFiler);
        }
      });
    }

    this.notificationsToShow.set(this.inputValue, false);
    this.sentInsightAppName.emit(this.inputValue);
    this.inputValue = '';
  }

/*    filterNotifications(event: AutoCompleteCompleteEvent) {
    this.filteredNotifications = [];
    let query = event.query.toLowerCase();
    this.table._value.forEach((ntf: ntfListTable) => {
      if (ntf.name.toLowerCase().indexOf(query) == 0 && !this.notificationsToShow.has(ntf.name) && !this.filteredNotifications.includes(ntf.name)) {
        this.filteredNotifications.push(ntf.name);
      }
    });
  }
 */
  //    filterNotifications(event: AutoCompleteCompleteEvent) {
  //     this.filteredNotifications = [];
  //     let query = event.query.toLowerCase();

  //     this.table._value.forEach((ntf: ntfListTable) => {
  //       if (ntf.name && ntf.name.toLowerCase().indexOf(query) == 0 &&
  //           !this.notificationsToShow.has(ntf.name) &&
  //           !this.filteredNotifications.includes(ntf.name)) {
  //         this.filteredNotifications.push(ntf.name);
  //       }
  //     });
  //   }



  // selectNotification(){
  //   if(!this.selectedNotifications) return;
  //   console.log(this.selectedNotifications )
  //   console.log(this.msgTypes);
  //   if(this.msgTypes.size <= 0){
  //     this.table._value.forEach((ntf: ntfListTable) => {
  //       const ntfName : string = ntf.name;
  //       const msgType : string = ntf.message_type;
  //       const msgFiler : msgSelected = {name: msgType, filtered: false};
  //       if(!this.msgTypes.has(ntfName)){
  //         this.msgTypes.set(ntfName, []);
  //       }
  //       if(!this.msgTypes.get(ntfName)?.some(msgSlct => msgSlct.name == msgType)){
  //         this.msgTypes.get(ntfName)?.push(msgFiler);
  //       }
  //     })
  //   }
  //   console.log(this.msgTypes);

  //   this.notificationsToShow.set(this.selectedNotifications, false);
  //   console.log(this.notificationsToShow);
  //   this.selectedNotifications = undefined;

  // }

  expandNtf(ntfToShow : string){
    this.notificationsToShow.set(ntfToShow, !this.notificationsToShow.get(ntfToShow));
  }


  selectAllMsg(ntf: string) {
    this.msgTypes.get(ntf)?.forEach((slc: msgSelected) => {
      slc.filtered = true;
      this.filterByMsgType(ntf, slc);
    });
    this.allSelected[ntf] = true;
  }


   removeNotification(notificationKey: string) {
    this.notificationsToShow.delete(notificationKey);
  }



filterByMsgType(notificationKey: string, msg: any) {
  this.allSelected[notificationKey] = false;

  if (!this.activeFilters[notificationKey]) {
    this.activeFilters[notificationKey] = [];
  }

  if (msg.filtered) {
    this.activeFilters[notificationKey].push(msg.name);
  } else {
    if (this.activeFilters[notificationKey]) {
      this.activeFilters[notificationKey] = this.activeFilters[notificationKey].filter((filter: any) => filter !== msg.name);
    }
  }
  this.applyFilters();
}


filterBySentCustomer() {

  if (this.checkboxYes && !this.checkboxNo) {
    this.activeFilters['sentCustomer'] = [true];
  } else if (!this.checkboxYes && this.checkboxNo) {
    this.activeFilters['sentCustomer'] = [false];
  } else {
    delete this.activeFilters['sentCustomer'];
  }

  this.applyFilters();
}

  async applyFilters() {

    if (this.activeFilters['sentCustomer']) {
      const sentCustomerFilter = this.activeFilters['sentCustomer'][0];
      const valueToEmit = sentCustomerFilter ? 'YES' : 'NO';
      this.sentCustomerFilterChange.emit(valueToEmit);
    }

    const allUnchecked = (Object.values(this.activeFilters) as Array<any>).every(filters => filters.length === 0);
    this.sentAllUnchecked.emit(allUnchecked);

    if (allUnchecked) {
      this.resetFilter();
    }

  }

}





    // if (activeFilters.length > 0) {
    //   let notificationsFilteredByNameAndType: ntfListTable[] = [];

    //   activeFilters.forEach(activeFilter => {
    //     if (activeFilter !== 'sentCustomer') {
    //       const filteredByName = notificationsFiltered.filter((byName: ntfListTable) => byName.name === activeFilter);
    //       console.log(`Filtered by name (${activeFilter}):`, filteredByName);

    //       filteredByName.forEach((filteredItem: ntfListTable) => {
    //         if (this.activeFilters[activeFilter].includes(filteredItem.message_type)) {
    //           notificationsFilteredByNameAndType.push(filteredItem);
    //           console.log('Filtered by name and type:', notificationsFilteredByNameAndType);
    //         }
    //       });
    //     }
    //   });

    //   if (notificationsFilteredByNameAndType.length > 0) {
    //     notificationsFiltered = notificationsFilteredByNameAndType;
    //     console.log('Notifications after name and type filters:', notificationsFiltered);
    //   }
    // }

    // this.table.filteredValue = notificationsFiltered;
    // console.log("Final notificationsFiltered:", notificationsFiltered);
