<div class="movanoUserPanel" [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoUserPanel_Header_Tittle">
    <span>{{ data.user }} tickets</span>
    <svg
      style="margin-bottom: 10px"
      (click)="closePanel()"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill="#C4D9EE"
      />
    </svg>
    <span>3 tickets</span>
  </div>

  <div *ngIf="!loadingUserDeviceList" class="movanoTableBox movanoDeviceTable">
    <p-table
      id="userDevicesTable"
      #userTable
      class="movano-Table"
      [value]="tickets"
      [paginator]="false"
      [scrollable]="true"
      scrollHeight="55vh"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th>{{ LITERALS.DEVICES.TICKET_CREATED }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_OPENED }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_SOLVED }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_TITLE }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_STATUS }}</th>
          <th>{{ LITERALS.DEVICES.TICKET_INFO }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex">
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td>25/01/23</td>
          <td>25/02/23</td>
          <td>25/03/23</td>
          <td>Lorem ipsum dolor sit</td>
          <td>
            <div class="movanoTable_Obj_Badge">new!</div>
          </td>
          <td>
            <svg
              style="cursor: pointer"
              (click)="openTicketsInfoPanel(data.user)"
              width="max(1vw,14px)"
              height="max(1vw,14px)"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="remove_red_eye" clip-path="url(#clip0_1510_2444)">
                <path
                  id="Vector"
                  d="M7.00004 3.79167C9.21087 3.79167 11.1825 5.03417 12.145 7C11.1825 8.96583 9.21671 10.2083 7.00004 10.2083C4.78337 10.2083 2.81754 8.96583 1.85504 7C2.81754 5.03417 4.78921 3.79167 7.00004 3.79167ZM7.00004 2.625C4.08337 2.625 1.59254 4.43917 0.583374 7C1.59254 9.56083 4.08337 11.375 7.00004 11.375C9.91671 11.375 12.4075 9.56083 13.4167 7C12.4075 4.43917 9.91671 2.625 7.00004 2.625ZM7.00004 5.54167C7.80504 5.54167 8.45837 6.195 8.45837 7C8.45837 7.805 7.80504 8.45833 7.00004 8.45833C6.19504 8.45833 5.54171 7.805 5.54171 7C5.54171 6.195 6.19504 5.54167 7.00004 5.54167ZM7.00004 4.375C5.55337 4.375 4.37504 5.55333 4.37504 7C4.37504 8.44667 5.55337 9.625 7.00004 9.625C8.44671 9.625 9.62504 8.44667 9.62504 7C9.62504 5.55333 8.44671 4.375 7.00004 4.375Z"
                  [ngStyle]="{ fill: darkMode ? 'white' : '#054280' }"
                />
              </g>
              <defs>
                <clipPath id="clip0_1510_2444">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div *ngIf="loadingUserDeviceList">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
