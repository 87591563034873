<div class="movanoStats" style="margin-top: 1%">
  <span class="movanoStats_Tittle">Notification statistics</span>
  <div style="display: flex; flex-direction: row">
    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 0.5em; padding-top: 3%;">
      <div class="movanoStats_LegendUser" style="gap: 0.4em;margin-right: 165px;">By insight application</div>
      <div class="movanoStats_DognutStats">
        <div echarts [options]="option_InsightApplication ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
        <div class="movanoStats_DognutStats_Highlight">
          <span *ngIf="insightApplicationPer.length > 0" style="font-size: 167%; font-weight: 700; color: '#061E37'"
            >{{ insightApplicationPer[0] }}%</span
          >
          <div *ngIf="insightApplicationPer.length > 1" style="display: flex; gap: 0.33em">
            <span *ngIf="insightApplicationPer.length > 1" style="font-size: 100%; font-weight: 400"
              >{{ insightApplicationPer[1] }}%</span
            >
            <span *ngIf="insightApplicationPer.length > 2" style="color: #0b84ff; font-size: 100%; font-weight: 400"
              >{{ insightApplicationPer[2] }}%</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px; margin-top: 3em">
      <div
        *ngFor="let insightApplication of insightApplicationName; let i = index"
        style="display: flex; gap: 1.1em; color: #022e5c; align-items: center"
        [style.color]="getInsightApplicationColor(i)"
      >
        <div
          [style.background-color]="getInsightApplicationColor(i)"
          style="width: 1.5em; border-radius: 0.75em; height: 0.67em"
        ></div>
        {{ insightApplication || "Others" }}
      </div>
    </div>
  </div>
  <div class="movanoStats_Divider"></div>
  <div style="display: flex; flex-direction: row">
    <div style="display: flex; flex-direction: column; align-items: flex-start; gap: 0.5em; padding-top: 3%;">
      <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px">By message type</div>
      <div class="movanoStats_DognutStats">
        <div echarts [options]="option_MessageType ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
        <div class="movanoStats_DognutStats_Highlight">
          <span *ngIf="messageTypePer.length > 0" style="font-size: 167%; font-weight: 700">{{
            messageTypePer[0]
          }}%</span>
          <div *ngIf="messageTypePer.length > 1" style="display: flex; gap: 0.33em">
            <span *ngIf="messageTypePer.length > 1" style="font-size: 100%; font-weight: 400">{{
              messageTypePer[1]
            }}%</span>
            <span *ngIf="messageTypePer.length > 2" style="color: #0b84ff; font-size: 100%; font-weight: 400">{{
              messageTypePer[2]
            }}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px; margin-top: 3em">
      <div
        *ngFor="let messageType of messageTypeName; let i = index"
        style="display: flex; gap: 1.1em; align-items: center;"
        [style.color]="getMessageTypeColor(i)"
      >
        <div
          [style.background-color]="getMessageTypeColor(i)"
          style="width: 1.5em; border-radius: 0.75em; height: 0.67em"
        ></div>
        {{ messageType || "Others" }}
      </div>
    </div>
  </div>
</div>
