import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private accountName: string = '';
  private accountType: string = '';
  private accountIdToken: string = '';
  private accountEmail: string = '';

  constructor() { }

  // Setters
  setAccountName(name: string) {
    this.accountName = name;
  }

  setAccountType(type: string) {
    this.accountType = type;
  }

  setAccountIdToken(token: string) {
    this.accountIdToken = token;
  }

  setAccountEmail(email: string) {
    this.accountEmail = email;
  }

  // Getters
  getAccountName(): string {
    return this.accountName;
  }

  getAccountType(): string {
    return this.accountType;
  }

  getAccountIdToken(): string {
    return this.accountIdToken;
  }

  getAccountEmail(): string {
    return this.accountEmail;
  }
}
