import { Component, HostListener, Input, SimpleChanges } from '@angular/core';
import { totalInfoPerMonth } from '../../shared/movano-interfaces';
import { abbrNum } from '../../shared/utils';
import { USE_SPACES, USE_CAPITALS, NUM_DECIMALS } from '../../shared/constants';
import { MovanoService } from '../../movano.service';
import { SharedService } from '../../stats/shared.service';
import { lastValueFrom } from 'rxjs';
import { TODAY, LAST_WEEK, LAST_SEVEN_DAYS, LAST_MONTH, YEAR_TO_DATE } from '../../../constants';

@Component({
  selector: 'app-movano-info-carousel',
  templateUrl: './movano-info-carousel.component.html',
  styleUrls: ['./movano-info-carousel.component.scss']
})
export class MovanoInfoCarouselComponent {

  @Input() darkMode: boolean;
  @Input() fontSize: number;
  @Input() totalWidth: number;
  //only give us info for production, sold and incidents
  @Input() monthData: totalInfoPerMonth;
  protected selectedDate: string = 'Last Week';

  @Input() pauseCarousel: boolean = true;

  // FORMAT OPTIONS
  numDecimals = NUM_DECIMALS;
  useCapitals = USE_CAPITALS
  useSpaces = USE_SPACES;

  //NEW USERS
  moreNewUser: boolean = true;
  totalNewUser: string = '0';
  differNewUser: string = '0';
  //NEW USERS

  //INCIDENTS
  moreIncidents: boolean = true;
  totalIncidents: string = '0'; //TODO: NEED INFO
  differIncidents: string = '0';
  //INCIDENTS

  //UNDER REPAIR
  moreUnderRep: boolean = false;
  totalUnderRep: string = '0'; //TODO: NEED INFO
  differUnderRep: string = '0';
  //UNDER REPAIR

  //SLEEP ANALYSIS
  moreSleepAnaly: boolean = false;
  totalSleepAnaly: string = '125'; //TODO: NEED INFO
  differSleepAnaly: string = '14';
  //SLEEP ANALYSIS

  //RINGS PRODUCTION
  moreProduction: boolean = true;
  totalProduction: string = '200 k';
  differProduction: string = '20 k';
  //RINGS PRODUCTION

  //RINGS SOLD
  moreSold: boolean = false;
  totalSold: string = '514';
  differSold: string = '25';
  //RINGS SOLD


  constructor(private movanoSvc: MovanoService, private sharedService: SharedService) {
    this.darkMode = true;
    this.fontSize = 40;
    this.totalWidth = 390;
    this.monthData = {} as totalInfoPerMonth;
  }

  async ngOnInit() {
    this.sharedService.calendarObservable$.subscribe(async data => {
      if(data.option === YEAR_TO_DATE){
        this.selectedDate = 'this year'
      }else if(data.option.includes('-')){
        this.selectedDate = 'custom date';
      }else{
        this.selectedDate = data.option.toLowerCase();
      }
      await this.setValues(data.option);
    })
  }

  get isPaused() {
    return this.pauseCarousel;
  }

  @HostListener('wheel', ['$event'])

  onScroll(event: WheelEvent) {

    if (this.pauseCarousel) {
      const element = event.currentTarget as HTMLElement;
      element.scrollLeft += event.deltaY;
      event.preventDefault();
    }
  }

  setValues = async (data: string) => {
    const dataOfSelectedDate = await this.getDashboardChartDataByDate(data);

    // NEW USERS
    this.totalNewUser = abbrNum(dataOfSelectedDate.newUsers.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differNewUser = abbrNum(dataOfSelectedDate.newUsers.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreNewUser = Number(this.differNewUser) >= 0;

    // INCIDENTS
    this.totalIncidents = abbrNum(dataOfSelectedDate.incidents.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differIncidents = abbrNum(dataOfSelectedDate.incidents.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreIncidents = Number(this.differIncidents) >= 0;

    // UNDER REPAIR
    this.totalUnderRep = abbrNum(dataOfSelectedDate.underRepair.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differUnderRep = abbrNum(dataOfSelectedDate.underRepair.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreUnderRep = Number(this.differUnderRep) >= 0;

    // SLEEP ANALYSIS
    this.totalSleepAnaly = abbrNum(dataOfSelectedDate.sleepSessions.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differSleepAnaly = abbrNum(dataOfSelectedDate.sleepSessions.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreSleepAnaly = Number(this.differSleepAnaly) >= 0;

    // RINGS PRODUCTION
    this.totalProduction = abbrNum(dataOfSelectedDate.ringsInProductions.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differProduction = abbrNum(dataOfSelectedDate.ringsInProductions.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreProduction = Number(this.differProduction) >= 0;

    // RINGS SOLD
    this.totalSold = abbrNum(dataOfSelectedDate.sales.count, this.numDecimals, this.useSpaces, this.useCapitals);
    this.differSold = abbrNum(dataOfSelectedDate.sales.dif, this.numDecimals, this.useSpaces, this.useCapitals);
    this.moreSold = Number(this.differSold) >= 0;
  }

  getDashboardChartDataByDate = async (date: string) => {
    if (date === TODAY) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesToday());
      return response.data.carousel;
    }

    if (date === LAST_WEEK) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastWeek());
      return response.data.carousel;
    }

    if (date === LAST_SEVEN_DAYS) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLast7Days());
      return response.data.carousel;
    }

    if (date === LAST_MONTH) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesLastMonth());
      return response.data.carousel;
    }

    if (date === YEAR_TO_DATE) {
      const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesYearToDate());
      return response.data.carousel;
    }

    const startDate = this.convertDateToISO(date.split('-')[0]);
    const endDate = this.convertDateToISO(date.split('-')[1]);

    const response = await lastValueFrom(this.movanoSvc.getStatsSummarySalesCustomDay(startDate, endDate));

    return response.data.carousel;
  }

  convertDateToISO = (dateString: string) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
}
