import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LAST_WEEK } from '../../constants';
import { INewCalendarSelectorData } from '../shared/movano-interfaces';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private calendarOption = new BehaviorSubject<INewCalendarSelectorData>({
    option: LAST_WEEK,
    startDate: undefined,
    endDate: undefined
  });

  public calendarObservable$ = this.calendarOption.asObservable();

  setCalendarSelectedOption(calendarData: string, startDate?: Date, endDate?: Date) {
    const data = {
      option: calendarData,
      startDate: startDate,
      endDate: endDate
    }

    this.calendarOption.next(data);
  }
}
