<ng-template pTemplate="header">
    <h3>{{LITERALS.FILTER.TITTLE}}</h3>
  </ng-template>
  <div [ngClass]="{'movanoDark': darkMode}">
    <div class="movanoFilterMenu" >
      <div class="movanoFilterMenu_Header">
        <span>{{LITERALS.FILTER.TITTLE}}</span>
        <a (click)="resetFilter();">{{LITERALS.FILTER.RESET}}</a>
        <svg (click)="closeHimself.emit()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE" />
        </svg>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{LITERALS.FILTER.USER_GENDER}}</span>
        <div class="movanoFilterMenu_Filter_Gender">
          <div class="movano_checkBox">
            <mat-checkbox  [(ngModel)]="filter_Male" (click)="applyFilter();"> </mat-checkbox>
            <span class="movanoTable_Obj_Badge">{{LITERALS.MOVANO.MASCULINE}}</span>
          </div>
          <div class="movano_checkBox">
            <mat-checkbox [(ngModel)]="filter_Female" (click)="applyFilter();"> </mat-checkbox>
            <span class="movanoTable_Obj_Badge movanoTable_Obj_Badge_Female">{{LITERALS.MOVANO.FEMININE}}</span>
          </div>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{LITERALS.FILTER.USER_COUNTRY}}</span>
        <mat-form-field>
          <mat-select (selectionChange)="applyFilter()" [(ngModel)]="filter_Country"
            [placeholder]="LITERALS.FILTER.USER_COUNTRY_PLACEHOLDER" panelClass="movano-select"
            [disableOptionCentering]="true">
            <mat-option value="USA">USA</mat-option> <!--TODO BY SERVER-->
            <mat-option value="SPAIN">Spain</mat-option>
            <mat-option value="ITALY">Italy</mat-option>
          </mat-select>
  
          <svg class="movanoTriangleInput" matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767" />
          </svg>
        </mat-form-field>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{LITERALS.FILTER.USER_REGISTER_DATE}}</span>
        <div class="movanoCalendar">
          <mat-icon [ngStyle]="{color : (false) ? '#FFFFFF' : ''}">calendar_today</mat-icon>
          <p-calendar 
          [maxDate]="today!" 
          selectionMode="range" 
          placeholder="Since      |   To" 
          (onSelect)="applyFilter()" 
          dateFormat="d MM ‘y"
            rangeSeparator="|" [(ngModel)]="filter_registerDate">
            <ng-template pTemplate="date" let-date>

              <div *ngIf="this.filter_registerDate[0]" [ngClass]="{'selectedDate' : date.day === filter_registerDate[0].getDate()}"> </div>
              <div *ngIf="this.filter_registerDate[1]" [ngClass]="{'interDate' : date.day != filter_registerDate[0].getDate() && date.day != filter_registerDate[1].getDate() }"></div>
              <div *ngIf="this.filter_registerDate[1]" [ngClass]="{ 'selectedDate' : date.day === filter_registerDate[1].getDate()}"></div>
              
              {{ date.day }}
            </ng-template>
          </p-calendar>
          <svg class="movanoTriangleInput" matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767" />
          </svg>
        </div>
      </div>
      <div class="movanoFilterMenu_Filter">
        <span class="movanoFilterMenu_Filter_Tittle">{{LITERALS.FILTER.USER_LAST_SYNC}}</span>
        <div class="movanoCalendar">
          <mat-icon [ngStyle]="{color : (false) ? '#FFFFFF' : ''}">calendar_today</mat-icon>
          <p-calendar 
          [maxDate]="today!" 
          selectionMode="range" 
          placeholder="Since      |   To" 
          (onSelect)="applyFilter()" 
          dateFormat="d MM ‘y"
          rangeSeparator="|" 
          [(ngModel)]="filter_lastSync">
            <ng-template pTemplate="date" let-date>
              <div *ngIf="this.filter_lastSync[0]" [ngClass]="{'selectedDate' : date.day === filter_lastSync[0].getDate()}"> </div>
              <div *ngIf="this.filter_lastSync[1]" [ngClass]="{'interDate' : date.day != filter_lastSync[0].getDate() && date.day != filter_lastSync[1].getDate() }"></div>
              <div *ngIf="this.filter_lastSync[1]" [ngClass]="{ 'selectedDate' : date.day === filter_lastSync[1].getDate()}"></div>
              {{ date.day }}
            </ng-template>
          </p-calendar>
          <svg class="movanoTriangleInput" matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767" />
          </svg>
        </div>
      </div>
    </div>
  </div>
