import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-main-button',
  templateUrl: './main-button.component.html',
  styleUrls: ['./main-button.component.scss']
})
export class MainButtonComponent {
  @Input() type: 'primary' | 'basic' = 'primary';
  @Input() color: 'blue' | 'red' = 'blue';
  @Input() label: string = 'Click me';
  @Input() disabled: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() fullWidthMarginBottom: boolean = false;
  @Input() iconName: string = '';
  @Input() iconSize: number = 18;
  @Input() extraStyles: { [klass: string]: any; } | null = null;

  @Output() buttonClick = new EventEmitter<void>();

  get buttonClasses() {
    return {
      'display-flex': this.iconName !== '',
      'btn-blue': this.color === 'blue' && this.type !== 'basic',
      'btn-red': this.color === 'red' && this.type !== 'basic',
      'btn-basic': this.type === 'basic',
      'btn-disabled': this.disabled,
      'btn-fullWidth': this.fullWidth,
      'btn-fullWidthMarginBottom': this.fullWidthMarginBottom
    };
  }

  get iconSizeWithPx(): string {
    return `${this.iconSize}px !important`;
  }

  onClick() {
    if (!this.disabled) {
      this.buttonClick.emit();
    }
  }
}
