import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionConfirmationDialogComponent } from '../action-confirmation-dialog/action-confirmation-dialog.component';
import { adminUsersPostData } from 'src/app/shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';
import { MainServiceService } from 'src/app/main-service.service';

@Component({
  selector: 'app-delete-user-admin',
  templateUrl: './delete-user-admin.component.html',
  styleUrls: ['./delete-user-admin.component.scss']
})
export class DeleteUserAdminComponent {
  confirmationText: string = '';
  adminUserId: string = '';
  adminUserName: string = '';

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<ActionConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { adminUser?: any },
    protected movanoService: MovanoService,
    private dialogCommunicationService: MainServiceService
  ){
  console.log(data)
  this.adminUserName = data.adminUser.name
  this.adminUserId = data.adminUser.id
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteForSureadminUser(): void {
    const updatedData: adminUsersPostData = {
      ...this.data.adminUser,
      adminUserStatusId: 3, // Estado de "Deleted"
    };

    this.movanoService.patchAdminUser(updatedData, this.adminUserId).subscribe({
      next: () => {
        this.dialogRef.close();
        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isDeletedMode: true }
        });

        this.dialogCommunicationService.emitOrganizationUpdated();
      },
      error: (err) => {
        console.error('Error deleting organization:', err);
      }
    });
  }
}


