<div class="movanoHeader" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoHeader_Tittle">
    <svg class="movanoHeader_Tittle_btn" width="max(1.3889vw, 20px)" height="max(1.11vw, 16px)"
      (click)="menuDisplay = true" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 567">
        <path id="Vector 19" d="M0 6.5L14.4375 6.5" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 21" d="M0 1L14.4375 0.999999" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 20" d="M0 12L8.25 12" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
      </g>
    </svg>

    <span class="movanoHeader_Tittle_txt">{{title}}</span>

    <svg class="movanoHeader_Tittle_btn" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" *ngIf="!searchDisplay && showTitle"
      (click)="searchDisplay = true" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
        fill="var(--main-blue-dark)" />
    </svg>
    <div *ngIf="searchDisplay && showTitle" class="movanoHeader_Tittle_Search scale-up-hor-left" >
      <svg (click)="search($event, 'user_id', table)" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)"
        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)" />
      </svg>
      <span class="p-input-icon-left">
        <input class="movanoInputSearch" type="text" pInputText (input)="search($event, 'user_id', table)" />
      </span>
      <svg width="max(0.833vw, 16px)" height="max(0.833vw, 16px)" (click)="table.reset(); searchDisplay = false;"
        viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE" />
      </svg>
    </div>

    <svg *ngIf="showTitle" (click)="filterDisplay = true" class="movanoHeader_Tittle_btn" width="max(0.9vw, 16px)"
      height="max(0.9vw, 16px)" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
        fill="var(--main-blue-dark)" />
    </svg>
  </div>
  <div class="movanoHeader_Logo" *ngIf="showTitle">
    <img class="movanoHeader_Logo_IMG"
      src="{{darkMode ? LITERALS.MOVANO.IMG_HEADER_DARK : LITERALS.MOVANO.IMG_HEADER}}">
    <span class="movanoHeader_Version">v{{LITERALS.MOVANO.VERSION}}</span>
  </div>
  <div class="movanoHeader_Functions">
    <div class="movano_switch" style="position: relative; ">
      <!-- <label class="switch">
        <input [checked]="darkMode" type="checkbox" (change)="changeTheme()">
        <span class="slider round"></span>
      </label> -->
    </div>
    <svg class="movanoHeader_Functions_Icon" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77761 8.76924C5.77761 5.86289 7.56864 3.37988 10.1005 2.36225C10.825 2.06917 10.7111 0.986418 9.94582 0.815457C9.0503 0.620072 8.0978 0.571226 7.11274 0.701482C3.44114 1.18994 0.502227 4.21026 0.119599 7.89001C-0.385145 12.7828 3.433 16.9103 8.21992 16.9103C8.81421 16.9103 9.38408 16.8451 9.94582 16.723C10.7192 16.5521 10.8413 15.4774 10.1086 15.1762C7.48723 14.126 5.76947 11.586 5.77761 8.76924Z"
        [ngStyle]="{fill : darkMode ? '#FFFFFF' : 'var(--main-blue-dark)', boxShadow: darkMode ? '0px 0px  5px rgba(255, 255, 255, 0.5)' : 'none'}" />
    </svg>

    <div (click)="userDisplay = true" class="movanoHeader_userIcon">
      <svg width="max(1.18vw, 17px)" height="max(1.32vw, 19px)" viewBox="0 0 17 19" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
      </svg>
    </div>
  </div>
</div>
<div class="movanoHeaderMovil" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoHeader_Tittle">
    <svg class="movanoHeader_Tittle_btn" (click)="menuDisplay = true" viewBox="0 0 18 18" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Group 567">
        <path id="Vector 19" d="M0 6.5L14.4375 6.5" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 21" d="M0 1L14.4375 0.999999" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
        <path id="Vector 20" d="M0 12L8.25 12" stroke="#284767" stroke-width="1.5" stroke-linecap="round" />
      </g>
    </svg>

    <img class="movanoHeader_Logo_IMG"
      src="{{LITERALS.MOVANO.IMG_HEADER_DARK}}">


    <div (click)="userDisplay = true" class="movanoHeader_userIcon">
      <svg width="max(1.18vw, 17px)" height="max(1.32vw, 19px)" viewBox="0 0 17 19" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 7.91667C10.6327 7.91667 12.3482 6.14333 12.3482 3.95833C12.3482 1.77333 10.6327 0 8.5 0C6.36727 0 4.63636 1.77333 4.63636 3.95833C4.63636 6.14333 6.36727 7.91667 8.5 7.91667ZM8.5 11.0833C5.67182 11.0833 0 12.54 0 15.4375V17.4167C0 18.2875 0.695454 19 1.54545 19H15.4545C16.3045 19 17 18.2875 17 17.4167V15.4375C17 12.54 11.3282 11.0833 8.5 11.0833Z" />
      </svg>
    </div>
  </div>
  <div class="movanoHeader_Tittle">
    <span class="movanoHeader_Tittle_txt">{{title}}</span>
    <div style="display: flex; align-items: center; gap: 20px;">
      <svg class="movanoHeader_Tittle_btn" *ngIf="!searchDisplay" (click)="searchDisplay = true" viewBox="0 0 16 16"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
          fill="var(--main-blue-dark)" />
      </svg>
      <div *ngIf="searchDisplay" class="movanoHeader_Tittle_Search scale-up-hor-left">
        <svg (click)="search($event, 'user_id', table)" viewBox="0 0 16 16" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
            fill="var(--main-blue-dark)" />
        </svg>
        <span class="p-input-icon-left">
          <input class="movanoInputSearch" type="text" pInputText (input)="search($event, 'user_id', table)" />
        </span>
        <svg (click)="table.reset(); searchDisplay = false;" viewBox="0 0 12 12" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE" />
        </svg>
      </div>

      <svg (click)="filterDisplay = true" class="movanoHeader_Tittle_btn" viewBox="0 0 13 13" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.364543 1.48084C0.443709 1.58375 4.90871 7.28375 4.90871 7.28375V12.0417C4.90871 12.4771 5.26496 12.8333 5.70829 12.8333H7.29954C7.73496 12.8333 8.09913 12.4771 8.09913 12.0417V7.27584C8.09913 7.27584 12.4454 1.71834 12.6512 1.465C12.857 1.21167 12.8333 0.958338 12.8333 0.958338C12.8333 0.522922 12.477 0.166672 12.0337 0.166672H0.966209C0.483293 0.166672 0.166626 0.546672 0.166626 0.958338C0.166626 1.11667 0.214126 1.30667 0.364543 1.48084Z"
          fill="var(--main-blue-dark)" />
      </svg>
    </div>
  </div>
</div>

<p-sidebar styleClass="menu-left" [modal]="false" [(visible)]="menuDisplay" position="left">
  <app-features-tab (closeHimself)="menuDisplay = false"></app-features-tab>
</p-sidebar>

<p-sidebar styleClass="menu-filter" [modal]="false" [(visible)]="filterDisplay" (onShow)="onResize()" position="left">
  <app-user-filter (closeHimself)="filterDisplay = false" [table]="table" [today]="today"></app-user-filter>
</p-sidebar>

<p-sidebar styleClass="user-menu" [(visible)]="userDisplay" position="right">
  <app-user-menu (closeHimself)="userDisplay = false"></app-user-menu>
</p-sidebar>