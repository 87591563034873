import { Component, OnDestroy, OnInit, Input } from '@angular/core';
//import * as console from 'console';
import { footerInfo, incidentCarrousel, salesProductionCarrousel, salesSyncCarrousel, totalInfoPerMonth } from '../shared/movano-interfaces';
import { deviceTable } from '../shared/movano-interfaces';
import { Table } from 'primeng/table';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MovanoService } from '../movano.service';
import { FilterService } from 'primeng/api';
import * as lit from '../shared/movano-literals';
import { RecoverPasswordComponent } from '../login/recover-password/recover-password.component';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy{
  protected LITERALS = lit;
  protected menuDisplay: boolean = false;
  protected darkMode: boolean = false;
  private darkModeSub : Subscription = new Subscription();
  protected userDisplay: boolean = false;
  @Input() title: string = '';
  @Input() table !: Table;
  @Input() filterDisplay: boolean = false;
  protected today?: Date;







  constructor(
    protected dialog: MatDialog,
    protected router: Router,
    protected movanoService: MovanoService,
    private filterService: FilterService,
    private darkModeSvc: DarkModeService) {

  }

  async ngOnInit() {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val : any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }


  async logOutWithCognito() {
    await this.movanoService.LogOut()
    this.router.navigate(['']);
  }

  recoverPass() {
    this.userDisplay = false;
    this.dialog.open(RecoverPasswordComponent);
  }
  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }
  updateInfo() {
    throw new Error('Method not implemented.');
  }
  goTo(_place: string) {
    this.router.navigate([_place]);
  }


  onResize() {
    const checkboxDivs = document.querySelectorAll('.movano_checkBox') as NodeListOf<HTMLElement>;
    const checkbox = document.querySelectorAll('mat-checkbox') as NodeListOf<HTMLElement>;
    const windowWidth = window.innerWidth;
    const checkboxSize = Math.max(windowWidth / 1440, 1) * 0.85; // Ajusta este valor según tus necesidades

    checkboxDivs.forEach((cb: HTMLElement) => {
      cb.style.width = `calc(50px * ${checkboxSize})`;
    });

    //console.log(checkbox);
    checkbox.forEach((cb: HTMLElement) => {
      cb.style.transform = `scale(${checkboxSize})`;
    });
  }

  toggleFilterDisplay() {
    this.filterDisplay = !this.filterDisplay;
  }
}
