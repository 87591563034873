import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { timeStamp } from 'console';
import { Calendar } from 'primeng/calendar';
import { SharedService } from '../stats/shared.service';
import { MainServiceService } from '../main-service.service';

@Component({
  selector: 'app-new-time-selector',
  templateUrl: './new-time-selector.component.html',
  styleUrls: ['./new-time-selector.component.scss']
})
export class NewTimeSelectorComponent {

  protected displayedTimeOptions: string[] = ['Today', 'Last week', 'Last month', 'Last 7 days', 'Year to date', 'Custom range', 'Custom day'];
  protected apiTimeframeOptions: string[] = ['today', 'lastWeek', 'lastMonth', 'customrange', 'last7Days', 'actualYear',];
  //protected timeOptions : string[] = ['', 'week', 'month', ''];
  protected rangeSelected: Date[] = [];
  protected today: Date;
  protected daySelected: Date;
  protected calendarUp: boolean = false;
  protected utc: boolean = false;
  protected actualTimeZone: string = '';
  protected moment = require('moment-timezone');
  protected lastSlctdDateOpt: number = 1;
  @Input() timeZones?: string[];
  @Input() slctdDateOpt: number = 1;
  @Input() disabled: boolean = false;
  @Input() defaultToUTC: boolean = true;
  @Output() initGetInfo = new EventEmitter<[number, boolean, Date | Date[], string, number]>();
  @ViewChild('calendarTimeSelector') calendarTmSlctr !: Calendar;
  @ViewChild('dateSelect') dateSelect!: MatSelect;
  protected dateDefaultOffset: boolean = true;
  protected dateOffsetValue: number = 0;



  constructor(private sharedService: SharedService, private mainService: MainServiceService) {

    this.today = new Date(Date.now());
    this.daySelected = new Date(Date.now());
  }


  ngOnInit(): void {
    this.mainService.offsetObservable$.subscribe(value => {
      this.dateDefaultOffset = value === 0;
      this.dateOffsetValue = value;
    });
  }

  putUTCclass() {
    const utc_option = document.querySelector('.UtcSelectors');
    const utc_list = utc_option?.parentElement;
    utc_list?.classList.add('UtcList');
  }

  getInfo(_slctdDateOpt?: number) {
    this.mainService.resetDateOffset();

    if (_slctdDateOpt !== undefined) {
      this.lastSlctdDateOpt = this.slctdDateOpt;
      this.slctdDateOpt = _slctdDateOpt;
    }

    if (this.defaultToUTC) {
      this.utc = true;
      this.actualTimeZone = 'UTC';
    }

    // Check for Custom Range, which requires a valid date range.
    if (this.slctdDateOpt === 3 && !this.rangeSelected[1]) return;
    // Emit the selected date option and other required data.
    this.initGetInfo.emit([
      this.slctdDateOpt,
      this.daySelected!.getTime() < this.today!.getTime(),
      (this.slctdDateOpt !== 3) ? this.daySelected! : this.rangeSelected!,
      (this.utc) ? this.actualTimeZone : this.timeZones![0] ?? '',

      this.lastSlctdDateOpt
    ]);

    this.sharedService.setCalendarSelectedOption(this.apiTimeframeOptions[this.slctdDateOpt], this.rangeSelected[0], this.rangeSelected[1])
    this.dateSelect.close();
  }


  updateDaySelected(_daySelected: Date, _timeZones: string[], _slctdDateOpt: number) {
    this.daySelected = _daySelected;
    this.timeZones = _timeZones;
    if (this.actualTimeZone == '') this.actualTimeZone = this.timeZones[0];
    this.slctdDateOpt = _slctdDateOpt;
  }

  updateDaySelectedSpot(_daySelected: Date, _slctdDateOpt: number) {
    this.daySelected = _daySelected;
    this.slctdDateOpt = _slctdDateOpt;

  }


  tittleText(): string {
    let secondPart: string = '';
    let firstPart: string = '';
    const dateFormat: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'long', day: 'numeric' };

    if (this.slctdDateOpt === 1 || this.slctdDateOpt === 2 ) {
        firstPart = this.displayedTimeOptions[this.slctdDateOpt];
        const customTitle = this.dateOffsetValue === 1 ? ['Actual week', 'Actual month'] : ['Last week' + ` (${this.dateOffsetValue})`, 'Last month' + ` (${this.dateOffsetValue})`];

        return this.dateDefaultOffset ? firstPart : customTitle[this.slctdDateOpt - 1];
    } else if (this.slctdDateOpt === 6 || this.slctdDateOpt === 0) {
        firstPart = this.daySelected.toLocaleDateString('en-US', dateFormat);
        secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
        return this.dateDefaultOffset ? firstPart : 'Previous day' + ` (${this.dateOffsetValue})`;
    }
    else if (this.slctdDateOpt === 4) {
      firstPart = 'Last 7 days';
        secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
        return this.dateDefaultOffset ? firstPart : 'Previous 7 days' + ` (${this.dateOffsetValue})`;
    }
    else if ( this.slctdDateOpt === 5) {
        firstPart = 'Year to date';
        secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
        return this.dateDefaultOffset ? firstPart : 'Previous year to date' + ` (${this.dateOffsetValue})`;
    }
    else if (this.slctdDateOpt === 3) {
      firstPart = this.rangeSelected[0]?.toLocaleDateString('en-US', dateFormat) || '';
      secondPart = this.rangeSelected[1]?.toLocaleDateString('en-US', dateFormat) || '';
      return firstPart;
    }

    return '';
  }

}


