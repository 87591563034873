import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Calendar } from 'primeng/calendar';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-stats-calendar',
  templateUrl: './stats-calendar.component.html',
  styleUrls: ['./stats-calendar.component.scss']
})
export class StatsCalendarComponent {

  protected displayedTimeOptions: string[] = ['Today', 'Last week', 'Last month', 'Last 7 days', 'Year to date', 'Custom range', 'Custom day'];
  protected rangeSelected: Date[] = [];
  protected today: Date;
  protected daySelected: Date;
  protected calendarUp: boolean = false;
  protected utc: boolean = false;
  protected actualTimeZone: string = '';
  protected moment = require('moment-timezone');
  protected lastSlctdDateOpt: number = 1;
  @Input() timeZones?: string[];
  @Input() slctdDateOpt: number = 2;
  @Input() disabled: boolean = false;
  @Output() initGetInfo = new EventEmitter<string>();
  @ViewChild('calendarTimeSelector') calendarTmSlctr!: Calendar;
  @ViewChild('dateSelect') dateSelect!: MatSelect;

  constructor(private sharedService: SharedService) {
    this.today = new Date(Date.now());
    this.daySelected = new Date(Date.now());

  }

  putUTCclass() {
    const utc_option = document.querySelector('.UtcSelectors');
    const utc_list = utc_option?.parentElement;
    utc_list?.classList.add('UtcList');
  }

  getInfo(_slctdDateOpt?: number) {
    if (_slctdDateOpt !== undefined) {
      this.lastSlctdDateOpt = this.slctdDateOpt;
      this.slctdDateOpt = _slctdDateOpt;
    }



    // Check for Custom Range, which requires a valid date range.
    if (this.slctdDateOpt === 3 && this.rangeSelected.length !== 2) {
      console.error('Custom range requires both start and end dates to be selected.');
      return;
    }

    // Prepare the output string based on selected option
    let output: string;

    switch (this.slctdDateOpt) {
      case 0:
        output = 'today';
        break;
      case 1:
        output = 'Last Week';
        break;
      case 2:
        output = 'Last Month';
        break;
      case 3:
        output = `${this.rangeSelected[0]?.toLocaleDateString('en-US')} - ${this.rangeSelected[1]?.toLocaleDateString('en-US')}`;
        break;
      case 4:
        output = 'Last 7 days';
        break;
      case 5:
        output = 'Year to date';
        break;
      default:
        output = 'Unknown option';
        break;
    }

    this.sharedService.setCalendarSelectedOption(output)
    // Emit the selected date option and other required data
    this.initGetInfo.emit(output);

    this.dateSelect.close();
  }


  updateDaySelected(_daySelected: Date, _timeZones: string[], _slctdDateOpt: number) {
    this.daySelected = _daySelected;
    this.timeZones = _timeZones;
    if (this.actualTimeZone === '') this.actualTimeZone = this.timeZones[0];
    this.slctdDateOpt = _slctdDateOpt;
  }

  tittleText(): string {
    let secondPart: string = '';
    let firstPart: string = '';
    const dateFormat: Intl.DateTimeFormatOptions = { year: '2-digit', month: 'long', day: 'numeric' };

    if (this.slctdDateOpt === 1 || this.slctdDateOpt === 2) {
      firstPart = this.displayedTimeOptions[this.slctdDateOpt];
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
      return firstPart + ' - ' + secondPart;
    } else if (this.slctdDateOpt === 6 || this.slctdDateOpt === 0) {
      firstPart = this.daySelected.toLocaleDateString('en-US', dateFormat);
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
      return firstPart + ' - ' + secondPart;
    } else if (this.slctdDateOpt === 4) {
      firstPart = 'Last 7 days';
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
      return firstPart + ' - ' + secondPart;
    } else if (this.slctdDateOpt === 5) {
      firstPart = 'Year to date';
      secondPart = (this.utc) ? `UTC + ${Math.round(this.moment().tz(this.actualTimeZone).utcOffset() / 60)}` : 'Time Zone';
      return firstPart + ' - ' + secondPart;
    } else if (this.slctdDateOpt === 3) {
      firstPart = this.rangeSelected[0]?.toLocaleDateString('en-US', dateFormat) || '';
      secondPart = this.rangeSelected[1]?.toLocaleDateString('en-US', dateFormat) || '';
      return firstPart + ' - ' + secondPart;
    }

    return '';
  }
}
