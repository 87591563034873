<div class="movanoHealthMetrics" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoHealthMetrics_Header">
    <div class="movanoHealthMetrics_Header_SubTittle movanoHealthMetrics_Desktop">
      <a (click)="returnToLastChart()">{{returnToLabel()}}</a>

      <div class="movanoHealthMetrics_Header_SubTittle_Measures">
        <div class="movanoHealthMetrics_Header_SubTittle_Measures_First">
          <mat-form-field class="example-full-width">
            <mat-select
              [(ngModel)]="selectedMeasureIndex"
              [disableOptionCentering]="true"
              panelClass="custom-select-panel"
              (selectionChange)="onSelectionChange($event)"
              #selectRef
              [placeholder]="getPlaceholderText()"
            >

            <mat-option *ngFor="let label of filteredMeasuresLabels; let i = index"  [value]="(i).toString()">
              <mat-checkbox *ngIf="isMultiSelect" [checked]="isSelected(i)" (click)="$event.stopPropagation(); toggleSelection(i)">
                {{ getDisplayLabel(label) }}
              </mat-checkbox>
              <span *ngIf="!isMultiSelect" (click)="changeMeasure(i)">{{ getDisplayLabel(label) }}</span>
            </mat-option>
              <mat-option>
                <div class="movano_switch lp">
                  <span style="margin-right: 20px; margin-left: 10px;">Multiselection</span>
                  <label class="switch" >
                    <input type="checkbox" [(ngModel)]="isMultiSelect" (change)="clearSelection()">
                    <span class="slider round"></span>
                  </label>
                  <button class="movanoButton" (click)="confirmSelection(selectRef)">Select</button>
                </div>
              </mat-option>
            </mat-select>
            <svg class="movanoTriangleInput" matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
                fill="#284767" />
            </svg>
          </mat-form-field>
        </div>

        <div *ngIf="weHaveBatteryInfo"  class="movano_switch lp movanoHealthMetrics_Header_SubTittle_Measures_Label Label_Battery">
          <svg viewBox="0 0 21 21" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_1512_3701)">
            <path
              d="M13.7113 3.5H12.25V1.75H8.75V3.5H7.28875C6.65 3.5 6.125 4.025 6.125 4.66375V18.0775C6.125 18.725 6.65 19.25 7.28875 19.25H13.7025C14.35 19.25 14.875 18.725 14.875 18.0863V4.66375C14.875 4.025 14.35 3.5 13.7113 3.5ZM9.625 17.5V12.6875H7.875L11.375 6.125V10.9375H13.125L9.625 17.5Z"
              [ngStyle]="{fill : darkMode ? 'white' : '#284767'}" />
          </g>
          <defs>
            <clipPath id="clip0_1512_3701">
              <rect width="21" height="21" fill="white" />
            </clipPath>
          </defs>
        </svg>
          <label class="switch">
            <input type="checkbox" [checked]="batteryDisplayed" (click)="toggleBatteryInfo()"  >
            <span class="slider round" ></span>
          </label>
        </div>
        <mat-form-field *ngIf="batteryDisplayed && weHaveBatteryInfo"
          class="movanoHealthMetrics_Header_SubTittle_BatteryComboBox">
          <mat-select [(ngModel)]="selectedDate" placeholder="{{measures_Battery!.battery[battery_Index].serial_number}}"
            panelClass="movano-select" [disableOptionCentering]="true">
            <mat-select-trigger>{{measures_Battery!.battery[battery_Index].device || ''}}</mat-select-trigger>
            <mat-option *ngFor="let battery of measures_Battery!.battery; let i = index"
              (click)="battery_Index = i; updateInfo()">
              {{battery.serial_number}}
            </mat-option>
          </mat-select>

          <svg matSuffix viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
              fill="#284767" />
          </svg>
        </mat-form-field>
      </div>

    </div>
  </div>
  <div></div>
  <div
    *ngIf="((measures_Day ?? []).length > 1 || (measures_MonthWeek ?? []).length > 1 || measures_Atomic.length > 1) || loadingMesureData; then displayData else noData">
  </div>
  <ng-template #displayData>
    <div *ngIf="!loadingMesureData; then chartBlock else loadingData"></div>
    <ng-template #chartBlock>

      <div style="position: absolute; height: max(34vw, 500px); width: 100%;">

        <div *ngIf="!(selectedDate == 0)&& measuresSelected.length === 1"
          style="width: 100%; position: absolute; display: flex; justify-content: center;">
          <div class="movanoHealthMetrics_Legend">
            <div *ngIf="measuresLabels[measuresSelected[0]] !== 'Steps' && measuresLabels[measuresSelected[0]] !== 'Calories Burn'" class="movanoHealthMetrics_Legend_Elements">
              <svg class="movanoHealthMetrics_Legend_Elements_MinMaxIcon" viewBox="0 0 2 10" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M1 9L1 1" stroke-width="2" stroke-linecap="round"
                  [ngStyle]="{stroke: measureColors[measuresLabels[measuresSelected[0]]][0]}" />
              </svg>
              <span [ngStyle]="{color : measureColors[measuresLabels[measuresSelected[0]]][0]}"> Maximum/Minimum </span>
            </div>
            <div class="movanoHealthMetrics_Legend_Elements">
              <svg class="movanoHealthMetrics_Legend_Elements_AverageIcon" viewBox="0 0 10 10" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4" fill="white" stroke-width="2"
                  [ngStyle]="{stroke: measureColors[measuresLabels[measuresSelected[0]]][1]}" />
              </svg>
              <span [ngStyle]="{color : measureColors[measuresLabels[measuresSelected[0]]][1]}">
                {{ (measuresLabels[measuresSelected[0]] === 'Steps' || measuresLabels[measuresSelected[0]] === 'Calories Burn') ? 'Total' : 'Average' }}
              </span>

            </div>
          </div>
        </div>

        <div *ngIf="measuresSelected.length === 1 && averageDayData" class="movanoHealthMetrics_Average" [ngStyle]="{
        bottom: 'calc('+(dataPeaks[measuresSelected[0]].average / (dataPeaks[measuresSelected[0]].max * 1.2))
                      + ((innerWidth > 1440) ? '* 32vw' : '* 460px')+ ')' }">
          <div class="movanoHealthMetrics_Average_Box"
            [ngStyle]="{
              borderColor: measureColors[measuresLabels[measuresSelected[0]]]
                ? measureColors[measuresLabels[measuresSelected[0]]][1]
                : '#E01485'
            }
            ">
            <span>{{ (measuresLabels[measuresSelected[0]] === 'Steps' || measuresLabels[measuresSelected[0]] === 'Calories Burn') ? 'Total' : 'Average' }}</span>
            <span [ngStyle]="{ color: measureColors[measuresLabels[measuresSelected[0]]]
              ? measureColors[measuresLabels[measuresSelected[0]]][0]
              : '#E01485'
             }">
              <!-- Mostrar stepsTotal si el label es 'Steps' -->
              <ng-container *ngIf="measuresLabels[measuresSelected[0]] === 'Steps'; else notSteps">
                {{ stepsTotal | number: '1.0-0' }}
              </ng-container>

              <!-- Mostrar caloriesTotal si el label no es 'Calories Burn' -->
              <ng-template #notSteps>
                <ng-container *ngIf="measuresLabels[measuresSelected[0]] === 'Calories Burn'; else showDataPeaks">
                  {{ this.caloriesTotal | number: '1.0-0' }}
                </ng-container>
              </ng-template>

              <!-- Mostrar dataPeaks si no se cumplen las condiciones anteriores -->
              <ng-template #showDataPeaks>
                {{ dataPeaks[measuresSelected[0]].average | number: '1.0-0' }}
              </ng-template>
            </span>
          </div>
        </div>
        <div *ngIf="selectedDate == 0 && measuresSelected.length === 1 && averageDayData"
          class="movanoHealthMetrics_MinMax" layout="vertical">
          <div class="movanoHealthMetrics_MinMax_Min">
            <span>Min</span>
            <span
            [ngStyle]="{ color: measureColors[measuresLabels[measuresSelected[0]]]
              ? measureColors[measuresLabels[measuresSelected[0]]][0]
              : '#E01485'
             }">{{dataPeaks[measuresSelected[0]].min
              | number:
              '1.0-0'}}</span>
          </div>
          <div class="movanoHealthMetrics_MinMax_Max">
            <span>Max</span>
            <span
            [ngStyle]="{ color: measureColors[measuresLabels[measuresSelected[0]]]
              ? measureColors[measuresLabels[measuresSelected[0]]][0]
              : '#E01485'
             }">{{dataPeaks[measuresSelected[0]].max
              | number:
              '1.0-0'}}</span>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #loadingData>
      <div class="movanoHealthMetrics_NoData">
        <p-progressSpinner [style]="{width: '3vw', height: '3vw', position: 'absolute', left : '38.5vw'}"
          styleClass="custom-spinner-blue" strokeWidth="2" animationDuration="1.5s">
        </p-progressSpinner>
      </div>
    </ng-template>
    <div *ngIf="!loadingMesureData" class="movanoHealthMetrics_RangeChart" [ngStyle]="{
        overflowX: (innerWidth < 1440) ? 'scroll' : 'hidden',
        width: '100%',
        left :  'unset'}">
      <div class="movanoHealthMetrics_ChartRange" [ngStyle]="{
          width: 'auto',
          height : innerWidth > 550 ? 'max(34vw, 500px)' : '450px'
        }" echarts [options]="options" class="range-chart" (chartClick)="onChartEvent($event, 'chartClick')">
      </div>
      <div *ngIf="batteryDisplayed && weHaveBatteryInfo" class="movanoHealthMetrics_ChartRange" [ngStyle]="{
        width: 'auto',
        height : innerWidth > 550 ? '10vw' : '450px'
      }" echarts [options]="options_Battery" class="range-chart" (chartClick)="onChartEvent($event, 'chartClick')">
      </div>
    </div>
  </ng-template>
  <ng-template #noData>
    <div class="movanoHealthMetrics_NoData">
      <span>NO DATA AVAILABLE</span>
    </div>
  </ng-template>
</div>
