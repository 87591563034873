import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MainServiceService } from 'src/app/main-service.service';

@Component({
  selector: 'app-chart-time-arrows',
  templateUrl: './chart-time-arrows.component.html',
  styleUrls: ['./chart-time-arrows.component.scss']
})
export class ChartTimeArrowsComponent {
  @Input() calendarData?: [any, boolean, Date | Date[], string, any];
  protected canGoNext = false;

  constructor(private mainService: MainServiceService) {

  }

  ngOnInit(): void {
    this.updateCanGoNext();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['calendarData']) {
      this.updateCanGoNext();
    }
  }

  updateOffset(value: number) {
    if (this.calendarData) {
      const dateCanHavePositivesValues = this.calendarData[0] === 1 || this.calendarData[0] === 2
      const maxValue = dateCanHavePositivesValues ? 1 : 0;
      const fixedNextValue = this.mainService.getDateOffset() < maxValue ? value : 0;

      if (this.mainService.getDateOffset() <= 0)
        this.canGoNext = true;

      this.mainService.updateDateOffset(value > 0 ? fixedNextValue : value);
    }

  }

  updateCanGoNext() {
    if (this.calendarData) {
      const dateCanHavePositivesValues = this.calendarData[0] === 1 || this.calendarData[0] === 2
      const maxValue = dateCanHavePositivesValues ? 1 : 0;

      if (this.mainService.getDateOffset() < maxValue)
        this.canGoNext = true;
    }
  }
}
