import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActionConfirmationDialogComponent } from '../action-confirmation-dialog/action-confirmation-dialog.component';
import { organizationPatchData, organizationPostData } from 'src/app/shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';
import { MainServiceService } from 'src/app/main-service.service';

@Component({
  selector: 'app-delete-organization-dialog',
  templateUrl: './delete-organization-dialog.component.html',
  styleUrls: ['./delete-organization-dialog.component.scss']
})
export class DeleteOrganizationDialogComponent {

  confirmationText: string = '';
  organizationId: string = '';
  organzationName: string = '';

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<ActionConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { organization?: any },
    protected movanoService: MovanoService,
    private dialogCommunicationService: MainServiceService
  ){

  this.organzationName = data.organization.name
  this.organizationId = data.organization.id
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  deleteForSureOrganization(): void {
    const updatedData: organizationPatchData = {
      ...this.data.organization,
      organizationStatusId: 3, // Estado de "Deleted"
    };

    this.movanoService.patchOrganization(updatedData, this.organizationId).subscribe({
      next: () => {
        this.dialogRef.close();
        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isDeletedMode: true }
        });

        this.dialogCommunicationService.emitOrganizationUpdated();
      },
      error: (err) => {
        console.error('Error deleting organization:', err);
      }
    });
  }
}
