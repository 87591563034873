import { Component, OnInit, AfterViewInit, HostListener, Input, SimpleChanges, OnChanges, EventEmitter, Output } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {footerInfo} from "../../shared/movano-interfaces";
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { fixPixelsBy1440 } from 'src/app/shared/utils';
import { lastValueFrom } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';

@Component({
  selector: 'app-movano-activity',
  templateUrl: './movano-activity.component.html',
  styleUrls: ['./movano-activity.component.scss']
})
export class MovanoActivityComponent implements OnInit, AfterViewInit, OnChanges {
  activityData: number[] = [];
  //activityOptions: any;
  activityLabels = ['6 am', '10 am', '2 pm', '6 pm', '10 pm', '2 am', '6 am'];

  asyncData: number[] = [];
  bestDay: string = '';
  bestDayValue: number = 0;
  averageDay: number = 0;
  countMonth: number = 0;
  //asyncOptions: any;
  asyncLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  public innerWidth: any;
  ctx : any;

  @Input() darkMode : boolean;
  @Input() footerData !: footerInfo;
  @Input() front: boolean;
  @Output() pauseActivity = new EventEmitter<boolean>();
  paused : boolean = false;
  bestHour: string = '0';
  bestHourValue: number = 0;
  averageHour: number = 0;
  option_activity ?: EChartsOption;

  protected bestHourWorkout: string = '';
  protected bestDayWorkoutsCount: string = '';
  protected avgWorkouts: string = '';

  protected bestDaySync: string = '';
  protected bestDayCountSyncs: string = '';
  protected avgSyncsPerDay: string = '';

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.update();
  }
  constructor(private primengConfig: PrimeNGConfig,protected movanoService: MovanoService,) {
    this.primengConfig.ripple = true;
    this.innerWidth = document.documentElement.clientWidth;
    this.darkMode = true;
    this.front = true;

  }

  ngOnInit() {
    // this.activityData = this.getSyncsHour(undefined);
    // this.asyncData = this.getSyncsWeek(undefined);
    this.update();
  }

  ngAfterViewInit() {
    // const canvas = document.getElementById('activityChart')!.getElementsByTagName('canvas')[0] as unknown as HTMLCanvasElement;
    // //console.log(document.getElementById('activityChart')!.getElementsByTagName('canvas')[0]);
    // this.ctx = canvas.getContext('2d');
    // var gradient = this.ctx!.createLinearGradient(0, 0, 0, Math.max(this.innerWidth * (this.innerWidth > 1024 ? 0.0915 : 0.15), 170));
    // gradient.addColorStop(0.4, 'rgba(37, 134, 221, 0.4)');
    // gradient.addColorStop(1, 'rgba(37, 134, 221, 0)');
    // this.asyncData.datasets[0].backgroundColor = gradient;
    // this.activityData.datasets[0].backgroundColor = gradient;
    // this.applyTheme();
  }

  async update() {
    const responseWorkouts = await lastValueFrom(this.movanoService.getUsersActivityLastMonth());
    const responseWearable = await lastValueFrom(this.movanoService.getTotalLastMonthInfo());

    console.log('WEAREBLE',responseWearable);

    this.bestHourWorkout = responseWorkouts.data.workout.bestHoursRange.hourRange
    this.bestDayWorkoutsCount = responseWorkouts.data.workout.bestHoursRange.count;
    this.avgWorkouts = responseWorkouts.data.workout.avg;

    this.bestDaySync = responseWearable.data.footer.devicesSyncsBestDay;
    this.bestDayCountSyncs = responseWearable.data.footer.devicesSyncsInBestDay;
    this.avgSyncsPerDay = responseWearable.data.footer.devicesSyncsAvg;

    // let gradient;
    // if (this.ctx) {
    //   gradient = this.ctx!.createLinearGradient(0, 0, 0, Math.max(this.innerWidth * (this.innerWidth > 1024 ? 0.0915 : 0.15), 170));
    //   gradient.addColorStop(0.4, 'rgba(37, 134, 221, 0.4)');
    //   gradient.addColorStop(1, 'rgba(37, 134, 221, 0)');
    // }
    // this.activityData = this.getSyncsHour(gradient);
    // this.asyncData = this.getSyncsWeek(gradient);
    //   this.asyncLabels = [];
    //   this.asyncData = [];
    // this.footerData.syncsLastMonth.forEach(syncPerDay => {
    //   this.asyncLabels.push(syncPerDay.dayOfWeek);
    //   this.asyncData.push(syncPerDay.count);
    //   if(this.bestDayValue < syncPerDay.count){
    //     this.bestDayValue = syncPerDay.count;
    //     this.bestDay = syncPerDay.dayOfWeek;
    //   }
    // });
    // this.activityData = this.footerData.syncsLastMonthHour.count;
    // this.activityLabels = this.convertHoursTo12HourFormat(this.footerData.syncsLastMonthHour.hour);
  //  let thisWeek = this.footerData?.syncsLastMonth.slice(0, 7);
  //   if (this.footerData?.syncsLastMonth && thisWeek) {
  //     this.countMonth = this.footerData?.syncsLastMonth.reduce((a, b) => a + b.count, 0);
  //     this.averageDay = Math.round(thisWeek.reduce((a, b) => a + b.count, 0) / 7);
  //   }
  //   let bestDay = thisWeek?.sort((a, b) => (b.count - a.count))[0];
  //   this.bestDayValue = this.footerData.syncsLastMonthWeekDay?.count[0]??0;
    // this.countMonth = this.footerData.syncsLastMonthWeekDay?.total??0;
    // this.averageDay = Math.round(this.footerData.syncsLastMonthWeekDay?.avg??0);


    // this.bestHour = this.getAMPM(this.footerData?.syncsLastMonthHour.hour[0]);
    // this.bestHourValue =  this.footerData?.syncsLastMonthHour.count[0]??0;
    // this.averageHour = Math.round( this.footerData?.syncsLastMonthHour.avg??0);
    // this.applyTheme();
  }

  applyTheme() {
    // this.activityOptions = this.asyncOptions = {

    //   plugins: {
    //     legend: false,
    //     tooltip: {
    //       enabled: false,
    //       position: 'nearest',
    //     },
    //   },
    //   interaction: {
    //     mode: 'nearest',
    //     axis: 'x',
    //     intersect: false
    //   },
    //   maintainAspectRatio: false,
    //   responsive: true,
    //   radius:  this.innerWidth * 0.00175,
    //   scales: {
    //     x: {
    //       display: false,
    //       stacked: 'single',
    //       ticks: {
    //         color: '#7797B8'
    //       },
    //       grid: {
    //         display: false,
    //         color: '#D1F5FF'
    //       },
    //       border: {
    //         display: false
    //       }
    //     },
    //     y: {
    //       display: false,
    //       beginAtZero: true,
    //       ticks: {
    //         color: 'black'
    //       },
    //       grid: {
    //         color: 'rgba(255,255,255,0)'
    //       }
    //     }
    //   }
    // };
    this.option_activity = {
      grid: {
        height: '82%',
        width: 'auto',
        left: '3%',
        right: '2%',
        top: '2%',
        bottom: '10%',
      },
      xAxis: {
        type: 'category',
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D1F5FF'
          }
        },
        boundaryGap: false,
        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(0.7vw, 10px)',
          show: true,
          hideOverlap: true,
          color: '#7797B8',
          // formatter: function (value: number, index: number) {
          //   return this.asyncLabels[index];
          // }
        },
        data: (this.front) ? this.activityLabels : this.asyncLabels,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          show: false,
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false,
        }
      },
      series: [
        {
          data: (this.front) ? this.activityData : this.asyncData,
          type: 'line',
          color: '#0B8CFF',
          symbol: 'circle',
          animation: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#2586DD66'
              },
              {
                offset: 1,
                color: '#2586DD00'
              }
            ])
          },
          lineStyle:{
            width: fixPixelsBy1440(1, this.innerWidth)
          },
          markLine: {
            silent: true,
            symbol: 'none',
            precision: 1,
            lineStyle: {
              color: '#0B8CFF',
              type: 'dashed'
            },
            label: {
              // distance: [-20, 20],
              // fontWeight: 700,
              // fontFamily: 'Zen Kaku Gothic Antique',
              // fontSize: fixPixelsBy1440(12, this.innerWidth),
              // color: '#00B377',
              show: false
            },
            data: [
              {
                type: 'average'
              }
            ]
          }
        }
      ]
    };
  }

  buttonHandle(front: boolean){
    this.front = front;
    this.update();
    console.log(this.option_activity);
  }
  // getSyncsWeek(gradient : any){
  //   let asyncData = {
  //     labels: ['We', 'Th', 'Fr', 'Sa', 'Su', 'Mo', 'Tu'],
  //     datasets: [
  //       {
  //         data: [0, 70, 140, 90, 110, 60, 30],
  //         type: 'line',
  //         borderColor: (this.darkMode) ? '#0DCCFF' : '#0B8CFF',
  //         pointBackgroundColor: (this.darkMode) ? '#0DCCFF' : '#0B8CFF',
  //         pointBorderColor: 'rgba(0,0,0,0)',
  //         Color: '#42A5F5',
  //         borderWidth: Math.max(this.innerWidth * 0.00069, 1),
  //         fill: true,
  //         backgroundColor: (gradient) ? gradient : 'blue'
  //       }
  //     ]
  //   };
  //   // if(isInit){
  //   //   return asyncData;
  //   // }

  //   this.asyncLabels = [];
  //   asyncData.datasets[0].data = [];
  //   const lastWeekStyle = false;
  //   if(lastWeekStyle) {
  //     let thisWeek = this.footerData?.syncsLastMonth.slice(0, 7);
  //     if (thisWeek) {
  //       for (let i: number = thisWeek.length; i > 0; i--) {
  //         this.asyncLabels.push(this.getDayOfWeekShortFromAbbreviation(thisWeek[i - 1].dayOfWeek));
  //         asyncData.datasets[0].data.push(thisWeek[i - 1].count);
  //       }
  //       let bestDay = thisWeek?.sort((a, b) => (b.count - a.count))[0];
  //       //console.log(bestDay?.dayOfWeek);
  //       this.bestDay = this.getDayOfWeeFromAbbreviation(bestDay?.dayOfWeek);
  //       this.bestDayValue = bestDay?.count;
  //     }
  //     if (this.footerData?.syncsLastMonth && thisWeek) {
  //       this.countMonth = this.footerData?.syncsLastMonth.reduce((a, b) => a + b.count, 0);
  //       this.averageDay = Math.round(thisWeek.reduce((a, b) => a + b.count, 0) / 7);
  //     }
  //   } else {
  //     this.asyncLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  //     this.bestDay = this.getDayOfWeeFromAbbreviation(this.footerData?.syncsLastMonthWeekDay?.dayOfWeek[0]);
  //     asyncData.datasets[0].data = new Array(7).fill(0);
  //     this.asyncLabels.forEach((day, id) => {
  //       let index = this.footerData?.syncsLastMonthWeekDay?.dayOfWeek.indexOf(this.getDayOfWeeDDBB(day));
  //       if(index === undefined)
  //         index = -1;
  //       if(index > -1)
  //         asyncData.datasets[0].data[id] = this.footerData?.syncsLastMonthWeekDay?.count[index]?this.footerData?.syncsLastMonthWeekDay?.count[index]:0;
  //       else
  //         asyncData.datasets[0].data[id] = 0;
  //     });
  //     this.bestDayValue = this.footerData?.syncsLastMonthWeekDay?.count[0]?this.footerData?.syncsLastMonthWeekDay?.count[0]:0;
  //     this.countMonth = this.footerData?.syncsLastMonthWeekDay?.total?this.footerData?.syncsLastMonthWeekDay?.total:0;
  //     this.averageDay = Math.round(this.footerData?.syncsLastMonthWeekDay?.avg?this.footerData?.syncsLastMonthWeekDay?.avg:0);
  //   }

  //   return asyncData;

  // }

  // getSyncsHour(gradient : any){
  //   let asyncData = {
  //     labels: ['6 am', '', '', '', '10 am', '', '', '', '2 pm', '', '', '', '6 pm', '', '', '', '10 pm', '', '', '', '2 am', '', '', '', '6 am'],
  //     datasets: [
  //       {
  //         data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  //         type: 'line',
  //         borderColor: (this.darkMode) ? '#0DCCFF' : '#0B8CFF',
  //         pointBackgroundColor: (this.darkMode) ? '#0DCCFF' : '#0B8CFF',
  //         pointBorderColor: 'rgba(0,0,0,0)',
  //         Color: '#42A5F5',
  //         borderWidth: Math.max(this.innerWidth * 0.00069, 1),
  //         fill: true,
  //         backgroundColor: (gradient) ? gradient : 'blue'
  //       }
  //     ]
  //   };
  //   const hour_labels=[6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0,1,2,3,4,5,6];
  //   const lastDayStyle = false;
  //   let thisDay = this.footerData?.syncsLastMonthHour;
  //   if(lastDayStyle) {
  //     thisDay = this.footerData?.syncsLastDayHour;
  //   }
  //   if (thisDay) {
  //     for (let i: number = 0; i < hour_labels.length; i++) {
  //       const index = thisDay.hour.indexOf(hour_labels[i]);
  //       if(index > -1){
  //         asyncData.datasets[0].data[i] = thisDay.count[index];
  //       }
  //     }
  //     this.bestHour = `${this.getAMPM(thisDay.hour[0])}`;
  //     this.bestHourValue = thisDay.count[0];
  //     this.averageHour = Math.round(thisDay.avg);
  //   }

  //   return asyncData;
  // }

  // getDayOfWeeDDBB(abbreviation: string){
  //   switch(abbreviation){
  //     case 'Su': return 'SU';
  //     case 'Mo': return 'MO';
  //     case 'Tu': return 'TU';
  //     case 'We': return 'WE';
  //     case 'Th': return 'TH';
  //     case 'Fr': return 'FR';
  //     case 'Sa': return 'SA';
  //     default: return '';
  //   }
  // }

  // getDayOfWeekShortFromAbbreviation(abbreviation: string){
  //   switch(abbreviation){
  //     case 'SU': return 'Su';
  //     case 'MO': return 'Mo';
  //     case 'TU': return 'Tu';
  //     case 'WE': return 'We';
  //     case 'TH': return 'Th';
  //     case 'FR': return 'Fr';
  //     case 'SA': return 'Sa';
  //     default: return '';
  //   }
  // }

  // getDayOfWeeFromAbbreviation(short: string|undefined){
  //   switch(short){
  //     case 'SU': return 'Sunday';
  //     case 'MO': return 'Monday';
  //     case 'TU': return 'Tuesday';
  //     case 'WE': return 'Wednesday';
  //     case 'TH': return 'Thursday';
  //     case 'FR': return 'Friday';
  //     case 'SA': return 'Saturday';
  //     default: return '';
  //   }
  // }
  convertHoursTo12HourFormat(hours: number[]): string[] {
    const hoursIn12HourFormat: string[] = [];

    for (const hour of hours) {
      // Convert to 12-hour format
      const hourIn12HourFormat = hour % 12 || 12;

      // Determine 'am' or 'pm'
      const period = hour < 12 ? 'am' : 'pm';

      // Create the string in 'h am/pm' format
      const hourString = `${hourIn12HourFormat} ${period}`;

      // Add to the resulting array
      hoursIn12HourFormat.push(hourString);
    }

    return hoursIn12HourFormat;
  }
  getAMPM(hour: number): string{
    let returnHour: number|string = hour > 12 ? hour - 12 : hour;
    if(returnHour<10)
      returnHour =  '0'+returnHour;
    return hour > 12 ? returnHour + ' PM' : returnHour + ' AM';
  }

}
