<div class="tab-container">
  <div
    class="tab"
    *ngFor="let tab of tabs"
    [class.active]="tab === selectedTab"
    (click)="selectTab(tab)"
  >
    {{ tab }}
  </div>
</div>
