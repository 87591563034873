import { ChangeDetectorRef, Component } from '@angular/core';
import { lastValueFrom, skip } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { INewCalendarSelectorData } from 'src/app/shared/movano-interfaces';
import { SharedService } from 'src/app/stats/shared.service';
import { adjustScale } from 'src/main';

@Component({
  selector: 'app-rmas-header',
  templateUrl: './rmas-header.component.html',
  styleUrls: ['./rmas-header.component.scss']
})
export class RmasHeaderComponent {
  protected dynamicText: string = 'last week';

  constructor(
    private sharedService: SharedService,
    private movanoSvc: MovanoService,
    private changeDetector: ChangeDetectorRef
  ) {
    // Initialization code
  }
  protected apiData?: any;

  async ngOnInit() {
    this.getStatsFromDB();
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    adjustScale(".responsiveScaleRmas", { maxScale: 1.22 });
  }

  async getStatsFromDB() {
    const response = await lastValueFrom(this.movanoSvc.getRmasHeaderData("lastMonth"));
    this.apiData = response.data;
    this.dynamicText = 'Last month'
    this.sharedService.calendarObservable$.pipe(skip(1)).subscribe(async (data: INewCalendarSelectorData) => {
      const response = await lastValueFrom(this.movanoSvc.getRmasHeaderData(
        data.option,
        data.startDate?.toISOString().split("T")[0],
        data.endDate?.toISOString().split("T")[0])
      );

      this.apiData = response.data;
      this.dynamicText = data.option === "customrange" ? "Custom range" : data.option
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (str: string) => str.toUpperCase());
    });
  }

  protected isDifferencePositive(): boolean {
    return this.apiData?.stats?.rmaReturns?.compare?.comparison?.difference > 0;
  }

  protected isDifferencePositive2(): boolean {
    return this.apiData?.stats?.exchanges?.compare?.comparison?.difference > 0;
  }
}
