import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, lastValueFrom } from 'rxjs';

import {
  barSideChart,
  footerInfo,
  incidentCarrousel,
  salesProductionCarrousel,
  salesSyncCarrousel,
  totalInfoPerMonth
} from '../shared/movano-interfaces';
import { MovanoService } from '../movano.service';
import { RecoverPasswordComponent } from '../login/recover-password/recover-password.component';
import * as lit from '../shared/movano-literals';
import { DarkModeService } from '../dark-mode.service';
import { MovanoBarChartComponent } from '../widgets/movano-bar-chart/movano-bar-chart.component';
import { DashboardTypeService } from '../dashboard-type.service';
import { NumberAttribute } from 'aws-cdk-lib/aws-cognito';
import { SharedService } from '../stats/shared.service';
import { ACTUAL_MONTH, ACTUAL_WEEK, LAST_MONTH, LAST_WEEK, SELECTED_MONTH } from '../../constants';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  // Text literals used for HTML
  protected LITERALS = lit;

  //Dashboard type
  protected dashboardType: string = 'Sales';
  private dashboardTypeSub: Subscription = new Subscription();

  //Dark mode
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();

  //windows with
  protected innerWidth: any;

  //Show or hide user menu tab
  protected userDisplay: boolean = false;
  //Show or hide features tab
  protected menuDisplay: boolean = false;

  // Selected time period for displaying data
  protected selectedDate: number = 1;
  // Selected time period for displaying data
  protected dateOpt: number = 1;
  protected today: Date;
  protected selectedDay: Date;
  protected rangeDaySelected: Date[];
  protected dayWeek: number = 33;


  //switch time bettwen
  protected REFRESH_TIME: number = 35000;

  //Pause all Dashboard
  protected pauseAll: boolean = false;

  //Date selec options
  protected dateOptions: Map<string, string[]> = new Map<string, string[]>([
    ["Weekly", ["Actual week", "Last week", "Select week"]],
    ["Monthly", ["Actual month", "Last month", "Select month"]],
    ["Yearly", ["Actual year", "Last year", "Select year"]],
    ["Date_range", ['']],
  ]
  )
  optionsOrder: string[] = ["Weekly", "Monthly", "Yearly", "Date_range"]

  dateOptionFixed(): string[] {
    return Array.from(this.dateOptions.keys()).filter((_, i) => i !== this.selectedDate);
  }

  constructor(
    public _dialog: MatDialog,
    private movanoSvc: MovanoService,
    private darkModeSvc: DarkModeService,
    private dashboardSVC: DashboardTypeService,
    private router: Router, private sharedService: SharedService) {
    this.innerWidth = document.documentElement.clientWidth;
    this.monthData;
    // this.footerData;
    this.today = new Date();
    this.selectedDay = new Date(this.today);
    this.rangeDaySelected = [];
    this.selectDate();
  }

  /**
   * Initializes the component and sets up initial values.
   * It retrieves the inner width of the document, subscribes to the dark mode variable,
   * sets the background color of the body based on the dark mode value,
   * and updates the information displayed on the dashboard.
   */
  ngOnInit() {
    this.sharedService.setCalendarSelectedOption(LAST_MONTH);

    this.innerWidth = document.documentElement.clientWidth;
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    this.dashboardTypeSub = this.dashboardSVC.getDashboardType().subscribe((val: any) => {
      this.dashboardType = val;
    });
    document.body.style.backgroundColor = this.darkMode ? '#0c1d35' : '#f3f9fe';
    this.updateInfo();
  }

  /**
   * Listens to the window resize event and updates the inner width of the component accordingly.
   * It ensures that the component's inner width stays synchronized with the actual window width.
   *
   * @param event The resize event object.
   */
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
  }

  /**
   * Updates the information displayed on the dashboard by retrieving fresh data.
   * It resets the data variables to undefined, then calls various methods to fetch the necessary data.
   * The methods include retrieving user details, total info per month, footer info,
   * sales and production carrousel data, sync and sleep carrousel data, and incident carrousel data.
   * After retrieving the data, it assigns the fetched values to the corresponding variables.
   */
  async updateInfo() {
    this.monthData = undefined;
    // this.salesAndProductionCarrouselData = undefined;
    // this.syncAndSleepCarrouselData = undefined;
    // this.incidentCarrouselData = undefined;
    // this.footerData = undefined;
    this.getUserDetails();
    this.getTotalInfoPerMonth();
    // this.getFooterInfo()
    // this.getSalesAndProductionCarrousel();
    // this.getSyncAndSleepCarrousel();
    // this.getIncidentCarrousel();
    await this.getBarSideChartData();
    await this.dashboardSVC.setDate(this.rangeDaySelected);
    await this.dashboardSVC.setDateOptSelected(this.selectedDate);
  }

  /**
   * Performs necessary cleanup tasks when the component is about to be destroyed.
   * It unsubscribes from the `darkModeSub` subscription to prevent memory leaks.
   * This method is called before the component is removed from the DOM.
   */
  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  /**
   * Initiates the password recovery process by opening the RecoverPasswordComponent dialog.
   * It sets the `userDisplay` property to false to hide user-related information before opening the dialog.
   * This method is triggered when the user wants to recover their password.
   */
  recoverPass() {
    this.userDisplay = false;
    this._dialog.open(RecoverPasswordComponent);
  }

  /**
   * Toggles the application's theme between dark mode and light mode.
   * This method is triggered when the user wants to change the theme.
   */
  changeTheme() {
    this.darkModeSvc.setVariable(!this.darkMode);
  }


  //The map & chart change every 12 seconds
  protected mapChartPaused: boolean = false;
  protected refreshTime_MapChart = this.REFRESH_TIME;
  protected refreshInterval_MapChart = setInterval(() => { this.nextDashboardType(true) }, this.refreshTime_MapChart);
  // protected actualDashboardType: number = 0;
  // protected dashboardTypes: string[] = ['Sales', 'Activity', 'Users', 'Spot checks'];
  protected dashboardState: boolean = true;
  /**
   * Changes the active dashboard type to the next one in the list.
   * It updates the value of `actualDashboardType` property based on its current value and the number of dashboard types available.
   * If the mapChart is not paused, it clears the current interval for refreshing the dashboard and sets a new interval to call this method again.
   * This method is triggered when the user wants to switch to the next dashboard type.
   */
  changeDashboardType() {
    if (!this.mapChartPaused) {
      this.dashboardSVC.next(this.dashboardType);
      clearInterval(this.refreshInterval_MapChart)
      this.refreshInterval_MapChart = setInterval(() => { this.changeDashboardType() }, this.refreshTime_MapChart);
    }
  }

  /**
   * Changes the next dashboard type based on the given parameter.
  */
  nextDashboardType(next: boolean) {
    this.changeState();
    if (next) {
      this.dashboardSVC.next(this.dashboardType);
      return;
    }
    this.dashboardSVC.previous(this.dashboardType);
  }


  //The stats change every 12 seconds
  protected statsPaused: boolean = false;
  protected refreshTime_Stats = this.REFRESH_TIME;
  protected refreshInterval_Stats = setInterval(() => { this.changeStats() }, this.refreshTime_Stats);
  protected statsFront: boolean = true;
  /**
   * Toggles the stats front state and sets the interval for changing stats.
   * If the stats are not paused, it toggles the `statsFront` property to switch the displayed stats.
   * It clears the current interval for refreshing the stats and sets a new interval to call this method again.
   * This method is triggered when the stats should be changed periodically.
   */
  changeStats() {
    if (!this.statsPaused) {
      this.statsFront = !this.statsFront;
      clearInterval(this.refreshInterval_Stats)
      this.refreshInterval_Stats = setInterval(() => { this.changeStats() }, this.refreshTime_Stats);
    }
  }

  //The activity change every 12 seconds
  protected activityPaused: boolean = false;
  protected refreshTime_Activity = this.REFRESH_TIME;
  protected refreshInterval_Activity = setInterval(() => { this.changeActivity() }, this.refreshTime_Activity);
  protected activityFront: boolean = true;
  /**
   * Toggles the activity front state and sets the interval for changing activity.
   * If the activity is not paused, it toggles the `activityFront` property to switch the displayed activity.
   * It clears the current interval for refreshing the activity and sets a new interval to call this method again.
   * This method is triggered when the activity should be changed periodically.
   */
  changeActivity() {
    if (!this.activityPaused) {
      this.activityFront = !this.activityFront;
      clearInterval(this.refreshInterval_Activity)
      this.refreshInterval_Activity = setInterval(() => { this.changeActivity() }, this.refreshTime_Activity);
    }
  }

  /**
   * Toggles the dashboard state between true and false.
   * This method is triggered when the dashboard state needs to be changed.
   */
  changeState() {
    //MAPS ARE DISABLED
    this.dashboardState = true;
  }

  /**
   * Logs out the user using Cognito authentication and navigates back to the login page.
   * It calls the `LogOut()` method from the `movanoSvc` service and waits for the logout operation to complete.
   * Once the logout is successful, it uses the `router` to navigate to the login page.
   */
  async logOutWithCognito() {
    await this.movanoSvc.LogOut();
    this.router.navigate(['login']);
  }

  /**
   * Retrieves user details to determine if the user is logged in.
   * It calls the `getUser()` method from the `movanoSvc` service and waits for the user details to be retrieved.
   * If user details are available, it can perform certain actions (currently commented out).
   * If user detailsð are not available (indicating the user is not logged in), it uses the `router` to navigate to the login page.
   */
  async getUserDetails() {
    const user = await this.movanoSvc.getUser();
    if (!user) {
      this.router.navigate(['login']);
    }
  }


  protected monthData?: totalInfoPerMonth;
  /**
   * Retrieves the total information for the current month and the previous month.
   * It calls the `getTotalLastMonthInfo()` method from the `movanoSvc` service and subscribes to the response.
   * If the response is available, it assigns the data to the `monthData` variable.
   * Currently, there are console logs commented out for debugging purposes.
   */
  async getTotalInfoPerMonth() {
    const totalData = await lastValueFrom(this.movanoSvc.getTotalLastMonthInfo());
    this.monthData = totalData;
  }


  protected barSideData?: barSideChart;
  async getBarSideChartData() {
    if (this.selectedDate === 0) {
      this.barSideData = await lastValueFrom(this.movanoSvc.getBarSideData_Mock_Week(this.rangeDaySelected[0], this.rangeDaySelected[1]));
      this.dashboardSVC.setBarSideData(this.barSideData);
      return;
    }
    if (this.selectedDate === 1) {
      this.barSideData = await lastValueFrom(this.movanoSvc.getBarSideData_Mock_Month(this.rangeDaySelected[0], this.rangeDaySelected[1]));
      this.dashboardSVC.setBarSideData(this.barSideData);
      return;
    }
    if (this.selectedDate === 2) {
      this.barSideData = await lastValueFrom(this.movanoSvc.getBarSideData_Mock_Year(this.rangeDaySelected[0], this.rangeDaySelected[1]));
      this.dashboardSVC.setBarSideData(this.barSideData);
      return;
    }
    if (this.selectedDate === 3) {
      this.barSideData = await lastValueFrom(this.movanoSvc.getBarSideData_Mock_Date_Range(this.rangeDaySelected[0], this.rangeDaySelected[1]));
      this.dashboardSVC.setBarSideData(this.barSideData);
      return;
    }

  }

  /**
   * Pauses or resumes the auto swapping between types of Maps/Chart widget info.
   * It toggles the value of the `mapChartPaused` variable.
   * If the swapping is not paused, it clears the interval `refreshInterval_MapChart` and sets a new interval to call `changeDashboardType()` at the specified `refreshTime_MapChart`.
   */
  pauseMapChart() {
    this.mapChartPaused = !this.mapChartPaused;

    clearInterval(this.refreshInterval_MapChart)
    if (!this.mapChartPaused) {
      this.refreshInterval_MapChart = setInterval(() => { this.changeDashboardType() }, this.refreshTime_MapChart);
    }
  }

  /**
 * Pauses or resumes the auto swapping between Stats widget info.
 * It toggles the value of the `statsPaused` variable.
 * If the swapping is not paused, it clears the interval `refreshInterval_Stats` and sets a new interval to call `changeStats()` at the specified `refreshTime_Stats`.
 */
  pauseStats() {
    this.statsPaused = !this.statsPaused;

    clearInterval(this.refreshInterval_Stats)
    if (!this.statsPaused) {
      this.refreshInterval_Stats = setInterval(() => { this.changeStats() }, this.refreshTime_Stats);
    }
  }


  /**
 * Pauses or resumes the auto swapping between Activity widget info.
 * It toggles the value of the `activityPaused` variable.
 * If the swapping is not paused, it clears the interval `refreshInterval_Activity` and sets a new interval to call `changeActivity()` at the specified `refreshTime_Activity`.
 */
  pauseActivity() {
    this.activityPaused = !this.activityPaused;

    clearInterval(this.refreshInterval_Activity)
    if (!this.activityPaused) {
      this.refreshInterval_Activity = setInterval(() => { this.changeActivity() }, this.refreshTime_Activity);
    }
  }

  /**
 * Pauses or resumes all widgets by calling the respective pause methods.
 * It toggles the value of the `pauseAll` variable and calls `pauseMapChart()`, `pauseStats()`, and `pauseActivity()` methods.
 */
  pauseAllWidgets() {
    this.pauseAll = !this.pauseAll;
    this.pauseMapChart();
    this.pauseStats();
    this.pauseActivity();
  }

  weekSelected() {
    this.dayWeek = this.selectedDay.getDate() - this.selectedDay.getDay();
  }
  async selectDate() {
    switch (this.selectedDate) {
      case 0:
        if (this.dateOpt === 0)
          this.sharedService.setCalendarSelectedOption(ACTUAL_WEEK)

        if (this.dateOpt === 1)
          this.sharedService.setCalendarSelectedOption(LAST_WEEK)
        break;
      case 1:
        if (this.dateOpt === 0)
          this.sharedService.setCalendarSelectedOption(ACTUAL_MONTH)

        if (this.dateOpt === 1)
          this.sharedService.setCalendarSelectedOption(LAST_MONTH)

        if (this.dateOpt === 2) {
          const date = new Date('Thu Aug 01 2024 00:00:00 GMT+0200');
          const month = date.getMonth();
          this.sharedService.setCalendarSelectedOption(SELECTED_MONTH + month.toString())
        }
        break;
      case 2:
        this.rangeDaySelected[1] = new Date(this.selectedDay);
        this.rangeDaySelected[0] = new Date(this.rangeDaySelected[1]);
        this.rangeDaySelected[0].setFullYear(this.rangeDaySelected[0].getFullYear() - 1);
        break;
    }
    this.updateInfo();
  }
  changeDateOption() {
    this.rangeDaySelected = [];
  }

  calendarClasss(year: number, month: number, day: number): string {
    if (this.selectedDate == 0) {
      let weekDay: boolean =
        day >= this.dayWeek && day <= (this.dayWeek + 6) &&
        month == this.selectedDay.getMonth() &&
        year == this.selectedDay.getFullYear();
      if (weekDay) return 'weekSelect';
    }
    if (this.selectedDate == 3) {
      if (!this.rangeDaySelected[0]) return '';
      if (!this.rangeDaySelected[1]) {
        const firstDay: boolean = (day === this.rangeDaySelected[0].getDate() && month === this.rangeDaySelected[0].getMonth());
        return firstDay ? 'slctdDateOpt' : '';
      }
      const rangeDay: boolean =
        (day === this.rangeDaySelected[0].getDate() && month === this.rangeDaySelected[0].getMonth()) ||
        (day === this.rangeDaySelected[1].getDate() && month === this.rangeDaySelected[1].getMonth());
      //console.log(day);
      return rangeDay ? 'slctdDateOpt' : 'interDate';
    }
    return '';
  }

}
