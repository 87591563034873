import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { lastValueFrom, skip } from "rxjs";
import { MovanoService } from "src/app/movano.service";
import { INewCalendarSelectorData } from "src/app/shared/movano-interfaces";
import { SharedService } from "src/app/stats/shared.service";
import { adjustScale } from "src/main";

@Component({
  selector: "app-sales-header",
  templateUrl: "./sales-header.component.html",
  styleUrls: ["./sales-header.component.scss"],
})
export class SalesHeaderComponent {
  protected dynamicText: string = "last week";
  protected sleepAnalysis: string = "";
  protected successAnalysis: string = "";
  protected spotChecks: string = "";
  protected avrgSpotPerDay: string = "";
  protected workout: string = "";
  protected avrgWorkPerDay: string = "";
  protected notifications: string = "";

  constructor(
    private sharedService: SharedService,
    private movanoSvc: MovanoService,
    private changeDetector: ChangeDetectorRef,
  ) {
    // Initialization code
  }

  protected apiData?: any;

  async ngOnInit() {
    this.getStatsFromDB();
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
    adjustScale(".responsiveScaleElement", { maxScale: 1.25 });
  }

  async getStatsFromDB() {
    const response = await lastValueFrom(this.movanoSvc.getSalesHeaderData("lastMonth"));
    this.apiData = response.data;
    this.dynamicText = 'Last month'
    this.sharedService.calendarObservable$.pipe(skip(1)).subscribe(async (data: INewCalendarSelectorData) => {
      const response = await lastValueFrom(this.movanoSvc.getSalesHeaderData(
        data.option,
        data.startDate?.toISOString().split("T")[0],
        data.endDate?.toISOString().split("T")[0])
      );

      this.apiData = response.data;
      this.dynamicText = data.option === "customrange" ? "Custom range" : data.option
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/^./, (str: string) => str.toUpperCase());
    });
  }

  protected isDifferencePositive(): boolean {
    return this.apiData?.stats?.newSales?.distinctCompare?.comparison?.difference > 0;
  }
}
