import { Component, AfterViewInit } from "@angular/core";
import * as echarts from "echarts";
import { MovanoService } from "src/app/movano.service";
import { SharedService } from "../shared.service";
import { lastValueFrom, Observable } from "rxjs";

@Component({
  selector: "app-stats-health",
  templateUrl: "./stats-health.component.html",
  styleUrls: ["./stats-health.component.scss"],
})
export class StatsHealthComponent implements AfterViewInit {
  measuresLabels: string[] = [
    "HR - Heart Rate",
    "RHR - Resting Heart Rate",
    "HRV - Heart Rate Variability",
    "Blood Oxygen",
    "BR - Breathing Rate",
    "Steps",
    "Calories Burn",
    "Skin Temperature Fahrenheit",
  ];

  private measureColors: { [key: string]: { initial: string; final: string; shadow: string } } = {
    "HR - Heart Rate": { initial: "#7FD4B0", final: "#D7F1EA", shadow: "rgba(0, 178, 119, 0.7)" },
    "RHR - Resting Heart Rate": { initial: "#8A4E77", final: "#E4B7D3", shadow: "rgba(86, 50, 106, 0.7)" },
    "HRV - Heart Rate Variability": { initial: "#E6A03C", final: "#FFE3A1", shadow: "rgba(213, 75, 9, 0.7)" },
    "Blood Oxygen": { initial: "#FF7A4D", final: "#FFB3B3", shadow: "rgba(255, 87, 51, 0.7)" },
    "BR - Breathing Rate": { initial: "#4FA3FF", final: "#D1E8FF", shadow: "rgba(26, 139, 255, 0.7)" },
    Steps: { initial: "#0F6BBF", final: "#A7C6E0", shadow: "rgba(5, 66, 128, 0.7)" },
    "Calories Burn": { initial: "#E84D4D", final: "#FFB3B3", shadow: "rgba(226, 43, 43, 0.7)" },
    "Skin Temperature Fahrenheit": { initial: "#C61D78", final: "#E3B3D4", shadow: "rgba(176, 16, 101, 0.7)" },
  };

  currentCalendarData: any;
  customCalendarData: any;

  selectedMeasureIndex: number = 0;
  selectedMeasureString: string = "Hr";
  dropdownOpen: boolean = false;

  myChart: any;
  protected stats?: any;
  protected statsToday?: any;

  constructor(private movanoSvc: MovanoService, private sharedService: SharedService) {}

  ngOnInit() {
    this.sharedService.calendarObservable$.subscribe((data) => {
      this.currentCalendarData = data;
      this.fetchDataForSelectedDate(data);
    });
  }

  selectMeasure(index: number): void {
    this.selectedMeasureIndex = index;
    this.updateChart(this.stats.data);
    this.dropdownOpen = false;
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  ngAfterViewInit(): void {
    this.initChart();
  }

  initChart(): void {
    const chartDom = document.querySelector(".statsHealthMetricsBarChart_Chart") as HTMLElement;
    this.myChart = echarts.init(chartDom);

    const option = {
      title: {
        text: "",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      legend: {
        data: [this.measuresLabels[this.selectedMeasureIndex]],
      },
      grid: {
        containLabel: true,
        show: false,
      },
      xAxis: {
        type: "category",
        data: [],
        splitLine: {
          show: true,
          lineStyle: {
            color: "#d1f5ff",
          },
        },
      },
      yAxis: {
        type: "value",
        splitLine: {
          show: false,
        },
      },
      series: [
        {
          name: this.measuresLabels[this.selectedMeasureIndex],
          type: "bar",
          data: [],
        },
      ],
    };

    this.myChart.setOption(option);
  }

  async fetchDataForSelectedDate(data: any) {
    switch (data.option) {
      case "today":
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsToday());
        this.updateChart(this.stats.data);
        break;
      case "Last Week":
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsLastWeek());
        this.updateChart(this.stats.data);
        break;
      case "Last Month":
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsLastMonth());
        this.updateChart(this.stats.data);
        break;
      case "Last 7 days":
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsLast7Days());
        this.updateChart(this.stats.data);
        break;
      case "Year to date":
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsYearToDate());
        this.updateChart(this.stats.data);
        break;
      case "customRange":
        const { dateStart, dateEnd } = this.customCalendarData;
        this.stats = await lastValueFrom(this.movanoSvc.getHealthMetricsStatsCustomDay(dateStart, dateEnd));
        this.updateChart(this.stats.data);
        break;
      default:
        return;
    }
  }

  getSelectedMeasureString(index: number) {
    switch (index) {
      case 0:
        return "hr";
      case 1:
        return "rhr";
      case 2:
        return "hrv";
      case 3:
        return "spO2";
      case 4:
        return "br";
      case 5:
        return "steps";
      case 6:
        return "caloriesBurn";
      case 7:
        return "skinTemperatureFahrenheit";
      default:
        return "hr";
    }
  }

  updateChart(data: any): void {
    const xAxisData: string[] = [];
    const seriesData: number[] = [];

    const metricKey = this.getSelectedMeasureString(this.selectedMeasureIndex);
    const metricData = data[metricKey].values;

    metricData.forEach((item: { date: string | number | Date; count: number }) => {
      const formattedDate = new Date(item.date).toLocaleDateString("en-US");
      xAxisData.push(formattedDate);
      seriesData.push(item.count);
    });

    const selectedDateText = this.getFormattedDateText(this.currentCalendarData);
    const measureLabel = this.measuresLabels[this.selectedMeasureIndex];
    const { initial, final, shadow } = this.measureColors[measureLabel] || {
      initial: "#000000",
      final: "#000000",
      shadow: "rgba(0, 0, 0, 0.7)",
    };

    this.myChart.setOption({
      xAxis: {
        type: "category",
        data: xAxisData,
        position: "top",
        axisLabel: {
          fontFamily: "Zen Kaku Gothic Antique",
          color: "#808080", // Color gris
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          fontFamily: "Zen Kaku Gothic Antique",
        },
      },
      series: [
        {
          name: this.measuresLabels[this.selectedMeasureIndex],
          type: "bar",
          barWidth: 8,
          data: seriesData,
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                { offset: 0, color: initial },
                { offset: 1, color: final },
              ],
            },
            borderRadius: [8, 8, 0, 0],
            shadowColor: shadow,
            shadowBlur: 10,
            emphasis: {
              color: shadow,
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: any) => {console.log('params', params[0].color.colorStops[0].color, params[0].axisValue,params[0].seriesName,params[0].data)
          return `
          <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">

              <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
                <div style="display: flex; flex-direction: column; color: ${params[0].color.colorStops[0].color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: 0.8em; font-weight: 500; line-height: 130.4%; color: #7797B8">
                     ${params[0].axisValue}
                  </span>
                  <span style="font-size: max(1vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${params[0].seriesName}
                  </span>
                  <span style="font-size: max(0.9vw, 20px); font-weight: 500; line-height: 130.4%;">${params[0].data}%</span>
                </div>
            </div>
          </div>
          `
        }
      },
      graphic: {
        type: "text",
        left: "15%",
        top: "24%",
        style: {
          text: selectedDateText,
          fill: "#d9e4ed",
          font: "3vw Microsoft YaHei",
          textAlign: "center",
        },
        z: -10,
      },
    });
  }

  getFormattedDateText(data: any): string {
    const today = new Date();
    let dateText = "";

    switch (data) {
      case "today":
        dateText = today.toLocaleDateString("en-US");
        break;
      case "Last Week":
        const lastWeekStart = new Date();
        lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
        const lastWeekEnd = new Date();
        lastWeekEnd.setDate(today.getDate() - today.getDay() - 1);
        dateText = `${lastWeekStart.toLocaleDateString("en-US")} - ${lastWeekEnd.toLocaleDateString("en-US")}`;
        break;
      case "Last 7 days":
        const last7DaysStart = new Date();
        last7DaysStart.setDate(today.getDate() - 6);
        dateText = `${last7DaysStart.toLocaleDateString("en-US")} - ${today.toLocaleDateString("en-US")}`;
        break;
      case "Last Month":
        const lastMonth = new Date();
        lastMonth.setMonth(today.getMonth() - 1);
        dateText = lastMonth.toLocaleString("en-US", { month: "long" });
        break;
      case "Year to date":
        dateText = `January 1 - ${today.toLocaleDateString("en-US")}`;
        break;
      case "customRange":
        const { dateStart, dateEnd } = this.currentCalendarData;
        dateText = `${new Date(dateStart).toLocaleDateString("en-US")} - ${new Date(dateEnd).toLocaleDateString(
          "en-US",
        )}`;
        break;
      default:
        dateText = "";
    }

    return dateText;
  }

  getShadowColor(): string {
    return this.measureColors[this.measuresLabels[this.selectedMeasureIndex]].shadow;
  }
}
