<div class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      'justify-content': 'end',
      position: 'relative',
      left: '20px',
      top: '-5px',
      opacity: '0.5'
    }"
  >
    <mat-icon (click)="closeDialog()" [ngStyle]="{ cursor: 'pointer' }">close</mat-icon>
  </div>
  <p class="title">Do you want to change view to [NAME]?</p>
  <app-main-button label="Yes, change view" [fullWidth]="true"></app-main-button>
  <app-main-button
    label="Cancel"
    type="basic"
    [fullWidth]="true"
    [extraStyles]="{ 'margin-top': '10px' }"
    (click)="closeDialog()"
  ></app-main-button>
</div>
