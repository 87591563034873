<div class="scrollBarUser">
  <div class="insightJobExeRun" [ngClass]="{ movanoDark: darkMode }">
    <div class="insightJobExeRun_Header">
      <div class="insightJobExeRun_Header_Top">
        <div>
          <span>Jobs Runs</span>
          <span style="margin-left: 10px">{{ jobLogListLength }}</span>
        </div>
        <svg (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
            fill="#C4D9EE"
          />
        </svg>
      </div>
      <div class="insightJobExeRun_Header_Bottom">
        <div class="insightJobExeRun_Header_Bottom_Name">
          <span>{{ lambdaTrigger }}</span>
          <div class="insightJobExeRun_Header_Bottom_Name_Subname">
            <div class="movanoTable_Obj_Badge movanoTable_Obj_Badge_green">staging</div>
            <span>EMR-deploy-Dev-06102023</span>
          </div>
        </div>
        <div class="insightJobExeRun_Header_Bottom_Filter_Block">
          <div class="movanoHeader_Tittle_Search scale-up-hor-left">
            <svg
              (click)="search($event, 'name', jobExecRunTable)"
              width="max(1.11vw, 16px)"
              height="max(1.11vw, 16px)"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
                fill="var(--main-blue-dark)"
              />
            </svg>
            <span class="p-input-icon-left">
              <input
                class="movanoInputSearch"
                type="text"
                [placeholder]="'Find job runs'"
                pInputText
                (input)="search($event, 'name', jobExecRunTable)"
              />
            </span>
          </div>
          <div class="movanoFilterMenu_Filter">
            <mat-form-field>
              <mat-select
                (selectionChange)="applyFilter()"
                [(ngModel)]="filter_Status"
                [placeholder]="'All run status'"
                panelClass="movano-select"
                [disableOptionCentering]="true"
              >
                <mat-option value="reset">All Status</mat-option>
                <mat-option value="succeed">Succeed</mat-option>
                <mat-option value="running">Running</mat-option>
                <mat-option value="finished with errors">Finished with errors</mat-option>
                <mat-option value="error in execution">Error in execution</mat-option>
              </mat-select>
              <svg
                class="movanoTriangleInput"
                matSuffix
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
                  fill="#284767"
                />
              </svg>
            </mat-form-field>
          </div>
        </div>
        <div class="insightJobExeRun_Header_Bottom_Filter_Block">
          <app-new-time-selector
            #list_calendar
            (initGetInfo)="emitGetInfo($event)"
            [slctdDateOpt]="selectedDate"
            [timeZones]="timeZones"
          ></app-new-time-selector>
        </div>
      </div>
    </div>
    <div>
      <div [ngStyle]="{ display: !loadingData ? 'block' : 'none' }" class="movanoTableBox_JobRuns">
        <p-table
          *ngIf="dataAvailable; else noData"
          id="jobExecRunTable"
          #jobExecRunTable
          dataKey="job_id"
          class="movano-Table"
          [globalFilterFields]="['job_id', 'job_logs_link', 'name', 'run_status', 'run_time', 'start_time']"
          [value]="insightJobExeRuns"
          [paginator]="true"
          [rows]="rows"
          [first]="pageSelected"
          [scrollable]="true"
          scrollHeight="75vh"
        >
          <ng-template pTemplate="header">
            <tr [ngClass]="'movanoTable_Header'">
              <th pSortableColumn="name" (click)="sort('name')">
                {{ LITERALS.RUN_NAME }}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="name-icon"
                    d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                    fill="#C4D9EE"
                  />
                </svg>
              </th>
              <th pSortableColumn="id" (click)="sort('job_id')">
                {{ LITERALS.RUN_ID }}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="id-icon"
                    d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                    fill="#C4D9EE"
                  />
                </svg>
              </th>
              <th>{{ LITERALS.NOTIFICATION_LOGS }}</th>
              <th pSortableColumn="start_time" (click)="sort('start_time')">
                {{ LITERALS.START_TIME }}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="start_time-icon"
                    d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                    fill="#C4D9EE"
                  />
                </svg>
              </th>
              <th pSortableColumn="run_time" (click)="sort('run_time')">
                {{ LITERALS.RUN_TIME }}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="run_time-icon"
                    d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                    fill="#C4D9EE"
                  />
                </svg>
              </th>
              <th pSortableColumn="run_status" (click)="sort('run_status')">
                {{ LITERALS.RUN_STATUS }}
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    id="run_status-icon"
                    d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                    fill="#C4D9EE"
                  />
                </svg>
              </th>
              <th class="last-header"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-job let-i="rowIndex" let-expanded="expanded">
            <tr [ngStyle]="{ background: darkMode ? '#f3f6f9' : 'white' }" [ngClass]="'movanoTable_Obj'">
              <td class="insightJobExeRun_FullTD">{{ job.name }}</td>
              <td style="max-width: max-content !important">
                {{ job.job_id }}
              </td>
              <td><a>View logs</a></td>
              <td>
                {{
                  job.start_time ? (job.start_time | date : "MMM d, y") + " / " + (job.start_time | date : "HH:mm") : ""
                }}
              </td>
              <td>{{ job.run_time }}</td>
              <td>
                <div class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(job.run_status)">
                  {{ job.run_status }}
                </div>
              </td>
              <td>
                <div *ngIf="job.notifications_count > 0" style="display: flex; gap: 10px">
                  <span style="font-weight: 700">{{ job.notifications_count }}</span>
                  <svg
                    (click)="handleIconClick(job.job_id)"
                    style="display: flex; align-self: center"
                    width="max(1.1vw, 16px)"
                    height="max(1.1vw, 16px)"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5C15.5 0.675 14.825 0 14 0ZM14 3L8 6.75L2 3V1.5L8 5.25L14 1.5V3Z"
                      fill="#284767"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="paginatorright" let-state>
            <paginator
              [pageSelected]="pageSelected"
              [rows]="rows"
              [state]="state"
              [lenght]="insightJobExeRuns.length"
              (updatePaginator)="updatePaginator($event)"
              [type]="type"
            ></paginator>
          </ng-template>
        </p-table>
      </div>
      <!--<div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div> -->
    </div>

    <div *ngIf="loadingData" style="position: relative; top: 80%; left: 50%">
      <p-progressSpinner
        styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
        strokeWidth="2"
        animationDuration="1.5s"
      >
      </p-progressSpinner>
    </div>
    <ng-template #noData>
      <div class="insightJobExeRun_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </ng-template>
  </div>
</div>
