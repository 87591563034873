import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { healthMeasure_Hour, healthMetrics_Battery, healthMeasure_Day, healthMetrics_Hour, healthMetrics_Day, deviceMeasures_Range_Sizes, deviceMeasures_Range_Time, measurementPeaks, healthMetrics_Atomic, healthMeasure_Atomic } from 'src/app/shared/movano-interfaces';
import { MovanoService } from 'src/app/movano.service';
import { Calendar } from 'primeng/calendar';
import { Subscription, lastValueFrom } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import * as  LITERALS from '../../../shared/movano-literals';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { abbrNum, fixPixelsBy1440, getDayArrraySimpleIV } from 'src/app/shared/utils';
import { UserPanelTimeSelectorComponent } from '../time-selector/user-panel-time-selector.component';
import { NewTimeSelectorComponent } from '../../../new-time-selector/new-time-selector.component'
import { MatSelect } from '@angular/material/select';
import { MainServiceService } from 'src/app/main-service.service';

interface MeasureColors {
  [key: string]: string[];
};
@Component({
  selector: 'app-user-panel-health-metrics',
  templateUrl: './user-panel-health-metrics.component.html',
  styleUrls: ['./user-panel-health-metrics.component.scss']
})
export class UserPanelHealthMetricsComponent implements OnChanges {

  // protected monthNames = ["Jan", "Feb", "March", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // protected monthNamesComplete = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  protected timeLabels: string[] = [
    '01am', '02am', '03am', '04am', '05am', '06am', '07am', '08am', '09am', '10am', '11am', '12am',
    '01pm', '02pm', '03pm', '04pm', '05pm', '06pm', '07pm', '08pm', '09pm', '10pm', '11pm', '12pm',];

  /*
  0 => today / any day
  1 => week
  2 => month (default)
  3 => range day
  */
  protected selectedDate: number = 2;
  protected lastSelectedDate: number = 1;
  protected displayedTimeOptions: string[] = ['Today', 'Last week', 'Last month', 'Last 3 months']; //Last 3 months will be deleted
  protected timeOptions: string[] = ['', 'week', 'month', ''];

  protected dayView: boolean = false;

  protected measures_Day?: healthMeasure_Hour[]; //All info per day
  protected measures_MonthWeek?: healthMeasure_Day[]; //All info per month / week
  protected measures_Atomic: healthMeasure_Atomic[] = []; //All info per day in atomic data
  protected secondsXaxys: number[] = []; //[0,1,2,3,...,45051,45052,...,86400]
  @Input() savedMeasureData: healthMetrics_Hour | healthMetrics_Day | undefined; //All saved info
  @Input() loadingMesureData: boolean = true;
  @Input() lastDaySelected?: Date;
  @Input() lastDayView?: boolean;
  @Input() calendarData?: [any, boolean, Date | Date[], string, any];

  options: EChartsOption = {}; //e-charts configuration

  protected measuresLabels: string[] = []; //Label names
  protected measuresSelected: number[] = [0];
  protected lastMeasureSelected: string = '';
  public isMultiSelect: boolean = true;
  public selectedMeasureIndex: number = 0;
  protected temporarySelection: number[] = [...this.measuresSelected];

  protected innerWidth: number = 0; //Real window with in pixels

  protected nextIsPosible: boolean = false;

  //DARK MODE
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();

  protected batteryDisplayed: boolean = false; //Show battery chart
  @Input() measures_Battery?: healthMetrics_Battery; //All battery saved info
  protected battery_Index: number = 0;
  protected weHaveBatteryInfo: boolean = false;
  options_Battery: EChartsOption = {}; //e-charts configuration

  protected timeZones: string[] = []; //UTC, Madrid, California... etc
  protected actualTimeZone?: string;
  protected utc: boolean = false;

  protected moment = require('moment-timezone'); //https://www.npmjs.com/package/moment-timezone

  protected dataPeaks: measurementPeaks[] = [];

  protected monthViewOptsDisplay: boolean = true;
  protected averageDayData: boolean = true;
  protected stepsTotal: number = 0;
  protected caloriesTotal: number = 0;
  protected actualMeasure: string = '';

  backupLabel: string = '';

  protected measuresDisplayMap: { [key: string]: string } = {
    'HR': 'HR - Heart Rate',
    'Resting Heart Rate': 'RHR - Resting Heart Rate',
    'HRV': 'HRV - Heart Rate Variability',
    'SpO2': 'Blood Oxygen',
    'BR': 'BR - Breathing Rate',
    'Steps': 'Steps',
    'Calories Burn': 'Calories Burn',
    'Skin Temperature Fahrenheit': 'Skin Temperature Fahrenheit',
  };

  //Colors per measure
  protected measureColors: MeasureColors = {
    SpO2: ['#0B84FF', '#054280', 'rgba(11, 132, 255, 0.5)', 'rgba(11, 132, 255, 0)'],
    BR: ['#FF830D', '#FF830D', 'rgba(255, 131, 13, 0.5)', 'rgba(255, 131, 13, 0)'],
    HR: ['#00B377', '#00593B', 'rgba(0, 178, 119, 0.5)', 'rgba(0, 178, 119, 0)'],
    Cal: ['#E22B2B', '#E22B2B', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)'],
    'Calories Burn': ['#E22B2B', '#E22B2B', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)'],
    Steps: ['#284767', '#061E37', 'rgba(5, 66, 128, 0.5)', 'rgba(5, 66, 128, 0)'],
    HRV: ['#d44602', '#fb5607', 'rgba(251, 86, 7, 0.5)', 'rgba(251, 86, 7, 0)'],
    'Resting Heart Rate': ['#663980', '#56326A', 'rgba(102, 57, 128, 0.5)', 'rgba(102, 57, 128, 0)'],
    'Skin Temperature Fahrenheit': ['#E01485', '#E01485', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)']
  };

  protected daySelected?: Date;
  protected rangeSelected: Date[] = [];
  protected today?: Date;
  @Input() daySelector!: NewTimeSelectorComponent;
  @Input() isUTC?: boolean;
  @Output() saveData = new EventEmitter<[healthMetrics_Hour | healthMetrics_Day | undefined, healthMetrics_Battery | undefined, Date | undefined, boolean | undefined]>();

  //CONSTRUCTOR
  constructor(
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService,
    private mainService: MainServiceService,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {
    this.daySelected = new Date(Date.now());
    this.today = new Date(this.daySelected.getFullYear(), this.daySelected.getMonth(), this.daySelected.getDate());
  };

  //INIT
  ngOnInit(): void {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
    this.innerWidth = document.documentElement.clientWidth;
    this.lastMeasureSelected = this.measuresLabels[this.selectedMeasureIndex];
  }

  //AFTER VIEW INIT
  ngAfterViewInit(): void {
    //If we are loading data
    if (this.loadingMesureData) this.getInfo();
    else if (this.savedMeasureData) {
      this.daySelected = this.lastDaySelected;
      this.selectedDate = this.lastDayView ? 0 : 2;
      (this.selectedDate == 0) ?
        this.healthMetrics_Hour_Parse(this.savedMeasureData as healthMetrics_Hour) :
        this.healthMetrics_Day_Parse(this.savedMeasureData as healthMetrics_Day);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['calendarData']) {
      this.getInfo(changes['calendarData'].currentValue)
    }

    if (changes['isUTC']) {
      this.getInfo()
    }
  }

  //ON DESTROY (dark mode un subscribe)
  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.updateInfo();
  }


  getDisplayLabel(label: string): string {
    return this.measuresDisplayMap[label] || label;
  }

  toggleBatteryInfo() {
    this.batteryDisplayed = !this.batteryDisplayed;
  }


  async getInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    if (calendarData) {
      this.selectedDate = parseInt(calendarData[0]);
      this.nextIsPosible = calendarData[1];

      if (this.selectedDate !== 3) {
        this.daySelected = calendarData[2] as Date;
      } else {
        this.rangeSelected = calendarData[2] as Date[];
      }


      this.actualTimeZone = calendarData[3];
      this.lastSelectedDate = parseInt(calendarData[4]);
    } else {
      this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();
    }

    // Reset variables
    this.loadingMesureData = true;
    this.measures_Day = undefined;
    this.measures_MonthWeek = undefined;
    this.measures_Battery = undefined;
    this.measures_Atomic = [];
    this.measuresLabels = [];

    let timeFrame: string | undefined;
    let dateStart: Date | undefined;
    let dateEnd: Date | undefined;

    // Handle different cases based on selectedDate
    switch (this.selectedDate) {
      case 0:
        timeFrame = 'today';
        dateStart = this.daySelected;
        dateEnd = this.daySelected;
        break;
      case 1:
        timeFrame = 'lastWeek';
        break;
      case 2:
        timeFrame = 'lastMonth';
        break;
      case 3:
        dateStart = this.rangeSelected[0];
        dateEnd = this.rangeSelected[1];
        break;
      case 4:
        timeFrame = 'last7Days';
        break;
      case 5:
        timeFrame = 'actualYear';
        break;
      default:
        break;
    }

    const saveDate = new Date(this.daySelected!.getFullYear(), this.daySelected!.getMonth(), this.daySelected!.getDate());
    this.saveData.emit([undefined, undefined, saveDate, undefined]); // Pass date
    this.daySelected = saveDate;

    if (this.batteryDisplayed) {
      this.batteryDisplayed = this.measures_Battery != undefined;
    }

    if (this.selectedDate === 0) { // Day gathering data
      if (this.averageDayData) {
        this.healthMetrics_Hour_Parse(
          await lastValueFrom(
            this.movanoService.getHealthMetrics_Hour(
              this.data.user,
              this.daySelected!,
              this.isUTC
            )
          )
        );
        return;
      }
      return;
    }

    this.healthMetrics_Day_Parse(
      await lastValueFrom(
        this.selectedDate !== 3
          ? this.movanoService.getHealthMetrics_Day(
            this.data.user,
            this.isUTC,
            undefined,
            undefined,
            timeFrame // Using the appropriate timeFrame based on the selectedDate
          )
          : this.movanoService.getHealthMetrics_Day(
            this.data.user,
            this.isUTC,
            dateStart,
            dateEnd,
            undefined
          )
      )
    );
  }


  healthMetrics_Hour_Parse(dml: healthMetrics_Hour) {
    this.mainService.updateUserRangedTimezones(dml.timezonesRanked);

    // Set to store available measures
    const measuresAvalible: Set<number> = new Set<number>();

    // Update measures_Day property with the provided data
    this.measures_Day = dml.measure;
    // If timeZones are not set, initialize them and set the actualTimeZones
    if (!this.timeZones) {
      this.timeZones = dml.timezones;
      this.actualTimeZone = this.timeZones[0];
      // Update the input field in the day selector component
      this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);
    }

    // Initialize dataPeaks index
    let index: number = 0;

    // Loop through each measure in measures_Day
    this.measures_Day.forEach((measure: healthMeasure_Hour | any) => {
      // Handle the battery measure (measure_id === 0 => battery)
      if (measure.measure_id === 0) {
        this.measures_Battery = measure;
        return;  // Skip parsing battery data
      }

      // Store measure_label in measuresLabels
      this.measuresLabels.push(measure.measure_label);

      // Add measure_id to measuresAvalible set
      measuresAvalible.add(measure.measure_id);

      // Initialize measurementPeaks object
      let peaks: measurementPeaks = { max: 0, min: 0, average: 0, average_Max: 0 };
      // Initialize count of measured data points
      let measuredCounts: number = 0;
      // Loop through each data point in the measure's data array
      measure.data.forEach((data: number) => {
        data = Math.round(data);
        if (data === 0) return; // Skip zero data points
        // Update peaks object based on current data point
        peaks.max = (+data > +peaks.max) ? +data : +peaks.max;
        peaks.min = (+data < +peaks.min || +peaks.min === 0) ? +data : +peaks.min;
        peaks.average_Max = (+data > +peaks.average_Max) ? +data : +peaks.average_Max;
        peaks.average = +peaks.average + +data;
        measuredCounts = +measuredCounts + 1;
      });

      // Calculate the average value
      peaks.average = peaks.average / measuredCounts;

      // Update dataPeaks array with calculated peaks
      if (this.dataPeaks.length <= index) {
        this.dataPeaks.push(peaks);
      } else {
        this.dataPeaks[index] = peaks;
      }
      index++;
    });
    // Fix the measure selection based on available measures
    this.fixMeasureSelect(measuresAvalible);

    // Update the information
    this.updateInfo();

    // Store the parsed data and battery information in the father
    this.saveData.emit([dml, this.measures_Battery!, this.daySelected ?? new Date(Date.now()), this.selectedDate == 0]);
  }

  healthMetrics_Day_Parse(dmr: healthMetrics_Day) {
    this.mainService.updateUserRangedTimezones(dmr.timezonesRanked);

    // Set to store available measures
    const measuresAvalible: Set<number> = new Set<number>();

    // Update measures_MonthWeek property with the provided data
    this.measures_MonthWeek = dmr.measure;

    // If timeZones are not set, initialize them and set the actualTimeZone
    if (!this.timeZones) {
      this.timeZones = dmr.timezones;
      this.actualTimeZone = this.timeZones[0];
      // Update the input field in the day selector component
      this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);
    }

    // Initialize dataPeaks index
    let index: number = 0;
    // Loop through each measure in measures_MonthWeek
    this.measures_MonthWeek.forEach((measure: healthMeasure_Day | any) => {
      // Handle the battery measure (measure_id === 0 => battery)
      if (measure.measure_id === 0) {
        this.measures_Battery = measure;
        return;  // Skip parsing battery data
      }
      // Store measure_label in measuresLabels
      this.measuresLabels.push(measure.measure_label);
      // Add measure_id to measuresAvalible set
      measuresAvalible.add(measure.measure_id);

      // Initialize measurementPeaks object
      let peaks: measurementPeaks = { max: 0, min: 0, average: 0, average_Max: 0 };
      // Initialize count of measured data points
      let measuredCounts: number = 0;
      // Loop through each data point in the measure's data array
      measure.avg.forEach((data: number) => {
        if (data === 0) return // Skip zero data points
        // Update peaks object based on current data point
        peaks.average = +peaks.average + +data;
        peaks.average_Max = (+data > +peaks.average_Max) ? +data : +peaks.average_Max;
        measuredCounts = +measuredCounts + 1;
      });

      //Save total calories and steps
      let stepsTotal = 0;
      let caloriesTotal = 0;

      if (measure.measure_label === "Steps" || measure.measure_label === "Calories Burn") {
        // Filtrar y sumar los valores válidos en avg que no sean -1
        const total = measure.avg
          .filter((value: number) => value !== -1)
          .reduce((acc: number, value: number) => acc + value, 0);

        if (measure.measure_label === "Steps") {
          stepsTotal = total;
          this.stepsTotal = stepsTotal
        } else if (measure.measure_label === "Calories Burn") {
          caloriesTotal = Math.round(total);
          this.caloriesTotal = caloriesTotal
        }
      }

      // Initialize arrays to store minimum and maximum values (which are not needed)
      measure.min = [];
      measure.max = [];
      // Loop through each pair of min-max data in measure.min_max array
      measure.min_max.forEach((data: number[]) => {
        // Loop through each pair of min-max data in measure.min_max array
        measure.min.push(data[0]);
        // Store the maximum value in the measure.max array
        measure.max.push(data[1]);
        // If the maximum value is 0, skip further processing for this data point
        if (data[1] === 0) return;
        // Update peaks.max if the current maximum value is greater than the current peak maximum
        peaks.max = (+data[1] > +peaks.max) ? +data[1] : +peaks.max;
      })

      // Calculate the average value
      peaks.average = peaks.average / measuredCounts;

      if (this.dataPeaks.length <= index) this.dataPeaks.push(peaks);
      else this.dataPeaks[index] = peaks;
      index++;
    })
    // Fix the measure selection based on available measures
    this.fixMeasureSelect(measuresAvalible);

    // Update the information
    this.updateInfo();

    // Store the parsed data and battery information in the father
    this.saveData.emit([dmr, this.measures_Battery!, this.daySelected ?? new Date(Date.now()), this.selectedDate == 0]);
  }

  healthMetrics_Day_Atomic(hmda: healthMetrics_Atomic) {
    this.mainService.updateUserRangedTimezones(hmda.timezonesRanked);

    hmda.measure.forEach(measureHmda => {
      if (measureHmda.measure_id != 0) {
        this.measuresLabels.push(measureHmda.measure_label);
        measureHmda.dataParsed = getDayArrraySimpleIV(measureHmda.data);
        this.measures_Atomic.push(measureHmda);
      }
    });
    this.secondsXaxys = Array.from({ length: hmda.measure_data_length }, (_, index) => index);
    // Update the information
    this.updateInfo();
  }

  putUTCclass() {
    const utc_option = document.querySelector('.UtcSelectors');
    const utc_list = utc_option?.parentElement;
    utc_list?.classList.add('UtcList');
  }

  /**
   * Adjusts the measure selection and colors when the selected date changes.
   * Also handles the scenario where the last selected measure might not be available.
   */
  fixMeasureSelect(measuresAvalible: Set<number>) {
    this.battery_Index = 0;
    this.weHaveBatteryInfo = (this.measures_Battery?.battery) ? this.measures_Battery!.battery.length > 0 : false;
    // this.measuresSelected = [0];
  }

  /**
 * Updates the information and configuration for the chart.
 * Sets up series data, axis labels, colors, and other chart settings.
 * Also triggers updating battery information.
 */
  updateInfo() {
    // Reset the loadingMesureData flag
    this.loadingMesureData = false;
    console.log('Selected measures:', this.measuresSelected);

    this.options = this.optionsConstructor();

    if (!this.averageDayData && this.selectedDate == 0) {
      this.options.yAxis = [];
      this.options.xAxis = [];
      this.options.tooltip = [];
      this.measuresSelected.forEach((measure: number, index: number) => {
        let hmA: healthMeasure_Atomic = this.measures_Atomic[measure];
        let colorSelected = this.measureColors[hmA.measure_label]!;
        const newSeries = {
          name: hmA.measure_label,
          yAxisIndex: index,
          symbolSize: fixPixelsBy1440(3, this.innerWidth),
          color: colorSelected[0],
          dimensions: ['x', 'y'],
          data: hmA.dataParsed,
          type: 'scatter',
          blendMode: 'source-over',
          large: true,
        };
        (this.options.yAxis as any[]).push(this.multipleYaxisConstructor(hmA.measure_label, index, colorSelected[0]));
        (this.options.series as any[]).push(newSeries);
      });

      const nexXaxys = {
        type: 'category',
        show: true,
        data: this.secondsXaxys,
        axisTick: {
          show: false
        },
        axisLine: {
          show: false
        },
        splitLine: {
          show: true,
          interval: function (index: number, value: string) {
            return index % 360 === 0;
          },
          lineStyle: {
            color: '#D1F5FF'
          }
        },
        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(10, this.innerWidth),
          show: true,
          hideOverlap: true,
          interval: function (index: number, value: string) {
            return index % 360 === 0;
          },
          formatter: function (value: string, index: number) {
            const timeLabels: string[] = ['00:00:00', '06:00:00', '12:00:00', '18:00:00'];
            return timeLabels[parseInt(value) / 360];
          },
          color: '#7797B8',
        }
      };
      const newToolTip = {
        formatter: (params: any) => {
          function secondsToHHMMSS(totalSeconds: number): string {
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            const formattedHours = hours.toString().padStart(2, '0');
            const formattedMinutes = minutes.toString().padStart(2, '0');
            const formattedSeconds = seconds.toString().padStart(2, '0');

            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          }
          return `
          <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
            <div style="display: flex; flex-direction: column; color: ${this.measureColors[params.seriesName][0]}">
              <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${params.seriesName}</span>
              <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${secondsToHHMMSS(params.data[0])}</span>
              <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                ${Math.round(params.data[1])}
              </span>
            </div>
          </div>`;
        },

        hideDelay: 3000,
        axisPointer: {
          type: 'none',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      };
      (this.options.tooltip as any[]).push(newToolTip);
      (this.options.xAxis as any[]).push(nexXaxys);
    }
    else if (this.measuresSelected.length > 1) {
      this.options.yAxis = [];
      this.measuresSelected.forEach((measure: number, index: number) => {
        const measureLabel = this.measuresLabels[measure];
        let colorSelected = this.measureColors[measureLabel]!;
        const newSeries = {
          type: 'line',
          name: measureLabel,
          yAxisIndex: index,
          symbolSize: fixPixelsBy1440(8, this.innerWidth),
          lineStyle: {
            width: fixPixelsBy1440(1, this.innerWidth),
          },
          color: colorSelected[0],
          data: (this.selectedDate == 0) ?
            (this.measures_Day) ? this.measures_Day![measure].data : []
            :
            (this.measures_MonthWeek) ? this.measures_MonthWeek![measure].avg : [],

          emphasis: {
            focus: 'series',
            itemStyle: {
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              shadowBlur: 10,
            },
            lineStyle: {
              shadowColor: 'rgba(0, 0, 0, 0.5)',
              shadowBlur: 10,
            },
          },

          markLine: (this.selectedDate != 0 && index == 0) ? this.dateMarkLineConstructor() : {},
        };
        (this.options.yAxis as any[]).push(this.multipleYaxisConstructor(measureLabel, index, colorSelected[0]));
        (this.options.series as any[]).push(newSeries);
      });
    } else {
      (!(this.selectedDate == 0) ? this.seriesDaysConstructor() : this.seriesMinuteConstructor()).forEach(element => {
        (this.options.series as any[])!.push(element);
      });

      const measureLabel = this.measuresLabels[this.measuresSelected[0]] || 'Skin Temperature Fahrenheit';

      if (measureLabel === 'SpO2') {
        (this.options.yAxis as any).max = 110;
        (this.options.yAxis as any).min = 60;
      } else if (measureLabel === 'Skin Temperature Fahrenheit') {
        (this.options.yAxis as any).max = 135;
        (this.options.yAxis as any).min = 0;
        this.measureColors[this.measuresLabels[this.measuresSelected[0]]][1] = '#E01485';
      } else {
        let peaks = this.dataPeaks[this.measuresSelected[0]].max * 1.2;
        let options = this.options.yAxis;
        (options as any).max = peaks;
      }
    }


    this.updateBatteryInfo();
  }



  seriesDaysConstructor(): echarts.SeriesOption[] {
    // Determine the selected measure label and color
    const measureLabelRange = this.measuresLabels[this.measuresSelected[0]];
    this.actualMeasure = measureLabelRange
    console.log('Measure', measureLabelRange)
    let colorSelectedRange = this.measureColors[measureLabelRange]!;

    return [
      {
        name: (this.measures_MonthWeek) ? this.measures_MonthWeek![this.measuresSelected[0]].measure_label : '',
        type: 'bar',
        stack: 'Total',
        label: {
          show: false,
          position: 'top'
        },
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent'
        },
        emphasis: {
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent'
          }
        },
        data: this.measures_MonthWeek?.[this.measuresSelected[0]]?.min ?? []
      },
      {
        z: 9,
        name: (this.measures_MonthWeek) ? this.measures_MonthWeek![this.measuresSelected[0]].measure_label : '',
        color: colorSelectedRange[0] ?? 'black',
        type: 'bar',
        barWidth: this.innerWidth > 1440 ? this.innerWidth * 0.002 : 3,
        stack: 'Total',
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
          position: 'top'
        },
        markLine: {
          symbol: 'none',
          label: {
            show: false
          },
          lineStyle: {
            color: colorSelectedRange[1],
            width: fixPixelsBy1440(1, this.innerWidth),
          },
          data: [{ yAxis: this.dataPeaks[this.measuresSelected[0]].average, name: 'Avg' }]
        },

        data:
          this.measures_MonthWeek?.[this.measuresSelected[0]]?.max.map(
            (max: number, index: number) => max - (this.measures_MonthWeek?.[this.measuresSelected[0]]?.min?.[index] ?? 0)) ?? []
      },
      {
        type: 'line',
        symbol: 'circle',
        z: 10,
        name: (this.measures_MonthWeek) ? this.measures_MonthWeek![this.measuresSelected[0]].measure_label : '',
        symbolSize: fixPixelsBy1440(10, this.innerWidth),
        itemStyle: {
          color: this.darkMode ? '#061E37' : 'white',
          borderColor: this.darkMode ? 'white' : colorSelectedRange[1],
          borderWidth: fixPixelsBy1440(3, this.innerWidth), //https://github.com/apache/echarts/issues/18447
        },
        markLine: this.dateMarkLineConstructor(),
        lineStyle: { width: 0, },
        data: this.measures_MonthWeek?.[this.measuresSelected[0]]?.avg ?? [],
      }
    ];
  }

  seriesMinuteConstructor(): echarts.SeriesOption[] {

    // Determine the selected measure label and color
    const measureLabelRange = this.measuresLabels[this.measuresSelected[0]] || 'Skin Temperature Fahrenheit';

    let colorSelectedRange = this.measureColors[this.actualMeasure] || ['#E01485', '#E01485', 'rgba(226, 43, 43, 0.5)', 'rgba(226, 43, 43, 0)'];

    const measureName = (this.measures_Day) ? this.measures_Day![this.measuresSelected[0]].measure_label === 'battery' ? 'Skin Temperature Fahrenheit' : this.measures_Day![this.measuresSelected[0]].measure_label : '';
    const measureData = (this.measures_Day) ? this.measures_Day![this.measuresSelected[0]].measure_label === 'battery' ? this.measures_Day![7].data : this.measures_Day![this.measuresSelected[0]].data : [];
    const avgPeak = this.dataPeaks[this.measuresSelected[0]].average;

    return [
      {
        name: measureName,
        type: 'line',
        color: colorSelectedRange[0],
        smooth: true,
        markLine: {
          symbol: 'none',
          label: {
            show: false
          },
          lineStyle: {
            color: colorSelectedRange[1],
            width: fixPixelsBy1440(1, this.innerWidth),
          },
          data: [{ yAxis: avgPeak, name: 'Avg' }]
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: colorSelectedRange[2]
            },
            {
              offset: 1,
              color: colorSelectedRange[3]
            }
          ])
        },
        emphasis: {
          focus: 'series'
        },
        data: measureData
      }
    ];
  }


  optionsConstructor(): EChartsOption {
    // Determine the selected measure label and color
    const measureLabelRange = this.measuresLabels[this.measuresSelected[0]];
    let colorSelectedRange = this.measureColors[measureLabelRange]!;
    return {
      renderer: 'svg',
      color: ['rgb(77, 119, 255)', 'rgb(116, 21, 219)', 'green', '#FF0087', '#FFBF00'],

      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          if (!params.seriesType) return '';

          const formatTemperature = (value: number) => {
            return value + '°';
          };
          if (this.selectedDate == 0) {
            return params.seriesType !== 'bar' ? `
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: ${this.measureColors[params.seriesName][0]}">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${params.seriesName}</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                    ${Math.round(this.measures_Day![this.measuresSelected[params.seriesIndex]].data[params.dataIndex])}
                </span>
              </div>
            </div>
            ` : ''
          }
          if (this.measuresSelected.length === 1 && (params.seriesName === 'HR' || params.seriesName === 'SpO2')) {
            return params.seriesType !== 'bar' ? `
            <div style="margin-bottom: 12px; font-size: 12px">
              <span>${this.measures_MonthWeek![this.measuresSelected[0]].timezone[params.dataIndex].label} ${this.measures_MonthWeek![this.measuresSelected[0]].timezone[params.dataIndex].offset}</span>
            </div>
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: ${colorSelectedRange[0]}">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">Max/Min</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${Math.round(this.measures_MonthWeek![this.measuresSelected[0]].max[params.dataIndex])}/${Math.round(this.measures_MonthWeek![this.measuresSelected[0]].min[params.dataIndex])}
                </span>
              </div>
              <div style="display: flex; flex-direction: column; padding-left: 15px; color: ${colorSelectedRange[1]}">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">Average</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${Math.round(this.measures_MonthWeek![this.measuresSelected[0]].avg[params.dataIndex])}
                </span>
              </div>
            </div>
                    ` : '';
          }
          return params.seriesType !== 'bar' ? `
            <div style="margin-bottom: 12px; font-size: 12px">
              <span>${this.measures_MonthWeek![this.measuresSelected[0]].timezone[params.dataIndex].label} ${this.measures_MonthWeek![this.measuresSelected[0]].timezone[params.dataIndex].offset}</span>
            </div>
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: ${this.measureColors[params.seriesName][0]}">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">${params.seriesName}</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                   ${ Math.round(this.measures_MonthWeek![this.measuresSelected[params.seriesIndex] ?? this.measuresSelected[0]].avg[params.dataIndex])}
                </span>
              </div>
            </div>
            ` : '';
        },
        hideDelay: 3000,
        //position: 'right',
        axisPointer: {
          type: 'none',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },

      grid: {
        right: (this.measuresSelected.length > 1) ? fixPixelsBy1440(50, this.innerWidth) * Math.floor(this.measuresSelected.length / 4) + fixPixelsBy1440(50, this.innerWidth) : fixPixelsBy1440(35, this.innerWidth),
        top: '5%',
        bottom: '2%',
        left: (this.measuresSelected.length > 1) ? fixPixelsBy1440(50, this.innerWidth) * Math.floor(this.measuresSelected.length / 3) + fixPixelsBy1440(50, this.innerWidth) : fixPixelsBy1440(15, this.innerWidth),
        // right: '0%',
        // bottom: '1%',
        containLabel: true,
        height: 'auto',
        width: 'auto',
      },
      xAxis: [
        {
          type: 'category',
          position: 'top',
          show: true,
          boundaryGap: false,
          splitLine: {
            show: true, // Mostrar las líneas en el grid
            lineStyle: {
              width: fixPixelsBy1440(1, this.innerWidth),
              color: this.darkMode ? 'rgba(255, 255, 255, 0.10)' : '#D1F5FF', // Color de las líneas en el grid
            },
          },
          data: (this.selectedDate == 0) ? this.timeLabels
            :
            (this.measures_MonthWeek) ? this.measures_MonthWeek![0].dates : [],

          axisLabel: {
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(1vw, 14px)',
            // line-height: '133.4%',
            color: 'rgba(119, 151, 184, 1)',
            formatter: (this.selectedDate == 0) ? '{value}' :
              (function (value) {
                let label = value.substring(value.length - 2);
                return label;
              })
          }
        }
      ],
      yAxis:
      {
        //offset: 20,

        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(1vw, 12px)',
          color: 'rgb(5, 66, 128)',
          verticalAlign: 'bottom',
          lineHeight: fixPixelsBy1440(30, this.innerWidth),
          formatter: function (value: any, index: any) {
            let label: string = Math.round(value).toString();
            return label;
          }
        },
      },
      series: []
    }
  }

  multipleYaxisConstructor(_name: string, _index: number, _color: string): any {
    let axisName;
    switch (_name) {
      case "Calories Burn":
        axisName = "Cal.";
        break;
      case "Resting Heart Rate":
        axisName = "RHR";
        break;
      case "Skin Temperature Fahrenheit":
        axisName = "ºF";
        break;
      default:
        axisName = _name;
    }
    return {
      type: 'value',
      name: axisName,
      alignTicks: true,
      offset: fixPixelsBy1440(35, this.innerWidth) * Math.floor(_index / 2) + fixPixelsBy1440(25, this.innerWidth),
      position: (_index % 2 === 1) ? 'right' : 'left',
      nameTextStyle: {
        fontSize: 'max(0.7vw, 10px)',
        fontWeight: 700,
        fontFamily: 'Zen Kaku Gothic Antique',
        align: (_index % 2 === 1) ? 'right' : 'left'
      },
      min: _name === "SpO2" ? 60 : 0,  // Set min to 60 for SpO2
      max: _name === "SpO2" ? 105 : undefined,
      axisLine: {
        show: true,
        lineStyle: {
          color: _color,
        }
      },
      axisLabel: {
        inside: true,
        fontWeight: 700,
        fontFamily: 'Zen Kaku Gothic Antique',
        fontSize: 'max(0.7vw, 10px)',
        color: _color,
        formatter: function (value: any) { return abbrNum(Math.round(value), 1, false, false) }
      }
    };
  }


  dateMarkLineConstructor(): any {
    let markLinesData: { xAxis: string, name: string }[] = [];
    const arrayLenght: number = this.measures_MonthWeek![0].dates.length;
    const firtsMonth: number = parseInt(this.measures_MonthWeek![0].dates[0].split('-')[1]);
    const lastMonth: number = parseInt(this.measures_MonthWeek![0].dates[arrayLenght - 1].split('-')[1]);
    for (let index = (firtsMonth + 1); index <= lastMonth; index++) {
      let str = index.toString();
      markLinesData.push({ xAxis: `2023-${(index < 10) ? ("0" + str) : str}-01`, name: LITERALS.MONTHS[index - 1] });
    }

    return {
      label: {
        show: true,
        fontWeight: 500,
        position: 'insideEndBottom',
        fontSize: fixPixelsBy1440(70, this.innerWidth),
        color: '#2f3943',
        opacity: 0.15,
        formatter: '{b}'
      },
      lineStyle: {
        type: 'solid',
        color: '#2ad2ff',
        width: fixPixelsBy1440(2, this.innerWidth)
      },
      symbol: 'none',
      data: markLinesData
    }
  }

  updateBatteryInfo() {
    this.weHaveBatteryInfo = (this.measures_Battery?.battery) ? this.measures_Battery!.battery.length > 0 : false;
    this.options_Battery = (this.weHaveBatteryInfo) ? {
      renderer: 'svg',
      color: ['rgb(77, 119, 255)', 'rgb(116, 21, 219)', 'green', '#FF0087', '#FFBF00'],

      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          return `
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: #7797B8">
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">Charge level</span>
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${params[0].data}%
                </span>
              </div>
            </div>
            `},
        hideDelay: 3000,
        //position: 'right',
        axisPointer: {
          type: 'none',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },

      grid: {
        right: fixPixelsBy1440(50, this.innerWidth) * Math.floor(this.measuresSelected.length / 4) + fixPixelsBy1440(50, this.innerWidth),
        top: '5%',
        bottom: '2%',
        left: fixPixelsBy1440(50, this.innerWidth) * Math.floor(this.measuresSelected.length / 3) + fixPixelsBy1440(50, this.innerWidth),
        // right: '0%',
        // bottom: '1%',
        containLabel: true,
        height: 'auto',//this.innerWidth > 1440 ? this.innerWidth * 0.33: this.innerWidth > 550 ? '480px' : '425px',
        width: 'auto',//this.innerWidth > 1440 ? 'auto' : '1095px',

      },
      xAxis: [
        {
          type: 'category',
          show: false,
        }
      ],
      yAxis: [
        {
          name: 'battery_left',
          type: 'value',
          minInterval: 50,
          show: true,
          position: 'left',
          alignTicks: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: 'gray',
            }
          },
          axisLabel: {
            inside: true,
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: 'max(1vw, 14px)',
            // line-height: '133.4%',
            color: 'rgba(119, 151, 184, 1)',
          }
        }
      ],
      series: [
        {
          name: (this.measures_Battery?.battery) ? this.measures_Battery.battery[this.battery_Index].device : '',
          type: 'line',
          color: '#7797B8',
          smooth: true,
          showSymbol: false,
          //symbol: 'none',
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(119, 151, 184, 0.40)'
              },
              {
                offset: 1,
                color: 'rgba(119, 151, 184, 0)'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: (this.measures_Battery?.battery) ? this.measures_Battery.battery[this.battery_Index].values : []
        }]
    } : {};
  }

  changeMeasure(index: number): void {
    if (!this.isMultiSelect) {
      this.selectedMeasureIndex = index;
      this.measuresSelected = [index];
      this.lastMeasureSelected = this.measuresLabels[index];
      this.updateInfo();
    }
  }

  isSelected(index: number): boolean {
    return this.temporarySelection.includes(index);
  }


  toggleSelection(index: number): void {

    const idx = this.temporarySelection.indexOf(index);
    if (idx > -1) {
      this.temporarySelection.splice(idx, 1);
    } else {
      this.temporarySelection.push(index);
    }
  }

  clearSelection(): void {
    if (!this.isMultiSelect) {
      const defaultMeasure = 'HR';
      const defaultIndex = this.measuresLabels.findIndex(label => label === defaultMeasure);
      if (defaultIndex !== -1) {
        this.selectedMeasureIndex = defaultIndex;
        this.measuresSelected = [defaultIndex];
        this.lastMeasureSelected = this.measuresLabels[defaultIndex];
        this.updateInfo();
      }
    } else {
      this.temporarySelection = [];
    }
  }

  onSelectionChange(event: any): void {
    if (!this.isMultiSelect) {
      this.changeMeasure(parseInt(event.value, 10));
    }
  }

  confirmSelection(selectRef: MatSelect): void {
    this.measuresSelected = [...this.temporarySelection];
    this.updateInfo();
    selectRef.close();
  }

  getPlaceholderText(): string {
    if (this.isMultiSelect) {
      if (this.temporarySelection.length === 1) {
        const index = this.temporarySelection[0];
        return this.getDisplayLabel(this.measuresLabels[index]);
      } else if (this.temporarySelection.length > 1) {
        return 'Multiselection';
      } else {
        return 'Select measures';
      }
    } else {
      return this.getDisplayLabel(this.actualMeasure);
    }
  }



  /**
 * Changes the selected measure, updating the measuresSelected array.
 * @param _index - The index of the measure to be added or removed.
 */
  /*   changeMeasure(_index: number) {
      // Check if the measure index is already in measuresSelected array
      let index = this.measuresSelected.indexOf(_index);
      // If index is not found, add the measure to the measuresSelected array
      if (index == -1) {
        this.measuresSelected.push(_index);
      } else {
        // If index is found and there is only one measure selected, exit the function
        if (this.measuresSelected.length <= 1) return;
        // Remove the measure from the measuresSelected array
        this.measuresSelected.splice(index, 1);
      }

      // Update lastMeasureSelected with the label of the first selected measure
      this.lastMeasureSelected = this.measuresLabels[this.measuresSelected[0]];

      // Update information based on the selected measures
      this.updateInfo();
    }

   */

  /**
   * Advances to the next date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next date is not possible, exit the function
    if (!this.nextIsPosible) return;
    // Depending on the selected date range, advance to the next day, week, or month
    switch (+this.selectedDate) {
      case 0: //Next day
        this.selectedDate = 0;
        this.daySelected?.setDate(this.daySelected.getDate() + 1);
        break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() + 1);
        break;
      case 1: //Next week
        this.daySelected?.setDate(this.daySelected.getDate() + 7);
        break;
      case 2: //Next month
        this.daySelected?.setMonth(this.daySelected.getMonth() + 1);
        break;
    }

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  /**
   * Goes to the previous date based on the selected date range.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    // Depending on the selected date range, go to the previous day, week, or month
    switch (+this.selectedDate) {
      case 0: //Previus day
        this.selectedDate = 0;
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 3:
        this.daySelected?.setDate(this.daySelected.getDate() - 1);
        break;
      case 1: //Previus week
        this.daySelected?.setDate(this.daySelected.getDate() - 7);
        break;
      case 2: //Previus month
        this.daySelected?.setMonth(this.daySelected.getMonth() - 1);
        break;
    }

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  /**
 * Selects a specific day and updates relevant properties and data.
 * @param _index - The index of the selected day in the data array.
 */

  selectDay(_index: number) {
    // Store the current selected date as the last selected date
    this.lastSelectedDate = this.selectedDate;
    // Set the selected date to a special value (-2 indicates any day selection)
    this.selectedDate = 0;
    // Get the date of the selected day from the data
    const selectedDay = this.measures_MonthWeek![this.measuresSelected[0]].dates[_index];
    // Convert the selected day to a Date object
    this.daySelected = new Date(selectedDay);
    // Reset battery information flag
    this.weHaveBatteryInfo = false;

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.selectedDate);
    this.getInfo();
  }

  /**
   * Handles a chart event and triggers the selection of a specific day.
   * @param event - The chart event object containing relevant data.
   * @param type - The type of event being handled.
   */
  onChartEvent(event: any, type: string) {
    // Call the selectDay function with the dataIndex from the event
    this.selectDay(event.dataIndex);
  }

  returnToLabel(): string {
    if (this.selectedDate > 0) return '';

    if (this.lastSelectedDate == 1) {
      this.backupLabel = 'Back to weekly view';
      return this.backupLabel;
    }
    if (this.lastSelectedDate == 2) {
      this.backupLabel = 'Back to monthly view';
      return this.backupLabel;
    }
    if (this.lastSelectedDate == 4) {
      this.backupLabel = 'Back to last 7 days view';
      return this.backupLabel;
    }
    if (this.lastSelectedDate == 5) {
      this.backupLabel = 'Back to yearly view';
      return this.backupLabel;
    }
    if (this.lastSelectedDate == 0) {
      return this.backupLabel || 'Back to default view';
    }

    this.backupLabel = `Back to
    ${this.rangeSelected[0].getDate()} ${this.rangeSelected[0].toLocaleString('default', { month: 'short' })} -
    ${this.rangeSelected[1].getDate()} ${this.rangeSelected[1].toLocaleString('default', { month: 'short' })} view`;

    return this.backupLabel;
  }



  returnToLastChart() {

    if (this.lastSelectedDate === 0) {

      if (this.backupLabel === 'Back to weekly view') {
        this.selectedDate = 1;
      } else if (this.backupLabel === 'Back to monthly view') {
        this.selectedDate = 2;
      } else if (this.backupLabel === 'Back to last 7 days view') {
        this.selectedDate = 4;
      } else if (this.backupLabel === 'Back to yearly view') {
        this.selectedDate = 5;
      } else {
        this.selectedDate = 0;
      }
    } else {
      const savedOption = this.selectedDate;
      this.selectedDate = this.lastSelectedDate;
      this.lastSelectedDate = savedOption;
    }

    this.getInfo();
  }



  get filteredMeasuresLabels() {
    const uniqueLabels = Array.from(new Set(this.measuresLabels));

    return uniqueLabels.filter(label => label !== 'Skin Temperature');
  }

}
