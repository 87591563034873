import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MovanoService } from 'src/app/movano.service';
import { ActionConfirmationDialogComponent } from '../action-confirmation-dialog/action-confirmation-dialog.component';
import { adminUsersPostData } from 'src/app/shared/movano-interfaces';
import { RecoverPasswordComponent } from 'src/app/login/recover-password/recover-password.component';
import { DeleteOrganizationDialogComponent } from '../delete-organization-dialog/delete-organization-dialog.component';
import { DeleteUserAdminComponent } from '../delete-user-admin/delete-user-admin.component';

@Component({
  selector: 'app-create-edit-admin-user',
  templateUrl: './create-edit-admin-user.component.html',
  styleUrls: ['./create-edit-admin-user.component.scss']
})
export class CreateEditAdminUserComponent {
  protected isEditMode: boolean = false;
  selectedRole: string = '';
  isDropdownOpen: boolean = false;
  isVisible = false;
  adminUserData: any = {
    id:'',
    name: '',
    role: '',
    email: '',
    password: '',
  };

  @Output() closeHimself = new EventEmitter<boolean>();

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<CreateEditAdminUserComponent>,
    protected movanoService: MovanoService,
    @Inject(MAT_DIALOG_DATA) public data: { isEditMode: boolean; adminUserData?: any }
  ) {
    this.isEditMode = data.isEditMode;
    this.adminUserData = {
      ...this.adminUserData,
      ...data.adminUserData,
      adminUserName: data.adminUserData?.firstName || '',
    };
    console.log(this.data);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  createAdminUser(): void {
    const newUserData = {
      newUser: {
        email: this.adminUserData.email,
        name: this.adminUserData.name,
        password: this.adminUserData.password,
        accountType: this.selectedRole || 'customerSupport',
      }
    };

    this.movanoService.postAdminUser(newUserData).subscribe({

      next: () => {
        this.dialogRef.close();

        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: false, isAddUser: true }
        });
      },
      error: (err) => {
        console.error('Error al crear lel user:', err);
      }
    });
  }

  updateOrganization(): void {
    if (!this.adminUserData?.id) return;

    let organizationStatus;
    switch (this.adminUserData?.status.trim().toLowerCase()) {
      case "active":
        organizationStatus = 1;
        break;
      case "inactive":
        organizationStatus = 2;
        break;
      case "deleted":
        organizationStatus = 3;
        break;
      case "blocked":
        organizationStatus = 4;
        break;
      default:
        organizationStatus = 1;
        break;
    }

    const updatedData: any = {
      id: this.adminUserData?.id,
      email: this.adminUserData.email,
      firstName: this.adminUserData.name,
      status: this.selectedRole ,
    };


    this.movanoService.patchAdminUser(updatedData, this.adminUserData.id).subscribe({
      next: () => {
        this.dialogRef.close();
        this.dialog.open(ActionConfirmationDialogComponent, {
          width: '356px',
          data: { isEditMode: true , isAddUser: true}
        });
      },
      error: (err) => {
        console.error('Error al actualizar la organización:', err);
      }
    });
  }

  deleteUser(): void {
    this.dialogRef.close();
    console.log(this.adminUserData)
    this.dialog.open(DeleteUserAdminComponent, {

      width: '364px',
      data: {
        isDeletedMode: true, adminUser: this.adminUserData
      }
    });
  }

  getBadgeColor(status: string): string {
    status = status.trim().toLowerCase();

    if (status === "active") {
      return "movanoTable_Obj_Badge_blueDark";
    } else if (status === "deleted") {
      return "movanoTable_Obj_Badge_Deleted";
    } else if (status === "inactive") {
      return "movanoTable_Obj_Badge_Inactive";
    } else if (status === "blocked") {
      return "movanoTable_Obj_Badge_Blocked";
    } else {
      return status;
    }
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  selectRole(role: string) {
    this.selectedRole = role;
    this.isDropdownOpen = false;
  }
  togglePasswordVisibility() {
    this.isVisible = !this.isVisible;
  }

  maskPassword(password: string): string {
    return '*'.repeat(password.length);
  }

  resetPass() {
    this.closeHimself.emit();
    this.dialog.open(RecoverPasswordComponent);
  }

  generatePassword(): void {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      password += charset.charAt(Math.floor(Math.random() * n));
    }
    this.adminUserData.password = password;
  }

}
