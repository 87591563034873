<app-header class="fixed-header" [table]="insightAppTable" [title]="LITERALS.NTF_INSIGHT_APP.TITTLE"></app-header>
<div class="insight-app" [ngClass]="{ movanoDark: darkMode }">
  <div [ngStyle]="{ display: !loadingData ? 'block' : 'none' }" class="movanoTableBox">
    <insight-app-filter
      [table]="insightAppTable"
      [isFiltered]="isFiltered"
      [filteredUsers]="filteredUsers"
      [insightApps]="insightApps"
    ></insight-app-filter>
    <p-table
      id="insightAppTable"
      #insightAppTable
      dataKey="name"
      class="movano-Table"
      [globalFilterFields]="['name', 'application_id', 'type', 'release_version', 'architecture', 'last_modified']"
      [value]="insightApps"
      [paginator]="true"
      [rows]="rows"
      [first]="pageSelected"
      (onFilter)="onFilter($event)"

    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th pSortableColumn="name" (click)="sort('name')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_NAME }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="name-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="application_id" (click)="sort('application_id')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_APPLICATION_ID }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="application_id-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="type" (click)="sort('type')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_TYPE }}
            <svg
              (click)="sort('type')"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                id="type-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="release_version" (click)="sort('release_version')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_RELEASE_VERSION }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="release_version-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="architecture" (click)="sort('architecture')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_ARCHITECTURE }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="architecture-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="last_modified" (click)="sort('last_modified')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_LAST_MODIFIED }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="last_modified-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="status" (click)="sort('status')">
            {{ LITERALS.NTF_INSIGHT_APP.TABLE_STATUS }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="status-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notification let-i="rowIndex" let-expanded="expanded">
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td>{{ notification.name }}</td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">{{ notification.application_id }}</td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">{{ notification.type }}</td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">{{ notification.release_version }}</td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">{{ notification.architecture }}</td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">
            {{
              notification.last_modified
                ? (notification.last_modified | date : "MMM d, y") +
                  " / " +
                  (notification.last_modified | date : "HH:mm ")
                : ""
            }}
          </td>
          <td [ngStyle]="{ opacity: expanded ? '0%' : '100%' }">
            <div class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(notification.status)">
              {{ notification.status }}
            </div>
          </td>
          <td class="movanoTable_click">
            <div
              type="button"
              pButton
              [pRowToggler]="notification"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="expanded ? 'pi pi-angle-up' : 'pi pi-angle-down'"
            ></div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-i="rowIndex" let-notification>
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
        >
          <td colspan="8">
            <div class="p-3" class="movanoTable_Inside">
              <div class="ntfInsightInside">
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_NAME }}</div>
                  <div class="ntfInsightProps">{{ notification.name }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_TYPE }}</div>
                  <div class="ntfInsightProps">{{ notification.type }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_ARCHITECTURE }}</div>
                  <div class="ntfInsightProps">{{ notification.architecture }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_INTERACTIVE_ENDPOINT }}</div>
                  <div class="ntfInsightProps">{{ notification.interactive_endpoint }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_CREATION_TIME }}</div>
                  <div class="ntfInsightProps">
                    {{
                      notification.creation_time
                        ? (notification.creation_time | date : "MMM d, y") +
                          " / " +
                          (notification.creation_time | date : "HH:mm ")
                        : ""
                    }}
                  </div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_APPLICATION_ID }}</div>
                  <div class="ntfInsightProps">{{ notification.application_id }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_RELEASE_VERSION }}</div>
                  <div class="ntfInsightProps">{{ notification.release_version }}</div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_STATUS }}</div>
                  <div class="ntfInsightProps">
                    <div class="movanoTable_Obj_Badge" [ngClass]="getBadgeColor(notification.status)">
                      {{ notification.status }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_ARN }}</div>
                  <div class="ntfInsightProps">{{ notification.arn }} <i class="pi pi-copy"></i></div>
                </div>
                <div>
                  <div class="ntfInsightProps">{{ LITERALS.NTF_INSIGHT_APP.SUB_LAST_UPDATED }}</div>
                  <div class="ntfInsightProps">
                    {{
                      notification.last_modified
                        ? (notification.last_modified | date : "MMM d, y") +
                          " / " +
                          (notification.last_modified | date : "HH:mm ")
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="ntfInsightBadges">
                <div matRipple class="ntfInsightBadges_Notifications" (click)="handleNtfClick(notification.name)">
                  Notifications
                </div>
                <div matRipple class="ntfInsightBadges_Jobs" (click)="handleJobClick(notification.name)">Jobs</div>
                <div class="ntfInsightBadges_Executions">Manual Execution</div>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <paginator
          [pageSelected]="pageSelected"
          [rows]="rows"
          [state]="state"
          [lenght]="insightApps.length"
          (updatePaginator)="updatePaginator($event)"
          [type]="type"
        ></paginator>
      </ng-template>
    </p-table>
    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>

  <div *ngIf="loadingData" style="position: absolute; top: 50%; left: 50%">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
