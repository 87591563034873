<div class="movanoStats" [ngClass]="{ movanoStats_Dark: darkMode }">
  <div *ngIf="front; then userStats; else werableStats"></div>
  <ng-template #userStats>
    <span class="movanoStats_Tittle">Users stats</span>

    <div class="movanoStats_DognutStats">
      <!-- <p-chart type="doughnut" [data]="genderData" id="genderChart" width="8em" height="8em" [options]="basicOptions">
        </p-chart> -->
      <div
        echarts
        [options]="option_Gender ?? option_DonutEmpty"
        style="height: 9em; width: 9em; padding-top: 10px"
      ></div>
      <div class="movanoStats_DognutStats_Percentage">
        <span [ngStyle]="{ color: this.getGenderColor(0) }" style="font-size: 180%; font-weight: 700;"
          >{{ this.genderStats.data.total_users.gender.woman.percentage | number : "1.0-0" }}%</span
        >
        <div style="display: flex; gap: 0.33em">
          <span
            [ngStyle]="{ color: this.getGenderColor(1) }"
            style="font-size: 100%; font-weight: 400"
            *ngIf="genderPercentageArray.length > 1"
            >{{ this.genderStats.data.total_users.gender.man.percentage | number : "1.0-0" }}%</span
          >
          <!--     <span [ngStyle]="{color: this.getGenderColor(2)}" style="font-size: 100%; font-weight: 400;"
                  *ngIf="genderPercentageArray.length>2">{{this.getGenderPercentage(2) | number: '1.0-0'}}</span> -->
        </div>
      </div>
      <!--    <div style=" display: flex; flex-direction: column; justify-content: center; padding-left: 20px;">
            <span [ngStyle]="{color: this.getGenderColor(0)}"
            style="font-size: movanoResize(16); font-weight: 700;">{{this.getGenderPercentage(0) | number: '1.0-0'}}% </span>
            <div style="display: flex; gap: 0.33em;">
              <span [ngStyle]="{color: this.getGenderColor(1)}" style="font-size: movanoResize(16); font-weight: 700;"
                *ngIf="genderPercentageArray.length>1">{{this.getGenderPercentage(1) | number: '1.0-0'}} % men </span>
            </div>
          </div> -->
    </div>

    <div class="movanoStats_LegendUser1" style="gap: 3.5em; position: relative; bottom: 0.5em">
      <div style="display: flex; flex-direction: column; gap: 2.5em; padding-top: 30px; transform: scale(0.8)">
        <div style="display: flex; gap: 1.5em; align-items: center">
          <!-- <img style="width: 1.6em;" src="../../assets/men_Icon.svg"> -->

          <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
              [ngStyle]="{ fill: darkMode ? '#0DCCFF' : '#0B84FF' }"
            />
          </svg>
          <div style="display: flex; flex-direction: column; font-size: 14px">
            <span
              [ngStyle]="{ color: this.getGenderColor(1) }"
              style="font-size: movanoResize(16); font-weight: 700"
              *ngIf="genderPercentageArray.length > 1"
              >{{ this.genderStats.data.total_users.gender.man.percentage }} %
            </span>

            <span [ngStyle]="{ color: darkMode ? '#0DCCFF' : '#0B84FF' }" style="font-size: 100%; font-weight: 500"
              >{{ this.genderStats.data.total_users.gender.man.count }} Men</span
            >
          </div>
        </div>
        <div style="display: flex; gap: 1.5em; color: #0b84ff; align-items: center">
          <img style="width: 1.6em" src="../../assets/women_Icon.svg" />
          <div style="display: flex; flex-direction: column; font-size: 14px">
            <span [ngStyle]="{ color: this.getGenderColor(0) }" style="font-size: movanoResize(16); font-weight: 700"
              >{{ this.genderStats.data.total_users.gender.woman.percentage }}%
            </span>
            <span style="color: #b01065; font-size: 100%; font-weight: 500"
              >{{ this.genderStats.data.total_users.gender.woman.count }} Women</span
            >
          </div>
        </div>
        <div style="display: flex; gap: 1.5em; align-items: center">
          <svg width="1.6em" viewBox="0 0 14 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0655 4.34992C11.0655 6.74984 9.14923 8.69984 6.79076 8.69984C4.43229 8.69984 2.51597 6.74984 2.51597 4.34992C2.51597 1.95001 4.43229 0 6.79076 0C9.14923 0 11.0655 1.95001 11.0655 4.34992ZM13.5714 13.3446C13.5714 11.3346 11.9647 9.69958 9.98937 9.69958H3.58205C1.60677 9.69958 0 11.3346 0 13.3446V26.3H3.47887V38H10.083V26.3H13.5619V19.8223V13.3446H13.5714Z"
              [ngStyle]="{ fill: darkMode ? '#d9d9d9' : '#646464' }"
            />
          </svg>
          <div style="display: flex; flex-direction: column; font-size: 14px">
            <span
              [ngStyle]="{ color: darkMode ? '#d9d9d9' : '#646464' }"
              style="font-size: movanoResize(16); font-weight: 700"
              *ngIf="genderPercentageArray.length > 1"
              >{{ this.genderStats.data.total_users.gender.other.percentage }} %
            </span>

            <span [ngStyle]="{ color: darkMode ? '#d9d9d9' : '#646464' }" style="font-size: 100%; font-weight: 500"
              >{{ this.genderStats.data.total_users.gender.other.count }} Other</span
            >
          </div>
        </div>
        <div style="display: flex; gap: 1.5em; align-items: center">
          <div style="font-size: 1.6em; color: #424242; font-weight: 700">Unknown</div>
          <div style="display: flex; flex-direction: column; font-size: 14px">
            <span
              [ngStyle]="{ color: '#424242' }"
              style="font-size: movanoResize(16); font-weight: 700"
              *ngIf="genderPercentageArray.length > 1"
              >{{ this.genderStats.data.total_users.gender.unkown.percentage }} %
            </span>

            <span [ngStyle]="{ color: '#424242' }" style="font-size: 100%; font-weight: 500"
              >{{ this.genderStats.data.total_users.gender.unkown.count }} Unknown</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="movanoStats_lastChart">
      <!-- <svg viewBox="0 0 2 98" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L1 97" stroke="#0B84FF" stroke-linecap="round" stroke-dasharray="4 4"/>
      </svg> -->
      <span [ngStyle]="{ color: darkMode ? '#7797B8' : '#284767' }" style="font-weight: 400; font-size: 78%"
        >Age Range</span
      >
      <p-chart
        type="bar"
        [data]="genderDataChart"
        id="genderAgeChart"
        width="{{ innerWidth > 425 ? 'max(15vw, 250px)' : innerWidth - 50 + 'px' }}"
        height="10em"
        [options]="barGender"
       >
      </p-chart>

      <div class="movanoStats_lastChart_Axys" style="padding-top: 25px;">
        <div class="movanoStats_lastChart_Axys_X">
          <span *ngFor="let age of xAxys">
            {{ age }}
          </span>
        </div>
      </div>
    </div>
    <div class="movanoStats_Buttons">
      <button [disabled]="front" (click)="changeInfo()">
        <svg
          width="max(0.69vw, 10px)"
          height="max(0.83vw, 12px)"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
            [ngStyle]="{ fill: darkMode ? '#D1F5FF' : '#284767', opacity: front ? 0.5 : 1 }"
          />
        </svg>
      </button>

      <button [disabled]="!front" (click)="changeInfo()">
        <svg
          width="max(0.69vw, 10px)"
          height="max(0.83vw, 12px)"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
            [ngStyle]="{ fill: darkMode ? '#D1F5FF' : '#284767', opacity: !front ? 0.5 : 1 }"
          />
        </svg>
      </button>
    </div>
  </ng-template>
  <ng-template #werableStats>
    <span class="movanoStats_Tittle">Wearable stats</span>
    <div style="display: flex; flex-direction: row;">
      <div class="movanoStats_DognutStats1">
        <div echarts [options]="option_WearableStats ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
        <div class="movanoStats_DognutStats1_Highlight">
          <span
            [ngStyle]="{ color: darkMode ? '#FFFFFF' : '#061E37' }"
            style="font-size: 167%; font-weight: 700"
            >{{ this.firmwareVersionPer[0] }}</span
          >
          <div style="display: flex; gap: 0.33em">
            <span
              [ngStyle]="{ color: darkMode ? '#000A14' : '#054280' }"
              style="font-size: 100%; font-weight: 400"
              >{{ this.firmwareVersionPer[1] }}</span
            >
            <span style="color: #0b84ff; font-size: 100%; font-weight: 400" *ngIf="this.getFirmwareVersionLength() > 2">{{ this.firmwareVersionPer[2] }}</span>
          </div>
        </div>
      </div>
      <div class="movanoStats_LegendUser" style="gap: 0.4em; padding-left: 20px;  width: 130px">
        Firmware version
        <div *ngFor="let firmware of firmwareVersionName; let i = index" style="display: flex; gap: 1.1em; color: #022e5c; align-items: center">
          <div [style.background-color]="getFirmwareColor(i)" style="width: 1.5em; border-radius: 0.75em; height: 0.67em"></div>
          {{ firmware || 'Others' }}
        </div>
      </div>
    </div>
    <div class="movanoStats_Divider" [ngStyle]="{ borderColor: darkMode ? '#ffffff2b' : '#D1F5FF' }"></div>
    <div style="display: flex; flex-direction: row">
      <div class="movanoStats_DognutStats1">
        <div echarts [options]="option_AppVersion ?? option_DonutEmpty" style="height: 8em; width: 8em"></div>
        <div class="movanoStats_DognutStats1_Highlight">
          <span
            [ngStyle]="{ color: darkMode ? '#FFFFFF' : '#49004B' }"
            style="font-size: 167%; font-weight: 700"
            >{{ this.appVersionPer[0] }}</span
          >
          <div style="display: flex; gap: 0.25em">
            <span
              [ngStyle]="{ color: darkMode ? '#FF77BF' : '#66006A' }"
              style="font-size: 100%; font-weight: 400"
              >{{ this.appVersionPer[1] }}</span
            >
            <span style="color: #b01065; font-size: 100%; font-weight: 400" >{{ this.appVersionPer[2] }}</span>
          </div>
        </div>
      </div>
      <div class="movanoStats_LegendUser" style="gap: 0.5em; padding-left: 20px; width: 100px">
        App version
        <div *ngFor="let app of appVersionName; let i = index" style="display: flex; gap: 1.1em; color: #022e5c; align-items: center">
          <div [style.background-color]="getAppColor(i)" style="width: 1.5em; border-radius: 0.75em; height: 0.67em"></div>
          {{ app || 'Others' }}
        </div>
      </div>
    </div>
    <!-- <div class="movanoStats_Divider" [ngStyle]="{borderColor : (darkMode) ? '#ffffff2b' : '#D1F5FF'}">
    </div> -->
    <!-- <div style="display: flex; flex-direction: row;">
      <div class="movanoStats_CharginChart">
        <div class="movanoStats_CharginChart_Bar" [ngStyle]="{bottom : percentageChar * 4.3 + 'em'}"></div>
      </div>
      <div class="movanoStats_LegendUser" style="gap: 0.67em;">
        <span style="font-size: 130%; font-weight: 400;">Charging time</span>
        <div
          style="display: flex; flex-direction: column; color: #00B377; font-weight: 700; font-size: 180%; align-items: start;">
          <span style="font-weight: 400; font-size: 63%;">Max</span>
          {{this.getChargingTimeMax()}}
        </div>
        <div
          style="display: flex; flex-direction: column; color: #0B84FF; font-weight: 700; font-size: 180%; align-items: start;">
          <span style="font-weight: 400; font-size: 63%;">Average</span>
          {{this.getChargingTimeMin()}}
        </div>
        <div
          style="display: flex; flex-direction: column; color: #E31482; font-weight: 700; font-size: 180%; align-items: start;">
          <span style="font-weight: 400; font-size: 63%;">Min</span>
          {{this.getChargingTimeAvg()}}
        </div>
      </div>
    </div> -->
    <div class="movanoStats_Buttons">
      <button [disabled]="front" (click)="changeInfo()">
        <svg
          width="max(0.69vw, 10px)"
          height="max(0.83vw, 12px)"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
            [ngStyle]="{ fill: darkMode ? '#D1F5FF' : '#284767', opacity: front ? 0.5 : 1 }"
          />
        </svg>
      </button>

      <button [disabled]="!front" (click)="changeInfo()">
        <svg
          width="max(0.69vw, 10px)"
          height="max(0.83vw, 12px)"
          viewBox="0 0 10 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
            [ngStyle]="{ fill: darkMode ? '#D1F5FF' : '#284767', opacity: !front ? 0.5 : 1 }"
          />
        </svg>
      </button>
    </div>
  </ng-template>
</div>
