<div class="movanoSleepData">
  <div class="movanoSleepData_Header">
    <a (click)="returnToLastChart()">{{returnToLabel()}}</a>
  </div>


  <div *ngIf="loadingData; else dataLoaded" class="movanoSleepData_NoData">
    <p-progressSpinner [style]="{width: '3vw', height: '3vw', position: 'absolute', left : '38.5vw'}"
      styleClass="custom-spinner-blue" strokeWidth="2" animationDuration="1.5s">
    </p-progressSpinner>
  </div>
  <ng-template #dataLoaded>
    <div *ngIf="dataAvailable; else noData" class="movanoSleepData_Body">
      <div class="movanoSleepData_Body_Block01" [ngStyle]="{backgroundColor: (slctdDateOpt === 0)?'unset' : '#f3f7fc'}">
        <div class="movanoSleepData_Zones">
          <span class="movanoSleepData_Zones_Title">{{(slctdDateOpt === 0) ? 'Sleeping Zones'
            : 'Sleeping zones total period' }}</span>
          <div class="movanoSleepData_SleepingZones">
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                [ngStyle]="{backgroundColor: sleepCl[3], width: (totalZone.totals_percentage.Awake * 0.75)+'%'}">
                <span style="line-height: 100%;"> {{totalZone.totals_percentage.Awake | number:'1.0-0'}}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Awake</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  {{minutesToHHMM(totalZone.totals_minutes.Awake)}}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                [ngStyle]="{backgroundColor: sleepCl[2], width:  (totalZone.totals_percentage.REM * 0.75)+'%'}">
                <span style="line-height: 100%;"> {{totalZone.totals_percentage.REM | number:'1.0-0'}}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">REM</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  {{minutesToHHMM(totalZone.totals_minutes.REM)}}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                [ngStyle]="{backgroundColor: sleepCl[1], width: (totalZone.totals_percentage.Light * 0.75)+'%'}">
                <span style="line-height: 100%;"> {{totalZone.totals_percentage.Light | number:'1.0-0'}}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Light</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  {{minutesToHHMM(totalZone.totals_minutes.Light)}}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                [ngStyle]="{backgroundColor: sleepCl[0], width: (totalZone.totals_percentage.Deep * 0.75)+'%'}">
                <span style="line-height: 100%;"> {{totalZone.totals_percentage.Deep | number:'1.0-0'}}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Deep</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  {{minutesToHHMM(totalZone.totals_minutes.Deep)}}
                </span>
              </div>
            </div>

          </div>

        </div>
        <div *ngIf="!(slctdDateOpt === 0)" class="movanoSleepData_TotalAnalytics">
          <div class="movanoSleepData_Zones_SubTitle">
            <span style="font-weight: 700;">Total Sleep </span>
            <span>&nbsp;</span>
            <span style="font-weight: 400;">{{minutesToHHMM(totalSleep.totals_minutes)}}</span>
          </div>
          <div style="color: #00B377;">
            <span style="font-weight: 700;">Sleepest day </span>
            <span>&nbsp;</span>
            <span style="font-weight: 400;">{{dayFormat(totalSleep.sleepest_day)}}</span>
          </div>
          <div style="color: #B01065;">
            <span style="font-weight: 700;">Least slept day </span>
            <span>&nbsp;</span>
            <span style="font-weight: 400;">{{dayFormat(totalSleep.least_sleep_day)}}</span>
          </div>
        </div>
        <div *ngIf="slctdDateOpt === 0; else totalData" class="movanoSleepData_ZonesActivity">
          <span class="movanoSleepData_ZonesActivity_Title"> {{(slctdDateOpt === 0) ? 'Sleeping Zones Activity'
            : 'Sleeping zones times' }} </span>
          <div class="movanoSleepData_ZonesActivity_Scroll">
            <div *ngFor="let activity of acticityZonePerDay; let i = index">
              <div class="movanoSleepData_ZonesActivity_Scroll_Line"
                [ngStyle]="{backgroundColor: (i%2 != 0)?'#e7eef6' : 'unset'}" *ngIf="activity.stage != ''">
                <span
                  class="movanoSleepData_ZonesActivity_Scroll_Line_ClockRange">{{rangeHoursFormated(activity.dateStart,
                  activity.dateEnd)}}</span>
                <span class="movanoSleepData_ZonesActivity_Scroll_Line_SleepCicle"
                  [ngStyle]="{color : sleepCl[stageMapping[activity.stage]]}">
                  {{sleepLbl[stageMapping[activity.stage]]}}</span>
                <span
                  class="movanoSleepData_ZonesActivity_Scroll_Line_AmountMinutes">{{minutesToHHMM(activity.lengthInMins)}}</span>
              </div>
            </div>
          </div>
        </div>

        <ng-template #totalData>
          <div class="movanoSleepData_ZonesActivityTotal">
            <span class="movanoSleepData_ZonesActivityTotal_Title"> Sleeping Zone Activity </span>
            <div class="movanoSleepData_ZonesActivityTotal_Data">
              <!--TITLE ROW-->
              <span>Zone</span>
              <span>Average</span>
              <span>Min</span>
              <span>Max</span>
              <!--AWAKE ROW-->
              <span class=" column-Title row-Awake">Awake</span>
              <span class="row-Awake">{{minutesToHHMM(acticityZonePerRangeDays.Awake.avg)}}</span>
              <span class="row-Awake">{{minutesToHHMM(acticityZonePerRangeDays.Awake.min)}}</span>
              <span class="row-Awake">{{minutesToHHMM(acticityZonePerRangeDays.Awake.max)}}</span>
              <!--REM ROW-->
              <span class=" column-Title row-REM">REM</span>
              <span class="row-REM">{{minutesToHHMM(acticityZonePerRangeDays.REM.avg)}}</span>
              <span class="row-REM">{{minutesToHHMM(acticityZonePerRangeDays.REM.min)}}</span>
              <span class="row-REM">{{minutesToHHMM(acticityZonePerRangeDays.REM.max)}}</span>
              <!--LIGHT ROW-->
              <span class=" column-Title row-Light">Light</span>
              <span class="row-Light">{{minutesToHHMM(acticityZonePerRangeDays.Light.avg)}}</span>
              <span class="row-Light">{{minutesToHHMM(acticityZonePerRangeDays.Light.min)}}</span>
              <span class="row-Light">{{minutesToHHMM(acticityZonePerRangeDays.Light.max)}}</span>
              <!--DEEP ROW-->
              <span class=" column-Title row-Deep">Deep</span>
              <span class="row-Deep">{{minutesToHHMM(acticityZonePerRangeDays.Deep.avg)}}</span>
              <span class="row-Deep">{{minutesToHHMM(acticityZonePerRangeDays.Deep.min)}}</span>
              <span class="row-Deep">{{minutesToHHMM(acticityZonePerRangeDays.Deep.max)}}</span>

            </div>
          </div>
        </ng-template>
      </div>
      <div class="movanoSleepData_Graph">
        <div class="movanoSleepData_Graph_Chart" echarts (chartClick)="onChartEvent($event, 'chartClick')"
          [options]="options_SleepData">
        </div>
        <app-chart-time-arrows class="movanoSleepData_Graph_Chart"
          [calendarData]="calendarData"></app-chart-time-arrows>
      </div>
    </div>
    <ng-template #noData>
      <div class="movanoSleepData_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </ng-template>
  </ng-template>
</div>
