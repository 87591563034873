import { Component, HostListener } from "@angular/core";
import { EChartsOption } from "echarts";
import { MovanoService } from "src/app/movano.service";
import { SharedService } from "src/app/stats/shared.service";
import { lastValueFrom, skip } from "rxjs";
import { INewCalendarSelectorData } from "src/app/shared/movano-interfaces";
import { adjustScale } from "src/main";

@Component({
  selector: "app-sales-donuts",
  templateUrl: "./sales-donuts.component.html",
  styleUrls: ["./sales-donuts.component.scss"],
})
export class SalesDonutsComponent {
  constructor(private sharedService: SharedService, private movanoSvc: MovanoService) { }

  public innerWidth: any;
  ctx: any;

  salesData: any;

  protected option_SalesDistribution?: EChartsOption;
  protected option_SizeDistribution?: EChartsOption;
  protected option_ColorDistribution?: EChartsOption;

  protected salesDistributionName = ['Returned', 'Sold', 'Exchanged'];
  protected salesDistributionPer = [0, 0, 0];
  protected salesDistributionCount = [0, 0, 0];

  protected sizeDistributionName = ["8", "6", "7", "9", "10", "11", "5", "12"];
  protected sizeDistributionPer = [40, 20, 10, 10, 5, 5, 3, 2];
  protected sizeDistributionCount = [0, 0, 0, 0, 0, 0, 0, 0];

  protected colorDistributionName = ["Rose Gold", "Gold", "Silver"];
  protected colorDistributionPer = [60, 30, 20];
  protected colorDistributionCount = [0, 0, 0];

  //SizeBarChart
  sizeDataChart: any;
  barSize: any;

  //ColorBarChart
  colorDataChart: any;
  colorBarSize: any;

  protected apiData?: any;

  protected colorDistributionID: any;
  protected colorDistributionText: any = ["#000000", "#000000", "#000000"];


  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
  }

  ngOnInit() {
    this.getStatsFromDB();
    this.subscribeToCalendarChanges();
  }

  ngAfterViewChecked() {
    adjustScale(".responsiveScaleDonutsSales", { maxScale: 1.36, minScale: 1.1, decayFactor: 1.1 });
  }

  async getStatsFromDB(): Promise<void> {
    await this.fetchAndProcessData("lastMonth");
  }

  async fetchAndProcessData(timeFrame: string): Promise<void> {
    const response = await lastValueFrom(this.movanoSvc.getSalesDonutsData(timeFrame));
    this.apiData = response.data;
    this.processData();
    this.updateInfo();
  }

  subscribeToCalendarChanges(): void {
    this.sharedService.calendarObservable$
      .pipe(skip(1))
      .subscribe(async (data: INewCalendarSelectorData) => {
        const response = await lastValueFrom(this.movanoSvc.getSalesDonutsData(
          data.option,
          data.startDate?.toISOString().split("T")[0],
          data.endDate?.toISOString().split("T")[0])
        );

        this.apiData = response.data;
        this.processData();
        this.updateInfo();
      });
  }

  processData(): void {
    const salesData = this.apiData.stats.newSales.salesDistribution[0];

    // Procesamos los datos para obtener solo los valores de porcentaje
    this.salesDistributionPer = Object.entries(salesData)
      .filter(([key]) => key.includes("percentage")) // Filtrar las claves con "percentage"
      .map(([_, value]) => Number(value)) // Convertir los valores a números
      .sort((a, b) => b - a); // Ordenar de mayor a menor

    // Procesamos los datos para obtener solo los valores de conteo
    this.salesDistributionCount = Object.entries(salesData)
      .filter(([key]) => key.includes("count") && !key.includes("percentage")) // Filtrar las claves con "count" pero no "percentage"
      .map(([_, value]) => Number(value)) // Convertir los valores a números
      .sort((a, b) => b - a);

    this.sizeDistributionName = this.apiData.stats.size.distribution.map((item: { size_name: string }) => item.size_name);
    this.sizeDistributionPer = this.apiData.stats.size.distribution.map((item: { percentage: number }) => item.percentage);
    this.sizeDistributionCount = this.apiData.stats.size.distribution.map((item: { count: number }) => item.count);

    const colorTextMap: Record<number, string> = {
      1: "#7797b8",
      2: "#e7c400",
      3: "#f9bc95",
    };
    this.colorDistributionName = this.apiData.stats.color.distribution.map((item: { color_name: string }) => item.color_name);
    this.colorDistributionText = this.apiData.stats.color.distribution.map((item: { id: number }) => colorTextMap[item.id]);
    this.colorDistributionPer = this.apiData.stats.color.distribution.map((item: { percentage: number }) => item.percentage);
    this.colorDistributionCount = this.apiData.stats.color.distribution.map((item: { count: number }) => item.count);
  }

  async updateInfo(): Promise<void> {
    this.innerWidth = document.documentElement.clientWidth;
    this.getSizeBars();
    this.getColorBars();

    this.option_SalesDistribution = this.createPieChartOptions(
      this.salesDistributionPer,
      this.salesDistributionName,
      (index: number) => this.getSalesDistributionColor(index),
      this.salesDistributionCount
    );

    this.option_SizeDistribution = this.createPieChartOptions(
      this.sizeDistributionPer,
      this.sizeDistributionName,
      (index: number) => this.getSizeDistributionColor(index),
      this.sizeDistributionCount,
      "Size "
    );

    this.option_ColorDistribution = this.createPieChartOptions(
      this.colorDistributionPer,
      this.colorDistributionName,
      (index: number) => this.colorDistributionText[index],
      this.colorDistributionCount
    );

    this.createConicGradient();
  }

  createConicGradient(): void {
    if (!this.ctx) return;

    const gradient = this.ctx.createConicGradient(0, 100, 100);
    gradient.addColorStop(0, "rgba(37, 134, 221,0)");
    gradient.addColorStop(0.5, "rgba(37, 134, 221,0.1)");
    gradient.addColorStop(0.75, "rgba(37, 134, 221,0)");
  }

  createPieChartOptions(
    data: any[],
    names: string[] | undefined,
    getColor: (index: number) => string,
    counts?: number[],
    suffix: string = ""
  ): Record<string, any> {
    return {
      tooltip: this.createTooltipOptions(data, names, getColor, counts, suffix),
      series: [this.createPieSeriesOptions(data, getColor)],
    };
  }

  createTooltipOptions(
    data: any[],
    names: string[] | undefined,
    getColor: (index: number) => string,
    counts: number[] | undefined,
    suffix: string
  ): Record<string, any> {
    return {
      show: true,
      trigger: "item",
      confine: true,
      borderColor: "transparent",
      extraCssText: "box-shadow: none; border: none; background-color: transparent;",
      backgroundColor: "transparent",
      formatter: (params: any) => {
        if (!names || names.length <= params.dataIndex) return "";

        const color = getColor(params.dataIndex);
        const name = names[params.dataIndex];
        const count = counts ? counts[params.dataIndex] : null;

        return `
                <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                          background-color: white; padding: 10px; border-radius: 5px;
                          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
                  <div style="display: flex; flex-direction: column;  gap: max(0.3vw, 5px)">
                      <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%; color: #7797B8;">
                          ${suffix}${name}
                      </span>
                      <span style="font-size: max(0.9vw, 20px); font-weight: 700; line-height: 130.4%; color: ${color};">
                          ${count}
                      </span>
                      <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%; color: ${color};">
                         ${params.percent.toFixed(2)}%
                      </span>
                  </div>
                </div>`;
      },
    };
  }

  createPieSeriesOptions(
    data: any[],
    getColor: (index: number) => string
  ): Record<string, any> {
    return {
      name: "Access From",
      type: "pie",
      radius: ["80%", "90%"],
      color: data.map((_, index: number) => getColor(index)),
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: "50%",
      },
      emphasis: {
        scaleSize: 2,
        itemStyle: {
          shadowColor: "rgba(0,0,0,0.25)",
          shadowBlur: 5,
        },
      },
      label: {
        show: false,
        position: "center",
      },
      labelLine: {
        show: false,
      },
      data,
    };
  }


  getSalesDistributionColor(index: number) {
    const baseColors = ["#00b377", "#e31482", "#7797b8"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    return '';
  }

  getSizeDistributionColor(index: number): string {
    const baseColors = ["#001933", "#054280", "#0b84ff", "#0dccff", "#b01065", "#e31482", "#7797b8", "#c4d9ee"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    return '';
  }

  //SIZE BAR CHART
  getSizeBars() {
    const ringSizes = this.sizeDistributionName;
    const totalPeoplePerSize = this.sizeDistributionPer;
    const colors = ["#001933", "#054280", "#0B84FF", "#0DCCFF", "#B01065", "#E31482", "#7797B8", "#C4D9EE"];

    const combinedData = ringSizes.map((size, index) => ({
      ringSize: size,
      people: totalPeoplePerSize[index],
      color: colors[index],
    }));

    const sortedData = combinedData.sort((a, b) => b.people - a.people);

    const sortedRingSizes = sortedData.map((item) => item.ringSize);
    const sortedPeople = sortedData.map((item) => item.people);
    const sortedColors = colors;

    this.sizeDataChart = {
      labels: sortedRingSizes,
      datasets: [
        {
          data: sortedPeople,
          backgroundColor: sortedColors,
          borderRadius: 100,
          barThickness: 12,
        },
      ],
    };

    this.barSize = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const value = parseFloat(context.raw).toFixed(2);
              return `${value}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          ticks: {
            color: "#7797B8",
            font: {
              family: "Zen Kaku Gothic Antique",
            },
          },
          grid: {
            display: false,
          },
          border: {
            display: true,
            color: " #edf2f9",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
        },
      },
    };
  }

  getColorBars() {
    const ringColors = this.colorDistributionName;
    const totalPeoplePerSize = this.colorDistributionPer;
    const colors = this.colorDistributionText;

    const combinedData = ringColors.map((color, index) => ({
      ringSize: color,
      people: totalPeoplePerSize[index],
      color: colors[index],
    }));

    const sortedData = combinedData.sort((a, b) => b.people - a.people);

    const sortedRingColors = sortedData.map((item) => item.ringSize);
    const sortedPeople = sortedData.map((item) => item.people);
    const sortedColors = colors;

    this.colorDataChart = {
      labels: sortedRingColors,
      datasets: [
        {
          data: sortedPeople,
          backgroundColor: sortedColors,
          borderRadius: 100,
          barThickness: 12,
        },
      ],
    };

    this.colorBarSize = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const value = parseFloat(context.raw).toFixed(2);
              return `${value}`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          ticks: {
            color: "#7797B8",
            font: {
              family: "Zen Kaku Gothic Antique",
            },
          },
          grid: {
            display: false,
          },
          border: {
            display: true,
            color: " #edf2f9",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
        },
      },
    };
  }
}
