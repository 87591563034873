<app-header class="fixed-header" [title]="LITERALS.SALES.TITTLE"></app-header>
<p-sidebar class="fixed-sidebar" styleClass="menu-filter" [modal]="false" (onShow)="onResize()" position="left">
</p-sidebar>

<div class="fixed-middle-bar">
  <div class="time-selector-container">
    <app-new-time-selector #calendar_selector></app-new-time-selector>
  </div>
  <app-tab-selector [tabs]="['Sales', 'RMAs', 'VIP']" (tabSelected)="onTabSelected($event)"></app-tab-selector>
  <div class="empty-space">
  </div>
</div>

<div class="movanoUsers" [ngClass]="{ movanoDark: darkMode }" *ngIf="selectedTab === 'VIP'">
  <div [ngStyle]="{ display: !loadingSalesManagementList ? 'block' : 'none' }" class="movanoTableBox">
    <p-table id="userTable" #userTable class="movano-Table" [globalFilterFields]="[
        'date',
        'document_number',
        'replacement_transaction',
        'po/check_number',
        'launch',
        'rma_type',
        'rma_number'
      ]" [value]="salesManagement" [paginator]="true" [rows]="rows" [first]="pageSelected" [scrollable]="true"
      scrollHeight="75vh">
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th (click)="toggleSort('date')">
            Date
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"
              [ngStyle]="{ transform: sortMode === 'asc' && sortedBy === 'date' ? 'rotate(180deg)' : 'rotate(0deg)' }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'date' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('documentNumber')">
            Document Number
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform: sortMode === 'asc' && sortedBy === 'documentNumber' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'documentNumber' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('replacementTransaction')">
            Replacement Transaction
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform:
                  sortMode === 'asc' && sortedBy === 'replacementTransaction' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'replacementTransaction' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('pOCheckNumber')">
            PO/ Check Number
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform: sortMode === 'asc' && sortedBy === 'pOCheckNumber' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'pOCheckNumber' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('launchId')">
            Launch
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform: sortMode === 'asc' && sortedBy === 'launchId' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'launchId' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('rmaType')">
            RMA Type
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform: sortMode === 'asc' && sortedBy === 'rmaType' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'rmaType' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
          <th (click)="toggleSort('rmaDocumentNumber')">
            RMA Number
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" [ngStyle]="{
                transform: sortMode === 'asc' && sortedBy === 'rmaDocumentNumber' ? 'rotate(180deg)' : 'rotate(0deg)'
              }">
              <path
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                [attr.fill]="sortedBy === 'rmaDocumentNumber' ? '#05428f' : '#C4D9EE'" />
            </svg>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-sales let-i="rowIndex">
        <tr [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }" [ngClass]="'movanoTable_Obj'">
          <td>{{ sales.date | date : "MM/dd/yy" }}</td>
          <td>{{ sales.documentNumber }}</td>
          <td>{{ sales.replacementTransaction }}</td>
          <td>{{ sales.pOCheckNumber }}</td>
          <td>{{ sales.launchId }}</td>
          <td>{{ sales.rmaType }}</td>
          <td>{{ sales.rmaDocumentNumber }}</td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <div class="movanoPaginator">
          <svg (click)="previousPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.3333 5.33335L3.21996 5.33335L6.94663 1.60669L5.99996 0.666687L0.666626 6.00002L5.99996 11.3334L6.93996 10.3934L3.21996 6.66669L11.3333 6.66669V5.33335Z"
              [ngStyle]="{ fill: state.page > 0 ? '#0B84FF' : '#AABED3' }" />
          </svg>

          <button *ngIf="!isFirstPageInRange()" (click)="selectPage(1)" [ngClass]="{ 'active-page': actualPage === 1 }">
            1
          </button>

          <svg *ngIf="shouldShowLeftEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button *ngFor="let page of getDisplayedPages()" [ngClass]="{ 'active-page': page === actualPage }"
            (click)="selectPage(page)">
            {{ page }}
          </button>

          <svg *ngIf="shouldShowRightEllipsis()" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="8" rx="4" fill="#C4D9EE" />
            <circle cx="9" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="13" cy="4" r="1" fill="var(--main-blue-dark)" />
            <circle cx="5" cy="4" r="1" fill="var(--main-blue-dark)" />
          </svg>

          <button *ngIf="!isLastPageInRange()" (click)="selectPage(totalPages)"
            [ngClass]="{ 'active-page': actualPage === totalPages }">
            {{ totalPages }}
          </button>

          <svg (click)="nextPage()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.666647 6.66665L8.77998 6.66665L5.05331 10.3933L5.99998 11.3333L11.3333 5.99998L5.99998 0.666646L5.05998 1.60665L8.77998 5.33331L0.666647 5.33331L0.666647 6.66665Z"
              [ngStyle]="{ fill: state.page < state.pageCount - 1 ? '#0B84FF' : '#AABED3' }" />
          </svg>
        </div>
      </ng-template>
    </p-table>
    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>

  <div *ngIf="loadingSalesManagementList" style="position: absolute; top: 50%; left: 50%">
    <p-progressSpinner styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}" strokeWidth="2"
      animationDuration="1.5s">
    </p-progressSpinner>
  </div>
</div>
