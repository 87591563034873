import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Table } from 'primeng/table';
import { MovanoService } from 'src/app/movano.service';
import { ntfInsightAppTable } from 'src/app/shared/movano-interfaces';

@Component({
  selector: 'insight-app-filter',
  templateUrl: './insight-app-filter.component.html',
  styleUrls: ['./insight-app-filter.component.scss']
})
export class InsightAppFilterComponent {
  protected filter_Status : string = 'All Status';
  protected searchDisplay: boolean = false;
   @Input() table !: Table;
  @Output() filterApplied = new EventEmitter<number>();
  @Input() filteredUsers: ntfInsightAppTable[] = [];
  @Input() isFiltered: boolean = false;
  @Input() insightApps: ntfInsightAppTable[] = [];
  constructor(private movanoService: MovanoService, private changeDetector: ChangeDetectorRef) {}


  search(event: any, _row: string, _table: Table) {
    _table.reset();
    _table.filter(event.target.value, _row, 'contains');
    this.isFiltered = true;
  }

  applyFilter() {
    this.table.reset();
    if (!this.filter_Status || this.filter_Status === 'reset') {
      this.isFiltered = false;
      return;
    }
    this.table.filter(this.filter_Status, 'status', 'equals');
    this.isFiltered = true;
  }



toggleSearchDisplay(value: boolean): void {
  this.searchDisplay = value;
  this.changeDetector.detectChanges();
}


 exportCSV() {
  if (this.insightApps && this.insightApps.length > 0) {
    const csvData = this.convertToCSV(this.insightApps);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'insightApps.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    console.error('No data to export');
  }
}

convertToCSV(objArray: any[]): string {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  let row = '';

  for (const index in array[0]) {
    if (array[0].hasOwnProperty(index)) {
      // Now convert each value to a string
      const header = index + ',';
      row += header;
    }
  }

  row = row.slice(0, -1);
  str += row + '\r\n';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (const index in array[i]) {
      if (array[i].hasOwnProperty(index)) {
        if (line !== '') line += ',';
        line += array[i][index];
      }
    }

    str += line + '\r\n';
  }
  return str;
} 
}


