<div class="movanoStats_Header">
  <div class="responsiveScaleElement">
    <div class="inner-container">
      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Number" style="color:#284767;">{{apiData.stats.totals.distinct}}</span>
        <div class="movanoCarousel_Element_Info">
          <span>total</span>
          <span>sales</span>
        </div>
      </div>

      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Number"
          style="color:#0b84ff; font-weight: bold;">{{apiData.stats.newSales.distinct}}</span>
        <div class="movanoCarousel_Element_Info2">
          <span>new sales</span>
          <span>{{dynamicText}}</span>
        </div>

        <div class="movanoCarousel_Element_Arrows">
          <svg width="max(0.733vw, 10px)" height="max(0.7vw, 9px)" viewBox="0 0 12 9" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
              [ngStyle]="{fill : isDifferencePositive()  ? '#00FFAA' : '#C4D9EE'}" />
          </svg>
          <svg width="max(0.733vw, 12px)" height="max(0.7vw, 9px)" viewBox="0 0 12 10" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
              [ngStyle]="{fill : isDifferencePositive()  ? '#C4D9EE' : '#E22B2B'}" />
          </svg>
        </div>
        <div class="movanoCarousel_Element_LastTxt">
          <span
            class="movanoCarousel_Element_LastTxt_More">{{apiData.stats.newSales.distinctCompare.comparison.difference}}
            {{isDifferencePositive() ? 'more' : 'less'}}</span>
          <span class="movanoCarousel_Element_LastTxt_Pre">than the last</span>
        </div>

        <div class="vertical-line"></div>

        <span class="movanoCarousel_Element_Number2"
          style="color:#0b84ff">{{apiData.stats.newSales.distinctCompare.current.avg}}</span>
        <div class="movanoCarousel_Element_Info">
          <span>avg sales</span>
          <span>per day</span>
        </div>
      </div>
      <div class="movanoCarousel_Element">
        <span class="movanoCarousel_Element_Number3"
          style="color:#00b377; font-weight: 500;">{{apiData.stats.size.sizerKit.value}}</span>
        <div class="movanoCarousel_Element_Info2">
          <span>sizer kit</span>
          <span>{{dynamicText}}</span>
        </div>
        <div class="vertical-line"></div>

        <span class="movanoCarousel_Element_Number3" style="color:#00b377">
          {{ apiData.stats.size.sizerKit.percentage | number:'1.0-0' }}%
        </span>
        <div class="movanoCarousel_Element_Info2">
          <span>of total sales</span>
          <span>per {{dynamicText}}</span>
        </div>
      </div>
      <div class="movanoCarousel_Element">
        <div class="movanoCarousel_Element_Info2">
          <span style="font-size: 20px;">{{apiData.stats.color.bestColor.color}}</span>
          <span>best color</span>
        </div>

        <div class="vertical-line"></div>

        <div class="movanoCarousel_Element_Info2">
          <span style="font-size: 20px;">{{apiData.stats.size.bestSize.size}}</span>
          <span>best size</span>
        </div>
        <span style="font-size: 14px; opacity: 0.5; align-self: flex-end; margin-bottom: 13px;">{{dynamicText}}</span>

      </div>
    </div>
  </div>
</div>
