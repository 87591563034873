<ng-template pTemplate="header">
  <h3>{{ LITERALS.FILTER.TITTLE }}</h3>
</ng-template>
<div [ngClass]="{ movanoDark: darkMode }">
  <div class="movanoFilterMenu">
    <div class="movanoFilterMenu_Header">
      <span>{{ LITERALS.FILTER.TITTLE }}</span>
      <a (click)="resetFilter()">{{ LITERALS.FILTER.RESET }}</a>
      <svg (click)="closeHimself.emit()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
          fill="#C4D9EE"
        />
      </svg>
    </div>
    <div class="movanoFilterMenu_Filter">
      <span class="movanoFilterMenu_Filter_Tittle">{{ LITERALS.FILTER.NTF_LIST_APPS }}</span>
      <div class="movanoFilterMenu_Filter_Gender">
        <div class="movanoHeader_Tittle_Search scale-up-hor-left">
          <svg
            width="max(1.11vw, 16px)"
            height="max(1.11vw, 16px)"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667V9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
              fill="var(--main-blue-dark)"
            />
          </svg>
          <span class="p-input-icon-left">
            <input type="text" [(ngModel)]="inputValue" (keydown.enter)="saveInputValue()" style="padding-left: 12%;"/>
          </span>
          <svg
            width="max(0.833vw, 16px)"
            height="max(0.833vw, 16px)"
            (click)="resetFilter()"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
              fill="#C4D9EE"
            />
          </svg>
        </div>
      </div>
      <!-- <div class="movanoListSideFilter_Apps" *ngFor="let notification of notificationsToShow | keyvalue; let i = index">
        <div class="movanoListSideFilter_Apps_Header">
          <span>{{ notification.key }}</span>
          <div class="movanoListSideFilter_Apps_Header_Buttons">
            <div
              type="button"
              pButton
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="notification.value ? 'pi pi-angle-up' : 'pi pi-angle-down'"
              (click)="expandNtf(notification.key)"
            ></div>
            <svg
              (click)="removeNotification(notification.key)"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                fill="#C4D9EE"
              />
            </svg>
          </div>
        </div>
        <div *ngIf="notification.value" class="movanoListSideFilter_Apps_Body">
          <span class="movanoListSideFilter_Apps_Body_Title">Message type</span>
          <mat-checkbox
            class="movano_checkBox device_firmware_box"
            [(ngModel)]="allSelected[notification.key]"
            (click)="selectAllMsg(notification.key)"
          >
            <span>All</span>
          </mat-checkbox>
          <mat-checkbox
            *ngFor="let msg of msgTypes.get(notification.key); let i = index"
            [(ngModel)]="msg.filtered"
            (click)="filterByMsgType(notification.key, msg)"
            class="movano_checkBox device_firmware_box"
          >
            <span>{{ msg.name }}</span>
          </mat-checkbox>
        </div>
      </div> -->

      <span class="movanoFilterMenu_Filter_Tittle" style="padding-top: 20px">Sent to customer</span>
      <mat-checkbox
        class="movano_checkBox device_firmware_box"
        [(ngModel)]="checkboxYes"
        (change)="filterBySentCustomer()"
      >
        <span [ngStyle]="{ color: 'rgba(0, 178, 119, 1)' }">Yes</span>
      </mat-checkbox>
      <mat-checkbox
        class="movano_checkBox device_firmware_box"
        [(ngModel)]="checkboxNo"
        (change)="filterBySentCustomer()"
      >
        <span [ngStyle]="{ color: 'rgba(227, 20, 130, 1)' }">No</span>
      </mat-checkbox>
    </div>
  </div>
</div>
