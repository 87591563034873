import { Component, HostListener, SimpleChanges } from "@angular/core";
import { SharedService } from "src/app/stats/shared.service";
import { EChartsOption } from "echarts";
import { lastValueFrom } from "rxjs";
import { MovanoService } from "src/app/movano.service";

@Component({
  selector: "app-stats-user-activity-donuts",
  templateUrl: "./stats-user-activity-donuts.component.html",
  styleUrls: ["./stats-user-activity-donuts.component.scss"],
})
export class StatsUserActivityDonutsComponent {
  constructor(private sharedService: SharedService, private movanoSvc: MovanoService) {}

  public innerWidth: any;
  ctx: any;

  insightApplicationData: any;


  protected option_InsightApplication?: EChartsOption;
  protected option_MessageType?: EChartsOption;

  protected insightApplicationName?: any;
  protected insightApplicationPer?: any;
  protected messageTypeName?: string;
  protected messageTypePer?: any;

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  protected option_DonutEmpty: EChartsOption = {
    tooltip: {
      show: false,
      trigger: "item",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["80%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: "50%",
        },
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
    ],
  };

  ngOnInit() {
    this.innerWidth = document.documentElement.clientWidth;
    this.sharedService.calendarObservable$.subscribe((data) => {
      this.updateInfo(data);
    });
    this.initializeWithLastWeekData();
  }
  ngAfterViewInit() {
    this.updateInfo();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.updateInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateInfo();
  }

  async initializeWithLastWeekData() {
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());
    this.insightApplicationName = this.getInsightApplicationName(this.statsLastWeek);
    this.insightApplicationPer = this.getInsightApplicationPer(this.statsLastWeek);
    this.messageTypeName = this.getMessageTypeName(this.statsLastWeek);
    this.messageTypePer = this.getMessageTypePer(this.statsLastWeek);
  }

  async updateInfo(data?: any) {
    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getUsersActivityToday());
        this.insightApplicationName = this.getInsightApplicationName(this.statsToday);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsToday);
        this.messageTypeName = this.getMessageTypeName(this.statsToday);
        this.messageTypePer = this.getMessageTypePer(this.statsToday);
        break;

      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());
        this.insightApplicationName = this.getInsightApplicationName(this.statsLastWeek);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsLastWeek);
        this.messageTypeName = this.getMessageTypeName(this.statsLastWeek);
        this.messageTypePer = this.getMessageTypePer(this.statsLastWeek);
        break;

      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getUsersActivityLastMonth());
        this.insightApplicationName = this.getInsightApplicationName(this.statsLastMonth);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsLastMonth);
        this.messageTypeName = this.getMessageTypeName(this.statsLastMonth);
        this.messageTypePer = this.getMessageTypePer(this.statsLastMonth);
        break;

      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getUsersActivityLast7Days());
        this.insightApplicationName = this.getInsightApplicationName(this.statsLast7Days);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsLast7Days);
        this.messageTypeName = this.getMessageTypeName(this.statsLast7Days);
        this.messageTypePer = this.getMessageTypePer(this.statsLast7Days);
        break;

      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getUsersActivityYearToDate());
        this.insightApplicationName = this.getInsightApplicationName(this.statsYearToDate);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsYearToDate);
        this.messageTypeName = this.getMessageTypeName(this.statsYearToDate);
        this.messageTypePer = this.getMessageTypePer(this.statsYearToDate);
        break;

      case 'customRange':
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getUsersActivityCustomDay(startDate, endDate));
        this.insightApplicationName = this.getInsightApplicationName(this.statsCustomRange);
        this.insightApplicationPer = this.getInsightApplicationPer(this.statsCustomRange);
        this.messageTypeName = this.getMessageTypeName(this.statsCustomRange);
        this.messageTypePer = this.getMessageTypePer(this.statsCustomRange);
        break;

      default:
        console.error('Unknown data value:', data);
    }

    let gradient;
    if (this.ctx) {
      gradient = this.ctx.createConicGradient(0, 100, 100);
      gradient.addColorStop(0, "rgba(37, 134, 221,0)");
      gradient.addColorStop(0.5, "rgba(37, 134, 221,0.1)");
      gradient.addColorStop(0.75, "rgba(37, 134, 221,0)");
    }

    this.innerWidth = document.documentElement.clientWidth;
    this.insightApplicationData = this.getInsightApplicationDonut();

    this.option_InsightApplication = {
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        borderColor: 'transparent',
        extraCssText: 'box-shadow: none; border: none; background-color: transparent;',
        backgroundColor: 'transparent',
        formatter: (params: any) => {
          if (!this.insightApplicationName || this.insightApplicationName.length <= params.dataIndex) {
            return '';
          }

          const color = this.getInsightApplicationColor(params.dataIndex);
          const applicationName = this.insightApplicationName[params.dataIndex];

          return `
            <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                      background-color: white; padding: 10px; border-radius: 5px;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
              <div style="display: flex; flex-direction: column; color: ${color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${applicationName}
                  </span>
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                     ${params.percent.toFixed(2)}%
                  </span>
              </div>
            </div>`;
        }
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "90%"],
          color: this.insightApplicationPer.map((value: any, index: number) => this.getInsightApplicationColor(index)),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: "50%",
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: "rgba(0,0,0,0.25)",
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.insightApplicationPer,
        },
      ],
    };
    this.option_MessageType = {
      tooltip: {
        show: true,
        trigger: 'item',
        confine: true,
        borderColor: 'transparent',
        extraCssText: 'box-shadow: none; border: none; background-color: transparent;',
        backgroundColor: 'transparent',
        formatter: (params: any) => {
          if (!this.messageTypeName || this.messageTypeName.length <= params.dataIndex) {
            return '';
          }

          const color = this.getMessageTypeColor(params.dataIndex);
          const messageType = this.messageTypeName[params.dataIndex];

          return `
            <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                      background-color: white; padding: 10px; border-radius: 5px;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
              <div style="display: flex; flex-direction: column; color: ${color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${messageType}
                  </span>
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                     ${params.percent.toFixed(2)}%
                  </span>
              </div>
            </div>`;
        }
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "90%"],
          color: this.messageTypePer.map((value: any, index: number) => this.getMessageTypeColor(index)),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: "50%",
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: "rgba(0,0,0,0.25)",
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.messageTypePer,
        },
      ],
    };
  }

  getInsightApplicationName(data: any) {
    let insightApplicationArray = data.data.notifications.byEngine;

    return insightApplicationArray.map((item: { engineName: any }) => item.engineName);
  }

  getInsightApplicationPer(data: any) {
    let insightApplicationArray = data.data.notifications.byEngine;
    let insightApplicationPerArray = [];

    for (let item of insightApplicationArray) {
      insightApplicationPerArray.push({
        percentage: parseFloat(item.percentage.toFixed(1)),
      });
    }

    return insightApplicationPerArray.map((item) => item.percentage);
  }

  getMessageTypeName(data: any) {
    let messageTypeArray = data.data.notifications.byType;

    return messageTypeArray.map((item: {
      type: any; messageTypeName: any }) => item.type);
  }

  getMessageTypePer(data: any) {
    let messageTypeArray = data.data.notifications.byType;
    let messageTypePerArray = [];

    for (let item of messageTypeArray) {
      messageTypePerArray.push({
        percentage: parseFloat(item.percentage.toFixed(1)),
      });
    }

    return messageTypePerArray.map((item) => item.percentage);
  }

  getInsightApplicationDonut() {
    let insightApplicationData = {
      labels: ["A", "B", "C", "D", "E", "F"],
      datasets: [
        {
          data: [150, 50, 50, 100, 200, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: "rgba(0, 179, 119, 0)",
          backgroundColor: ["#C4D9EE", "#7797B8", "#0DCCFF", "#0B84FF"],
          hoverBackgroundColor: ["#C4D9EE", "#7797B8", "#0DCCFF", "#0B84FF"],
        },
        {},
        {},
        {},
      ],
    };
    insightApplicationData.labels = [];
    insightApplicationData.datasets[0].data = [];

    return insightApplicationData;
  }

  getMessageTypeDonut() {
    let messageTypeData = {
      labels: ["A", "B", "C", "D", "E", "F"],
      datasets: [
        {
          data: [150, 50, 50, 100, 200, 350],
          borderRadius: 1000,
          borderWidth: 0,
          spacing: 5,
          borderColor: "rgba(0, 179, 119, 0)",
          backgroundColor: ["#C4D9EE", "#7797B8", "#0DCCFF", "#0B84FF"],
          hoverBackgroundColor: ["#C4D9EE", "#7797B8", "#0DCCFF", "#0B84FF"],
        },
        {},
        {},
        {},
      ],
    };
    messageTypeData.labels = [];
    messageTypeData.datasets[0].data = [];

    return messageTypeData;
  }


  getInsightApplicationColor(index: number): string {
    const baseColors = ["#061E37", "#054280", "#0b84ff"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[2];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
      return this.adjustColorWithPhase(startColor, transitionStep * 2, "#0dccff");
    } else if (transitionStep < 0.75) {
      return this.adjustColorWithPhase("#0dccff", (transitionStep - 0.5) * 4, "#87cefa"); // Tono intermedio claro
    } else {
      return this.adjustColorWithPhase("#87cefa", (transitionStep - 0.75) * 4, "#d3d3d3"); // Gris claro
    }
  }

  getMessageTypeColor(index: number): string {
    const baseColors = ["#19518a", "#01b377", "#ff830d", "#e31482", "#0b84ff"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[baseColors.length - 1];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
      return this.adjustColorWithPhase(startColor, transitionStep * 2, "#0dccff");
    } else if (transitionStep < 0.75) {
      return this.adjustColorWithPhase("#0dccff", (transitionStep - 0.5) * 4, "#87cefa");
    } else {
      return this.adjustColorWithPhase("#87cefa", (transitionStep - 0.75) * 4, "#d3d3d3");
    }
  }



  adjustColorWithPhase(startHex: string, step: number, endHex: string): string {
    let [r1, g1, b1] = [
      parseInt(startHex.slice(1, 3), 16),
      parseInt(startHex.slice(3, 5), 16),
      parseInt(startHex.slice(5, 7), 16),
    ];
    let [r2, g2, b2] = [
      parseInt(endHex.slice(1, 3), 16),
      parseInt(endHex.slice(3, 5), 16),
      parseInt(endHex.slice(5, 7), 16),
    ];

    const r = Math.round(r1 + (r2 - r1) * step);
    const g = Math.round(g1 + (g2 - g1) * step);
    const b = Math.round(b1 + (b2 - b1) * step);

    return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }
}
