import { Component, HostListener, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EChartsOption } from 'echarts';
import * as  LITERALS from '../../../shared/movano-literals';
import { lastValueFrom } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { SleepingZonesActivity, maxMinAvg, sleepData_Day, sleepData_Hour, sleepData_HourIntervals, sleepingZonesTotal, totalSleep } from 'src/app/shared/movano-interfaces';
import { fixPixelsBy1440 } from 'src/app/shared/utils';
import { NewTimeSelectorComponent } from 'src/app/new-time-selector/new-time-selector.component';
import { MainServiceService } from 'src/app/main-service.service';

@Component({
  selector: 'app-user-panel-sleep-data',
  templateUrl: './user-panel-sleep-data.component.html',
  styleUrls: ['./user-panel-sleep-data.component.scss']
})
export class UserPanelSleepDataComponent implements OnInit {


  protected loading: boolean = false; //Flag that indicates whether data loading is in progress.


  protected loadingData: boolean = true;
  protected dataAvailable: boolean = true;

  protected sleepCl = ['#0B84FF', '#284767', '#0DCCFF', '#E31482'];
  protected sleepLbl: string[] = ['Light', 'Deep', 'REM', 'Awake'];
  protected stageMapping: { [key: string]: number } = {
    'light time': 0,
    'deep time': 1,
    'REM time': 2,
    'awake time': 3
  };

  options_SleepData: EChartsOption = {};

  private innerWidth: number = 1440;

  protected totalZone: sleepingZonesTotal;
  protected totalSleep: totalSleep;
  protected acticityZonePerRangeDays: SleepingZonesActivity;
  protected acticityZonePerDay: sleepData_HourIntervals[] = [];

  //CALENDAR VARIABLES
  protected slctdDateOpt: number = 2;
  protected lastSlctdDateOpt: number = 0;
  protected timeOptions: string[] = ['', 'week', 'month', ''];
  protected timeZones?: string[];
  protected actualTimeZone: string = '';
  protected rangeSelected: Date[];
  protected today: Date;
  protected daySelected?: Date;
  protected nextIsPosible: boolean = false; //Indicates whether it's possible to load data for the next day. Today => false

  protected markLinesData: any[] = [];
  protected areaToShow: any[] = [];

  @Input() calendarData?: [any, boolean, Date | Date[], string, any];
  @Input() daySelector !: NewTimeSelectorComponent;
  @Input() isUTC?: boolean;


  constructor(private movanoService: MovanoService, @Inject(MAT_DIALOG_DATA) protected data: { user: string }, private mainService: MainServiceService) {
    this.today = new Date(Date.now());
    this.daySelected = new Date(Date.now());
    this.rangeSelected = [new Date(Date.now()), new Date(Date.now())];
    this.totalZone = {
      totals_literal: { Awake: 0, Deep: 0, Light: 0, REM: 0 },
      totals_minutes: { Awake: 0, Deep: 0, Light: 0, REM: 0 },
      totals_percentage: { Awake: 0, Deep: 0, Light: 0, REM: 0 }
    };
    this.acticityZonePerRangeDays = {
      Awake: { max: 0, min: 0, avg: 0 },
      Deep: { max: 0, min: 0, avg: 0 },
      Light: { max: 0, min: 0, avg: 0 },
      REM: { max: 0, min: 0, avg: 0 }
    };
    this.totalSleep = {
      least_sleep_day: "",
      sleepest_day: "",
      total_by_day: [],
      total_minutes_check: 0,
      totals_literal: "",
      totals_minutes: 0
    }
  }

  ngOnInit(): void {
    this.innerWidth = document.documentElement.clientWidth;
    this.getInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['calendarData']) {
      this.getInfo(changes['calendarData'].currentValue)
    }

    if (changes['isUTC']) {
      this.getInfo()
    }
  }

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
    this.getInfo();
  }
  /**
   * Advances to the next day.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next day is tomorrow, exit the function
    if (!this.nextIsPosible) return;

    //Advances to the next day.
    this.daySelected?.setDate(this.daySelected.getDate() + 1);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  /**
   * Goes to the previous day
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    //Goes to the previous day
    this.daySelected?.setDate(this.daySelected.getDate() - 1);

    // Update the input field in the day selector component
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);

    // Call the getInfo function to update data with the new date
    this.getInfo();
  }

  async getInfo(calendarData?: [any, boolean, Date | Date[], string, any]) {
    if (calendarData) {
      this.lastSlctdDateOpt = this.slctdDateOpt;
      this.slctdDateOpt = parseInt(calendarData[0]);
      this.nextIsPosible = calendarData[1];
      (this.slctdDateOpt != 3) ? this.daySelected = calendarData[2] as Date : this.rangeSelected = calendarData[2] as Date[];
      this.actualTimeZone = calendarData[3];
    } else this.daySelected!.getTime() < this.today!.getTime();
    this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();
    this.loadingData = true;
    this.dataAvailable = true;
    try {

      let timeFrame: string | undefined;
      let dateStart: Date | undefined;
      let dateEnd: Date | undefined;
      let sleepData;


      switch (this.slctdDateOpt) {
        case 0:
          timeFrame = 'today';
          dateStart = this.daySelected;
          dateEnd = this.daySelected;

          break;
        case 1:
          timeFrame = 'lastWeek';
          break;
        case 2:
          timeFrame = 'lastMonth';
          break;
        case 3:
          dateStart = this.rangeSelected[0];
          dateEnd = this.rangeSelected[1];
          break;
        case 4:
          timeFrame = 'last7Days';
          break;
        case 5:
          timeFrame = 'actualYear';
          break;
        case 0:
        default:
          break;
      }

      if (this.slctdDateOpt == 0) {
        sleepData = await lastValueFrom(this.movanoService.getSleepDataPerHour(this.data.user, this.daySelected ?? this.today, this.isUTC));

        this.totalZone = sleepData.sleeping_zones_total;
        this.totalSleep = sleepData.total_sleep;
        this.acticityZonePerDay = sleepData.intervals;

        this.sleepDataFormat_Hour(sleepData)

      } else {
        sleepData = (this.slctdDateOpt != 3) ?
          await lastValueFrom(this.movanoService.getSleepDataPerDay(this.data.user, undefined, undefined, timeFrame, this.isUTC)) :
          await lastValueFrom(this.movanoService.getSleepDataPerDay(this.data.user,
            dateStart,
            dateEnd,
            undefined,
            this.isUTC));

        this.totalZone = sleepData.sleeping_zones_total;
        this.totalSleep = sleepData.total_sleep;
        this.acticityZonePerRangeDays = sleepData.sleeping_zones_activity;

        this.sleepDataFormat_Day(sleepData);
      }

      this.mainService.updateUserRangedTimezones(sleepData.timezonesRanked);

      this.loadingData = false;
      if (!this.timeZones) {
        this.timeZones = sleepData.timezones;
        this.actualTimeZone = this.timeZones[0];
        // Update the input field in the day selector component
        this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);
      }
      this.dataAvailable = this.totalSleep.totals_minutes > 0;
    } catch (error) {
      console.log(error);
      this.loadingData = false;
      this.dataAvailable = false;
    }
  }

  processDataPerDay(data: sleepData_Day) {
    let markLinesData: { xAxis: string }[] = [];
    const firtsMonth: number = parseInt(data.date_start.split('-')[1]);
    const lastMonth: number = parseInt(data.date_end.split('-')[1]);
    const year: string = data.date_start.split('-')[0];
    for (let index = (firtsMonth + 1); index <= lastMonth; index++) {
      let str = index.toString();
      markLinesData.push({ xAxis: `${year}-${(index < 10) ? ("0" + str) : str}-01` })
    }
  }

  /*  processDataPerHour (data: sleepData_Hour) {
     let markLineData: {xAxis: string}[] = [];
     const day = markDay.getDate().toString().padStart(2, '0');
     const month = LITERALS.MONTHS[markDay.getMonth()];
     const formattedDate = `${day} ${month}`;
     console.log(formattedDate);
   } */

  returnToLabel(): string {
    if (this.slctdDateOpt > 0) return '';
    if (this.lastSlctdDateOpt == 1) return `Back to weekly view`;
    if (this.lastSlctdDateOpt == 2) return `Back to monthly view`;
    if (this.lastSlctdDateOpt == 4) return `Back to last 7 days view`;
    if (this.lastSlctdDateOpt == 5) return `Back to yearly view`;
    if (this.lastSlctdDateOpt == 0) return '';
    return `Back to
      ${this.rangeSelected[0].getDate()} ${this.rangeSelected[0].toLocaleString('default', { month: 'short' })} -
      ${this.rangeSelected[1].getDate()} ${this.rangeSelected[1].toLocaleString('default', { month: 'short' })} view`;
  }



  returnToLastChart() {
    const savedOption = this.slctdDateOpt;
    this.slctdDateOpt = this.lastSlctdDateOpt;
    this.lastSlctdDateOpt = savedOption;
    this.getInfo();
  }


  sleepDataFormat_Hour(data: sleepData_Hour) {
    const enum sleepVl { Light = 0, Deep = 1, REM = 2, Awake = 3, }


    this.options_SleepData = {
      tooltip: {
        trigger: 'axis',
        triggerOn: "mousemove",
        className: "movanoSleepData_SleepingZones",
        axisPointer: {
          type: 'line',
        },
        formatter: (params: any) => {
          const dateFormat: string = params[0].axisValueLabel.split(' ')[0] ?? '-';
          const date = new Date(params[0].axisValueLabel);
          let intervalStart: string = '';
          let intervalEnd: string = '';
          let minutesBetween: number = 0;
          let stageIndex: number = -1;
          data.intervals.forEach((interval: any) => {
            const init = new Date(interval.dateStart);
            const end = new Date(interval.dateEnd);
            if (date.getTime() >= init.getTime() && date.getTime() <= end.getTime()) {
              minutesBetween = (end.getTime() - init.getTime()) / 60000;

              intervalStart = this.hoursFormated(interval.dateStart);
              intervalEnd = this.hoursFormated(interval.dateEnd);
              stageIndex = this.stageMapping[interval.stage];
              return;
            }
          });

          return `
          <div class="movanoSleepData_SleepingZones">
            <span class="movanoSleepData_SleepingZones_Date">${this.dayFormat(dateFormat)}</span>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">${intervalStart} - ${intervalEnd}</span>
              <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_BigTitle" style="color: ${this.sleepCl[stageIndex]}">
              ${this.sleepLbl[stageIndex]}  Zone</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  ${this.minutesToHHMM(minutesBetween)}
                </span>
              </div>
            </div>
          </div>
            `}
      },
      xAxis: {
        type: 'time',
        show: true,
        axisTick: { show: false },
        axisLine: { show: false },
        boundaryGap: ['5%', '5%'],
        ///boundaryGap: false,
        // min: -1,
        // max: data.days,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D1F5FF'
          }
        },
        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(10, this.innerWidth),
          show: true,
          hideOverlap: true,
          color: '#7797B8',
          formatter: function (value: number, index: number) {
            const markDayDate = new Date(value);
            const hours = markDayDate.getHours();
            const minutes = markDayDate.getMinutes();

            const amPm = hours >= 12 ? "pm" : "am";
            const formattedHours = hours % 12; // Convert 0 to 12 for 12-hour format

            const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
            return formattedTime;
          }
        }
      },
      yAxis: [
        {
          position: 'left', // Position the first yAxis on the left
          splitNumber: 3,
          min: 0,
          max: 3,
          splitLine: {
            show: true,
            lineStyle: {
              color: '#D1F5FF'
            },
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            inside: true,
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: fixPixelsBy1440(12, this.innerWidth),
            verticalAlign: 'bottom',
            lineHeight: fixPixelsBy1440(30, this.innerWidth),
            color: function (value: any, index) {
              const sleepCl: string[] = ['#0B84FF', '#284767', '#0DCCFF', '#E31482'];
              return sleepCl[value];
            },
            formatter: function (value: number) {
              const sleepLbl: string[] = ['Light', 'Deep', 'REM', 'Awake'];
              return sleepLbl[value];
            },
          }
        },
        {
          position: 'right', // Position the second yAxis on the right
          splitNumber: 3,
          min: 0,
          max: 3,
          splitLine: {
            show: false, // Hide split lines for the right axis
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            inside: true,
            fontWeight: 700,
            fontFamily: 'Zen Kaku Gothic Antique',
            fontSize: fixPixelsBy1440(12, this.innerWidth),
            verticalAlign: 'bottom',
            lineHeight: fixPixelsBy1440(30, this.innerWidth),
            color: function (value: any, index) {
              const sleepCl: string[] = ['#0B84FF', '#284767', '#0DCCFF', '#E31482'];
              return sleepCl[value];
            },
            formatter: function (value: number) {
              const sleepLbl: string[] = ['Light', 'Deep', 'REM', 'Awake'];
              return sleepLbl[value];
            },
          }
        }
      ],


      grid: {
        top: '5%', bottom: '8%', left: '1%', right: '1%', width: '98%', height: '90%'
      },
      visualMap: {
        show: false,
        pieces: [
          { gt: -1.0, lte: 0.5, color: this.sleepCl[sleepVl.Light] },
          { gte: 0.5, lte: 1.5, color: this.sleepCl[sleepVl.Deep] },
          { gte: 1.5, lte: 2.5, color: this.sleepCl[sleepVl.REM] },
          { gte: 2.5, lte: 3.5, color: this.sleepCl[sleepVl.Awake] }
        ],
        outOfRange: {
          color: 'black'
        }
      },
      series: []
    }

    let newSeries: any;
    let completeData: any[] = []
    data.intervals.forEach((entry: any) => {
      //const stageNumber = parseInt(entry.stage);
      const stageNumber = this.stageMapping[entry.stage];
      let fechaYHoraaww = new Date(entry.dateStart)
      const start = [fechaYHoraaww, stageNumber];
      let fechaYHora = new Date(entry.dateEnd); // Convierte la cadena a un objeto Date
      fechaYHora.setTime(fechaYHora.getTime() - 1000);
      const end = [fechaYHora, stageNumber];
      const data: any[] = [start, end];

      newSeries =
      {
        data: data,
        type: 'line',
        name: 'realSeries',
        showSymbol: false,
        lineStyle: {
          width: fixPixelsBy1440(10, this.innerWidth),
          cap: 'round',
        },
        emphasis: {
          focus: 'series',
          blurScope: 'global'
        },
        z: 99
      };
      (this.options_SleepData.series as any[]).push(newSeries);
      completeData.push(start);
      completeData.push(end);
    });
    const lastInterval: Date = completeData[completeData.length - 1][0];


    const markDay = new Date(lastInterval.getFullYear(), lastInterval.getMonth(), lastInterval.getDate());
    const day = markDay.getDate().toString().padStart(2, '0');
    const month = LITERALS.MONTHS[markDay.getMonth()];
    const formattedDate = `${day} ${month}`;
    console.log(formattedDate);

    (this.options_SleepData.series as any[]).push({
      data: completeData,
      type: 'line',
      showSymbol: false,
      emphasis: {
        focus: 'none',
        blurScope: 'coordinateSystem'
      },
      lineStyle: {
        width: fixPixelsBy1440(1, this.innerWidth),
        color: 'rgba(5, 66, 128, 0.5)',
        cap: 'round'
      },
      z: 1,

      markLine: {
        label: {

          show: true,
          fontWeight: 500,
          position: 'insideEndBottom',
          fontSize: fixPixelsBy1440(70, this.innerWidth),
          color: '#2f3943',
          opacity: 0.15,
          formatter: '{b}'
        },
        lineStyle: {
          type: 'solid',
          color: '#2ad2ff',
          width: fixPixelsBy1440(1, this.innerWidth)
        },
        symbol: 'none',
        emphasis: {
          label: {
            show: false
          }
        },
        data: [{ xAxis: markDay.toISOString().split('T')[0], name: formattedDate }]
        //this returns the value I need but it's not showing

      },
    });

  }

  minutesToHHMM(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const leftMinutes = Math.floor(minutes % 60);

    if (hours > 0) return `${hours}h ${leftMinutes}min`;
    if (minutes > 1) return `${leftMinutes}min`;
    if (minutes > 0) return '< 1min';
    return '0 min';
  }

  rangeHoursFormated(initDate: string, endDate: string): string {
    return `${this.hoursFormated(initDate)} - ${this.hoursFormated(endDate)}`;
  }

  hoursFormated(_date: string): string {
    const markDayDate = new Date(_date);
    const hours = markDayDate.getHours();
    const minutes = markDayDate.getMinutes();

    const amPm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12; // Convert 0 to 12 for 12-hour format

    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amPm}`;
  }

  putUTCclass() {
    const utc_option = document.querySelector('.UtcSelectors');
    const utc_list = utc_option?.parentElement;
    utc_list?.classList.add('UtcList');

  }

  dayFormat(date: string): string {
    const dateParts = date.split(/[-T]/);
    const withYear = dateParts.length <= 3;
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months start at 0 (January is 0)
    const day = parseInt(dateParts[2]);
    const dateObj = new Date(year, month, day);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    // Get the day of the week number (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeekNumber = dateObj.getDay();
    // Get the name of the day of the week
    const dayOfWeekName = daysOfWeek[dayOfWeekNumber];
    const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthNumber = dateObj.getMonth();
    const shortMonthName = shortMonthNames[monthNumber];

    if (withYear) return `${dayOfWeekName}, ${day} ${shortMonthName} ‘${year % 100}`;
    return `${day} ${shortMonthName}`;

  }


  getDayOfWeek(date: string): string {
    // Convert the date in "YYYY/MM/DD" format to a Date object
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Months start at 0 (January is 0)
    const day = parseInt(dateParts[2]);
    const dateObj = new Date(year, month, day);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    // Get the day of the week number (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeekNumber = dateObj.getDay();

    // Get the name of the day of the week
    const dayOfWeekName = daysOfWeek[dayOfWeekNumber];

    return `${dayOfWeekName} ${day}`;
  }


  sleepDataFormat_Day(data: sleepData_Day) {

    console.log(data)

    const enum sleepVl {
      Light = 0,
      Deep = 1,
      REM = 2,
      Awake = 3,
    }

    //REMEMBER TO ADJUST MARKLINE. xAxis and position help. But make sure to check ALL dates, as we need dates in year to date and ranges to be vertical

    let markLinesData: { xAxis: string, name: string }[] = [];
    const firstMonth: number = parseInt(data.date_start.split('-')[1]);
    const lastMonth: number = parseInt(data.date_end.split('-')[1]);
    const year: string = data.date_start.split('-')[0];
    const startDate = new Date(data.date_start);
    const endDate = new Date(data.date_end);
    const diffInDays = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
    console.log(diffInDays);



    if (this.slctdDateOpt === 1 || this.slctdDateOpt === 4) {
      // Calculate the day range for the mark line label
      const startDay = startDate.getDate();
      const endDay = endDate.getDate();
      const monthName = LITERALS.MONTHS[firstMonth - 1];  // Assuming `LITERALS.MONTHS` is an array of month names

      markLinesData.push({
        xAxis: data.date_start,
        name: `${startDay}-${endDay} ${monthName}`,

      });
    } else if (this.slctdDateOpt === 2) {
      // Display only the month name
      const monthName = LITERALS.MONTHS[firstMonth - 1];
      markLinesData.push({
        xAxis: `${year}-${(firstMonth < 10) ? ("0" + firstMonth) : firstMonth}-01`,
        name: monthName
      });
    } else if (this.slctdDateOpt === 3 || this.slctdDateOpt === 5) {
      // Original logic for custom range or year-to-date
      for (let index = (firstMonth + 1); index <= lastMonth; index++) {
        let str = index.toString();
        markLinesData.push({
          xAxis: `${year}-${(index < 10) ? ("0" + str) : str}-01`,
          name: LITERALS.MONTHS[index - 1],
        });
        console.log(markLinesData);
      }
    }


    const weekNames: boolean = (this.slctdDateOpt == 1)
      || (this.slctdDateOpt == 4) || (this.slctdDateOpt == 3 && this.rangeSelected[1].getTime() - this.rangeSelected[0].getTime() < 600000000);
    //console.log(this.rangeSelected[1].getTime() - this.rangeSelected[0].getTime());

    this.options_SleepData = {
      tooltip: {
        trigger: 'axis',
        className: "movanoSleepData_SleepingZones",
        formatter: (params: any) => {
          if (params[6].data === "-") return '';
          const formattedDate = this.dayFormat(params[0].axisValue);

          const max: number = parseInt(params[6].data) + parseInt(params[4].data) + parseInt(params[2].data) + parseInt(params[0].data);
          //console.log(max);
          function minutesToHMin(minutes: number) {
            const hours = Math.floor(minutes / 60);
            const leftMinutes = minutes % 60;
            return `${hours}h ${leftMinutes}min`;
          }
          function calculatePercentage(value: number, max: number): number {
            return max === 0 ? 0 : Math.round((value / max) * 100);
          }

          const currentIndex = params[0].dataIndex;
          const currentTimezone = this.timeZones ? this.timeZones[currentIndex] as unknown as { label: string, offset: string } : {} as { label: string, offset: string };

          return `
          <div class="movanoSleepData_SleepingZones">
            <span class="movanoSleepData_SleepingZones_Date">${formattedDate}</span>
            <p class="timezone-text">${currentTimezone.label} ${currentTimezone.offset}</p>

            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                style="background-color: ${this.sleepCl[sleepVl.Awake]}; width: ${Math.round((params[6].data / max) * 200)}px;">
                <span style="line-height: 100%;">${calculatePercentage(params[6].data, max)}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Awake</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  ${minutesToHMin(params[6].data)}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                style="width:${Math.round((params[4].data / max) * 200)}px; background-color: ${this.sleepCl[sleepVl.REM]}">
                <span style="line-height: 100%;">${calculatePercentage(params[4].data, max)}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">REM</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  ${minutesToHMin(params[4].data)}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                style="width:${Math.round((params[2].data / max) * 200)}px; background-color: ${this.sleepCl[sleepVl.Deep]}">
                <span style="line-height: 100%;">${calculatePercentage(params[2].data, max)}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Deep</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  ${minutesToHMin(params[2].data)}
                </span>
              </div>
            </div>
            <div class="movanoSleepData_SleepingZones_Cicle">
              <div class="movanoSleepData_SleepingZones_Cicle_Bar"
                style="width:${Math.round((params[0].data / max) * 200)}px; background-color: ${this.sleepCl[sleepVl.Light]}">
                <span style="line-height: 100%;">${calculatePercentage(params[0].data, max)}%</span>
              </div>
              <div class="movanoSleepData_SleepingZones_Cicle_TextBlock">
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Title">Light</span>
                <span class="movanoSleepData_SleepingZones_Cicle_TextBlock_Text">
                  ${minutesToHMin(params[0].data)}
                </span>
              </div>
            </div>
          </div>
            `},
        hideDelay: 300,
        //position: 'right',
        axisPointer: {
          type: 'none',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      animation: false,
      xAxis: {
        type: 'category',
        show: true,
        data: data.datesArray,
        axisTick: {
          show: false,
          alignWithLabel: true
        },
        axisLine: {
          show: false
        },
        boundaryGap: true,
        min: -1,
        max: data.days,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#D1F5FF'
          }
        },
        axisLabel: {
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(0.7vw, 10px)',
          show: true,
          hideOverlap: true,
          interval: 0,
          color: '#7797B8',
          formatter: weekNames ? (value: string, index: number) => {
            if (!value || value == '') return '';
            return this.getDayOfWeek(value);
          } : (value: string, index: number) => {
            if (!value || value == '') return '';
            const dateParts = value.split("-");
            return dateParts[2];
          }
        }
      },
      yAxis: [{

        splitLine: {
          show: true,
          lineStyle: {
            color: '#D1F5FF'
          },
        },
        //maxInterval: 1,
        axisLine: {
          show: false,

        },
        axisLabel: {
          inside: true,
          fontWeight: 700,
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: fixPixelsBy1440(12, this.innerWidth),
          verticalAlign: 'bottom',
          lineHeight: fixPixelsBy1440(30, this.innerWidth),
          color: '#305b97',
          formatter: function (value: number, index: number) {
            return Math.round(value / 60) + 'h';
          },
          interval: function (index: number, value: any) {
            return value % 60 === 0;
          },
        }
      }],
      grid: {
        top: '5%',
        bottom: '8%',
        left: '0%',
        right: '0%',
        width: '100%',
        height: '90%'
      },
      series: [
        {
          data: data.Light,
          color: this.sleepCl[sleepVl.Light],
          emphasis: {
            disabled: true,

          },
          type: 'bar',
          stack: 'Bars',
          barWidth: fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth),
          z: 5
        },
        {
          data: data.Light,
          stack: 'Circles',
          symbol: 'circle',
          symbolSize: (value: number, params: Object) => {
            if (value <= 0) return 0;
            return fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth);
          },
          color: this.sleepCl[sleepVl.Light],
          type: 'line',
          lineStyle: {
            width: 0
          },
          emphasis: {
            scale: false,
            disabled: true,

          },
          itemStyle: {
            borderWidth: 0,
            color: this.sleepCl[sleepVl.Light]
          },
          z: 6
        },
        {
          data: data.Deep,
          color: this.sleepCl[sleepVl.Deep],
          type: 'bar',
          stack: 'Bars',
          emphasis: {
            disabled: true,

          },
          barWidth: fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth),
          z: 4,
          markLine: {
            silent: true,
            precision: 1,

            label: {
              show: true,
              fontWeight: 500,
              position: 'insideEndBottom',
              fontSize: fixPixelsBy1440(55, this.innerWidth),
              color: '#2f3943',
              opacity: 0.15,
              formatter: '{b}',

            },
            lineStyle: {
              type: 'solid',
              color: '#2ad2ff',
              width: (diffInDays < 31) ? fixPixelsBy1440(0, this.innerWidth) : fixPixelsBy1440(2, this.innerWidth),
            },
            symbol: 'none',
            emphasis: {
              label: {
                show: false
              }
            },
            data: markLinesData,
          }
        },
        {
          data: data.Deep,
          stack: 'Circles',
          symbol: 'circle',
          symbolSize: (value: number, params: Object) => {
            if (value <= 0) return 0;
            return fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth);
          },
          color: this.sleepCl[sleepVl.Deep],
          type: 'line',
          lineStyle: {
            width: 0
          },
          emphasis: {
            scale: false,
          },
          itemStyle: {
            borderWidth: 0,
            color: this.sleepCl[sleepVl.Deep]
          },
          z: 3,

        },
        {
          data: data.REM,
          emphasis: {
            disabled: true,

          },
          color: this.sleepCl[sleepVl.REM],
          type: 'bar',
          stack: 'Bars',
          barWidth: fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth),
          z: 1
        },
        {
          data: data.REM,
          stack: 'Circles',
          symbol: 'circle',
          symbolSize: (value: number, params: Object) => {
            if (value <= 0) return 0;
            return fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth);
          },
          color: this.sleepCl[sleepVl.REM],
          type: 'line',
          lineStyle: {
            width: 0
          },
          itemStyle: {
            borderWidth: 0,
            color: this.sleepCl[sleepVl.REM]
          },
          emphasis: {
            scale: false,
            disabled: true,

          },
          z: 2
        },
        {
          data: data.Awake,
          emphasis: {
            disabled: true,

          },
          color: this.sleepCl[sleepVl.Awake],
          type: 'bar',
          stack: 'Bars',
          itemStyle: {
            borderRadius: [fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth), fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth), 0, 0],
          },
          barWidth: fixPixelsBy1440(Math.min(480 / data.days, 16), this.innerWidth),
          z: 0
        },
      ]
    }
  }

  /**
* Selects a specific day and updates relevant properties and data.
* @param _index - The index of the selected day in the data array.
*/
  selectDay(_date: string) {
    this.lastSlctdDateOpt = this.slctdDateOpt
    this.slctdDateOpt = 0;
    const dateParts: string[] = _date.split('-');
    // // Get the date of the selected day from the data
    const selectedDay: Date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
    // // Convert the selected day to a Date object
    this.daySelected = new Date(selectedDay);
    // Call the getInfo function to update information with the selected day
    this.daySelector.updateDaySelected(this.daySelected!, this.timeZones!, this.slctdDateOpt);
    this.getInfo();
  }

  /**
   * Handles a chart event and triggers the selection of a specific day.
   * @param event - The chart event object containing relevant data.
   * @param type - The type of event being handled.
   */
  onChartEvent(event: any, type: string) {
    // Call the selectDay function with the dataIndex from the event
    console.log(event);
    this.selectDay(event.name);
  }


}
