<div class="movanoChart {{darkMode ? chartTypes[chartNow]+'_Dark' : chartTypes[chartNow]}}"
  [ngClass]="{'movanoChart_Dark' : darkMode}">
  <div class="movanoChart_Header">
    <span class="movanoChart_Header_Tittle">{{chartTypes[chartNow]}}</span>
    <span class="movanoChart_Header_Day">‘23</span>
    <div class="movanoChart_Header_Buttons">
      <button (click)="passToMap(false)">
        <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.33334 10.3166L9.33334 1.68329C9.33334 1.02495 8.60834 0.624954 8.05 0.983288L1.26667 5.29995C0.750001 5.62495 0.750001 6.37495 1.26667 6.70829L8.05 11.0166C8.60834 11.375 9.33334 10.975 9.33334 10.3166Z"
            [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1}" />
        </svg>
      </button>

      <button (click)="passToMap(true)">
        <svg viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.666664 1.68332V10.3167C0.666664 10.975 1.39166 11.375 1.95 11.0167L8.73333 6.69999C9.25 6.37499 9.25 5.62499 8.73333 5.29165L1.95 0.98332C1.39166 0.624986 0.666664 1.02499 0.666664 1.68332Z"
            [ngStyle]="{fill : (darkMode) ? '#D1F5FF' : '#284767', opacity: 1 }" />
        </svg>
      </button>
    </div>
    <div style="width: 100%; display: flex; justify-content: center;">
      <div class="movanoChart_Header_Legend">
        <!--SALES-->
        <div *ngIf="chartTypes[chartNow] == 'Sales'" class="movanoChart_Header_Legend_Elements">
          <img style="width: max(0.83333vw, 12px);" src="../../assets/directSales_Chart.svg" alt="">
          <span style="color: #0B84FF;"> New Devices linked </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Sales'" class="movanoChart_Header_Legend_Elements">
          <svg width="max(0.83333vw, 12px)" height="max(0.83333vw, 12px)" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.30006 5.88667C7.30006 6.57696 6.72704 7.15273 6.00004 7.15273C5.27303 7.15273 4.70001 6.57696 4.70001 5.88667C4.70001 5.19638 5.27303 4.62061 6.00004 4.62061C6.72704 4.62061 7.30006 5.19638 7.30006 5.88667Z"
              [ngStyle]="{stroke: darkMode ? '#FFFFFF' : '#024889'}" />
            <ellipse cx="10.2" cy="1.76606" rx="1.80003" ry="1.76606"
              [ngStyle]="{fill: darkMode ? '#FFFFFF' : '#054280'}" />
            <ellipse cx="10.1998" cy="10.0075" rx="1.80003" ry="1.76606"
              [ngStyle]="{fill: darkMode ? '#FFFFFF' : '#054280'}" />
            <ellipse cx="1.80003" cy="1.76606" rx="1.80003" ry="1.76606"
              [ngStyle]="{fill: darkMode ? '#FFFFFF' : '#054280'}" />
            <ellipse cx="1.80003" cy="10.0075" rx="1.80003" ry="1.76606"
              [ngStyle]="{fill: darkMode ? '#FFFFFF' : '#054280'}" />
          </svg>
          <span [ngStyle]="{color: darkMode ? '#FFFFFF' : '#054280'}"> Parnet sales </span>
        </div>
        <!------------------------------------------------------------------------------------->


        <!--ACTIVITY-->
        <div *ngIf="chartTypes[chartNow] == 'Activity'" class="movanoChart_Header_Legend_Elements Activity">
          <img style="width: max(1.111vw, 16px);" src="../../assets/syncs_Chart.svg">
          <span style="color: #00B277;"> Syncs </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Activity'" class="movanoChart_Header_Legend_Elements Activity">
          <svg width="max(0.4861vw, 10px)" height="max(0.69vw, 10px)" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.00501 5.00002C4.00501 3.21502 5.10501 1.69002 6.66001 1.06502C7.10501 0.885015 7.03501 0.220015 6.56501 0.115015C6.01501 -0.0049846 5.43001 -0.0349847 4.82501 0.0450153C2.57001 0.345015 0.765013 2.20002 0.530013 4.46002C0.220013 7.46502 2.56501 10 5.50501 10C5.87001 10 6.22001 9.96002 6.56501 9.88502C7.04001 9.78002 7.11501 9.12002 6.66501 8.93502C5.05501 8.29002 4.00001 6.73002 4.00501 5.00002Z"
              [ngStyle]="{fill: darkMode ? '#FFFFFF' : '#012E1E'}" />
          </svg>
          <span [ngStyle]="{color: darkMode ? '#FFFFFF' : '#061E37'}"> Sleep analysis </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Activity'" class="movanoChart_Header_Legend_Elements Activity">
          <svg width="max(1.111vw, 16px)" height="max(0.625vw, 9px)" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="16" y="9" width="16" height="9" rx="4.5" transform="rotate(-180 16 9)"
              [ngStyle]="{fill : darkMode ? 'rgba(187, 255, 232, 0.5)' : '#92E0C6'}" />
            <rect x="15" y="8" width="7" height="7" rx="3.5" transform="rotate(-180 15 8)" fill="white" />
          </svg>
          <span [ngStyle]="{color : darkMode ? 'rgba(187, 255, 232, 0.5)' : '#92E0C6'}"> Inactive </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Activity'" class="movanoChart_Header_Legend_Elements Activity">
          <svg width="max(1.111vw, 16px)" height="max(0.625vw, 9px)" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="9" rx="4.5" [ngStyle]="{fill : darkMode ? '#71FFBB' : '#00B377'}"
              fill-opacity="0.75" />
            <rect x="1" y="1" width="7" height="7" rx="3.5" fill="white" />
          </svg>
          <span [ngStyle]="{color: darkMode ? '#71FFBB' : 'rgba(0, 179, 119, 0.75)'}"> Active </span>
        </div>
        <!------------------------------------------------------------------------------------->


        <!--INCIDENTS-->
        <div *ngIf="chartTypes[chartNow] == 'Incidents'" class="movanoChart_Header_Legend_Elements">
          <svg width="max(0.7vw, 10px)" height="max(0.7vw, 10px)" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.235 9.5H8.765C9.535 9.5 10.015 8.665 9.63 8L5.865 1.495C5.48 0.829996 4.52 0.829996 4.135 1.495L0.37 8C-0.0150004 8.665 0.464999 9.5 1.235 9.5ZM5 6C4.725 6 4.5 5.775 4.5 5.5V4.5C4.5 4.225 4.725 4 5 4C5.275 4 5.5 4.225 5.5 4.5V5.5C5.5 5.775 5.275 6 5 6ZM5.5 8H4.5V7H5.5V8Z"
              [ngStyle]="{fill: darkMode ? '#FEFDFE' : '#66006A'}" />
          </svg>
          <span [ngStyle]="{color: darkMode ? '#FEFDFE' : '#66006A'}"> Incidents </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Incidents'" class="movanoChart_Header_Legend_Elements">
          <svg width="max(0.625vw, 9px)" height="max(0.625vw, 9px)" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.05922 4.94062C1.03994 4.8 1.03031 4.65469 1.03031 4.5C1.03031 4.35 1.03995 4.2 1.06404 4.05937L0.0857792 3.31875C-0.000963211 3.25312 -0.0250587 3.12656 0.0279503 3.03281L0.953202 1.47656C1.01103 1.37344 1.13151 1.34062 1.23752 1.37344L2.38927 1.82344C2.63022 1.64531 2.88563 1.49531 3.16995 1.38281L3.34344 0.192187C3.36271 0.0796874 3.45909 0 3.57475 0H5.42525C5.54091 0 5.63247 0.0796874 5.65175 0.192187L5.82523 1.38281C6.10955 1.49531 6.36978 1.65 6.60591 1.82344L7.75766 1.37344C7.86368 1.33594 7.98415 1.37344 8.04198 1.47656L8.96241 3.03281C9.02024 3.13125 9.00096 3.25312 8.90458 3.31875L7.92632 4.05937C7.95042 4.2 7.96969 4.35469 7.96969 4.5C7.96969 4.64531 7.96006 4.8 7.93596 4.94062L8.91422 5.68125C9.00096 5.74687 9.02506 5.87344 8.97205 5.96719L8.0468 7.52344C7.98897 7.62656 7.86849 7.65937 7.76248 7.62656L6.61073 7.17656C6.36978 7.35469 6.11437 7.50469 5.83005 7.61719L5.65656 8.80781C5.63247 8.92031 5.54091 9 5.42525 9H3.57475C3.45909 9 3.36271 8.92031 3.34825 8.80781L3.17477 7.61719C2.89045 7.50469 2.63022 7.35469 2.39409 7.17656L1.24234 7.62656C1.13632 7.66406 1.01585 7.62656 0.958021 7.52344L0.0327692 5.96719C-0.0250587 5.86406 -0.000963211 5.74687 0.0905981 5.68125L1.05922 4.94062ZM4.5 6.1875C5.45417 6.1875 6.23485 5.42812 6.23485 4.5C6.23485 3.57187 5.45417 2.8125 4.5 2.8125C3.54583 2.8125 2.76515 3.57187 2.76515 4.5C2.76515 5.42812 3.54583 6.1875 4.5 6.1875Z"
              [ngStyle]="{fill: darkMode ? '#FF60B4' : '#AF0F65'}" />
          </svg>
          <span [ngStyle]="{color: darkMode ? '#FF60B4' : '#AF0F65'}"> Under repair </span>
        </div>
        <div *ngIf="chartTypes[chartNow] == 'Incidents'" class="movanoChart_Header_Legend_Elements">
          <svg width="max(0.555vw, 8px)" height="max(0.555vw, 8px)" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.11111 0H0.888889C0.395556 0 0 0.4 0 0.888889V7.11111C0 7.6 0.395556 8 0.888889 8H7.11111C7.60444 8 8 7.6 8 7.11111V0.888889C8 0.4 7.60444 0 7.11111 0ZM3.11111 6.22222L0.888889 4L1.51556 3.37333L3.11111 4.96444L6.48444 1.59111L7.11111 2.22222L3.11111 6.22222Z"
              fill="#00B377" />
          </svg>
          <span style="color: #00B377;"> Reparied </span>
        </div>
        <!------------------------------------------------------------------------------------->
 </div>
    </div>
    <div class="movanoChart_Header_Months" *ngIf="this.selectedDate == 1 ">
      <span>febraury</span>
      <span>march</span>
    </div>
    <div class="movanoChart_Header_3Months" *ngIf="this.selectedDate == 3  || this.selectedDate== 6">
      <span>December</span>
      <span>January</span>
      <span>febraury</span>
      <span>march</span>
    </div>
    <div class="movanoChart_Header_Divider" layout="vertical" style="left: 50%;"></div>
    <div class="movanoChart_Header_Divider" layout="vertical" style="left: 96%;"></div>
    <div class="movanoChart_Header_Average {{chartTypes[chartNow]+'_Average'}}"
      [ngStyle]="{bottom: 'calc(' + (avgValueChart[chartNow] / maxValueChart[chartNow]) + ' * 28.82vw)'}"
      layout="vertical">
      <div class="movanoChart_Header_Average_Box {{chartTypes[chartNow]+'_Box'}}">
        <span style="font-weight: 500;  color: var(--main-denim-light);">Average</span>
        <span style="font-weight: 700;">{{avgValueChart[chartNow] | number: '1.0-0'}}</span>
      </div>
      <svg viewBox="0 0 1050 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line y1="0.867188" x2="1050" y2="0.867188" stroke="#00B377" stroke-dasharray="4 4" />
      </svg>
    </div>
    <div class="movanoChart_Header_YAxys">
      <div class="movanoChart_Header_YAxys_Mark">
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
        <span>{{(maxValueChart[chartNow]) * 0.8 | number: '1.0-0'}}</span>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
        <span>{{(maxValueChart[chartNow]) * 0.6 | number: '1.0-0'}}</span>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
        <span>{{(maxValueChart[chartNow]) * 0.4 | number: '1.0-0'}}</span>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
        <span>{{(maxValueChart[chartNow]) * 0.2 | number: '1.0-0'}}</span>
      </div>
    </div>
    <div class="movanoChart_Header_YAxys">
      <div class="movanoChart_Header_YAxys_Mark">
        <span>{{(maxValueChart[chartNow]) * 0.8 | number: '1.0-0'}}</span>
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <span>{{(maxValueChart[chartNow]) * 0.6 | number: '1.0-0'}}</span>
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <span>{{(maxValueChart[chartNow]) * 0.4 | number: '1.0-0'}}</span>
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
      </div>
      <div class="movanoChart_Header_YAxys_Mark">
        <span>{{(maxValueChart[chartNow]) * 0.2 | number: '1.0-0'}}</span>
        <svg   viewBox="0 0 6 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="0.0390625" y1="0.5" x2="5.23708" y2="0.5" [ngStyle]="{stroke : (darkMode) ? '#FFFFFF' : '#0144A8'}"
            stroke-opacity="0.2" />
        </svg>
      </div>
    </div>
  </div>
  <!-- <p-chart *ngIf="innerWidth > 426" type="line" [data]="chartData" id="myChart"
    width="{{innerWidth > 1024 ? 'calc(100vw - max(25vw, 360px))' : (innerWidth - 16) + 'px'}}"
    height="max(28.958vw,417px)"
    [options]="basicOptions" [plugins]="[pluginLabel]">
  </p-chart>
  <p-chart *ngIf="innerWidth <= 426" type="line" [data]="chartData" id="myChart"
    width="{{(innerWidth - 10) + 'px'}}"
    height="369px"
    [options]="basicOptions" [plugins]="[pluginLabel]">
  </p-chart> -->

</div>
<div echarts [options]="option_users??{}" style="height: auto; width: auto;"></div>
