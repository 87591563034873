<app-header class="fixed-header" [table]="insightJobTable" [title]="LITERALS.NTF_INSIGHT_JOB.TITTLE"></app-header>
<div class="insight-job" [ngClass]="{ movanoDark: darkMode }">
  <div [ngStyle]="{ display: !loadingData ? 'block' : 'none' }" class="movanoTableBox">
    <insight-job-executions-filter
      [table]="insightJobTable"
      [name]="name"
      (insightJobsChanged)="onInsightJobsChanged($event)"
      (loadingDataChange)="onLoadingDataChange($event)"
      (dataAvailableChange)="onDataAvailable($event)"
      (getInfo)="handleGetInfo($event)"
      [isFiltered]="isFiltered"
      [filteredUsers]="filteredUsers"
    ></insight-job-executions-filter>
    <p-table
      id="insightJobTable"
      #insightJobTable
      dataKey="name"
      class="movano-Table"
      [globalFilterFields]="[
        'insight_name',
        'lambda_trigger',
        'runs_succeed',
        'runs_error',
        'schedule',
        'last_run ',
        'next_run',
        'status_last_job'
      ]"
      [value]="insightJobs"
      [paginator]="true"
      [rows]="rows"
      [first]="pageSelected"
      (onFilter)="onFilter($event)"
    >
      <ng-template pTemplate="header">
        <tr [ngClass]="'movanoTable_Header'">
          <th pSortableColumn="job_name" (click)="sort('job_name')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_EXECUTION }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="job_name-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="runs" (click)="sort('runs')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_RUNS }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="runs-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="schedule" (click)="sort('schedule')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_SCHEDULE }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="schedule-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="last_run" (click)="sort('last_run')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_LAST_RUN }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="last_run-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="next_run" (click)="sort('next_run')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_NEXT_RUN }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="next_run-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th pSortableColumn="status_last_task" (click)="sort('status_last_task')">
            {{ LITERALS.NTF_INSIGHT_JOB.TABLE_STATUS }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                id="status_last_task-icon"
                d="M5.25 -2.29485e-07L5.25 9.1275L1.0575 4.935L-2.62268e-07 6L6 12L12 6L10.9425 4.9425L6.75 9.1275L6.75 -2.95052e-07L5.25 -2.29485e-07Z"
                fill="#C4D9EE"
              />
            </svg>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-notification let-i="rowIndex" let-expanded="expanded">
        <tr
          [ngStyle]="{
            background: darkMode ? (i % 2 == 0 ? '#05294d' : '#05305B') : i % 2 == 0 ? '#f3f6f9' : 'white'
          }"
          [ngClass]="'movanoTable_Obj'"
        >
          <td class="insight-job_DAG">
            <span>{{ notification.insight_name }}</span>
            <span>{{ notification.lambda_trigger }}</span>
          </td>
          <td>
            <div class="insight-job_status">
              <div class="insight-job_run insight-job_run_running">
                <svg
                  style="margin-bottom: 2px"
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.41876 0.632812H4.66876V1.88281H8.41876V0.632812ZM5.91876 8.75781H7.16876V5.00781H5.91876V8.75781ZM10.9375 4.62031L11.825 3.73281C11.5563 3.41406 11.2625 3.11406 10.9438 2.85156L10.0563 3.73906C9.08751 2.96406 7.86876 2.50156 6.54376 2.50156C3.43751 2.50156 0.918762 5.02031 0.918762 8.12656C0.918762 11.2328 3.43126 13.7516 6.54376 13.7516C9.65626 13.7516 12.1688 11.2328 12.1688 8.12656C12.1688 6.80781 11.7063 5.58906 10.9375 4.62031ZM6.54376 12.5078C4.12501 12.5078 2.16876 10.5516 2.16876 8.13281C2.16876 5.71406 4.12501 3.75781 6.54376 3.75781C8.96251 3.75781 10.9188 5.71406 10.9188 8.13281C10.9188 10.5516 8.96251 12.5078 6.54376 12.5078Z"
                    fill="#0B84FF"
                  />
                </svg>
                {{ notification.runs_running }}
              </div>
              <div class="insight-job_run insight-job_run_succeed">
                <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3.49574 7.10067L0.888004 4.30201L0 5.24832L3.49574 9L11 0.946309L10.1182 0L3.49574 7.10067Z"
                    fill="#00B277"
                  />
                </svg>
                {{ notification.runs_succeed }}
              </div>
              <div class="insight-job_run insight-job_run_error">
                <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9 0.906428L8.09357 0L4.5 3.59357L0.906428 0L0 0.906428L3.59357 4.5L0 8.09357L0.906428 9L4.5 5.40643L8.09357 9L9 8.09357L5.40643 4.5L9 0.906428Z"
                    fill="#E31482"
                  />
                </svg>
                {{ notification.runs_error }}
              </div>
            </div>
          </td>
          <td>{{ notification.schedule }}</td>
          <td>
            {{
              notification.last_run
                ? (notification.last_run | date : "MMM d, y") + " / " + (notification.last_run | date : "HH:mm")
                : ""
            }}
          </td>
          <td>
            {{
              notification.next_run
                ? (notification.next_run | date : "MMM d, y") + " / " + (notification.next_run | date : "HH:mm")
                : ""
            }}
          </td>
          <td>
            <div
              *ngIf="notification.status_last_job"
              class="movanoTable_Obj_Badge"
              [ngClass]="getBadgeColor(notification.status_last_job)"
            >
              {{ notification.status_last_job }}
            </div>
          </td>
          <td class="movanoTable_click" (click)="openNtfRuns(notification.insight_name)">
            <div
              type="button"
              pButton
              [pRowToggler]="notification"
              class="p-button-text p-button-rounded p-button-plain"
              [icon]="'pi pi-arrow-right'"
            ></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorright" let-state>
        <paginator
          [pageSelected]="pageSelected"
          [rows]="rows"
          [state]="state"
          [lenght]="insightJobs.length"
          (updatePaginator)="updatePaginator($event)"
          [type]="type"
        ></paginator>
      </ng-template>
    </p-table>
    <ng-template #noData>
      <div class="ntf-list_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </ng-template>
    <div id="mobileScroll" class="movanoTableBox_mobileShadow"></div>
    <div id="mobileScroll_Left" class="movanoTableBox_mobileShadow movanoTableBox_mobileShadow_Left"></div>
  </div>

  <div *ngIf="loadingData" style="position: absolute; top: 50%; left: 50%">
    <p-progressSpinner
      styleClass="{{ darkMode ? 'custom-spinner' : 'custom-spinner-blue' }}"
      strokeWidth="2"
      animationDuration="1.5s"
    >
    </p-progressSpinner>
  </div>
</div>
