<div [class]="showBoxShadow ? 'boxShadow' : ''" class="movanoBarChart" [ngClass]="{'movanoBarChart_Dark' : darkMode}">
  <div *ngIf="showYAxisStats"  class="movanoBarChart_Header">
    <!--TITLE & BUTTONS-->
    <!------------------------------------------------------------------------------------->
    <span *ngIf="showTitle" class="movanoBarChart_Header_Tittle">
      <ng-container *ngIf="dashboardType === 'Sales'">Devices</ng-container>
      <ng-container *ngIf="dashboardType === 'SalesManagement'">Sales</ng-container>
      <ng-template #otherType>{{ dashboardType }}</ng-template>
    </span>
    <!-- Average -->
    <!-- <span *ngIf="showAverage" class="movanoBarChart_Header_Average">Average</span> -->
    <!------------------------------------------------------------------------------------->

    <!--LEGEND HEADER-->
    <!------------------------------------------------------------------------------------->
    <div style="width: 1043px; display: flex; justify-content: center;" *ngIf="showLegend">
      <div class="movanoBarChart_Header_Legend">
        <div *ngFor="let legendObj of legendObjects.get(dashboardType); let i = index"
          class="movanoBarChart_Header_Legend_Elements {{dashboardType}}"
          [class]="selectedLegend === i ? 'legendActive' : 'legendNoActive'"
          (click)="changeLegend(i)">
          <img *ngIf="showImages"  [src]="ICONS + dashboardType + i + (darkMode ? '_Dark.svg' : '.svg')" style="height: max(0.69vw, 10px);">
          <span [style.color]="darkMode ? legendObj.colorDark : legendObj.colorLight"> {{legendObj.text}} </span>
        </div>
      </div>
    </div>
    <!------------------------------------------------------------------------------------->
  </div>

  <!--CHART-->
  <div echarts [options]="option_users??{}" class="movanoBarChart_Chart"></div>
</div>
