import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { SharedService } from 'src/app/stats/shared.service';

@Component({
  selector: 'app-stats-user-activity-header',
  templateUrl: './stats-user-activity-header.component.html',
  styleUrls: ['./stats-user-activity-header.component.scss']
})
export class StatsUserActivityHeaderComponent {
  protected dynamicText: string = 'last week';
  protected sleepAnalysis: string = '';
  protected successAnalysis: string = '';
  protected spotChecks: string = '';
  protected avrgSpotPerDay: string = '';
  protected workout: string = '';
  protected avrgWorkPerDay: string = '';
  protected notifications: string = '';

  constructor(
    private sharedService: SharedService,
    private movanoSvc: MovanoService,
    private changeDetector: ChangeDetectorRef
  ) {
    // Initialization code
  }

  protected statsToday?: any;
  protected statsLastWeek?: any;
  protected statsLastMonth?: any;
  protected statsLast7Days?: any;
  protected statsYearToDate?: any;
  protected statsCustomRange?: any;

  ngOnInit() {
    this.sharedService.calendarObservable$.subscribe((data: any) => {
      this.updateInfo(data);
    });
    this.initializeWithLastWeekData();
  }

  async initializeWithLastWeekData() {
    this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());
    this.dynamicText = 'Last Week';
    this.sleepAnalysis = this.formatNumber(this.statsLastWeek.data.sleepAnalysis.count);
    this.successAnalysis = this.formatNumber(this.statsLastWeek.data.sleepAnalysis.successfulPercentage);
    this.spotChecks = this.formatNumber(this.statsLastWeek.data.spotChecks.count);
    this.avrgSpotPerDay = this.formatNumber(this.statsLastWeek.data.spotChecks.avg);
    this.workout = this.formatNumber(this.statsLastWeek.data.workout.count);
    this.avrgWorkPerDay = this.formatNumber(this.statsLastWeek.data.workout.avg);
    this.notifications = this.formatNumber(this.statsLastWeek.data.notifications.count);
    this.changeDetector.detectChanges();
  }

  async updateInfo(data?: any) {
    switch (data) {
      case 'today':
        this.statsToday = await lastValueFrom(this.movanoSvc.getUsersActivityToday());
        this.dynamicText = 'today';
        this.sleepAnalysis = this.formatNumber(this.statsToday.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsToday.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsToday.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsToday.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsToday.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsToday.data.workout.avg);
        this.notifications = this.formatNumber(this.statsToday.data.notifications.count);
        break;
      case 'Last Week':
        this.statsLastWeek = await lastValueFrom(this.movanoSvc.getUsersActivityLastWeek());
        this.dynamicText = 'Last Week';
        this.sleepAnalysis = this.formatNumber(this.statsLastWeek.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsLastWeek.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsLastWeek.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsLastWeek.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsLastWeek.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsLastWeek.data.workout.avg);
        this.notifications = this.formatNumber(this.statsLastWeek.data.notifications.count);
        break;
      case 'Last Month':
        this.statsLastMonth = await lastValueFrom(this.movanoSvc.getUsersActivityLastMonth());
        this.dynamicText = 'Last Month';
        this.sleepAnalysis = this.formatNumber(this.statsLastMonth.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsLastMonth.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsLastMonth.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsLastMonth.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsLastMonth.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsLastMonth.data.workout.avg);
        this.notifications = this.formatNumber(this.statsLastMonth.data.notifications.count);
        break;
      case 'Last 7 days':
        this.statsLast7Days = await lastValueFrom(this.movanoSvc.getUsersActivityLast7Days());
        this.dynamicText = 'Last 7 days';
        this.sleepAnalysis = this.formatNumber(this.statsLast7Days.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsLast7Days.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsLast7Days.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsLast7Days.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsLast7Days.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsLast7Days.data.workout.avg);
        this.notifications = this.formatNumber(this.statsLast7Days.data.notifications.count);
        break;
      case 'Year to date':
        this.statsYearToDate = await lastValueFrom(this.movanoSvc.getUsersActivityYearToDate());
        this.dynamicText = 'Year to date';
        this.sleepAnalysis = this.formatNumber(this.statsYearToDate.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsYearToDate.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsYearToDate.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsYearToDate.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsYearToDate.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsYearToDate.data.workout.avg);
        this.notifications = this.formatNumber(this.statsYearToDate.data.notifications.count);
        break;
      case 'customRange':
        const startDate = '2024-01-01';
        const endDate = '2024-01-31';
        this.statsCustomRange = await lastValueFrom(this.movanoSvc.getUsersActivityCustomDay(startDate, endDate));
        this.dynamicText = 'customRange';
        this.sleepAnalysis = this.formatNumber(this.statsCustomRange.data.sleepAnalysis.count);
        this.successAnalysis = this.formatNumber(this.statsCustomRange.data.sleepAnalysis.successfulPercentage);
        this.spotChecks = this.formatNumber(this.statsCustomRange.data.spotChecks.count);
        this.avrgSpotPerDay = this.formatNumber(this.statsCustomRange.data.spotChecks.avg);
        this.workout = this.formatNumber(this.statsCustomRange.data.workout.count);
        this.avrgWorkPerDay = this.formatNumber(this.statsCustomRange.data.workout.avg);
        this.notifications = this.formatNumber(this.statsCustomRange.data.notifications.count);
        break;
      default:
        console.error('Unknown calendarData value:');
        this.dynamicText = 'unknown';
    }
    this.changeDetector.detectChanges();
  }

  formatNumber(value: number): string {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return formattedValue.endsWith('.0') ? `${Math.round(value / 1000)}k` : `${formattedValue}k`;
    }
    return value % 1 === 0 ? `${value}` : value.toFixed(1);
  }
}
