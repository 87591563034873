<div class="movanoTotalInfo"
[ngClass]="{'movanoTotalInfo_Dark' : darkMode}">
  <div style="display: flex; flex-direction: column;">
    <span style="font-size: 12px; color:white">Users</span>
    <div style="display: flex; flex-direction: row; justify-content: space-evenly; ">
      <div class="movanoTotalInfo_TxtBlock">
        <span class="movanoTotalInfo_TxtBlock_Tittle--Main">{{totalUsers}}</span>
        <div class="movanoTotalInfo_TxtBlock_Txt">
          <span>total</span>
          <span>Users</span>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="movanoTotalInfo_TxtBlock">
        <span class="movanoTotalInfo_TxtBlock_Tittle--Secondary">{{activeUsers}}</span>
        <div class="movanoTotalInfo_TxtBlock_Txt">
          <span>active</span>
          <span>users</span>
        </div>
      </div>
      <div class="vertical-line"></div>
      <div class="movanoTotalInfo_TxtBlock">
        <span class="movanoTotalInfo_TxtBlock_Tittle--Secondary">{{newUsers}}</span>
        <div class="movanoTotalInfo_TxtBlock_Txt">
          <span>new</span>
          <span>users</span>
        </div>
      </div>
    </div>
  </div>
</div>
