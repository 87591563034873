<div class="movanoStats_Header">
  <div class="responsiveScaleRmas">
    <div class="inner-container">
      <div class="movanoCarousel_Element" style="min-width: 395px !important">
        <span class="movanoCarousel_Element_Number3" style="color: #b01065; font-weight: 900">{{
          apiData.stats.rma.salesPercentage.sales
        }}</span>
        <div class="movanoCarousel_Element_Info2">
          <span>total</span>
          <span>RMAs</span>
        </div>
        <div class="vertical-line"></div>

        <span class="movanoCarousel_Element_Number3" style="color: #054280"
          >{{ apiData.stats.rma.salesPercentage.PercentageOverSales }}%</span
        >
        <div class="movanoCarousel_Element_Info2">
          <span>of total </span>
          <span>sales</span>
        </div>
      </div>
      <div class="movanoCarousel_Element" style="min-width: 490px !important">
        <span class="movanoCarousel_Element_Number" style="color: #e31482; font-weight: bold">{{
          apiData.stats.rmaReturns.compare.current.value
        }}</span>
        <div class="movanoCarousel_Element_Info2">
          <span>returns</span>
          <span>{{ dynamicText }}</span>
        </div>

        <div class="movanoCarousel_Element_Arrows">
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
              [ngStyle]="{ fill: isDifferencePositive() ? '#00FFAA' : '#C4D9EE' }"
            />
          </svg>
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
              [ngStyle]="{ fill: isDifferencePositive() ? '#C4D9EE' : '#E22B2B' }"
            />
          </svg>
        </div>
        <div class="movanoCarousel_Element_LastTxt">
          <span class="movanoCarousel_Element_LastTxt_More"
            >{{ apiData.stats.rmaReturns.compare.comparison.difference }}
            {{ isDifferencePositive() ? "more" : "less" }}</span
          >
          <span class="movanoCarousel_Element_LastTxt_Pre">than the last period</span>
        </div>

        <div class="vertical-line"></div>

        <span class="movanoCarousel_Element_Number2" style="color: #e31482">{{
          apiData.stats.rmaReturns.compare.comparison.avg
        }}</span>
        <div class="movanoCarousel_Element_Info">
          <span>avg returns</span>
          <span>per day</span>
        </div>
      </div>
      <div class="movanoCarousel_Element" style="min-width: 490px !important">
        <span class="movanoCarousel_Element_Number" style="color: #7797b8; font-weight: bold">{{
          apiData.stats.exchanges.compare.current.value
        }}</span>
        <div class="movanoCarousel_Element_Info2">
          <span>exchanges</span>
          <span>{{ dynamicText }}</span>
        </div>

        <div class="movanoCarousel_Element_Arrows">
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.68332 8.63611L10.3167 8.63611C10.975 8.63611 11.375 7.91111 11.0167 7.35277L6.69999 0.569442C6.37499 0.0527751 5.62499 0.0527752 5.29165 0.569442L0.98332 7.35278C0.624986 7.91111 1.02499 8.63611 1.68332 8.63611Z"
              [ngStyle]="{ fill: isDifferencePositive2() ? '#00FFAA' : '#C4D9EE' }"
            />
          </svg>
          <svg
            width="max(0.833vw, 12px)"
            height="max(0.7vw, 9px)"
            viewBox="0 0 12 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.3167 0.96936L1.68335 0.96936C1.02501 0.96936 0.625013 1.69436 0.983346 2.25269L5.30001 9.03603C5.62501 9.55269 6.37501 9.55269 6.70835 9.03603L11.0167 2.25269C11.375 1.69436 10.975 0.96936 10.3167 0.96936Z"
              [ngStyle]="{ fill: isDifferencePositive2() ? '#C4D9EE' : '#E22B2B' }"
            />
          </svg>
        </div>
        <div class="movanoCarousel_Element_LastTxt">
          <span class="movanoCarousel_Element_LastTxt_More"
            >{{ apiData.stats.exchanges.compare.comparison.difference }}
            {{ isDifferencePositive2() ? "more" : "less" }}</span
          >
          <span class="movanoCarousel_Element_LastTxt_Pre">than the last period</span>
        </div>

        <div class="vertical-line"></div>

        <span class="movanoCarousel_Element_Number2" style="color: #7797b8">{{
          apiData.stats.exchanges.compare.comparison.avg
        }}</span>
        <div class="movanoCarousel_Element_Info">
          <span>avg exchanges</span>
          <span>per day</span>
        </div>
      </div>
    </div>
  </div>
</div>
