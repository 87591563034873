import { NONE_TYPE } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { json } from 'stream/consumers';

@Component({
  selector: 'app-tester',
  templateUrl: './tester.component.html',
  styleUrls: ['./tester.component.scss']
})
export class TesterComponent {
  public percentage: number = 60;
  selectIconRotate = false;
  consolClick() {
    console.log("click");
  }
  onChartEvent(event: any, type: string) {
    console.log('chart event:', type, event);
  }
  options: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      formatter: (params: any) => {
        return `
        <div style="display: flex;  flex-direction: column;  font-family: Zen Kaku Gothic Antique;">
          <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">10%</span>
            <div style="display: flex; font-family: Zen Kaku Gothic Antique;">
              <div style="display: flex; flex-direction: column; color: #7797B8; border-right: 2px solid #D1F5FF; padding-right: 10px; gap: 5px">
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${params[2].data}
                </span>
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">10%</span>
              </div>
              <div style="display: flex; flex-direction: column; color: #7797B8; padding-left: 10px; gap: 5px">
                <span style="font-size: max(1.4vw, 20px); font-weight: 700; line-height: 130.4%;">
                  ${params[3].data}
                </span>
                <span style="font-size: max(0.7vw, 10px); font-weight: 500; line-height: 130.4%;">10%</span>
              </div>
          </div>
        </div>
        `},
    },
    xAxis: {
      type: 'category',
      show: false,
      splitLine: {
        show: false,
      }
    },
    yAxis: {
      type: 'value',
      splitNumber: 4,
      nameTextStyle: {
        fontSize: 'max(0.7vw, 10px)',
        fontWeight: 700,
        fontFamily: 'Zen Kaku Gothic Antique',
      },
      axisLabel: {
        inside: true,
        formatter: function (value: any, index: any) {
          if (index === 0) return '';
          return '- ' + value;
        },
        fontWeight: 700,
        color: '#284767',
        fontFamily: 'Zen Kaku Gothic Antique',
        fontSize: 'max(0.7vw, 10px)',
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: false,
      }
    },
    series: [

      {
        name: 'Line 4',
        type: 'line',
        stack: 'Total',
        zlevel: 1,
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        emphasis: {
          itemStyle: {
            borderWidth: 0,

          },
        },
        symbolSize: 0,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(11, 132, 255, 0.40)' },
            { offset: 1, color: 'rgba(13, 204, 255, 0.40)' }
          ])
        },
        data: [220, 402, 231, 134, 190, 230, 120]
      },
      {
        name: 'Line 5',
        type: 'line',
        stack: 'Total',
        zlevel: 1,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        itemStyle: {
          borderWidth: 0,

        },
        symbolSize: 0,
        showSymbol: false,
        emphasis: {
          itemStyle: {
            borderWidth: 0,

          },
        },
        label: {
          show: true,
          position: 'top'
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(5, 66, 128, 0.50)' },
            { offset: 1, color: 'rgba(11, 132, 255, 0.50)' }
          ])
        },
        data: [220, 302, 181, 234, 210, 290, 150]
      },
      {
        type: 'bar',
        data: [50, 100, 59, 32, 21, 56, 90],
        emphasis: {
          itemStyle: {
            color: '#054280',
            shadowColor: 'rgba(0, 0, 0, 0.5)', // Color de la sombra
            shadowBlur: 10, // Intensidad de la sombra
          }
        },
        barMinWidth: 1,
        zlevel: 2,
        stack: 'one',
        barWidth: 4,
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(5, 66, 128, 0.50)' },
            { offset: 1, color: 'rgba(11, 132, 255, 0.50)' }
          ])
        }
      },
      {
        type: 'bar',
        stack: 'one',
        data: [120, 200, 150, 80, 70, 110, 130],
        barMinWidth: 1,
        emphasis: {
          itemStyle: {
            color: '#0B84FF',
            shadowColor: 'rgba(0, 0, 0, 0.5)', // Color de la sombra
            shadowBlur: 10, // Intensidad de la sombra
          }
        }, label: {
          show: true,
          position: 'top',
          color: '#284767',
          fontFamily: 'Zen Kaku Gothic Antique',
          fontSize: 'max(0.7vw, 10px)',
          formatter: function (params: any) {
            // Mostrar etiqueta solo cada 4 valores (0-indexed)
            if (params.dataIndex % 3 === 0) {
              return params.value;
            } else {
              return '';
            }
          },
        },
        zlevel: 2,
        barWidth: 4,
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(11, 132, 255, 0.40)' },
            { offset: 1, color: 'rgba(13, 204, 255, 0.40)' }
          ])
        }
      },
    ]
  };



}
