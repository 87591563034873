<div class="movano-select-container">
  <div class="custom-select-wrapper">
    <button class="custom-select-btn" (click)="toggleDropdown()">
      {{ measuresLabels[selectedMeasureIndex] }}
      <svg class="movanoTriangleInput" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
          fill="#284767"
        />
      </svg>
    </button>
    <div class="custom-select-dropdown" *ngIf="dropdownOpen">
      <div
        *ngFor="let measure of measuresLabels; let i = index"
        (click)="selectMeasure(i)"
        class="custom-select-option"
        [class.selected]="i == selectedMeasureIndex"
      >
        {{ measure }}
      </div>
    </div>
  </div>

  <div class="legend-container">
    <span [ngStyle]="{ color: getShadowColor() }">{{ measuresLabels[selectedMeasureIndex] }}</span>
  </div>

  <div echarts class="statsHealthMetricsBarChart_Chart"></div>
</div>
