<div class="movanoUserPanel" [ngClass]="{'movanoDark': darkMode}">
  <div class="movanoUserPanel_Header_Tittle">
    <div *ngFor="let page of notificationData.pages" style="display: flex; flex-direction: column;">
      <span class="movanoUserPanel_Header_Tittle_title"> {{ page.title }} </span>
      <span class="movanoUserPanel_Header_Tittle_Subtitle"> {{page.subtitle }}</span>
    </div>

    
    <svg (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
        fill="#C4D9EE" />
    </svg>
  </div>
  <div *ngIf="!notificationData">
    <p-progressSpinner styleClass="{{darkMode ? 'custom-spinner' : 'custom-spinner-blue'}}" strokeWidth="2"
      animationDuration="1.5s">
    </p-progressSpinner>
  </div>
  <div *ngFor="let page of notificationData.pages" class="movanoUserPanel_Body">
  
    <span>{{page.body}}</span>
    <img [src]="page.imageUrl">
  
  </div>
</div>