<app-header [title]="LITERALS.STATS_SUMMARY.TITTLE"></app-header>
<div class="header-container">
  <div class="time-selector-container">
    <app-stats-calendar #list_calendar (initGetInfo)="handleInfo($event)"></app-stats-calendar>
  </div>

  <app-tab-selector [tabs]="['Users', 'Devices', 'User Activity', 'Health Metrics']"
    (tabSelected)="selectTab($event)"></app-tab-selector>

  <div class="icons-container">
    <svg style="margin-right:30px; cursor:pointer;" width="max(1.11vw, 17px)" height="max(1.11vw, 19px)"
      viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.186 1.1897e-05H4.60464L3.00801 0L3 16C3 16 3.71406 16 4.59662 16H13.3953C14.2779 16 15 16 15 16V4.75109L10.186 1.1897e-05ZM13.3953 14.393H4.60464V1.53712H9.38372V5.55458H13.3953V14.393Z"
        fill="#054280" />
      <path d="M0 10H17V19H0V10Z" fill="#054280" />
      <path d="M12.8696 17L11.1546 12.1H12.1276L13.3316 15.775L14.5426 12.1H15.5156L13.7936 17H12.8696Z" fill="white" />
      <path
        d="M8.99207 17.105C8.57207 17.105 8.18474 17.014 7.83007 16.832C7.48007 16.6453 7.2024 16.3303 6.99707 15.887L7.76007 15.467C7.8954 15.7097 8.05874 15.8987 8.25007 16.034C8.4414 16.1693 8.7004 16.237 9.02707 16.237C9.34907 16.237 9.56374 16.1833 9.67107 16.076C9.7784 15.964 9.83207 15.817 9.83207 15.635C9.83207 15.5183 9.80407 15.418 9.74807 15.334C9.69674 15.25 9.58007 15.1707 9.39807 15.096C9.22074 15.0167 8.9454 14.9327 8.57207 14.844C8.1194 14.7367 7.78574 14.564 7.57107 14.326C7.3564 14.088 7.24907 13.7917 7.24907 13.437C7.24907 13.143 7.31907 12.8863 7.45907 12.667C7.59907 12.4477 7.79507 12.2773 8.04707 12.156C8.30374 12.03 8.60474 11.967 8.95007 11.967C9.2674 11.967 9.5474 12.016 9.79007 12.114C10.0327 12.212 10.2381 12.3403 10.4061 12.499C10.5787 12.6577 10.7141 12.8303 10.8121 13.017L10.0911 13.556C10.0211 13.444 9.93474 13.3343 9.83207 13.227C9.73407 13.115 9.61274 13.0217 9.46807 12.947C9.3234 12.8723 9.14607 12.835 8.93607 12.835C8.71674 12.835 8.53707 12.8817 8.39707 12.975C8.26174 13.0637 8.19407 13.2037 8.19407 13.395C8.19407 13.4697 8.21274 13.5467 8.25007 13.626C8.29207 13.7007 8.3854 13.7753 8.53007 13.85C8.6794 13.9247 8.91507 14.0017 9.23707 14.081C9.78307 14.2117 10.1774 14.4077 10.4201 14.669C10.6674 14.9257 10.7911 15.243 10.7911 15.621C10.7911 16.1017 10.6277 16.4703 10.3011 16.727C9.97907 16.979 9.54274 17.105 8.99207 17.105Z"
        fill="white" />
      <path
        d="M4.54096 17.119C4.09762 17.119 3.70096 17.014 3.35096 16.804C3.00096 16.594 2.72562 16.2977 2.52496 15.915C2.32896 15.5277 2.23096 15.0727 2.23096 14.55C2.23096 14.0227 2.32896 13.5677 2.52496 13.185C2.72562 12.7977 3.00096 12.499 3.35096 12.289C3.70096 12.0743 4.09762 11.967 4.54096 11.967C4.95629 11.967 5.32962 12.0603 5.66096 12.247C5.99696 12.4337 6.26529 12.695 6.46596 13.031L5.65396 13.5C5.53729 13.2947 5.38329 13.136 5.19196 13.024C5.00529 12.912 4.78829 12.856 4.54096 12.856C4.26562 12.856 4.02529 12.9283 3.81996 13.073C3.61929 13.213 3.46296 13.409 3.35096 13.661C3.23896 13.913 3.18296 14.2093 3.18296 14.55C3.18296 14.886 3.23896 15.18 3.35096 15.432C3.46296 15.684 3.61929 15.8823 3.81996 16.027C4.02529 16.167 4.26562 16.237 4.54096 16.237C4.78829 16.237 5.00529 16.181 5.19196 16.069C5.38329 15.9523 5.53729 15.7937 5.65396 15.593L6.46596 16.062C6.26529 16.3933 5.99696 16.6523 5.66096 16.839C5.32962 17.0257 4.95629 17.119 4.54096 17.119Z"
        fill="white" />
    </svg>

    <svg style="margin-right:47px;" width="max(1.11vw, 16px)" height="max(1.11vw, 16px)" viewBox="0 0 3 15" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.3">
        <circle cx="1.5" cy="1.5" r="1.5" fill="#054280" />
        <circle cx="1.5" cy="7.5" r="1.5" fill="#054280" />
        <circle cx="1.5" cy="13.5" r="1.5" fill="#054280" />
      </g>
    </svg>
  </div>
  <app-stats-users style="display: none;" [calendarData]="selectedValue"></app-stats-users> <!-- Componente Users -->
  <app-stats-devices style="display: none;" [calendarData]="selectedValue"></app-stats-devices>
</div>

<router-outlet></router-outlet>
