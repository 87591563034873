<div class="movanoWaveformData">
  <div class="movanoWaveformData_Header">
    <div class="movanoWaveformData_Header_Buttons">
      <div *ngIf="!loading" class="movano_switch" style="position: relative; ">
        <label class="switch">
          <input [(ngModel)]="syncZoom" [checked]="syncZoom" type="checkbox">
          <span class="slider round"><span class="sliderText" style="font-weight: 700;">Sync Zooms</span></span>
        </label>
      </div>
    </div>
    <div class="movanoCalendar">
      <mat-icon [ngStyle]="{color : (false) ? '#FFFFFF' : ''}">calendar_today</mat-icon>
      <p-calendar #dayCalendar [maxDate]="today!" (onSelect)="startGatheringBlocks()" *ngIf="!utc"
        dateFormat="d MM ‘y - {{'\''+'Time Zone'+'\''}}" [(ngModel)]="daySelected">
        <ng-template pTemplate="header">
          <div class="movanoHealthMetrics_Header_SubTittle_UTC">
            <div class="movano_switchTimeZone" style="margin-left: 1.25vw; margin-bottom: 15px;">
              <label class="switch">
                <input type="checkbox" [(ngModel)]="utc" (change)="startGatheringBlocks()">
                <span class="slider round">
                  <span class="sliderText" [ngClass]="{'selected' : utc}">UTC</span>
                  <span class="sliderText" [ngClass]="{'selected' : !utc}">{{'TZ'}}</span>
                </span>
              </label>
            </div>

          </div>
        </ng-template>
      </p-calendar>
      <p-calendar #dayCalendar [maxDate]="today!" (onSelect)="startGatheringBlocks()" *ngIf="utc"
        dateFormat="d MM ‘y - 'UTC'" [(ngModel)]="daySelected">
        <ng-template pTemplate="header">
          <div class="movanoHealthMetrics_Header_SubTittle_UTC">
            <div class="movano_switchTimeZone" style="margin-left: 1.25vw; margin-bottom: 15px;">
              <label class="switch">
                <input type="checkbox" [(ngModel)]="utc" (change)="startGatheringBlocks()">
                <span class="slider round">
                  <span class="sliderText" [ngClass]="{'selected' : utc}">UTC</span>
                  <span class="sliderText" [ngClass]="{'selected' : !utc}">{{'TZ'}}</span>
                </span>
              </label>
            </div>
            <div class="movano-dropdown">
              <p-dropdown (onChange)="startGatheringBlocks()"
                placeholder="UTC + {{moment().tz(actualTimeZone).utcOffset() / 60 | number: '2.0-0'}} - {{actualTimeZone?.split('/')![1] || 'UTC'}}"
                [options]="timeZones!" [(ngModel)]="actualTimeZone" [dropdownIcon]="''"
                optionLabel="UTC + {{moment().tz(actualTimeZone).utcOffset() / 60 | number: '2.0-0'}} - {{actualTimeZone?.split('/')![1] || 'UTC'}}">
                <ng-template pTemplate="selectedItem">
                  <span class="flex align-items-center gap-2">
                    UTC + {{moment().tz(actualTimeZone).utcOffset() / 60 | number: '2.0-0'}} -
                    {{actualTimeZone!.split("/")[1] ||
                    actualTimeZone!.split("/")[0]}}
                  </span>
                </ng-template>
                <ng-template let-timeZone pTemplate="item">
                  <span class="flex align-items-center gap-2">
                    UTC + {{moment().tz(timeZone).utcOffset() / 60 | number: '2.0-0'}} - {{timeZone.split("/")[1] ||
                    timeZone.split("/")[0]}}
                  </span>
                </ng-template>
              </p-dropdown>
              <svg viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
                  fill="#284767" />
              </svg>
            </div>
          </div>
        </ng-template>
      </p-calendar>
      <svg style="transform: rotate(0deg); position: absolute; bottom: 42%; right:0.84vw" width="0.555vw"
        height="0.4166vw" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.35199 -2.83255e-08L0.648012 -3.21366e-07C0.136801 -3.43711e-07 -0.173808 0.514539 0.104446 0.910793L3.45643 5.72499C3.7088 6.09167 4.2912 6.09167 4.55004 5.72499L7.89555 0.910793C8.17381 0.514539 7.8632 -5.97973e-09 7.35199 -2.83255e-08Z"
          fill="#284767" />
      </svg>
    </div>
  </div>
  <div *ngIf="loading; then loadingData else waveFormData"></div>
  <ng-template #loadingData>
    <div class="movanoWaveformData_NoData progessBarMovano">
      <span>{{barLabels[blocksLoaded]}}</span>
      <p-progressBar [style]="{width: '30vw', height: '3vw', position: 'absolute', left : '25vw'}"
        [value]="(blocksLoaded*100)/6" [showValue]="false"
        [color]="'linear-gradient(224deg, #0B84FF 0%, #0DCCFF 100%)'"></p-progressBar>
      <p-progressBar mode="indeterminate"
        [style]="{width: '28vw', height: '0.14vw', position: 'absolute', left : '26vw', top: '3.25vw'}"
        [color]="'#0B84FF'">
      </p-progressBar>
    </div>
  </ng-template>
  <ng-template #waveFormData>

    <div *ngIf="measures_options.length <= 0">
      <div class="movanoWaveformData_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </div>

  </ng-template>
  <div #waveFormDataBlocks class="movanoWaveformData_Charts">

  </div>
</div>
<!-- [options]="option" (mousemove)="hoverChartEvent($event, i)" (mouseup)="onChartEvent($event, 'chartClickUp')"  -->
