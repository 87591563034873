<div class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      'justify-content': 'end',
      position: 'relative',
      left: '20px',
      top: '-5px',
      opacity: '0.5'
    }"
  >
    <mat-icon (click)="closeDialog()" [ngStyle]="{ cursor: 'pointer' }">close</mat-icon>
  </div>
  <div class="svg-icon">
    <img
      *ngIf="!isRecoverQuestionMode && !isDeletedMode"
      src="../../../../assets/tick.svg"
      width="32"
      height="32"
      alt="Tick Circle"
    />

    <img
      *ngIf="isRecoverQuestionMode"
      src="../../../../assets/replay-circle.svg"
      width="32"
      height="32"
      alt="Replay Circle"
    />

    <img *ngIf="isDeletedMode" src="../../../../assets/delete.svg" width="32" height="32" alt="Delete Circle" />
  </div>

  <p class="title">{{ message }}</p>
  <app-main-button label="{{ labelButton }}" [fullWidth]="true" (click)="toggleRecoverMode()"></app-main-button>
</div>
