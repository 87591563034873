<div class="scrollBarUser">
  <div class="movanoUserPanel">
    <div class="movanoUserPanel_header" [ngClass]="{ movanoDark: darkMode }">
      <div class="calendarAndUserTittle">
        <div class="calendar-toggle">
          <app-new-time-selector #calendar_selector [hidden]="selectedTab === 'waveformData'"
            (initGetInfo)="getCalendarInfo($event)" [slctdDateOpt]="selectedDate"
            [timeZones]="timeZones"></app-new-time-selector>

          <div class="movano_switchTimeZone" style="margin-left: 1.25vw; margin-bottom: 5px">
            <label class="switch">
              <input type="checkbox" [(ngModel)]="utc" />
              <span class="slider round">
                <span class="sliderText" [ngClass]="{ selected: utc }">UTC</span>
                <span class="sliderText" [ngClass]="{ selected: !utc }">{{ "TZ" }}</span>
              </span>
            </label>
          </div>

          <p style="margin-left: 40px ;" *ngIf="userTimezonesCount > 0 && !utc" [title]="userTimezones">{{
            userTimezonesCount === 1 ? userTimezones : userTimezonesCount + ' timezones' }}</p>
        </div>
        <div style="display: flex; flex-direction: row;">
          <div class="userId">
            <span> User ID </span>
            <span class="movanoUserPanel_header_user">
              {{ data.user }}
            </span>
          </div>
          <div class="closeIcon">
            <svg (click)="closePanel()" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.25 1.8075L10.1925 0.75L6 4.9425L1.8075 0.75L0.75 1.8075L4.9425 6L0.75 10.1925L1.8075 11.25L6 7.0575L10.1925 11.25L11.25 10.1925L7.0575 6L11.25 1.8075Z"
                fill="#9FB9D3" />
            </svg>
          </div>
        </div>
      </div>

      <div class="movanoUserPanel_header_buttons">
        <div class="movanoUserPanel_header_buttons_chartType"
          [ngClass]="{ 'movanoUserPanel_header_buttons_chartType-selected': selectedTab === 'healthMetrics' }"
          (click)="selectTab('healthMetrics')">
          Health Metrics
        </div>

        <!-- <div class="movanoUserPanel_header_buttons_chartType"
        [ngClass]="{'movanoUserPanel_header_buttons_chartType-selected' : selectedTab === 'waveformData'}"
        (click)="selectTab('waveformData')">Waveform Data</div> -->

        <div class="movanoUserPanel_header_buttons_chartType"
          [ngClass]="{ 'movanoUserPanel_header_buttons_chartType-selected': selectedTab === 'workoutUsage' }"
          (click)="selectTab('workoutUsage')">
          Workout Usage
        </div>

        <div class="movanoUserPanel_header_buttons_chartType"
          [ngClass]="{ 'movanoUserPanel_header_buttons_chartType-selected': selectedTab === 'sleepData' }"
          (click)="selectTab('sleepData')">
          Sleep Data
        </div>

        <div class="movanoUserPanel_header_buttons_chartType"
          [ngClass]="{ 'movanoUserPanel_header_buttons_chartType-selected': selectedTab === 'spotCheck' }"
          (click)="selectTab('spotCheck')">
          Spot Check
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="selectedTab">
      <ng-template ngSwitchCase="healthMetrics">
        <app-user-panel-health-metrics [savedMeasureData]="measures_LastData" [measures_Battery]="measures_Battery"
          [loadingMesureData]="needHaveHealthMetrics" [lastDaySelected]="daySelected" [lastDayView]="dayView"
          [calendarData]="calendarInfo" [daySelector]="calendar_selector" [isUTC]="utc"
          (saveData)="healthMetricsData($event)">
        </app-user-panel-health-metrics>
      </ng-template>

      <ng-template ngSwitchCase="waveformData">
        <app-user-panel-waveform-data [lastDaySelected]="daySelected"></app-user-panel-waveform-data>
      </ng-template>

      <ng-template ngSwitchCase="workoutUsage">
        <app-workout-usage [calendarData]="calendarInfo" [isUTC]="utc"></app-workout-usage>
      </ng-template>

      <ng-template ngSwitchCase="sleepData">
        <app-user-panel-sleep-data [calendarData]="calendarInfo" [daySelector]="calendar_selector"
          [isUTC]="utc"></app-user-panel-sleep-data>
      </ng-template>

      <ng-template ngSwitchCase="spotCheck">
        <user-panel-spot-check [calendarData]="calendarInfo" [daySelector]="calendar_selector"
          [isUTC]="utc"></user-panel-spot-check>
      </ng-template>
    </ng-container>
  </div>
</div>
