import { AfterViewInit, Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { MovanoService } from 'src/app/movano.service';
import { healthMeasure_Hour, healthMetrics_Battery, healthMeasure_Day, healthMetrics_Hour, healthMetrics_Day, deviceMeasures_Range_Sizes, deviceMeasures_Range_Time, measurementPeaks } from 'src/app/shared/movano-interfaces';
import { Calendar } from 'primeng/calendar';
import { Subscription, lastValueFrom } from 'rxjs';
import { DarkModeService } from 'src/app/dark-mode.service';
import { abbrNum, fixPixelsBy1440 } from 'src/app/shared/utils';
import { mixpanelUserProfile } from 'src/app/shared/movano-interfaces';

@Component({
  selector: 'app-user-panel-activity-log',
  templateUrl: './user-panel-activity-log.component.html',
  styleUrls: ['./user-panel-activity-log.component.scss']
})
export class UserPanelActivityLogComponent implements AfterViewInit{

  protected measures_Day?: healthMeasure_Hour[]; //All info per day
  protected measures_MonthWeek?: healthMeasure_Day[]; //All info per month / week
  @Input() lastDaySelected?: Date;
  protected actualTimeZone?: string;

  protected daySelected?: Date;
  protected today?: Date;
  protected innerWidth: number = 0; //Real window with in pixels
  protected nextIsPosible: boolean = false;
  protected moment = require('moment-timezone');

  protected loadingData: boolean = true;
  protected loadingActivityFeed: boolean = true;

  inputValue: any = "";
  mixpanelUserProfile: mixpanelUserProfile | undefined;
  dataAvailable = true;

  calendarOpen : boolean = false;

  //DARK MODE
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();

  @ViewChild('dayCalendar') daySelector !: Calendar;
  @Output() saveData = new EventEmitter<[healthMetrics_Hour | healthMetrics_Day | undefined, healthMetrics_Battery | undefined, Date | undefined, boolean | undefined]>();

  @HostListener('window:resize', ['$event']) //Event to know the window width
  onResize(event: any) {
    this.innerWidth = document.documentElement.clientWidth;
  }

  //CONSTRUCTOR
  constructor(
    protected movanoService: MovanoService,
    private darkModeSvc: DarkModeService,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  };


  //INIT
  async ngAfterViewInit() {
    this.daySelected = this.lastDaySelected ?? new Date(Date.now());
    const righNow = new Date(Date.now());
    this.today = new Date(righNow.getFullYear(), righNow.getMonth(), righNow.getDate());
    this.innerWidth = document.documentElement.clientWidth;
    this.getInfo()
  }

  //ON DESTROY (dark mode un subscribe)
  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  resetInput() {
    this.inputValue = '';
  }

  async getInfo() {
    this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();

    try {
      this.loadingActivityFeed = true;
      //if(this.loadingData){
        // const activityData = await lastValueFrom(this.movanoService.getMixpanelUserProfile(this.data.user));
        // this.mixpanelUserProfile = activityData;
        this.dataAvailable = true;
      //}
    } catch (error) {
      if(this.loadingData)this.dataAvailable = false;
    }

    this.loadingData = false;
    this.loadingActivityFeed = false;
  }

  /**
   * Advances to the next day.
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  nextDate() {
    // If next day is tomorrow, exit the function
    if (!this.nextIsPosible) return;

    //Advances to the next day.
    this.daySelected?.setDate(this.daySelected.getDate() + 1);
    this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();
    // Update the input field in the day selector component
    this.daySelector.updateInputfield();

    // Call the getInfo function to update data with the new date
    //this.getInfo();
  }

  /**
   * Goes to the previous day
   * Updates properties and calls the getInfo function to fetch data for the new date.
   */
  previusDate() {
    //Goes to the previous day
    this.daySelected?.setDate(this.daySelected.getDate() - 1);
    this.nextIsPosible = this.daySelected!.getTime() < this.today!.getTime();
    // Update the input field in the day selector component
    this.daySelector.updateInputfield();

    // Call the getInfo function to update data with the new date
    //this.getInfo();
  }

}
