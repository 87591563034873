import { Component, HostListener, SimpleChanges } from "@angular/core";
import { EChartsOption } from "echarts";
import { lastValueFrom, skip } from "rxjs";
import { MovanoService } from "src/app/movano.service";
import { INewCalendarSelectorData } from "src/app/shared/movano-interfaces";
import { SharedService } from "src/app/stats/shared.service";
import { adjustScale } from "src/main";

@Component({
  selector: "app-rmas-donuts",
  templateUrl: "./rmas-donuts.component.html",
  styleUrls: ["./rmas-donuts.component.scss"],
})
export class RmasDonutsComponent {
  constructor(private sharedService: SharedService, private movanoSvc: MovanoService) {}

  public innerWidth: any;
  ctx: any;

  front: boolean = true;

  protected option_SizeDistribution?: EChartsOption;
  protected option_ColorDistribution?: EChartsOption;
  protected option_rmasDistribution?: EChartsOption;

  protected rmasSalesDistributionName = ["exchanged", "returned"];
  protected rmasSalesDistributionPer = [78, 20];
  protected rmasSalesDistributionCount = [0, 0];

  protected sizeDistributionName = ["8", "6", "7", "9", "10", "11", "5", "12"];
  protected sizeDistributionPer = [40, 20, 10, 10, 5, 5, 3, 2];
  protected sizeDistributionCount = [0, 0, 0, 0, 0, 0, 0, 0];

  protected colorDistributionName = ["Rose Gold", "Gold", "Silver"];
  protected colorDistributionPer = [60, 30, 20];
  protected colorDistributionCount = [0, 0, 0];

  protected sizeBarsDistributionName = ["8", "6", "7", "9", "10", "11", "5", "12"];
  protected sizeBarsDistributionPer = [40, 20, 10, 10, 5, 5, 3, 2];

  protected colorBarsDistributionName = ["Rose Gold", "Gold", "Silver"];
  protected colorBarsDistributionPer = [60, 30, 20];

  protected apiData?: any;
  protected apiBarsData?: any;
  protected colorMap: any = {
    "Rose Gold": "#f9bc95",
    "Gold": "#e7c400",
    "Silver": "#7797b8",
  };

  //SizeBarChart
  sizeDataChart: any;
  barSize: any;

  //ColorBarChart
  colorDataChart: any;
  colorBarSize: any;

  protected option_DonutEmpty: EChartsOption = {
    tooltip: {
      show: false,
      trigger: "item",
    },
    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["80%", "90%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: "50%",
        },
        label: {
          show: false,
          position: "center",
        },
        labelLine: {
          show: false,
        },
        data: [],
      },
    ],
  };

  ngOnInit() {
    this.getStatsFromDB();
    this.subscribeToCalendarChanges();
  }

  ngAfterViewChecked() {
    adjustScale(".responsiveScaleDonutsSales", { maxScale: 1.15, minScale: 1.1, decayFactor: 1.1 });
  }

  async getStatsFromDB(): Promise<void> {
    await this.fetchAndProcessData("lastMonth");
  }

  async fetchAndProcessData(timeFrame: string): Promise<void> {
    const response = await lastValueFrom(this.movanoSvc.getRmasDonutsData(timeFrame));
    this.apiData = response.data;
    const response2 = await lastValueFrom(this.movanoSvc.getRmasDonutsBarsData());
    this.apiBarsData = response2.data
    this.processData();
    this.updateInfo();
  }

  subscribeToCalendarChanges(): void {
    this.sharedService.calendarObservable$
      .pipe(skip(1))
      .subscribe(async (data: INewCalendarSelectorData) => {
        const response = await lastValueFrom(this.movanoSvc.getRmasDonutsData(
          data.option,
          data.startDate?.toISOString().split("T")[0],
          data.endDate?.toISOString().split("T")[0])
        );

        this.apiData = response.data;
        this.processData();
        this.updateInfo();
      });
  }

  async processData(): Promise<void> {
    const salesData = this.apiData.stats.rma;

    this.rmasSalesDistributionPer =  this.apiData.stats.rma.byTypes.values.map((item: { percentage: number }) => item.percentage);
    this.rmasSalesDistributionCount = this.apiData.stats.rma.byTypes.values.map((item: { value: number }) => item.value);

    this.sizeDistributionName = this.apiData.stats.rma.bySizes.values.map((item: { size: string }) => item.size);
    this.sizeDistributionPer = this.apiData.stats.rma.bySizes.values.map((item: { percentage: number }) => item.percentage);
    this.sizeDistributionCount = this.apiData.stats.rma.bySizes.values.map((item: { value: number }) => item.value);

    const colorTextMap: Record<number, string> = {
      1: "#7797b8",
      2: "#e7c400",
      3: "#f9bc95",
    };
    this.colorDistributionName = this.apiData.stats.rma.byColors.values.map((item: { color: string }) => item.color);
    // this.colorDistributionText = this.apiData.stats.color.distribution.map((item: { id: number }) => colorTextMap[item.id]);
    this.colorDistributionPer = this.apiData.stats.rma.byColors.values.map((item: { percentage: number }) => item.percentage);
    this.colorDistributionCount = this.apiData.stats.rma.byColors.values.map((item: { value: number }) => item.value);

    this.sizeBarsDistributionName = this.apiBarsData.stats.rma.bySizes.values.map((item: { size: string }) => item.size);
    this.sizeBarsDistributionPer = this.apiBarsData.stats.rma.bySizes.values.map((item: { percentage: number }) => item.percentage);

    this.colorBarsDistributionName = this.apiBarsData.stats.rma.byColors.values.map((item: { color: string }) => item.color);
    this.colorBarsDistributionPer = this.apiBarsData.stats.rma.byColors.values.map((item: { percentage: number }) => item.percentage);
  }

  async updateInfo(data?: any) {
    let gradient;
    if (this.ctx) {
      gradient = this.ctx.createConicGradient(0, 100, 100);
      gradient.addColorStop(0, "rgba(37, 134, 221,0)");
      gradient.addColorStop(0.5, "rgba(37, 134, 221,0.1)");
      gradient.addColorStop(0.75, "rgba(37, 134, 221,0)");
    }

    this.innerWidth = document.documentElement.clientWidth;
    this.getSizeBars();
    this.getColorBars();

    this.option_rmasDistribution = {
      tooltip: {
        show: true,
        trigger: "item",
        confine: true,
        borderColor: "transparent",
        extraCssText: "box-shadow: none; border: none; background-color: transparent;",
        backgroundColor: "transparent",
        formatter: (params: any) => {
          if (!this.rmasSalesDistributionName || this.rmasSalesDistributionName.length <= params.dataIndex) {
            return "";
          }

          const color = this.getRmasDistributionColor(params.dataIndex);
          const salesDistributionName = this.rmasSalesDistributionName[params.dataIndex];
          const count = this.rmasSalesDistributionCount[params.dataIndex];

          return `
            <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                      background-color: white; padding: 10px; border-radius: 5px;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
              <div style="display: flex; flex-direction: column; color: ${color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${salesDistributionName}
                  </span>
                  <span style="font-size: max(0.9vw, 20px); font-weight: 700; line-height: 130.4%; color: ${color};">
                      ${count}
                  </span>
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${params.percent.toFixed(2)}%
                  </span>
              </div>
            </div>`;
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "90%"],
          color: this.rmasSalesDistributionPer?.map((value: any, index: number) =>
            this.getRmasDistributionColor(index),
          ),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: "50%",
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: "rgba(0,0,0,0.25)",
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.rmasSalesDistributionPer,
        },
      ],
    };
    this.option_SizeDistribution = {
      tooltip: {
        show: true,
        trigger: "item",
        confine: true,
        borderColor: "transparent",
        extraCssText: "box-shadow: none; border: none; background-color: transparent;",
        backgroundColor: "transparent",
        formatter: (params: any) => {
          if (!this.sizeDistributionName || this.sizeDistributionName.length <= params.dataIndex) {
            return "";
          }

          const color = this.getSizeDistributionColor(params.dataIndex);
          const sizeType = this.sizeDistributionName[params.dataIndex];
          const count = this.sizeDistributionCount[params.dataIndex];

          return `
            <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                      background-color: white; padding: 10px; border-radius: 5px;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
              <div style="display: flex; flex-direction: column; color: ${color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${sizeType}
                  </span>
                  <span style="font-size: max(0.9vw, 20px); font-weight: 700; line-height: 130.4%; color: ${color};">
                      ${count}
                  </span>
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                     ${params.percent.toFixed(2)}%
                  </span>
              </div>
            </div>`;
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "90%"],
          color: this.sizeDistributionPer.map((value: any, index: number) => this.getSizeDistributionColor(index)),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: "50%",
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: "rgba(0,0,0,0.25)",
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.sizeDistributionPer,
        },
      ],
    };
    this.option_ColorDistribution = {
      tooltip: {
        show: true,
        trigger: "item",
        confine: true,
        borderColor: "transparent",
        extraCssText: "box-shadow: none; border: none; background-color: transparent;",
        backgroundColor: "transparent",
        formatter: (params: any) => {
          if (!this.colorDistributionName || this.colorDistributionName.length <= params.dataIndex) {
            return "";
          }

          const colorDistributionName = this.colorDistributionName[params.dataIndex];
          const color = this.colorMap[colorDistributionName] || "#000";
          const count = this.colorDistributionCount[params.dataIndex];

          return `
            <div style="display: flex; flex-direction: column; font-family: Zen Kaku Gothic Antique;
                      background-color: white; padding: 10px; border-radius: 5px;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); width: 7vw">
              <div style="display: flex; flex-direction: column; color: ${color}; gap: max(0.3vw, 5px)">
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                      ${colorDistributionName}
                  </span>
                  <span style="font-size: max(0.9vw, 20px); font-weight: 700; line-height: 130.4%; color: ${color};">
                      ${count}
                  </span>
                  <span style="font-size: max(0.9vw, 10px); font-weight: 400; line-height: 130.4%;">
                     ${params.percent.toFixed(2)}%
                  </span>
              </div>
            </div>`;
        },
      },
      series: [
        {
          name: "Access From",
          type: "pie",
          radius: ["80%", "90%"],
          color: this.colorDistributionName.map((name: string) => this.colorMap[name] || "#000"),
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: "50%",
          },
          emphasis: {
            scaleSize: 2,
            itemStyle: {
              shadowColor: "rgba(0,0,0,0.25)",
              shadowBlur: 5,
            },
          },
          label: {
            show: false,
            position: "center",
          },
          labelLine: {
            show: false,
          },
          data: this.colorDistributionPer,
        },
      ],
    };
  }

  changeInfo() {
    this.front = !this.front;
  }



  getRmasDistributionColor(index: number): string {
    const baseColors = ["#e31482", "#7797b8"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[2];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
      return this.adjustColorWithPhase(startColor, transitionStep * 2, "#0dccff");
    } else if (transitionStep < 0.75) {
      return this.adjustColorWithPhase("#0dccff", (transitionStep - 0.5) * 4, "#87cefa"); // Tono intermedio claro
    } else {
      return this.adjustColorWithPhase("#87cefa", (transitionStep - 0.75) * 4, "#d3d3d3"); // Gris claro
    }
  }

  getSizeDistributionColor(index: number): string {
    const baseColors = ["#001933", "#054280", "#0b84ff", "#0dccff", "#b01065", "#e31482", "#7797b8", "#c4d9ee"];

    if (index < baseColors.length) {
      return baseColors[index];
    }

    const maxIndex = 20;
    const startColor = baseColors[2];

    const transitionStep = (index - baseColors.length) / (maxIndex - baseColors.length);

    if (transitionStep < 0.5) {
      return this.adjustColorWithPhase(startColor, transitionStep * 2, "#0dccff");
    } else if (transitionStep < 0.75) {
      return this.adjustColorWithPhase("#0dccff", (transitionStep - 0.5) * 4, "#87cefa"); // Tono intermedio claro
    } else {
      return this.adjustColorWithPhase("#87cefa", (transitionStep - 0.75) * 4, "#d3d3d3"); // Gris claro
    }
  }

  getColorDistributionColor(index: number): string {

    return this.colorMap[this.colorDistributionName[index]] || "#000"; // Color por defecto
  }

  getColorFromName(name: string): string {
    return this.colorMap[name] || "#000"; // Si el nombre no coincide, devuelve un color por defecto (#000)
  }

  adjustColorWithPhase(startHex: string, step: number, endHex: string): string {
    let [r1, g1, b1] = [
      parseInt(startHex.slice(1, 3), 16),
      parseInt(startHex.slice(3, 5), 16),
      parseInt(startHex.slice(5, 7), 16),
    ];
    let [r2, g2, b2] = [
      parseInt(endHex.slice(1, 3), 16),
      parseInt(endHex.slice(3, 5), 16),
      parseInt(endHex.slice(5, 7), 16),
    ];

    const r = Math.round(r1 + (r2 - r1) * step);
    const g = Math.round(g1 + (g2 - g1) * step);
    const b = Math.round(b1 + (b2 - b1) * step);

    return `#${[r, g, b].map((x) => x.toString(16).padStart(2, "0")).join("")}`;
  }

  //SIZE BAR CHART

  getSizeBars() {
    const ringSizes = this.sizeBarsDistributionName;
    const totalPeoplePerSize = this.sizeBarsDistributionPer;
    const colors = ["#001933", "#054280", "#0B84FF", "#0DCCFF", "#B01065", "#E31482", "#7797B8", "#C4D9EE"];

    const combinedData = ringSizes.map((size, index) => ({
      ringSize: size,
      people: totalPeoplePerSize[index],
      color: colors[index],
    }));

    const sortedData = combinedData.sort((a, b) => b.people - a.people);

    const sortedRingSizes = sortedData.map((item) => item.ringSize);
    const sortedPeople = sortedData.map((item) => item.people);
    const sortedColors = colors;

    this.sizeDataChart = {
      labels: sortedRingSizes,
      datasets: [
        {
          data: sortedPeople,
          backgroundColor: sortedColors,
          borderRadius: 100,
          barThickness: 12,
        },
      ],
    };

    this.barSize = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.raw} personas`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          ticks: {
            color: "#7797B8",
            font: {
              family: "Zen Kaku Gothic Antique",
            },
          },
          grid: {
            display: false,
          },
          border: {
            display: true,
            color: " #edf2f9",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
        },
      },
    };
  }

  getColorBars() {
    const ringColors = this.colorBarsDistributionName;
    const totalPeoplePerSize = this.colorBarsDistributionPer;
    const colorMap: { [key: string]: string } = {
      "Rose Gold": "#f9bc95",
      "Gold": "#e7c400",
      "Silver": "#7797b8",
    };

    // Convertir los nombres a sus colores correspondientes
    const colors = ringColors.map(color => colorMap[color] || "#000000");

    const combinedData = ringColors.map((color, index) => ({
      ringSize: color,
      people: totalPeoplePerSize[index],
      color: colors[index],
    }));

    const sortedData = combinedData.sort((a, b) => b.people - a.people);

    const sortedRingColors = sortedData.map((item) => item.ringSize);
    const sortedPeople = sortedData.map((item) => item.people);
    const sortedColors = colors;

    this.colorDataChart = {
      labels: sortedRingColors,
      datasets: [
        {
          data: sortedPeople,
          backgroundColor: sortedColors,
          borderRadius: 100,
          barThickness: 22,
        },
      ],
    };

    this.colorBarSize = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.raw} personas`;
            },
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: true,
          ticks: {
            color: "#7797B8",
            font: {
              family: "Zen Kaku Gothic Antique",
            },
          },
          grid: {
            display: false,
          },
          border: {
            display: true,
            color: " #edf2f9",
          },
        },
        y: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth: 0,
        },
      },
      layout: {
        padding: {
          top: 0,
          bottom: 0,
        },
      },
    };
  }
}
