import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { MainServiceService } from "src/app/main-service.service";
import { MovanoService } from "src/app/movano.service";
import { organizationPatchData, organizations } from "src/app/shared/movano-interfaces";

@Component({
  selector: "app-action-confirmation-dialog",
  templateUrl: "./action-confirmation-dialog.component.html",
  styleUrls: ["./action-confirmation-dialog.component.scss"],
})
export class ActionConfirmationDialogComponent {
  protected isEditMode: boolean = false;
  protected isRecoverQuestionMode: boolean = false;
  protected isDeletedMode: boolean = false;
  protected isRecoverMode: boolean = false;
  protected isAddUser: boolean = false;
  organizationData: any;
  organizationName: string='';

  @Input() isRecoverQuestion: boolean = false;
  @Input() isRecover: boolean = false;

  constructor(
    protected dialog: MatDialog,
    private dialogRef: MatDialogRef<ActionConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isEditMode: boolean;
      isRecoverQuestionMode: boolean;
      isRecoverMode: boolean;
      isDeletedMode: boolean;
      organizationData?: any;
      isAddUser: boolean;
    },
    private router: Router,
    protected movanoService: MovanoService,
    private dialogCommunicationService: MainServiceService
  ) {
    this.isEditMode = data.isEditMode;
    this.isRecoverQuestionMode = data.isRecoverQuestionMode;
    this.isRecoverMode = data.isRecoverMode;
    this.isDeletedMode = data.isDeletedMode;
    this.organizationData = data.organizationData || {};
    this.isAddUser = data.isAddUser
    if (this.isRecoverQuestionMode) this.organizationName = data.organizationData.name || {};
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  get message(): string {
    if (this.isEditMode) return "Organization updated!";
    if (this.isDeletedMode) return "Organization deleted!";
    if (this.isRecoverQuestionMode) return `Are you sure you want to recover ${this.organizationName}?`;
    if (this.isRecoverMode) return "Organization recovered!";
    if (this.isAddUser) return "Movano user created!"
    if (this.isAddUser && this.isEditMode) return "Movano user updated!"
    return "Organization created!";
  }

  get labelButton(): string {
    if (this.isRecoverQuestionMode) return "Recover organization";
    if (this.isAddUser) return "Back to Movano users"
    return "Back to organization";
  }

  toggleRecoverMode(): void {
    if (this.isRecoverQuestionMode) {
      const updatedData: organizationPatchData = {
        ...this.data.organizationData,
        organizationStatusId: 1,
      };

      this.movanoService.patchOrganization(updatedData, this.organizationData.id).subscribe({
        next: () => {
          this.dialogRef.close();
          this.dialog.open(ActionConfirmationDialogComponent, {
            data: {
              width: "356px",
              isRecoverMode: true,
            },
          });
          this.dialogCommunicationService.emitOrganizationUpdated();
        },
        error: (err) => {
          console.error('Error updating organization:', err);
        }
      });

    } else {
      this.dialogRef.close();
      this.router.navigate(["organizations"]);
    }
  }

  handleAddUserClick(): void {
    this.closeDialog();
    this.router.navigate(['admin-users']);
  }
}
