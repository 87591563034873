import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(){
    let dark = localStorage.getItem('darkMode');
    if(!dark) {
      dark = 'false';
      localStorage.setItem('darkMode', 'false');
    }

    this.setVariable(dark === 'true');
  }

  getVariable() {
    return this.darkMode.asObservable();
  }

  setVariable(valor: boolean) {
    this.darkMode.next(valor);
    localStorage.setItem('darkMode', (valor) ? 'true' : 'false');
    document.body.style.backgroundColor = valor ? '#0c1d35' : '#f3f9fe';
  }
}
