import { Component } from '@angular/core';

@Component({
  selector: 'app-cognito-user-table',
  templateUrl: './cognito-user-table.component.html',
  styleUrls: ['./cognito-user-table.component.scss']
})
export class CognitoUserTableComponent {

}
