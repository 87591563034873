import { Component, OnInit, ViewChild } from '@angular/core';
import { ntfListTable } from 'src/app/shared/movano-interfaces';
import { DarkModeService } from '../../dark-mode.service';
import * as lit from '../../shared/movano-literals';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MovanoService } from 'src/app/movano.service';
import { MatDialog } from '@angular/material/dialog';
import { NtfNotificationsListMessageComponent } from './message/ntf-notifications-list-message.component';
import { Renderer2, ElementRef } from '@angular/core';
import { NotificationListFilterComponent } from './filter/notification-list-filter.component';

@Component({
  selector: 'ntf-notification-list',
  templateUrl: './ntf-notification-list.component.html',
  styleUrls: ['./ntf-notification-list.component.scss']
})
export class NtfNotificationListComponent implements OnInit {

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false;
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected ntfLists: ntfListTable[] = [];
  filteredUsers: ntfListTable[] = [];
  isFiltered: boolean = false;
  protected type: string = 'notifications';
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 50;
  protected dataAvailable : boolean = false;
  public jobId?: string;
  public name?: string;

  sortedColumn = '';
  sortDirection: 'asc' | 'desc' = 'asc';

  protected maxPagesToShow = 5;
  totalPages: number = 0;
  actualPage: number = 1;
  @ViewChild(NotificationListFilterComponent) childComponent!: NotificationListFilterComponent;


  protected statusColor : Map<string, string> = new Map<string, string>([
    ['dev', 'green'],
    ['staging', 'orange'],
    ['prod', 'blue']
  ])

  constructor(
    protected dialog: MatDialog,
    private route: ActivatedRoute,
    protected movanoService: MovanoService,
    private renderer: Renderer2,
    private el: ElementRef,
    private darkModeSvc: DarkModeService,
  ) {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.jobId = params['jobId'];
      console.log(`Job ID from params: ${this.jobId}`);
    });

    this.route.queryParams.subscribe(params => {
      this.name = params['insightAppName'];
      console.log(`insightAppName from params: ${this.name}`);
    });
    this.onTotalPagesChange(this.totalPages)
    this.sort('insightName');
  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }

  onNtfListsChanged(updatedNtfLists: ntfListTable[]) {
    this.ntfLists = updatedNtfLists;
  }
  onLoadingDataChange(loading: boolean) {
    this.loadingData = loading;
}

onDataAvailable(data: boolean){
  this.dataAvailable = data;
}
onFilter(event: any) {
  this.filteredUsers = event.filteredValue;
  this.isFiltered = this.filteredUsers.length !== this.ntfLists.length;
}


  protected openUserPanel(notification: ntfListTable) {
    this.dialog.open(NtfNotificationsListMessageComponent, {
      data: { notification }
    });
  }

  getNotificationStatus(sentCustomer: boolean) {
    if (sentCustomer) {
      return { text: 'Yes', color: 'rgba(0, 178, 119, 1)' };
    } else {
      return { text: 'No', color: 'rgba(227, 20, 130, 1)' };
    }
  }


  onTotalPagesChange(newTotalPages: number) {
    this.totalPages = newTotalPages;
  }

  onActualPageChange(newActualPage: number) {
    this.actualPage = newActualPage;
  }


  getDisplayedPages(): number[] {
    const pages: number[] = [];

    let startPage = Math.max(1, this.actualPage - Math.floor(this.maxPagesToShow / 2));
    let endPage = Math.min(this.totalPages, startPage + this.maxPagesToShow - 1);

    if (endPage - startPage + 1 < this.maxPagesToShow) {
      startPage = Math.max(1, endPage - this.maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  }

  nextPage() {
    if (this.actualPage < this.totalPages) {
      this.actualPage++;
      this.childComponent.loadData();
    }
  }

  selectPage(page: number) {
    this.actualPage = page;
    this.childComponent.loadData();
  }

  previousPage() {
    if (this.actualPage > 1) {
      this.actualPage--;
      this.childComponent.loadData();
    }
  }

  isFirstPageInRange(): boolean {
    return this.getDisplayedPages().includes(1);
  }

  isLastPageInRange(): boolean {
    return this.getDisplayedPages().includes(this.totalPages);
  }

  shouldShowLeftEllipsis(): boolean {
    return this.actualPage > Math.ceil(this.maxPagesToShow / 2) + 1;
  }

  shouldShowRightEllipsis(): boolean {
    return this.actualPage < this.totalPages - Math.floor(this.maxPagesToShow / 2);
  }

  sort(column: string) {
    console.log('sort')
    if (this.movanoService.currentSortedColumn && this.movanoService.currentSortedColumn !== column) {

      this.movanoService.sortOrder[this.movanoService.currentSortedColumn] = 'none';

      const prevIcon = this.el.nativeElement.querySelector(`#${this.movanoService.currentSortedColumn}-icon`);
      const { fill, transform } = this.movanoService.updateIcon(this.movanoService.currentSortedColumn);
      this.renderer.setStyle(prevIcon, 'fill', fill);
      this.renderer.setStyle(prevIcon, 'transform', transform);
      this.renderer.setStyle(prevIcon, 'transform-origin', 'center');
    }
    this.movanoService.sortTable(this.ntfLists, column);
    const icon = this.el.nativeElement.querySelector(`#${column}-icon`);
    const { fill, transform } = this.movanoService.updateIcon(column);
    this.renderer.setStyle(icon, 'fill', fill);
    this.renderer.setStyle(icon, 'transform', transform);
    this.renderer.setStyle(icon, 'transform-origin', 'center');
  }
}
