import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as lit from '../shared/movano-literals';
import { Router } from '@angular/router';
import { DarkModeService } from '../dark-mode.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../login/auth.service';
import { MovanoService } from '../movano.service';


@Component({
  selector: 'app-features-tab',
  templateUrl: './features-tab.component.html',
  styleUrls: ['./features-tab.component.scss']
})
export class FeaturesTabComponent {
  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  @Output() closeHimself = new EventEmitter<boolean>();
  protected accountType: string = '';

  constructor(
    private router: Router,
    private darkModeSvc: DarkModeService,
    private authService: AuthService,
    protected movanoService: MovanoService,
  ) {

    }

  async ngOnInit() {
    this.accountType =  await this.movanoService.getAccountType();

    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  }

  ngOnDestroy() {
    this.darkModeSub.unsubscribe();
  }

  goTo(_place: string) {
    this.router.navigate([_place]);
  }
}
