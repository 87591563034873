import { Component, Input } from '@angular/core';
import { MovanoService } from '../../../app/movano.service';

@Component({
  selector: 'app-stats-total-info',
  templateUrl: './stats-total-info.component.html',
  styleUrls: ['./stats-total-info.component.scss']
})
export class StatsTotalInfoComponent {

  @Input() darkMode: boolean;
  usersStatsToday: any;

  constructor( private movanoService: MovanoService ) {
    this.darkMode = true;
  }
  ngOnInit(): void {
      this.movanoService.getStatsSummaryUsersToday().subscribe(
        (data) => {
          this.usersStatsToday = data;
        },
        (error) => {
          console.error('Error ', error);
        }
      );
    }
}
