import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { IDynamicScaleProperties } from './app/shared/movano-interfaces';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


/**
 * Dynamically adjusts the scale of an element based on the window size.
 * The scale decreases as the window size reduces, with customizable min and max values.
 *
 *  Use example:
 *  window.addEventListener('resize', () => adjustScale('.movanoStats_Header'));
 *  adjustScale('.movanoStats_Header');
 *
 *
 * @param targetClassName - The CSS class selector of the target element to scale (e.g., '.responsiveScaleElement').
 * @param properties - (Optional) Properties to customize scaling:
 *    - maxScale: Maximum scale value (default: 1.21).
 *    - minScale: Minimum scale value (default: 1.2).
 *    - decayFactor: Controls how quickly the scale decreases as the window size shrinks (default: 1).
 */
export const adjustScale = (targetClassName: string, properties?: IDynamicScaleProperties) => {
  const maxScale = properties?.maxScale ?? 1.21;
  const minScale = properties?.minScale ?? 1;
  const decayFactor = properties?.decayFactor ?? 1.2;

  const maxWidth = 1920;
  const currentWidth = window.innerWidth;

  const scaleValue = Math.max(
    minScale,
    maxScale * Math.pow((currentWidth / maxWidth), decayFactor)
  );

  const statsHeader = document.querySelector(targetClassName) as HTMLElement;
  const parent = statsHeader.parentElement;

  if (statsHeader && parent){
    const isScrollable =
    parent.scrollWidth > parent.clientWidth

    const marginOffset =  parent.scrollWidth - parent.clientWidth;

    statsHeader.style.marginLeft = isScrollable ? `${marginOffset}px` : '0px';
    statsHeader.style.transform = `scale(${scaleValue})`;
    statsHeader.style.display = 'inline-block';
  }

}
