import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MainServiceService {
  private userRangedTimezones: BehaviorSubject<{label: string, offset: string}[]> = new BehaviorSubject<{label: string, offset: string}[]>([]);
  private currentDateOffset = new BehaviorSubject<number>(0);
  offsetObservable$ = this.currentDateOffset.asObservable();
  private organizationUpdatedSource = new Subject<void>();
  private adminUserUpdatedSource = new Subject<void>();
  organizationUpdated$ = this.organizationUpdatedSource.asObservable();
  constructor() { }

  getUserRangedTimezones() {
    return this.userRangedTimezones.asObservable();
  }

  updateUserRangedTimezones(timezones: { label: string; offset: string }[]) {
    this.userRangedTimezones.next(timezones);
  }

  updateDateOffset(value: number) {
    const newValue = this.currentDateOffset.getValue() + value;
    this.currentDateOffset.next(newValue);
  }

  resetDateOffset() {
    this.currentDateOffset.next(0);
  }

  getDateOffset() {
    return this.currentDateOffset.getValue();
  }
  emitOrganizationUpdated() {
    this.organizationUpdatedSource.next();
  }

  emitAdminUserUpdated() {
    this.adminUserUpdatedSource.next();
  }
}
