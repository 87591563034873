<div class="donutsContainer">
  <div class="responsiveScaleDonutsSales">
    <div class="inner-container">
      <div class="movanoStats" style="height: 200px; width: 280px;">
        <span class="movanoStats_Tittle">Sales Distribution</span>
        <div style="display: flex; flex-direction: row">
          <div>
            <div class="movanoStats_DognutStats">
              <div *ngIf="option_SalesDistribution" echarts [options]="option_SalesDistribution"
                style="height: 130px; width: 130px; margin-top: 16px"></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span style="font-size: 167%; font-weight: 700; color: '#00b377'">{{ salesDistributionPer[0] |
                  number:'1.0-0' }}%</span>
                <div>
                  <span style="font-size: 100%; font-weight: 400; color: '#e31482'">{{ salesDistributionPer[1] |
                    number:'1.0-0' }}%</span>
                  <span style="color: #7797b8; font-size: 100%; font-weight: 400">{{ salesDistributionPer[2] |
                    number:'1.0-0' }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="movanoStats_LegendUser" style="gap: 5px; padding-left: 20px; margin-top: 55px">
            <div *ngFor="let salesDistribution of salesDistributionName; let i = index"
              style="display: flex; gap: 8px; color: #022e5c; align-items: center;"
              [style.color]="getSalesDistributionColor(i)">
              <div [style.background-color]="getSalesDistributionColor(i)"
                style="width: 1.5em; border-radius: 0.75em; height: 0.67em"></div>
              {{ salesDistribution || "Others" }}
            </div>
          </div>
        </div>
      </div>
      <!-- SEGUNDO DONUT -->
      <div class="movanoStats" style="height: 200px; width: 600px;">
        <span class="movanoStats_Tittle">Size Distribution</span>
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column;">
            <div class="movanoStats_DognutStats">
              <div *ngIf="option_SizeDistribution" echarts [options]="option_SizeDistribution"
                style="height: 130px; width: 130px; margin-top: 16px "></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span style="font-size: 167%; font-weight: 700; color: '#061E37'">{{ sizeDistributionPer[0] |
                  number:'1.0-0'}}%</span>
                <div style="display: flex; gap: 0.33em">
                  <span style="font-size: 100%; font-weight: 400">{{ sizeDistributionPer[1] | number:'1.0-0'}}%</span>
                  <span style="color: #0b84ff; font-size: 100%; font-weight: 400">{{ sizeDistributionPer[2] |
                    number:'1.0-0'}}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="sizeChart">
          <p-chart type="bar" [data]="sizeDataChart" id="sizeChart" width="400px" height="7em" [options]="barSize">
          </p-chart>
        </div>

      </div>

      <!-- TERCER DONUT -->
      <div class="movanoStats" style="height: 200px; width: 420px;">
        <span class="movanoStats_Tittle">Color Distribution</span>
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column;">
            <div class="movanoStats_DognutStats">
              <div *ngIf="option_ColorDistribution" echarts [options]="option_ColorDistribution"
                style="height: 130px; width: 130px; margin-top: 16px "></div>
              <div class="movanoStats_DognutStats_Highlight">
                <span style="font-size: 167%; font-weight: 700; " [style.color]="colorDistributionText[0]">{{
                  colorDistributionPer[0] | number:'1.0-0' }}%</span>
                <div style="display: flex; gap: 0.33em">
                  <span style="font-size: 100%; font-weight: 400;" [style.color]="colorDistributionText[1]">{{
                    colorDistributionPer[1] | number:'1.0-0'}}%</span>
                  <span style=" font-size: 100%; font-weight: 400" [style.color]="colorDistributionText[2]">{{
                    colorDistributionPer[2] | number:'1.0-0'}}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="colorChart">
          <p-chart type="bar" [data]="colorDataChart" id="colorChart" width="200px" height="7em"
            [options]="colorBarSize">
          </p-chart>
        </div>
      </div>
    </div>
  </div>
</div>
