<div class="movanoStats_Header">
  <div class="movanoCarousel_Element" >
    <span class="movanoCarousel_Element_Number" style="color:#284767">{{this.sleepAnalysis}}</span>
    <div class="movanoCarousel_Element_Info" >
      <span>sleep analysis</span>
      <span>{{dynamicText}}</span>
    </div>
    <div class="vertical-line"></div>

    <span class="movanoCarousel_Element_Number" style="color:#93a2b2">{{this.successAnalysis}}%</span>
    <div class="movanoCarousel_Element_Info">
      <span>successful</span>
      <span>analysis</span>
    </div>
  </div>
  <div class="movanoCarousel_Element" >
    <span class="movanoCarousel_Element_Number" style="color:#0b84ff; font-weight: bold;">{{this.spotChecks}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>spot checks</span>
      <span>{{dynamicText}}</span>
    </div>
    <div class="vertical-line"></div>

    <span class="movanoCarousel_Element_Number" style="color:#0b84ff">{{this.avrgSpotPerDay}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>average</span>
      <span>per day</span>
    </div>
  </div>
  <div class="movanoCarousel_Element" >
    <span class="movanoCarousel_Element_Number" style="color:#00b377; font-weight: bold;">{{workout}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>workout</span>
      <span>{{this.dynamicText}}</span>
    </div>
    <div class="vertical-line"></div>

    <span class="movanoCarousel_Element_Number" style="color:#00b377">{{this.avrgWorkPerDay}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>average</span>
      <span>per day</span>
    </div>
  </div>
  <div class="movanoCarousel_Element" style="padding: 0 1%; ">
    <span class="movanoCarousel_Element_Number" style="color:#ff830d; font-weight: bold;">{{notifications}}</span>
    <div class="movanoCarousel_Element_Info">
      <span>notifications</span>
      <span>{{dynamicText}}</span>
    </div>
  </div>
</div>
