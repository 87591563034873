<div class="movanoSpotCheck">
  <div class="movanoSpotCheck_Header">
    <div class="movanoSpotCheck_Header_Buttons">
   <!--    <button (click)="previusDate()">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 8.75L4.7875 8.75L11.775 1.7625L10 1.74846e-06L-1.74846e-06 10L10 20L11.7625 18.2375L4.7875 11.25L20 11.25L20 8.75Z"
            [ngStyle]="{fill : '#0B84FF'}" />
        </svg>
      </button>
      <button [disabled]="!nextIsPosible" (click)="nextDate()">
        <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.64949e-07 11.25L15.2125 11.25L8.225 18.2375L10 20L20 10L10 -8.74228e-07L8.2375 1.7625L15.2125 8.75L9.83506e-07 8.75L7.64949e-07 11.25Z"
            [ngStyle]="{fill : (nextIsPosible) ? '#0B84FF' : '#AABED3'}" />
        </svg>
      </button> -->
    </div>
    <div class="movanoSpotCheck_Header_Measures">
      <div *ngFor="let label of filteredMeasuresLabels(); let i = index"
        [ngStyle]="{
          'font-weight': (measuresSelected[i]) ? '700' : '400',
          'boxShadow': (measuresSelected[i]) ? 'none' : '',
          'background': (measuresSelected[i]) ? 'transparent' : '',
          'color': (measuresSelected[i]) ? measureColors[label][0] : ''
        }"
        class="movanoSpotCheck_Header_Measures_Label"
        (click)="changeMeasure(i)">
        {{ label }}
      </div>
      <div class="movanoSpotCheck_Header_Measures">
        <div class="movano_switch lp">
          <label class="switch" *ngIf="measuresSelectedLP !== undefined">
            <input type="checkbox" [checked]="measuresSelectedLP" (change)="toggleMeasureLP()">
            <span class="slider round"></span>
          </label>
        </div>
        <div class="movanoSpotCheck_Header_Measures_LP" (click)="toggleMeasureLP()" *ngIf="measuresSelectedLP !== undefined">
          Low perfusion
        </div>
      </div>
    </div>
    <a (click)="returnToLastChart()">{{returnToLabel()}}</a>
   <!--  <app-user-panel-time-selector #spot_calendar (initGetInfo)="getInfo($event)"
      [timeZones]="timeZones"></app-user-panel-time-selector> -->
  </div>


  <div *ngIf="loadingData; else dataLoaded" class="movanoSpotCheck_NoData">
    <p-progressSpinner [style]="{width: '3vw', height: '3vw', position: 'absolute', left : '38.5vw'}"
      styleClass="custom-spinner-blue" strokeWidth="2" animationDuration="1.5s">
    </p-progressSpinner>
  </div>
  <ng-template #dataLoaded>
    <div *ngIf="dataAvailable; else noData" class="movanoSpotCheck_Body">
      <div class="movanoSpotCheck_Body_Block01">
        <div *ngIf="slctdDateOpt !== 0; else dayData"  class="movanoSpotCheck_Zones">
          <div class="movanoSpotCheck_Zones_Total">
            <span class="movanoSpotCheck_Zones_Total_Title">Total Spot Checks</span>
            <span class="movanoSpotCheck_Zones_Total_Number">{{spotCheckDaily.spotchecks_count}}</span>
          </div>
          <div class="movanoSpotCheck_Zones_LowPrfsn">
            <span class="movanoSpotCheck_Zones_LowPrfsn_Title">Low perfusion</span>
            <span class="movanoSpotCheck_Zones_LowPrfsn_Number">{{spotCheckDaily.low_perfusion_count}}</span>
            <span class="movanoSpotCheck_Zones_LowPrfsn_Title">{{spotCheckDaily.low_perfusion_percentage}}%</span>
          </div>
        </div>
        <div class="movanoSpotCheck_History">
          <span class="movanoSpotCheck_History_Title"> Spot Check History</span>
          <div class="movanoSpotCheck_History_Scroll" *ngIf="slctdDateOpt !== 0; else oneDayHistory">
            <div class="movanoSpotCheck_History_Scroll_Line movanoSpotCheck_History_Scroll_LineDays">
              <span class="movanoSpotCheck_History_Scroll_Line_Date">Date</span>
              <span style="font-weight: 700; color: #054280;"> Checks</span>
              <span style="font-weight: 700; color: #00B277;">HR</span>
              <span style="font-weight: 700; color: #0B84FF;">SpO2</span>
              <span style="font-weight: 700; color: #E31482;">LP</span>
            </div>
             <div *ngFor="let spotCheck of spotCheckDaily.measurement_list let i = index">
              <div *ngIf="spotCheck.spotchecks_count > 0" class="movanoSpotCheck_History_Scroll_Line" [ngStyle]="{backgroundColor: (i%2 != 0)?'#e7eef6' : 'unset'}" style="grid-template-columns: auto 1fr 1fr 1fr 1fr">
                <span class="movanoSpotCheck_History_Scroll_Line_Date">{{spotCheck.date  | date : 'MM/dd/yy'}}</span>
                <span  style="font-weight: 500; color: #054280;">{{spotCheck.spotchecks_count}}</span>
                <span style="font-weight: 500; color: #00B277;">
                  {{ spotCheck.hr_avg !== null ? spotCheck.hr_avg : 'No Data' }}
                </span>
                <span style="font-weight: 500; color: #0B84FF;">
                  {{ spotCheck.spO2_avg !== null ? spotCheck.spO2_avg : 'No Data' }}
                </span>
                <span style="font-weight: 500; color: #E31482;">{{spotCheck.low_perfusion_count}}</span>
              </div>
            </div>
          </div>
          <ng-template #oneDayHistory>
            <div class="movanoSpotCheck_History_Scroll">
              <div *ngFor="let spotCheck of spotCheckHourly.measurement_list; let i = index">
                  <div class="movanoSpotCheck_History_Scroll_Line" [ngStyle]="{backgroundColor: (i%2 != 0)?'#e7eef6' : 'unset'}">
                    <span class="movanoSpotCheck_History_Scroll_Line_Date">{{spotCheck.date  | date : 'M/d/yy - HH:mm:ss' : 'UTC'}}</span>
                    <span class="movanoSpotCheck_History_Scroll_Line_Measure" [ngStyle]="{color: (spotCheck.measure_label == 'Spot HR')?'#00B277' : '#0B84FF'}"> {{spotCheck.measure_label.replace('Spot ', '')}} </span>
                    <span class="movanoSpotCheck_History_Scroll_Line_Value">{{spotCheck.measure_value}}</span>
                    <span *ngIf="spotCheck.low_perfusion" class="movanoSpotCheck_History_Scroll_Line_LP">LP</span>
                  </div>
              </div>
            </div>
          </ng-template>
          <ng-template #dayData>
            <div   class="movanoSpotCheck_Zones">
          <div class="movanoSpotCheck_Zones_Total">
            <span class="movanoSpotCheck_Zones_Total_Title">Total Spot Checks</span>
            <span class="movanoSpotCheck_Zones_Total_Number">{{spotCheckHourly.spotchecks_count}}</span>
          </div>
          <div class="movanoSpotCheck_Zones_LowPrfsn">
            <span class="movanoSpotCheck_Zones_LowPrfsn_Title">Low perfusion</span>
            <span class="movanoSpotCheck_Zones_LowPrfsn_Number">{{spotCheckHourly.low_perfusion_count}}</span>
            <span class="movanoSpotCheck_Zones_LowPrfsn_Title">{{spotCheckHourly.low_perfusion_percentage}}%</span>
          </div>
        </div>

          </ng-template>
        </div>
      </div>
      <div class="movanoSpotCheck_Graph">
        <span class="date-tag">{{dateTag}}</span>

        <div class="movanoSpotCheck_Graph_Chart" echarts (chartClick)="onChartEvent($event, 'chartClick')"
          [options]="options_SleepData">
        </div>
        <app-chart-time-arrows [calendarData]="calendarData"></app-chart-time-arrows>
      </div>
    </div>
    <ng-template #noData>
      <div class="movanoSpotCheck_NoData">
        <span>NO DATA AVAILABLE</span>
      </div>
    </ng-template>
  </ng-template>
</div>
