import { Component, Inject, Input } from '@angular/core';
import { deviceTable } from 'src/app/shared/movano-interfaces';
import { ticketsTable } from 'src/app/shared/movano-interfaces';
import * as lit from '../../shared/movano-literals';
import { DarkModeService } from '../../dark-mode.service';
import { Subscription } from 'rxjs';
import { MovanoService } from 'src/app/movano.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { UsersBugsInfoComponent } from '../users-bugs-info/users-bugs-info.component';

@Component({
  selector: 'app-users-bugs',
  templateUrl: './users-bugs.component.html',
  styleUrls: ['./users-bugs.component.scss']
})
export class UsersBugsComponent {
  protected devices : deviceTable[] = [];
  protected tickets: ticketsTable[] = [];
  protected LITERALS = lit;
  protected loadingUserDeviceList : boolean = true;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();



  constructor(private darkModeSvc: DarkModeService, protected movanoService: MovanoService,
              protected dialogRef: MatDialogRef<deviceTable>,
              protected dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) protected data: { user: string })
    {
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
     this.getBugsList();
     this.getUserDeviceList();
  }

   async getBugsList() {
    this.loadingUserDeviceList = true;
    this.movanoService.getTicketsTable().subscribe((res: any) => {
      this.tickets = res.tickets;
      this.loadingUserDeviceList = false;
      console.log(res);
    });
  }


  async getUserDeviceList() {
    this.loadingUserDeviceList = true;
    this.movanoService.getDevicesByCustomerUUIDorDeviceSerial(this.data.user).subscribe((res: any) => {
      this.devices = res;
      this.loadingUserDeviceList = false;
      console.log(this.data.user);
    });
  }

  protected closePanel() {
    this.dialogRef.close();
  }

  protected openBugsInfoPanel (_userId: string){
    this.dialog.open(UsersBugsInfoComponent, {data: {user: _userId}});
    console.log(this.data.user);
    this.closePanel();
  }


}






