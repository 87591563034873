<div class="container">
  <div
    [ngStyle]="{
      display: 'flex',
      'justify-content': 'end',
      position: 'relative',
      left: '20px',
      top: '-5px',
      opacity: '0.5'
    }"
  >
    <mat-icon (click)="closeDialog()" [ngStyle]="{ cursor: 'pointer' }">close</mat-icon>
  </div>
  <div class="svg-icon">
    <img src="../../../../assets/delete.svg" width="32" height="32" alt="Delete Circle" />
  </div>
  <p class="title">Are you sure you want to delete "{{organzationName}}"?</p>
  <p class="writeDelete">Write "DELETE" to confirm</p>
  <input
    type="text"
    [(ngModel)]="confirmationText"
    placeholder='Write "DELETE"'
    [style.width]="'100%'"
    [style.margin-bottom]="'10px'"
  />
  <app-main-button
    label="Delete organization"
    [fullWidth]="true"
    color="red"
    [disabled]="confirmationText !== 'DELETE'"
    (click)="confirmationText === 'DELETE' ? deleteForSureOrganization() : null"
  ></app-main-button>
</div>
