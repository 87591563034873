import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-view-organization',
  templateUrl: './change-view-organization.component.html',
  styleUrls: ['./change-view-organization.component.scss']
})
export class ChangeViewOrganizationComponent {
  constructor(private dialogRef: MatDialogRef<ChangeViewOrganizationComponent>) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
