<button *ngIf="percentage == -1; else Loading" 
class="movanoButton {{outlined ? 'outlined' : ''}} {{color}} {{size}} " [disabled]="disable??false">
  <span>{{buttonText}}</span>
</button>

<ng-template #Loading>
  <button class="movanoButton {{outlined ? 'outlined' : ''}} {{size}} " [disabled]="disable??false">
    <span style="top: calc(50% - 7px);" >{{buttonText}}</span>
    <div *ngIf="!outlined" class="{{color}} {{size}}" [ngStyle]="{'width' : percentage+'%'}"></div>
  </button>
</ng-template>