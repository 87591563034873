import { AfterViewInit, Component, OnInit, Input, Inject } from '@angular/core';
import { ntfTable  } from 'src/app/shared/movano-interfaces';
import { DarkModeService } from '../../dark-mode.service';
import * as lit from '../../shared/movano-literals';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MovanoService } from 'src/app/movano.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
//import { NtfNotificationsListMessageComponent } from './message/ntf-notifications-list-message.component';
import { Renderer2, ElementRef } from '@angular/core';
import { UsersNtfListMessageComponent } from './message/users-ntf-list-message.component';
//import { NotifiationListSideFilterComponent } from './side-filter/notifiation-list-side-filter.component';

@Component({
  selector: 'app-users-notifications-list',
  templateUrl: './users-notifications-list.component.html',
  styleUrls: ['./users-notifications-list.component.scss']
})
export class UsersNotificationsListComponent {

  protected LITERALS = lit;
  protected darkMode: boolean = false;
  private darkModeSub: Subscription = new Subscription();
  protected menuDisplay: boolean = false;
  protected userDisplay: boolean = false;
  protected loadingData: boolean = false;
  protected filterDisplay: boolean = false;
  protected ntfLists: ntfTable [] = [];
  protected type: string = 'notifications';
  protected pageSelected: number = 0;
  protected firstPage: number = 0;
  protected lastPage: number = 0;
  protected rows: number = 16;
  protected dataAvailable : boolean = false;
  public jobId?: string;
  public name?: string; 
   user: string;
   filteredUsers: ntfTable [] = [];
   isFiltered: boolean = false;
  



  protected statusColor : Map<string, string> = new Map<string, string>([
    ['dev', 'green'],
    ['staging', 'orange'],
    ['prod', 'blue']
  ])

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ntfTable >,
    private route: ActivatedRoute,
    protected movanoService: MovanoService,
    private renderer: Renderer2, private el: ElementRef,
    private darkModeSvc: DarkModeService,
    @Inject(MAT_DIALOG_DATA) protected data: { user: string }) {
      this.user = data.user;
    this.darkModeSub = this.darkModeSvc.getVariable().subscribe((val: any) => {
      this.darkMode = val;
    });
  
  
  }

  ngOnInit(): void {

  }

  updatePaginator(data: number[]) {
    this.rows = data[0];
    this.pageSelected = data[1];
  }

  onNtfListsChanged(updatedNtfLists: ntfTable []) {
    this.ntfLists = updatedNtfLists;
  }
   
  onLoadingDataChange(loading: boolean) {
    this.loadingData = loading;
}

onDataAvailable(data: boolean){
  this.dataAvailable = data;
}
protected closePanel() {
  this.dialogRef.close();
}


protected openUserPanel(notification: ntfTable ) {
  this.dialog.open(UsersNtfListMessageComponent, {
    data: { notification }
  });
  //this.closePanel(); 
}
onFilter(event: any) {
  this.filteredUsers = event.filteredValue;
  this.isFiltered = this.filteredUsers.length !== this.ntfLists.length;
}


  getNotificationStatus(sentCustomer: boolean) {
    if (sentCustomer) {
      return { text: 'Yes', color: 'rgba(0, 178, 119, 1)' };
    } else {
      return { text: 'No', color: 'rgba(227, 20, 130, 1)' };
    }
  } 
}



